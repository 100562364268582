import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "@fontsource/poppins";
import App from './App';
import {Provider} from 'react-redux'
import store from './redux/store'
import reportWebVitals from './reportWebVitals';
import Login from './routes/Onboarding/UserLogin'
import Home from './routes/Home'
import Dashboard from './routes/Dashboard/Dashboard'
import Vehicles from './routes/Vehicles/Vehicles'
import Reservations from './routes/Reservations/Reservations'
import Settings from './routes/Settings/Settings'
import NewReservation from './routes/Reservations/NewReservation'
import NewChauffeur from './routes/Reservations/NewChauffeur'
import Calendar from './routes/Reservations/Calendar'
import Analytics from './routes/Analytics/Analytics'
import CheckInCheckOut from './routes/Reservations/CheckInCheckOut'
import ClientsList from './routes/Clients/ClientsList'
import NewClient from './routes/Clients/NewClient'
import ClaimsList from './routes/Claims/Claims'
import NewClaim from './routes/Claims/NewClaim'
import GPSHome from './routes/GPS/GPSHome'
import PaymentsHome from './routes/Payments/PaymentsHome'
import NewVehicle from './routes/Vehicles/NewVehicle'
import NewMaintenance from './routes/Vehicles/NewMaintenance'
import PartnerProfile from './routes/Clients/PartnerProfile'
import NewPartner from './routes/Clients/NewPartner';
import Notifications from './routes/Notifications/Notifications'
import NewExpense from './routes/Vehicles/NewExpense'
import Leads from './routes/Leads/Leads'

import {BrowserRouter, Route, Routes} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/home" element={<Home/>}>
          <Route path="overview" element={<Dashboard/>}/>
          <Route path="reservations" element={<Reservations/>}/>
          <Route path="calendar" element={<Calendar/>}/>
          <Route path="new-reservation" element={<NewReservation/>}/>
          <Route path="new-chauffeur" element={<NewChauffeur/>}/>
          <Route path="availability" element={<NewReservation/>}/>

          <Route path="reservation/check-in" element={<CheckInCheckOut/>}/>
          <Route path="reservation/check-out" element={<CheckInCheckOut/>}/>

          <Route path="payments" element={<PaymentsHome/>}/>
          <Route path="gps" element={<GPSHome/>}/>
          <Route path="claims" element={<ClaimsList/>}/>
          <Route path="claims/new-claim" element={<NewClaim/>}/>
          <Route path="clients" element={<ClientsList/>}/>
          <Route path="clients/new-client" element={<NewClient/>}/>
          <Route path="clients/update-client" element={<NewClient/>}/>
          <Route path="clients/partner-profile" element={<PartnerProfile/>}/>
          <Route path="clients/new-partner" element={<NewPartner/>}/>

          <Route path="vehicles" element={<Vehicles/>}/>
          <Route path="new-vehicle" element={<NewVehicle/>}/>
          <Route path="update-vehicle" element={<NewVehicle/>}/>
          <Route path="new-expense" element={<NewExpense/>}/>
          <Route path="update-expense" element={<NewExpense/>}/>
          <Route path="new-maintenance" element={<NewMaintenance/>}/>
          <Route path="update-maintenance" element={<NewMaintenance/>}/>

          <Route path="analytics" element={<Analytics/>}/>
          <Route path="settings" element={<Settings/>}/>
          <Route path="notifications" element={<Notifications/>}/>
          <Route path="leads" element={<Leads/>}/>
          {/*<Route path="rooms" element={<Rooms />} >
                <Route path=":roomId" element={<Room />} />
              </Route>*/}
        </Route>
        <Route
          path="*"
          element={
            <main style={{padding: "1rem"}}>
              <h1>404 not found</h1>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
