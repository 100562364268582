const colors = {
	theme: '#23395D',
	buttonTheme: '#5481CB',
	themeLight: '#5481CB',
	buttonThemeLight: '#5481CB',
	buttonTheme_Light: '#AFC2E1',
	tabTheme: '#5481CB',
	backgroundColor: '#ffffff',
	primaryTextColor: '#23395D',
	secondaryTextColor: '#A0A3BD',
	tertiaryTextColor: '#ffffff',
	darkTextColor: '#23395D',
	contentBackgroundColor: '#FFFFFF',
	tabButtonTheme: '#5481CB',
	tableLineColor: '#C7C7C7',
	status_colors: {
		completed: '#50C8FC',
		waiting: '#A996FF',
		renting: '#A6F787',
		cancelled: '#ff0000'
	},
	availableColor: '#A6F787',
	unavailableColor: '#FFDF9A',
	notifBackgroundColor: '#5481CB44'
}

export default colors
