import React,{useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 600,
	bgcolor: 'background.paper',
	border: '0px solid #000',
	boxShadow: 24,
	p: 1,
	borderColor: 'white',
};


export default function CustomModal({show, handleClose, children, containerWidth, containerHeight, containerPadding, backgroundColor}) {
	const [showModal, setShowModal] = useState(false)

	useEffect(() => {
		setShowModal(show)
	},[show])

	return (
		<Modal
			open={showModal}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={[modalStyle,{width: containerWidth || 500, height: containerHeight || '50vh', p: 0, backgroundColor: backgroundColor || 'white'}]}>
				{children}
			</Box>
		</Modal>
	)
}

