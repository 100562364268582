import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {API_CALL_STATUS} from '../../metadata/enums'
import {GET_ALL_CLAIMS, UPDATE_CLAIM, ADD_CLAIM, GET_CLAIM_DETAILS} from '../actionTypes'
import Config from "../../config";
import axios from "axios";
import {LOGGER} from "../../utils/Logger";

const initialState = {
	claims: [],
	claim_to_update: null,
	status: API_CALL_STATUS.IDLE,
	error: null,
	claim_added: false,
	claim_updated: null,
}

export const getAllClaims = createAsyncThunk(GET_ALL_CLAIMS, async (payload) => {
	console.log('token', payload)
	const config = {
		method: 'get',
		url: `${Config.BACKEND_URL}claims`,
		headers: {Authorization: payload.token},
	}

	try {
		let res = await axios(config)
		return res.data
	}catch(err) {
		LOGGER.error('Error when getting all claims', err)
		return []
	}

})

export const getClaimDetails = createAsyncThunk(GET_CLAIM_DETAILS, async (payload) => {
	try {
		const config = {
			method: 'get',
			url: `${Config.BACKEND_URL}claims/${payload._id}`,
			headers: { Authorization: payload.token, contentType: "application/json",},
		}

		let res = await axios(config)
		return res.data
	}catch(err) {
		LOGGER.log('error when adding adding client', err)
	}

	// return order
})

export const addClaimAndConfirm = createAsyncThunk(ADD_CLAIM, async (payload) => {
	try {
		const config = {
			method: 'post',
			url: `${Config.BACKEND_URL}claims`,
			headers: { Authorization: payload.token, contentType: "application/json",},
			data: payload.data
		}

		let res = await axios(config)
		let data = Object.assign({}, payload.data)
		data._id = res.data?._id
		return data
	}catch(err) {
		LOGGER.log('error when adding adding claim', err)
		return null
	}

	// return order
})

export const updateClaimAndConfirm = createAsyncThunk(UPDATE_CLAIM, async (payload) => {
	try {
		let id = payload.data._id
		const config = {
			method: 'put',
			url: `${Config.BACKEND_URL}claims/${id}`,
			headers: { Authorization: payload.token, contentType: "application/json",},
			data: payload.data
		}

		await axios(config)
		return payload.data
	}catch(err) {
		LOGGER.log('error when adding updating client', err)
	}

	// return order
})

const claimsSlice = createSlice({
	name: 'claims',
	initialState,
	reducers: {
		updateUpdatedClaimStatus(state, action) {
			return Object.assign({}, state, {claim_updated: action.payload})
		},
		updateAddedClaimStatus(state, action) {
			return Object.assign({}, state, {claim_added: action.payload})
		},
		updateClaimToUpdate(state, action) {
			return Object.assign({}, state, {claim_to_update: action.payload})
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getAllClaims.rejected, (state, action) => {
				state.status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
			.addCase(getAllClaims.pending, (state, action) => {
				state.status = API_CALL_STATUS.LOADING
			})
			.addCase(getAllClaims.fulfilled, (state, action) => {
				state.status = API_CALL_STATUS.SUCCEEDED
				state.claims = action.payload
			})
			.addCase(getClaimDetails.rejected, (state, action) => {
				state.status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
			.addCase(getClaimDetails.pending, (state, action) => {
				state.status = API_CALL_STATUS.LOADING
			})
			.addCase(getClaimDetails.fulfilled, (state, action) => {
				state.status = API_CALL_STATUS.SUCCEEDED
				state.claim_to_update = action.payload
			})
			.addCase(addClaimAndConfirm.rejected, (state, action) => {
				state.status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
			.addCase(addClaimAndConfirm.pending, (state, action) => {
				state.status = API_CALL_STATUS.LOADING
			})
			.addCase(addClaimAndConfirm.fulfilled, (state, action) => {
				if(action.payload) {
					state.status = API_CALL_STATUS.SUCCEEDED
					state.claims.unshift(action.payload)
					state.claim_added = true
				} else {
					state.status = API_CALL_STATUS.FAILED
					state.error = 'A claim has been already created for the rental'
				}
			})
			.addCase(updateClaimAndConfirm.rejected, (state, action) => {
				state.status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
			.addCase(updateClaimAndConfirm.pending, (state, action) => {
				state.status = API_CALL_STATUS.LOADING
			})
			.addCase(updateClaimAndConfirm.fulfilled, (state, action) => {
				state.status = API_CALL_STATUS.SUCCEEDED
				let updatedClaim = action.payload
				let temp = []

				state.claims.forEach(claim => {
					if(claim._id === updatedClaim._id) {
						temp.push(updatedClaim)
					}
					else
						temp.push(claim)
				})
				state.claims = temp
				state.claim_updated = true
			})
	}
})



export const { addClaim ,updateClaim, updateClaimToUpdate, updateAddedClaimStatus, updateUpdatedClaimStatus} = claimsSlice.actions

export default claimsSlice.reducer
