import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {API_CALL_STATUS} from '../../metadata/enums'
import {FETCH_ALL_LEADS, UPDATE_ONE_LEAD} from '../actionTypes'
import Config from "../../config";
import axios from "axios";
import {LOGGER} from "../../utils/Logger";

const initialState = {
  leads: [],
  status: API_CALL_STATUS.IDLE,
  error: null,
  lead_updated: false
}

export const getAllLeads = createAsyncThunk(FETCH_ALL_LEADS, async (payload) => {
  const config = {
    method: 'get',
    url: `${Config.BACKEND_URL}leads`,
    headers: {Authorization: payload.token},
  }

  try {
    let res = await axios(config)
    return res.data
  } catch (err) {
    LOGGER.error('Error when getting all leads', err)
    return []
  }

})

export const updateOneLead = createAsyncThunk(UPDATE_ONE_LEAD, async (payload) => {
  try {
    let id = payload.data._id
    const config = {
      method: 'put',
      url: `${Config.BACKEND_URL}leads/${id}`,
      headers: {Authorization: payload.token, contentType: "application/json",},
      data: payload.data
    }

    await axios(config)
    console.log('updated')
    return payload.data
  } catch (err) {
    LOGGER.log('error when adding updating lead', err)
  }

  // return order
})

const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    updateLeadUpdateStatus(state, action) {
      return Object.assign({}, state, {lead_updated: action.payload})
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllLeads.rejected, (state, action) => {
        state.status = API_CALL_STATUS.FAILED
        state.error = action.error.message
      })
      .addCase(getAllLeads.pending, (state, action) => {
        state.status = API_CALL_STATUS.LOADING
      })
      .addCase(getAllLeads.fulfilled, (state, action) => {
        state.status = API_CALL_STATUS.SUCCEEDED
        state.leads = action.payload
      })
      .addCase(updateOneLead.rejected, (state, action) => {
        state.status = API_CALL_STATUS.FAILED
        state.error = action.error.message
      })
      .addCase(updateOneLead.pending, (state, action) => {
        state.status = API_CALL_STATUS.LOADING
      })
      .addCase(updateOneLead.fulfilled, (state, action) => {
        state.status = API_CALL_STATUS.SUCCEEDED
        let temp = []
        state.leads.forEach(lead => {
          if (lead._id === action.payload._id)
            temp.push(Object.assign({}, lead, action.payload))
          else
            temp.push(lead)
        })
        state.leads = temp
        state.lead_updated = true
      })
  }
})


export const { updateLeadUpdateStatus } = leadsSlice.actions

export default leadsSlice.reducer
