import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { vehiclesMapSelector } from '../../../redux/slices/vehiclesSlice'
import GeneralStyles from "../../GeneralStyles.module.scss";
import Styles from "../styles/NewClient.module.scss";
import Colors from '../../../config/colors'
import file from '../../../assets/logos/file.svg'

import CustomModal from "../../../components/general/CustomModal";
import CustomButtonContained from "../../../components/general/CustomButtonContained";
import CustomLoaderSpinner from "../../../components/general/CustomLoaderSpinner";
import PdfViewerComponent from "../../../components/PdfViewer/Pdfviewer";



const RenderVehicleInfo = ({ partnerSplits, setActiveVehicles, parentLoading }) => {
    const vehiclesMap = useSelector(state => vehiclesMapSelector(state))

    // for uploading agreements:
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedPdf, setSelectedPdf] = useState('chauffeurPdf.pdf');
    const [showOldFile_and_UploadMenu, setShowOld_and_FileUploadMenu] = useState(false);
    const [showUploadMenuThenFile, setShowUploadMenuThenFile] = useState(false);
    const [selectedPdfData, setSelectedPdfData] = useState(null);

    useEffect(() => {
        if (!parentLoading) {
            // partnerSlice is done loading getting active vehicles : this is to avoid bad state error
            let activeVehicles = 0;
            if (!partnerSplits) return;
            partnerSplits.forEach((vehicle) => {
                if (vehiclesMap[vehicle.Vehicle]?.status) {
                    activeVehicles++;
                }
            })
            setActiveVehicles(activeVehicles);
        }
    }, [parentLoading])

    if (parentLoading) {
        return (
            <div style={{ width: '36%', backgroundColor: Colors.contentBackgroundColor }}>
                <CustomLoaderSpinner />
            </div>
        )
    }


    const fileObj = ['application/pdf'];
    const handleFileChange = (e, selectedVehicleId) => {
        e.preventDefault()
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile && fileObj.includes(selectedFile.type)) {
                let documentFileObjecUrl = URL.createObjectURL(e.target.files[0]);
                setSelectedPdfData({
                    name: selectedFile.name,
                    url: documentFileObjecUrl,
                });
            }
            else {
                // invalid file type
                alert('please select a pdf');
                setSelectedPdfData(null);
            }
        }
        else {
            // no file selected
            alert('please select pdf file');
        }
    }

    const RenderOldFile_and_UploadMenu = () => {
        return (
            <CustomModal
                show={showOldFile_and_UploadMenu}
                handleClose={() => setShowOld_and_FileUploadMenu(false)}
                containerWidth={window.innerWidth - 100}
                containerHeight={window.innerHeight - 100}
            >
                <CustomButtonContained
                    text={'Replace Document'}
                    onClick={() => { }}
                    color={'primary'}
                />
            </CustomModal>
        )
    }

    const RenderUploadMenuThenFile = () => {
        return (
            <CustomModal
                show={showUploadMenuThenFile}
                handleClose={() => setShowUploadMenuThenFile(false)}
                containerWidth={window.innerWidth - 100}
                containerHeight={window.innerHeight - 100}
            >
                <input type="file" className='form-control'
                    required onChange={handleFileChange}
                />
                {selectedPdfData && (
                    <PdfViewerComponent document={selectedPdfData.url} />
                )}
            </CustomModal>
        )
    }


    const setupAndRenderFileUpload = (vehicle) => {
        console.log('from vehicle', vehicle);
        setSelectedVehicle(vehicle);
        // if there is a pdf agreement already assoicated with it :
        if (true) {
            setShowUploadMenuThenFile(true);
        }
        // if there is no agreement associated with it: 
        else {
            setShowOld_and_FileUploadMenu(true);
        }
    }








    const RenderVehicleTable = () => {
        let activeVehicles = 0;
        let rows = []
        if (!partnerSplits) return;

        partnerSplits.forEach((vehicle, index) => {
            let isActive = false;
            if (vehiclesMap[vehicle.Vehicle]?.status) {
                activeVehicles++;
                isActive = true;
            }

            const classString = `${Styles.tableRowEntry} ${vehicle.Status === 'Inactive' ? Styles.inactive : ''}`
            rows.push((
                <div
                    key={index}
                    className={Styles.tableRow}
                    style={{ borderBottom: `0.5px solid ${Colors.tableLineColor}` }}>
                    <p style={{ width: '25%', cursor: 'auto' }} className={classString}>{vehicle.Vehicle}</p>
                    <p style={{ width: '25%', cursor: 'auto' }} className={classString}>{vehicle.percentage} %</p>
                    <div style={{ width: '25%', alignItems: "center", cursor: 'auto' }} className={classString}>
                        <div style={{ backgroundColor: `${(!isActive) ? Colors.buttonTheme_Light : Colors.buttonTheme}`, marginRight: 6, borderRadius: 8, padding: '0px 10px', color: Colors.tertiaryTextColor, textAlign: "center", alignSelf: "center", display: "inline" }}>
                            {(!isActive) ? 'InActive' : 'Active'}
                        </div>
                    </div>
                    <img onClick={() => { setupAndRenderFileUpload(vehicle) }} src={file} style={{ width: '25%', height: 30, cursor: 'pointer', paddingRight: '20px' }} className={classString} />
                </div>
            ))
        })

        const rowStyle = {
            color: Colors.primaryTextColor, fontWeight: '600', fontSize: 14
        }
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingBottom: '20px' }}>
                <div className={Styles.tableRow} style={{ borderBottom: `0.5px solid ${Colors.tableLineColor}` }}>
                    <p style={{ width: '25%' }} className={rowStyle}>Vehicle</p>
                    <p style={{ width: '25%' }} className={rowStyle}>Share %</p>
                    <p style={{ width: '25%' }} className={rowStyle}>Status</p>
                    <p style={{ width: '25%' }} className={rowStyle}>Agreement</p>
                </div>
                {rows}
            </div>
        )
    }

    return (
        <>
            <div style={{ width: '36%' }}>
                <p style={{ fontWeight: 'bold', width: '100%', fontSize: 18, marginTop: 30, marginBottom: 10 }}>Vehicles</p>
                <div className={GeneralStyles.boxShadow} style={{ paddingLeft: 10, paddingRight: 4, width: '100%', marginTop: 0, borderRadius: 10 }}>
                    <RenderVehicleTable />
                </div>
            </div>
            <RenderOldFile_and_UploadMenu />
            <RenderUploadMenuThenFile />
        </>
    )
}
export default RenderVehicleInfo;