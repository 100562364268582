import React from 'react'
import Colors from "../../../config/colors";
import Styles from "../styles/NewClient.module.scss"
import Constants from "../../../config/constants";


const MetricBox = ({mainText, subText, isMainPricing}) => {
  return (
    <div className={Styles.metricBox}>
      <label style={{
        fontSize: 22,
        color: Colors.tertiaryTextColor,
        width: '100%',
        textAlign: 'center',
        marginBottom: 6
      }}>{`${isMainPricing ? '$ ' : ''}${mainText}`}</label>
      <label style={{
        fontSize: Constants.subHeaderSize,
        color: Colors.secondaryTextColor,
        width: '100%',
        textAlign: 'center'
      }}>{subText}</label>
    </div>
  )
}
export default MetricBox
