import React, {useState, useEffect} from 'react'
import Colors from '../../config/colors'
import {Document, Page, PDFViewer, StyleSheet, Text, View, Image, Tspan} from "@react-pdf/renderer";
import CarImage from '../../assets/images/car.png'
import Logo from '../../assets/images/new_logo.png'
import TNCStrings from '../../metadata/TNC'
import Helpers from '../../utils/helpers'
import CalendarIcon from '../../assets/logos/calendar.png'
import PinIcon from '../../assets/logos/pin.png'
import moment from 'moment'


const BACKGROUND_COLOR = '#5481CB'
const TEXT_COLOR = '#23395D'

const pdfStyles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		backgroundColor: 'white',
	},
	section: {
		padding: '10px 10px',
		flexDirection: 'column',
		color: TEXT_COLOR,
		height: '100%',
		width: '100%'
	},
	footer: {
		position: 'absolute',
		bottom: 10,
		left: 20,
		fontSize: 12
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	headerText: {
		fontSize: 14,
		fontWeight: 'bold',
		width: '100%',
		textAlign: 'center',
		padding: '6px 0px',
		color: 'white'
	},
	entryText: {
		fontSize: 12,
		width: '100%',
		textAlign: 'center',
		padding: '3px 0px'
	},
	tableCell: {
		height: '100%',
		borderRight: `0.5px solid #000000`,
		display: 'flex',
		flexDirection: 'row',
		alignItems:'center'
	}
});



const AnalyticsPdfTemplate = ({allVehicles, profitabilityData, startDate, endDate, selectedVehicles}) => {

	const [totalRevenue, setTotalRevenue] = useState(0)
	const [totalExpense, setTotalExpense] = useState(0)
	const [totalRes, setTotalRes] = useState(0)
	const [totalDays, setTotalDays] = useState(0)
	const [toShowVehicles, setToShowVehicles] = useState([])

	useEffect(() => {
		if(!selectedVehicles || selectedVehicles.length === 0){
			let vehicles = []
			allVehicles.forEach(vehicle => {
				if(!profitabilityData)
					return
				let vehicleBreakdown = profitabilityData[vehicle.id] || {}
				if(vehicle.status === false) {
					console.log('is false', vehicle, vehicleBreakdown)
					//add the vehicle only if there is a reservation
					if(vehicleBreakdown && typeof vehicleBreakdown.total_reservations === 'number' && vehicleBreakdown.total_reservations !== 0)
						vehicles.push(vehicle)
				} else {
					vehicles.push(vehicle)
				}

			})

			setToShowVehicles(vehicles)
		} else {
			let vehicles = []
			allVehicles.forEach(vehicle => {
				if(!profitabilityData)
					return
				let vehicleBreakdown = profitabilityData[vehicle.id] || {}
				if(vehicle.status === false) {
					console.log('is false', vehicle, vehicleBreakdown)
					//add the vehicle only if there is a reservation
					if(vehicleBreakdown && typeof vehicleBreakdown.total_reservations === 'number' && vehicleBreakdown.total_reservations !== 0)
						if(checkIfVehicleSelected(vehicle.id))
							vehicles.push(vehicle)
				} else {
					if(checkIfVehicleSelected(vehicle.id))
						vehicles.push(vehicle)
				}

			})

			setToShowVehicles(vehicles)
		}
	},[allVehicles, selectedVehicles])

	const checkIfVehicleSelected = (id) => {
		let flag = false
		selectedVehicles.every(inner => {
			if(inner.value === id) {
				flag = true
				return false
			}

			return true

		})

		return flag
	}

	useEffect(() => {
		if(toShowVehicles && toShowVehicles.length > 0 && profitabilityData) {
			let totalRevenue = 0
			let totalExpense = 0
			let totalRes = 0
			let totalDays = 0
			toShowVehicles.forEach(vehicle => {
				let vehicleData = profitabilityData[vehicle.id] || {}
				let revenue = vehicleData['revenue'] || 0
				let expense = vehicleData['expense'] || 0
				let totalReservations = vehicleData['total_reservations'] || 0
				totalRevenue += revenue
				totalExpense += expense
				totalRes += totalReservations
				totalDays += vehicleData.days
			})
			setTotalRevenue(totalRevenue)
			setTotalExpense(totalExpense)
			setTotalRes(totalRes)
			setTotalDays(totalDays)
		}
	},[toShowVehicles, profitabilityData])

	const renderFooter = () => {
		// return <Text style={pdfStyles.footer} fixed>©2023-2024 DDE Rentals, LLC</Text>
		return <Text style={[pdfStyles.footer,{color: 'white'}]} fixed>footer</Text>
	}



	const renderPage1 = (startIndex, endIndex) => {
		let elements = []
		for(let i=startIndex;i<=endIndex;i++) {
			let vehicle = toShowVehicles[i]
			let vehicleData = profitabilityData[vehicle?.id] || {}
			let revenue = vehicleData['revenue'] || 0
			let expense = vehicleData['expense'] || 0
			let totalReservations = vehicleData['total_reservations'] || 0
			elements.push(
				<View style={[pdfStyles.row, {border: '0.5px solid black'}]}>
					<View style={[pdfStyles.tableCell, {width: '40%'}]}>
						<Text style={pdfStyles.entryText}>{vehicle?.id}</Text>
					</View>
					<View style={[pdfStyles.tableCell, {width: '10%'}]}>
						<Text style={pdfStyles.entryText}>{totalReservations}</Text>
					</View>
					<View style={[pdfStyles.tableCell, {width: '11%'}]}>
						<Text style={pdfStyles.entryText}>{vehicleData.days || 0}</Text>
					</View>
					<View style={[pdfStyles.tableCell, {width: '13%'}]}>
						<Text style={pdfStyles.entryText}>${Number(revenue).toFixed(2)}</Text>
					</View>
					<View style={[pdfStyles.tableCell, {width: '13%'}]}>
						<Text style={pdfStyles.entryText}>${Number(expense).toFixed(2)}</Text>
					</View>
					<View style={[pdfStyles.tableCell, {width: '13%', borderRightWidth: 0}]}>
						<Text style={pdfStyles.entryText}>${Number(revenue-expense).toFixed(2)}</Text>
					</View>


				</View>
			)
			if(i === toShowVehicles.length - 1) {
				//if the last element is shown, show the total expense
				elements.push(
					<View style={[pdfStyles.row, {border: '0.5px solid black', backgroundColor:'gray'}]}>
						<View style={[pdfStyles.tableCell, {width: '40%'}]}>
							<Text style={pdfStyles.headerText}>Total</Text>
						</View>
						<View style={[pdfStyles.tableCell, {width: '10%'}]}>
							<Text style={pdfStyles.headerText}>{totalRes}</Text>
						</View>
						<View style={[pdfStyles.tableCell, {width: '11%'}]}>
							<Text style={pdfStyles.headerText}>{totalDays}</Text>
						</View>
						<View style={[pdfStyles.tableCell, {width: '13%'}]}>
							<Text style={pdfStyles.headerText}>${Number(totalRevenue).toFixed(2)}</Text>
						</View>
						<View style={[pdfStyles.tableCell, {width: '13%'}]}>
							<Text style={pdfStyles.headerText}>${Number(totalExpense).toFixed(2)}</Text>
						</View>
						<View style={[pdfStyles.tableCell, {width: '13%', borderRightWidth: 0}]}>
							<Text style={pdfStyles.headerText}>${Number(totalRevenue-totalExpense).toFixed(2)}</Text>
						</View>


					</View>
				)
			}
		}

		return (
			<View style={pdfStyles.section}>
				{
					startIndex === 0 &&
					<View>
						<Text style={{fontSize: 16, fontWeight: '600', width: '100%', marginBottom: 10}}>VEHICLE PROFITABILITY BREAKDOWN</Text>
						<View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
							<Text style={{fontSize: 14, fontWeight: '600'}}>Date Range: </Text>
							<Text style={{fontSize: 14, fontWeight: '600'}}>{moment(startDate).format('MM/DD/YY')} - {moment(endDate).format('MM/DD/YY')}</Text>
						</View>
						<View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: 5, marginBottom: 10}}>
							<Text style={{fontSize: 14, fontWeight: '600'}}>Vehicles: </Text>
							<Text style={{fontSize: 14, fontWeight: '600'}}>{toShowVehicles && toShowVehicles.length === allVehicles.length ? 'All Vehicles' : `Selected Vehicles (${toShowVehicles.length})`}</Text>
						</View>
					</View>

				}

				<View style={[pdfStyles.row, {border: '0.5px solid black', backgroundColor: 'gray'}]}>
					<View style={[pdfStyles.tableCell, {width: '40%'}]}>
						<Text style={pdfStyles.headerText}>Vehicle</Text>
					</View>
					<View style={[pdfStyles.tableCell, {width: '10%'}]}>
						<Text style={pdfStyles.headerText}>Total Res</Text>
					</View>
					<View style={[pdfStyles.tableCell, {width: '11%'}]}>
						<Text style={pdfStyles.headerText}>Days Rented</Text>
					</View>
					<View style={[pdfStyles.tableCell, {width: '13%'}]}>
						<Text style={pdfStyles.headerText}>Revenue</Text>
					</View>
					<View style={[pdfStyles.tableCell, {width: '13%'}]}>
						<Text style={pdfStyles.headerText}>Expense</Text>
					</View>
					<View style={[pdfStyles.tableCell, {width: '13%', borderRightWidth: 0}]}>
						<Text style={pdfStyles.headerText}>Profit</Text>
					</View>


				</View>

				{elements}

			</View>

		)
	}

	const renderer = () => {
		let elements = []
		let rowsPerPage = 20
		if(toShowVehicles.length > rowsPerPage) {
			let i = 0
			while(i < toShowVehicles.length) {
				if(i+rowsPerPage < toShowVehicles.length) {
					elements.push(
						<Page size="A4" style={pdfStyles.page} orientation={"landscape"}>
							{renderPage1(i, i+rowsPerPage-1)}
						</Page>
					)
				} else {
					elements.push(
						<Page size="A4" style={pdfStyles.page} orientation={"landscape"}>
							{renderPage1(i, toShowVehicles.length-1)}
						</Page>
					)
				}


				i = i + rowsPerPage
			}
		} else {
			elements.push(
				<Page size="A4" style={pdfStyles.page} orientation={"landscape"}>
					{renderPage1(0,toShowVehicles.length-1)}
				</Page>
			)
		}

		return (
			<Document>
				{elements}
			</Document>
		)
	}

	return (
		<PDFViewer style={{height: '100%', width: '100%'}}>
			{renderer()}
		</PDFViewer>
	)
}

export default AnalyticsPdfTemplate
