import React, {useState, useEffect} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
// import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import PageHeader from "../../components/general/PageHeader";
import CustomButtonContained from "../../components/general/CustomButtonContained";
import {updateReservation, getAllReservationsSelector} from "../../redux/slices/reservationsSlice";
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import Colors from '../../config/colors'
import {vehiclesMapSelector} from "../../redux/slices/vehiclesSlice";
import Styles from "../Dashboard/styles/Dashboard.module.scss";
import Helpers from "../../utils/helpers";
import {changeUserAccess} from "../../redux/slices/authSlice";
import CustomSelect from "../../components/general/CustomSelect";
import {API_CALL_STATUS} from "../../metadata/enums";
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";

const localizer = momentLocalizer(moment)

const CalendarPage = () => {
	let dispatch = useDispatch()
	let navigate = useNavigate()
	const mongoUser = useSelector(state => state.user.mongoUser)
	const settingsState = useSelector(state => state.settings)
	let reservationsState = useSelector(state => state.reservations)
	let vehiclesMap = useSelector(state => vehiclesMapSelector(state))
	let vehiclesState = useSelector(state => state.vehicles)


	const [loading, setLoading] = useState(true)
	const [currentDateTime, setCurrentDateTime] = useState(moment())
	const [events, setEvents] = useState([])
	const [showOverlay, setShowOverlay] = useState(false)
	const [selectedVehicles, setSelectedVehicles] = useState(null)


	useEffect(() => {
		setTimeout(() => {
			setCurrentDateTime(currentDateTime.add(1,'minute'))
		},60000)
	},[])


	useEffect(() => {
		if(reservationsState.status === API_CALL_STATUS.LOADING) {
			setLoading(true)
		} else {
			let temp = []
			reservationsState.reservations.forEach(reservation => {
				if(!reservation.deleted) {
					temp.push({
						id: reservation._id,
						title: vehiclesMap[reservation.vehicle_id]?.make,
						start:new Date(reservation.pickup_datetime),
						end: new Date(reservation.dropoff_datetime),
						reservation: reservation
					})
				}

			})

			reservationsState.old_reservations.forEach(reservation => {
				if(!reservation.deleted) {
					temp.push({
						id: reservation._id,
						title: vehiclesMap[reservation.vehicle_id]?.make,
						start: new Date(reservation.pickup_datetime),
						end: new Date(reservation.dropoff_datetime),
						reservation: reservation
					})
				}
			})
			setEvents(temp)
			setLoading(false)
		}
	},[reservationsState])

	const onNewReservationClicked = () => {
		if(!Helpers.checkAccess(mongoUser, Helpers.getRolesMap(settingsState), 'create_reservations')) {
			dispatch(changeUserAccess(false))
		} else {
			if(showOverlay) {
				setShowOverlay(false)
				document.getElementById("overlay").style.display = "none";
			} else {
				setShowOverlay(true)
				document.getElementById("overlay").style.display = "block";
			}
		}
	}

	const onSelectEvent = (event) => {
		if(!Helpers.checkAccess(mongoUser, Helpers.getRolesMap(settingsState), 'view_reservations')) {
			dispatch(changeUserAccess(false))
		} else {
			let reservation = event.reservation
			dispatch(updateReservation(reservation))
			if(reservation.isChauffeur) {
				navigate('/home/new-chauffeur')
			} else {
				navigate('/home/new-reservation')
			}
		}
	}

	const onNewNavigatorClicked = (key) => {
		dispatch(updateReservation(null))
		setShowOverlay(false)
		document.getElementById("overlay").style.display = "none";
		if(key === 'reservation') {
			navigate('/home/new-reservation')
		} else {
			navigate('/home/new-chauffeur')
		}
	}

	const renderVehicleSelect = () => {
		let options = []
		vehiclesState.vehicles.forEach(vehicle => {
			if(vehicle.status === undefined || vehicle.status) {
				options.push({label: vehicle.make, value: vehicle.id})
			}
		})

		return (
			<div className={GeneralStyles.boxShadow} style={{width: '50%', minHeight: 40, borderRadius: 10, marginTop: 10, marginBottom: 20}}>
				<CustomSelect
					isMulti={true}
					placeholder={'select vehicle'}
					borderColor={'transparent'}
					value={selectedVehicles}
					options={options}
					onChange={setSelectedVehicles}
				/>
			</div>
		)
	}

	const renderCalendar = () => {
		if(loading) {
			return (
				<div
					className={GeneralStyles.boxShadow}
					style={{height: '50vh', backgroundColor: Colors.contentBackgroundColor, padding: 20, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
					<CustomLoaderSpinner />
				</div>
			)
		}

		let eventsToShow = []
		if(!selectedVehicles || selectedVehicles.length === 0){
			eventsToShow = events
		} else {
			let vehicleIds = selectedVehicles.map(selected => selected.value)
			events.forEach(event => {
				if(vehicleIds.includes(event.reservation?.vehicle_id))
					eventsToShow.push(event)
			})
		}


		return (
			<div
				className={GeneralStyles.boxShadow}
				style={{height: '80vh', backgroundColor: Colors.contentBackgroundColor, padding: 20, borderRadius: 10, overflowY: 'scroll', paddingBottom: 100}}>
				<Calendar
					onSelectEvent={onSelectEvent}
					localizer={localizer}
					defaultView="month"
					events={eventsToShow}
					startAccessor="start"
					endAccessor="end"
					style={{ height: '90%'}}
					onClick
				/>
			</div>
		)
	}


	return (
		<div className={GeneralStyles.container}>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
				<PageHeader header={'Calendar'} subHeader={`${currentDateTime.format('dddd, MMM Do')} | ${currentDateTime.format('h:mm A')}`} />
				<CustomButtonContained
					text={'+ New Reservation'}
					onClick={onNewReservationClicked}
					style={{position: 'absolute', right: 0, zIndex: 10}}
					color={showOverlay ? 'white' : 'primary'}
				/>
			</div>

			<div className={GeneralStyles.overlay} id={'overlay'} />

			{
				showOverlay &&
				<div style={{position: 'absolute', right: 20, top: 80, backgroundColor: Colors.theme, height: 110, width: 220, borderRadius: 10, zIndex: 10}}>
					<div
						onClick={() => onNewNavigatorClicked('reservation')}
						style={{paddingLeft: 10,display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: `1px solid ${Colors.secondaryTextColor}`, cursor: 'pointer'}}>
						<p style={{width: 180, color: Colors.tertiaryTextColor}}>Daily Rentals</p>
						<p style={{color: Colors.tertiaryTextColor}}>{`>`}</p>
					</div>
					<div
						onClick={() => onNewNavigatorClicked('chauffeur')}
						style={{paddingLeft: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer'}}>
						<p style={{width: 180, color: Colors.tertiaryTextColor}}>Chauffeur Service</p>
						<p style={{color: Colors.tertiaryTextColor}}>{`>`}</p>
					</div>
				</div>
			}


			{renderVehicleSelect()}
			{renderCalendar()}

		</div>
	)
}

export default CalendarPage
