import GeneralStyles from "../../routes/GeneralStyles.module.scss";
import CardPng from "../../assets/logos/cardPng.png";
import DollarPng from "../../assets/logos/dollarPng.png";
import Helpers from "../../utils/helpers";
import moment from "moment/moment";
import SettingsButton from "../../assets/logos/settingsButton.png";
import React from "react";

const PaymentTile = ({payment, onClick, totalPrice, index}) => {
  return (
    <div
      key={index?.toString()}
      className={GeneralStyles.boxShadow}
      style={{
        borderRadius: 10,
        width: '99%',
        marginRight: 10,
        display: 'flex',
        flexDirection: 'row',
        padding: `10px 0px`,
        marginTop: 10
      }}>
      <div
        style={{
          width: '13%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          height: '100%',
          paddingTop: 6
        }}>
        <img src={payment?.isSecurityHold ? CardPng : DollarPng} height={20} width={20}/>
      </div>

      <div style={{
        width: '75%'
      }}>
        <label className={GeneralStyles.darkText}
               style={{fontSize: 16, fontWeight: 'bold'}}>{Helpers.getPaymentTileTitle(payment, totalPrice)}</label>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <label className={GeneralStyles.darkText} style={{fontSize: 14, width: '23%'}}>Type : </label>
          <label className={GeneralStyles.darkText} style={{
            fontSize: 14,
            fontWeight: 'bold'
          }}>{payment?.transactionType}</label>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <label className={GeneralStyles.darkText} style={{fontSize: 14, width: '23%'}}>Status : </label>
          <label className={GeneralStyles.darkText} style={{
            fontSize: 14,
            fontWeight: 'bold'
          }}>{payment?.transactionStatus}</label>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <label className={GeneralStyles.darkText} style={{fontSize: 14, width: '23%'}}>Amount : </label>
          <label className={GeneralStyles.darkText} style={{
            fontSize: 14,
            fontWeight: 'bold'
          }}>$ {Number(payment?.amount).toFixed(2)}</label>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <label className={GeneralStyles.darkText} style={{fontSize: 14, width: '23%'}}>Date : </label>
          <label className={GeneralStyles.darkText} style={{
            fontSize: 14,
            fontWeight: 'bold'
          }}>{moment(payment?.transactionDate).format('MM/DD/YY, hh:mm A')}</label>
        </div>

      </div>
      <div
        onClick={onClick}
        style={{width: '12%', display: 'flex', flexDirection: 'row', cursor: 'pointer'}}>
        <img src={SettingsButton} height={30} width={30} style={{}}/>
      </div>

    </div>

  )

}

export default PaymentTile
