import React, {useState, useEffect} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import PageHeader from "../../components/general/PageHeader";
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import {useDispatch, useSelector} from "react-redux";
import {API_CALL_STATUS, LEAD_TYPES} from "../../metadata/enums";
import {LOGGER} from "../../utils/Logger";
import moment from "moment";
import Styles from "./styles/Leads.module.scss";
import Colors from "../../config/colors";
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";
import {useNavigate} from "react-router-dom";
import {getAllLeads, updateOneLead, updateLeadUpdateStatus} from "../../redux/slices/leadsSlice";
import {vehiclesMapSelector} from "../../redux/slices/vehiclesSlice";
import CustomModal from "../../components/general/CustomModal";
import CustomButtonContained from "../../components/general/CustomButtonContained";

const Leads = () => {
  let navigate = useNavigate()
  let dispatch = useDispatch()

  const leadsState = useSelector(state => state.leads)
  const token = useSelector(state => state.auth.token)
  const vehiclesMap = useSelector(state => vehiclesMapSelector(state))

  const [loading, setLoading] = useState(true)
  const [showSuccess, setShowSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showError, setShowError] = useState(false)
  const [allLeads, setAllLeads] = useState([])
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectedLead, setSelectedLead] = useState(null)

  useEffect(() => {
    if(token)
      dispatch(getAllLeads({token}))
  }, [token]);

  useEffect(() => {
    if(leadsState.status === API_CALL_STATUS.LOADING) {
      setLoading(true)
    } else if(leadsState.status === API_CALL_STATUS.FAILED) {
      setLoading(true)
      setShowError(true)
      setErrorMessage('something went wrong when getting the leads data')
      LOGGER.error('error effect', leadsState.error)
    } else {
      setLoading(false)
      setAllLeads(leadsState.leads)
      if(leadsState.lead_updated) {
        // lead was just updated
        setSuccessMessage('Successfully updated lead status')
        setShowSuccess(true)
        dispatch(updateLeadUpdateStatus(false))
      }
    }
  },[leadsState])


  const onMarkLeadClicked = (openedFlag) => {
    if(window.confirm(`Are you sure you want to mark the lead as ${openedFlag ? 'Unread' : 'Read'}`)) {
      let toSend = Object.assign({}, selectedLead, {opened: !openedFlag})
      setLoading(true)
      dispatch(updateOneLead({token: token, data: toSend}))
      setSelectedLead(null)
      setModalIsOpen(false)
    }

  }


  const renderContent = () => {
    if(loading) {
      return (
        <div className={GeneralStyles.container} style={{height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
          <CustomLoaderSpinner />
        </div>
      )
    }

    let elements = allLeads.map((lead, index) => {
      let vehicle = vehiclesMap[lead?.vehicle_id] || null
      let dates = lead.dates || null
      let dateString = ''
      if(!dates) {
        dateString = 'N/A'
      } else {
        let {start_date, end_date} = dates
        let startDateString = start_date ? moment(start_date).format('MM/DD/YY') : 'N/A'
        let endDateString = end_date ? moment(end_date).format('MM/DD/YY') : 'N/A'
          dateString = `${startDateString} - ${endDateString}`
      }
      let channel = lead.channel
      let channelString = 'Unknown'
      Object.keys(LEAD_TYPES).forEach(key => {
        if(LEAD_TYPES[key] === channel)
          channelString = key
      })
      return (
        (
          <div
            onClick={() => {
              setSelectedLead(lead)
              setModalIsOpen(true)
            }}
            key={index.toString()}
            className={Styles.listRow}
            style={{backgroundColor: lead.opened ? 'transparent' : Colors.notifBackgroundColor, cursor: 'pointer'}}
          >
            <div style={{width: '25%', marginRight: 10}}>
              <p className={Styles.tableEntry}>{lead.name || ''}</p>
            </div>
            <div style={{width: '30%', marginRight: 10}}>
              <p className={Styles.tableEntry}>{vehicle ? `${vehicle.make}-${vehicle.color}-${vehicle.license_plate}` : 'Unknown'}</p>
            </div>
            <div style={{width: '20%', marginRight: 10}}>
              <p className={Styles.tableEntry}>{dateString}</p>
            </div>
            <div style={{width: '15%', marginRight: 10}}>
              <p className={Styles.tableEntry}>{channelString}</p>
            </div>
            <div style={{width: '10%', marginRight: 10}}>
              <p className={Styles.tableEntry}>{moment(lead.created_at).format('MM/DD/YY hh:mm A')}</p>
            </div>
          </div>
        )
      )
    })


    return (
      <div className={Styles.listContainer}>
        <div className={Styles.listRow}>
          <div style={{width: '25%', marginRight: 10}}>
            <p className={Styles.tableHeader}>Name</p>
          </div>
          <div style={{width: '30%', marginRight: 10}}>
            <p className={Styles.tableHeader}>Requested Vehicle</p>
          </div>
          <div style={{width: '20%', marginRight: 10}}>
            <p className={Styles.tableHeader}>Dates</p>
          </div>
          <div style={{width: '15%', marginRight: 10}}>
            <p className={Styles.tableHeader}>Lead Channel</p>
          </div>
          <div style={{width: '10%', marginRight: 10}}>
            <p className={Styles.tableHeader}>Created</p>
          </div>
        </div>
        {
          elements && elements.length > 0 ?
            elements
            :
            <p style={{color: Colors.primaryTextColor, fontSize: 20, marginTop: 100, width: '100%', textAlign: 'center'}}>No leads to show</p>
        }
      </div>
    )
  }

  const renderModalContent = () => {
    if(!selectedLead)
      return null
    let vehicle = vehiclesMap[selectedLead?.vehicle_id] || null
    let dates = selectedLead.dates || null
    let channel = selectedLead.channel
    let channelString = 'Unknown'
    Object.keys(LEAD_TYPES).forEach(key => {
      if(LEAD_TYPES[key] === channel)
        channelString = key
    })

    return (
      <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', height: '100%'}}>
        <label className={GeneralStyles.darkText} style={{fontWeight: 600, fontSize: 20, marginTop: 10}}>
          {'Lead'}
        </label>
        <div style={{
          width: '80%',
          marginTop: 20,
          height: '100%',
          overflowY: 'scroll'
        }}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <label className={Styles.modalLabel}>Client Name</label>
            <label style={{fontSize: 18, color: Colors.secondaryTextColor, marginRight: 10}}>:</label>
            <label className={Styles.modalValue}>{selectedLead?.name || 'N/A'}</label>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <label className={Styles.modalLabel}>Client Email</label>
            <label style={{fontSize: 18, color: Colors.secondaryTextColor, marginRight: 10}}>:</label>
            <label className={Styles.modalValue}>{selectedLead?.email || 'N/A'}</label>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <label className={Styles.modalLabel}>Requested Vehicle</label>
            <label style={{fontSize: 18, color: Colors.secondaryTextColor, marginRight: 10}}>:</label>
            <label className={Styles.modalValue}>{vehicle ? `${vehicle.make}-${vehicle.color}-${vehicle.license_plate}` : 'Unknown'}</label>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <label className={Styles.modalLabel}>Start Date</label>
            <label style={{fontSize: 18, color: Colors.secondaryTextColor, marginRight: 10}}>:</label>
            <label className={Styles.modalValue}>{dates && dates.start_date ? moment(dates.start_date).format('MMM Do YYYY, hh:mm A') : 'Not Specified'}</label>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <label className={Styles.modalLabel}>End Date</label>
            <label style={{fontSize: 18, color: Colors.secondaryTextColor, marginRight: 10}}>:</label>
            <label className={Styles.modalValue}>{dates && dates.end_date ? moment(dates.end_date).format('MMM Do YYYY, hh:mm A') : 'Not Specified'}</label>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <label className={Styles.modalLabel}>Lead Channel</label>
            <label style={{fontSize: 18, color: Colors.secondaryTextColor, marginRight: 10}}>:</label>
            <label className={Styles.modalValue}>{channelString}</label>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <label className={Styles.modalLabel}>Created Date</label>
            <label style={{fontSize: 18, color: Colors.secondaryTextColor, marginRight: 10}}>:</label>
            <label className={Styles.modalValue}>{moment(selectedLead?.created_at).format('MMM Do YYYY, hh:mm A')}</label>
          </div>
        </div>

        <div style={{position: 'absolute', bottom: 10}}>
          <CustomButtonContained
            text={selectedLead?.opened ? 'Mark as Unread' : 'Mark as Read'}
            onClick={() => onMarkLeadClicked(selectedLead?.opened)}
            style={{}}
            color={'primary'}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={GeneralStyles.container} style={{overflowY: 'hidden'}}>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
        <PageHeader header={'Leads'}/>
        {/*<CustomButtonContained*/}
        {/*	text={'+ New Reservation'}*/}
        {/*	onClick={onNewReservationClicked}*/}
        {/*	style={{position: 'absolute', right: 40, zIndex: 10}}*/}
        {/*	color={showOverlay ? 'white' : 'primary'}*/}
        {/*/>*/}
      </div>

      <PositionedSnackbar
        onClose={() => {
          setShowError(false)
          setShowSuccess(false)
          setErrorMessage('')
          setSuccessMessage('')
        }}
        severity={showError ? 'error' : 'success'}
        openFlag={showError || showSuccess}
        message={showError ? errorMessage : successMessage}
      />

      {renderContent()}

      <CustomModal
        show={modalIsOpen}
        handleClose={() => setModalIsOpen(false)}
        containerWidth={window.innerWidth / 2.5}
        containerHeight={window.innerHeight / 2}
      >
        {renderModalContent()}
      </CustomModal>

    </div>
  )
}

export default Leads
