import React, {useState, useEffect} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import PageHeader from "../../components/general/PageHeader";
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import {useDispatch, useSelector} from "react-redux";
import {API_CALL_STATUS} from "../../metadata/enums";
import {LOGGER} from "../../utils/Logger";
import moment from "moment";
import Styles from "./styles/NewClient.module.scss";
import Colors from "../../config/colors";
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";
import CustomButtonContained from '../../components/general/CustomButtonContained'
import SearchIcon from "../../assets/logos/searchIcon.png";
import CustomTextFieldNew from '../../components/general/CustomTextFieldNew';
import Constants from "../../config/constants";
import Fuse from "fuse.js";
import BackArrow from "../../assets/logos/backArrow.svg";
import {useNavigate} from "react-router-dom";
import {updateClientToUpdate, addClientAndConfirm, updateClientAndConfirm} from '../../redux/slices/clientsSlice'
import CustomSelect from "../../components/general/CustomSelect";
import CustomGooglePlaces from "../../components/general/CustomGooglePlaces";
import CustomGooglePlacesNew from "../../components/general/CustomGooglePlacesNew";
import Helpers from "../../utils/helpers";
import {vehiclesMapSelector} from "../../redux/slices/vehiclesSlice";
import {updateReservation} from "../../redux/slices/reservationsSlice";
import CustomModal from "../../components/general/CustomModal";
import {Calendar} from "react-date-range";
import InsuranceDetailsModal from "../../components/axle/InsuranceDetailsModal";
import DocsViewModal from "../../components/axle/DocsViewModal";

const getEmptyCustomer = () => {
	return (
		{
			fName: '',
			lName: '',
			email: '',
			phone: '',
			address: null
		}
	)
}

const NewClient = () => {
	let navigate = useNavigate()
	let dispatch = useDispatch()

	let clientsState = useSelector(state => state.clients)
	const token = useSelector(state => state.auth.token)
	const vehiclesMap = useSelector(state => vehiclesMapSelector(state))

	const [showInsuranceModal, setShowInsuranceModal] = useState(false)
	const [showAxleModal, setShowAxleModal] = useState(false)
	const [axleUrls, setAxleUrls] = useState(null)
	const [clientToUpdate, setClientToUpdate] = useState(null)
	const [loading, setLoading] = useState(true)
	const [showSuccess, setShowSuccess] = useState(false)
	const [successMessage, setSuccessMessage] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [showError, setShowError] = useState(false)
	const [customerInfo, setCustomerInfo] = useState({
		fName: '',
		lName: '',
		email: '',
		phone: '',
		address: null
	})

	const [licenseInfo, setLicenseInfo] = useState({
		number: '',
		expiry_date: new Date(),
		state: ''
	})
	const [insuranceInfo, setInsuranceInfo] = useState({
		provider: '',
		policy_no: '',
	})

	const [showLicenseDateSelector, setShowLicenseDateSelector] = useState(false)


	useEffect(() => {
		if(clientsState.status === API_CALL_STATUS.LOADING) {
			setLoading(true)
		} else if(clientsState.status === API_CALL_STATUS.FAILED) {
			setLoading(true)
			setShowError(true)
			setErrorMessage('something went wrong when getting the clients data')
			LOGGER.error('error effect', clientsState.error)
		} else {
			setLoading(false)
			if(clientsState.client_added || clientsState.client_updated) {
				//new client was added successfully
				navigate(-1)
			}

			if(clientsState.client_to_update) {
				setClientToUpdate(clientsState.client_to_update)
				let {fName, lName, address, email, phone, insurance, license} = clientsState.client_to_update
				setCustomerInfo(clientsState.client_to_update)
				license && setLicenseInfo(license)
				insurance && setInsuranceInfo(insurance)
			}

		}
	},[clientsState])

	const validateData = () => {
		let missingField = false

		Object.keys(customerInfo).every(field => {
			if (field !== 'address' && (!customerInfo[field] || customerInfo[field].length === 0)) {
				missingField = true
				return false
			}
			return true
		})

		if(!customerInfo.address || Object.keys(customerInfo.address).length !== 2)
			missingField = true

		if (missingField) {
			setShowError(true)
			setErrorMessage(`Missing customer information`)
			return false
		} else {
			if(!Helpers.validateEmail(customerInfo.email)) {
				setShowError(true)
				setErrorMessage(`Invalid Email`)
				return false
			}
			return true
		}
	}

	const onSavePressed = () => {
		if(!validateData()) {
			setShowError(true)
			return
		}

		setLoading(true)
		let toSave = Object.assign({}, customerInfo, {created_at: new Date().toISOString()})
		if(clientToUpdate) {
			let toSend = Object.assign({}, customerInfo, {_id: clientToUpdate._id})
			if(insuranceInfo?.provider && insuranceInfo?.provider.length > 0)
				toSend['insurance'] = insuranceInfo

			if(licenseInfo?.number && licenseInfo?.number.length > 0)
				toSend['license'] = Object.assign({}, licenseInfo, {expiry_date: new Date(licenseInfo.expiry_date).toISOString()})
			dispatch(updateClientAndConfirm({token, data: toSend}))
		}else {
			if(insuranceInfo?.provider && insuranceInfo?.provider.length > 0)
				toSave['insurance'] = insuranceInfo

			if(licenseInfo?.number && licenseInfo?.number.length > 0)
				toSave['license'] = Object.assign({}, licenseInfo, {expiry_date: new Date(licenseInfo.expiry_date).toISOString()})
			dispatch(addClientAndConfirm({token, data: toSave}))
		}
	}


	const renderMetricBox = (main, sub, isPricing) => {
		return (
			<div className={Styles.metricBox}>
				<label style={{fontSize: 22, color: Colors.tertiaryTextColor, width: '100%', textAlign: 'center', marginBottom: 6}}>{`${isPricing ? '$ ':''}${main}`}</label>
				<label style={{fontSize: Constants.subHeaderSize, color: Colors.secondaryTextColor, width: '100%', textAlign: 'center'}}>{sub}</label>
			</div>
		)
	}


	const renderReservations = () => {
		let rows = []
		let {reservations} = clientToUpdate
		reservations.forEach((res, index) => {
			rows.push((
				<div
					onClick={() => {
						dispatch(updateReservation(res))
						if(res.isChauffeur) {
							navigate('/home/new-chauffeur')
						} else {
							navigate('/home/new-reservation')
						}
					}}
					className={Styles.tableRow}
					style={{borderBottom: `0.5px solid ${Colors.tableLineColor}`}}>
					<p style={{width: '10%'}} className={Styles.tableRowEntry}>{`#${res._id}`}</p>
					<p style={{width: '20%'}} className={Styles.tableRowEntry}>{vehiclesMap ? vehiclesMap[res.vehicle_id]?.make : '-'}</p>
					<p style={{width: '20%'}} className={Styles.tableRowEntry}>{moment(res.pickup_datetime).format('MM/DD/YY : hh:mm A')}</p>
					<p style={{width: '20%'}} className={Styles.tableRowEntry}>{moment(res.dropoff_datetime).format('MM/DD/YY : hh:mm A')}</p>
					<p style={{width: '17%'}} className={Styles.tableRowEntry}>$0</p>
					<p style={{width: '13%'}} className={Styles.tableRowEntry}>{`$${res.totalPrice.toFixed(2)}`}</p>
				</div>
			))
		})

		return (
			<div style={{width: '100%', marginTop: 30}}>
				<p style={{fontWeight: 'bold', width: '100%', fontSize: 18, marginBottom: 10}}>Reservation History</p>
				<div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
					<div className={Styles.tableRow}>
						<p style={{width: '10%'}} className={Styles.tableHeader}>Res #</p>
						<p style={{width: '20%'}} className={Styles.tableHeader}>Vehicle</p>
						<p style={{width: '20%'}} className={Styles.tableHeader}>Pick-Up Date/Time</p>
						<p style={{width: '20%'}} className={Styles.tableHeader}>Drop-Off Date/Time</p>
						<p style={{width: '17%'}} className={Styles.tableHeader}>Outstanding Balance</p>
						<p style={{width: '13%'}} className={Styles.tableHeader}>Total Paid</p>
					</div>
					{rows}
				</div>
			</div>
		)
	}

	const renderMetricRow = () => {
		let {reservations} = clientToUpdate
		let total = 0
		let upcoming = 0
		let totalSpend = 0
		reservations.forEach(reservation => {
			if(reservation.deleted !== true) {
				totalSpend += reservation.totalPrice
				total++
				let {pickup_datetime, dropoff_datetime} = reservation
				if(moment(pickup_datetime).isAfter(moment()) || moment(dropoff_datetime).isAfter(moment())) {
					upcoming++
				}
			}
		})

		return (
			<div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', alignItems: 'center'}}>
				{renderMetricBox(total, 'Total Reservations')}
				{renderMetricBox(upcoming, 'Upcoming Reservations')}
				{renderMetricBox(totalSpend.toFixed(2), 'Total Spend', true)}
				{renderMetricBox(0, 'Outstanding Balance', true)}
				{renderMetricBox('0/0 Used', 'Membership Days')}
			</div>
		)
	}

	const renderClientInfo = () => {
		return (
			<div style={{width: '100%'}}>
				<p style={{fontWeight: 'bold', width: '100%', fontSize: 18, marginTop: 30, marginBottom: 10}}>Client Information</p>
				<div style={{display: 'flex', flexDirection: 'column', width: '60%'}}>
					<label
						className={GeneralStyles.darkText}
						style={{fontSize: Constants.labelSize}}>
						Full Name:
					</label>
						<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginBottom: 20, marginTop: 4}}>
							<div style={{width: '47%'}}>
								<CustomTextFieldNew
									width={'100%'}
									label={'First name'}
									placeholder={'first name'}
									value={customerInfo.fName}
									onChange={(text) => {
										setCustomerInfo(Object.assign({}, customerInfo, {fName: text}))
									}}
								/>
							</div>
							<div style={{width: '47%', marginLeft: '4%'}}>
								<CustomTextFieldNew
									width={'100%'}
									label={'Last name'}
									placeholder={'last name'}
									value={customerInfo.lName}
									onChange={(text) => {
										setCustomerInfo(Object.assign({}, customerInfo, {lName: text}))
									}}
								/>
							</div>
						</div>

						<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginBottom: 20}}>
							<div style={{width: '47%'}}>
								<label
									className={GeneralStyles.darkText}
									style={{fontSize: Constants.labelSize}}>
									Email:
								</label>
								<CustomTextFieldNew
									marginTop={4}
									width={'100%'}
									label={'Email Address'}
									placeholder={'email address'}
									value={customerInfo.email}
									onChange={(text) => {
										setCustomerInfo(Object.assign({}, customerInfo, {email: text}))
									}}
								/>
							</div>
							<div style={{width: '47%', marginLeft: '4%'}}>
								<label
									className={GeneralStyles.darkText}
									style={{fontSize: Constants.labelSize}}>
									Phone:
								</label>
								<CustomTextFieldNew
									marginTop={4}
									width={'100%'}
									label={'Phone Number'}
									placeholder={'phone number'}
									value={customerInfo.phone}
									onChange={(text) => {
										setCustomerInfo(Object.assign({}, customerInfo, {phone: Helpers.checkPhoneNumberFormat(text)}))
									}}
								/>
							</div>
						</div>

						<div style={{width: '99.5%', marginBottom: 20}}>
							<label
								className={GeneralStyles.darkText}
								style={{fontSize: Constants.labelSize}}>
								Address:
							</label>
							<div style={{marginTop: 4}}>
								<CustomGooglePlacesNew
									label={'Address'}
									placeholder={'search customer address'}
									value={customerInfo.address}
									onChange={(value) => {
										setCustomerInfo(Object.assign({}, customerInfo, {address: value}))
									}}
								/>
							</div>
						</div>

					{/*license info*/}
					<label
						className={GeneralStyles.darkText}
						style={{fontSize: Constants.labelSize}}>
						License Info:
					</label>
					<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: 5}}>
						<div style={{width: '47%'}}>
							<CustomTextFieldNew
								width={'100%'}
								label={'License #'}
								placeholder={'License #'}
								value={licenseInfo?.number}
								onChange={(text) => {
									setLicenseInfo(Object.assign({}, licenseInfo, {number: text}))
								}}
							/>
						</div>
						<div style={{width: '47%', marginLeft: '4%'}}>
							<CustomTextFieldNew
								width={'100%'}
								label={'State'}
								placeholder={'State'}
								value={licenseInfo?.state}
								onChange={(text) => {
									setLicenseInfo(Object.assign({}, licenseInfo, {state: text}))
								}}
							/>
						</div>
					</div>

					<div style={{width: '100%', marginTop: 10, marginBottom: 20}}>
						<div
							onClick={() => setShowLicenseDateSelector(true)}
							className={GeneralStyles.boxShadow}
							style={{width: '46%', padding: '4px 10px', borderRadius: 10, marginTop: 5, display: 'flex', flexDirection: 'column', cursor: 'pointer'}}>
							<label className={GeneralStyles.darkText} style={{fontSize: 14, fontWeight: 'bold'}}>
								Expiry Date
							</label>
							<label className={GeneralStyles.darkText}>{moment(licenseInfo?.expiry_date).format('MM/DD/YYYY')}</label>
						</div>

					</div>

					{/*insurance info*/}
					<label
						className={GeneralStyles.darkText}
						style={{fontSize: Constants.labelSize}}>
						Insurance Info:
					</label>

					<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: 5}}>
						<div style={{width: '47%'}}>
							<CustomTextFieldNew
								width={'100%'}
								label={'Policy Provider'}
								placeholder={'Policy Provider'}
								value={insuranceInfo?.provider}
								onChange={(text) => {
									setInsuranceInfo(Object.assign({}, insuranceInfo, {provider: text}))
								}}
							/>
						</div>
						<div style={{width: '47%', marginLeft: '4%'}}>
							<CustomTextFieldNew
								width={'100%'}
								label={'Policy #'}
								placeholder={'Policy #'}
								value={insuranceInfo?.policy_no}
								onChange={(text) => {
									setInsuranceInfo(Object.assign({}, insuranceInfo, {policy_no: text}))
								}}
							/>
						</div>
					</div>

					<CustomButtonContained
						text={'Insurance Details'}
						onClick={() => {
							setShowInsuranceModal(true)
						}}
						style={{width: 250, marginTop: 2}}
					/>

					</div>

			</div>
		)
	}

	const renderLicenseDateModal = () => {
		return (
			<CustomModal
				show={showLicenseDateSelector}
				handleClose={() => setShowLicenseDateSelector(false)}
				containerWidth={window.innerWidth/1.5}
				containerHeight={window.innerHeight/1.8}
			>
				<div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: Colors.backgroundColor}}>
					<Calendar
						date={moment(licenseInfo?.expiry_date).toDate()}
						onChange={(date) => {
							setLicenseInfo(Object.assign({}, licenseInfo, {expiry_date: date}))
						}}
					/>

					<CustomButtonContained
						text={'Done'}
						onClick={() => {
							setShowLicenseDateSelector(false)
						}}
						style={{marginTop: 2}}
					/>

				</div>
			</CustomModal>
		)
	}

	const renderContent = () => {
		return (
			<div style={{width: '100%'}}>
				{clientToUpdate && renderMetricRow()}
				{renderClientInfo()}
				{clientToUpdate && renderReservations()}
				{renderLicenseDateModal()}

				{
					showInsuranceModal &&
					<InsuranceDetailsModal
						showAxleModal={(urls) => {
							setShowInsuranceModal(false)
							setAxleUrls(urls)
							setShowAxleModal(true)
						}}
						customerInfo={customerInfo}
						modalIsOpen={showInsuranceModal}
						onModalClose={() => {
							setShowInsuranceModal(false)
						}}
					/>
				}

				{
					showAxleModal &&
					<DocsViewModal
						urls={axleUrls}
						modalIsOpen={showAxleModal}
						onModalClose={() => {
							setShowAxleModal(false)
						}}
					/>
				}
			</div>
		)
	}

	if(loading) {
		return (
			<div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: Colors.contentBackgroundColor, width: '100%'}}>
				<CustomLoaderSpinner />
			</div>
		)
	}

	return (
		<div className={GeneralStyles.container} style={{overflowY: 'scroll', height: '98vh'}}>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
				<PageHeader header={clientToUpdate ? 'Update Client' : 'New Client'} subHeader={clientToUpdate ? `Customer Since: ${moment(clientToUpdate.created_at).format('MMMM Do, YYYY')}` : ''}/>
				<div style={{position: 'absolute', right: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', top: 24}}>
					<CustomButtonContained
						text={clientToUpdate ? '\u2713 Save Changes' : 'Add Client'}
						onClick={onSavePressed}
						style={{marginRight: 2}}
						color={'primary'}
					/>
					<img
						onClick={() => {
							setCustomerInfo(getEmptyCustomer())
							dispatch(updateClientToUpdate(null))
							navigate(-1)
						}}
						src={BackArrow} style={{width: 40, height: 40, cursor: 'pointer'}}/>
				</div>

			</div>

			<PositionedSnackbar
				onClose={() => {
					setShowError(false)
					setShowSuccess(false)
					setErrorMessage('')
					setSuccessMessage('')
				}}
				severity={showError ? 'error' : 'success'}
				openFlag={showError || showSuccess}
				message={showError ? errorMessage : successMessage}
			/>
			{renderContent()}
		</div>
	)
}

export default NewClient
