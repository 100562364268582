import React, { useState, useEffect, useRef } from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import PageHeader from "../../components/general/PageHeader";
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { API_CALL_STATUS, VEHICLE_TYPES } from "../../metadata/enums";
import { LOGGER } from "../../utils/Logger";
import moment from "moment";
import Colors from "../../config/colors";
import Styles from './styles/NewVehicle.module.scss'
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";
import CustomButtonContained from "../../components/general/CustomButtonContained";
import BackArrow from "../../assets/logos/backArrow.svg";
import { useNavigate } from "react-router-dom";
import {
	addVehicleAndConfirm,
	setUpdatedStatus,
	updateNewVehicleStatus,
	updateVehicle,
	updateVehicleAndConfirm, vehiclesMapSelector
} from "../../redux/slices/vehiclesSlice";
import ReactS3Client from "../../services/S3Client";
import Config from "../../config";
import Helpers from "../../utils/helpers";
import { changeUserAccess } from "../../redux/slices/authSlice";
import UploadIcon from "../../assets/logos/uploadIcon.svg";
import Constants from "../../config/constants";
import CustomTextFieldNew from "../../components/general/CustomTextFieldNew";
import CalendarIcon from '../../assets/logos/calendar.png'
import CustomModal from "../../components/general/CustomModal";
import { Calendar } from "react-date-range";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import colors from "../../config/colors";
import RenderPartnerSplitInformation from './components/partnerInformation';
import CustomSwitch from "../../components/general/CustomSwitch";

const getEmptyObject = () => {
	return {
		make: '',
		pictureUrl: '',
		dailyPrice: '',
		hourlyPrice: '',
		id: '',
		type: [],
		deposit: '',
		mileage_allowance: '',
		mileage_overage_price: '',
		fuel_charge: '',
		vin: '',
		license_plate: '',
		color: ''
	}
}

const getEmptyFinancesObject = () => {
	return {
		buy_price: '',
		buy_date: null,
		down_payment: '',
		term: '',
		monthly_payment: '',
		payment_schedule: '',
		finance_company: '',
		insurance_monthly_payment: '',
		insurance_payment_schedule: '',
		insurance_provider: ''
	}
}

const getEmptyDetailsObject = () => {
	return {
		"engine_capacity": "",
		"engine_bhp": "",
		"seats": "",
		"0to60": "",
		"msrp": "",
		daily_price: "",
		deposit: "",
		mileage_allowance: "",
		mileage_overage_price: "",
		fuel_charge: ""
	}
}

const NewVehicle = () => {
	let navigate = useNavigate()
	let dispatch = useDispatch()
	let fileRef = useRef(null)

	const mongoUser = useSelector(state => state.user.mongoUser)
	const vehiclesStore = useSelector(state => state.vehicles)
	const token = useSelector(state => state.auth.token)
	const vehiclesMap = useSelector(state => vehiclesMapSelector(state))
	const settingsState = useSelector(state => state.settings)

	const [showDropdownModal, setShowDropdownModal] = useState(false)
	const [dropdownModalField, setDropdownModalField] = useState(null)
	const [tab, setTab] = useState(0)
	const [loading, setLoading] = useState(true)
	const [showSuccess, setShowSuccess] = useState(false)
	const [selectedImage, setSelectedImage] = useState(null);
	const [imageUrl, setImageUrl] = useState(null)
	const [successMessage, setSuccessMessage] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [showDateSelector, setShowDateSelector] = useState(false)
	const [showError, setShowError] = useState(false)
	const [vehicleDetails, setVehicleDetails] = useState({
		make: '',
		pictureUrl: '',
		dailyPrice: '',
		hourlyPrice: '',
		id: '',
		type: [],
		deposit: '',
		mileage_allowance: '',
		mileage_overage_price: '',
		fuel_charge: '',
		vin: '',
		license_plate: '',
		color: ''
	})
	const [finances, setFinances] = useState({
		buy_price: '',
		buy_date: null,
		down_payment: '',
		term: '',
		monthly_payment: '',
		payment_schedule: '',
		finance_company: '',
		insurance_monthly_payment: '',
		insurance_payment_schedule: '',
		insurance_provider: ''
	})
	const [partnerDetails, setPartnerDetails] = useState({
		partner_id: 0,
		percentage: 0,
		wholeSaleRate: 0
	});
	const [details, setDetails] = useState({
		"engine_capacity": "",
		"engine_bhp": "",
		"seats": "",
		"0to60": "",
		"msrp": "",
		daily_price: "",
		deposit: "",
		mileage_allowance: "",
		mileage_overage_price: "",
		fuel_charge: ""
	})
	const [oldPartner_id, setOldPartner_id] = useState('');
	const [onlineStatus, setOnlineStatus] = useState(true)

	useEffect(() => {
		if (vehiclesStore.status === API_CALL_STATUS.LOADING) {
			setLoading(true)
		} else if (vehiclesStore.status === API_CALL_STATUS.FAILED) {
			setLoading(true)
			setShowError(true)
			setErrorMessage('something went wrong when getting the settings data')
			LOGGER.error('error effect', vehiclesStore.error)
		} else {
			setLoading(false)

			if (vehiclesStore.updated_vehicle || vehiclesStore.new_vehicle) {
				setImageUrl(null)
				setSelectedImage(null)
				setVehicleDetails(null)
				navigate('/home/vehicles')
			}

			if (vehiclesStore.vehicle_to_update) {
				//show update vehicle with details
				let temp = Object.assign({}, vehiclesStore.vehicle_to_update)
				setVehicleDetails(temp)
				setImageUrl(temp.pictureUrl)
				// adding partner info if there is any:
				if (temp.partner) {
					setPartnerDetails({
						...temp.partner
					})
					setOldPartner_id(temp.partner.partner_id);
				}
				if (temp.finances) {
					setFinances(Object.assign({}, temp.finances, { buy_date: temp.finances.buy_date ? moment(temp.finances.buy_date).toDate() : new Date() }))
				}
				setOnlineStatus(temp.online_status !== false)
				if(temp.details && Object.keys(temp.details).length > 0) {
					setDetails(temp.details)
				} else {
					setDetails(getEmptyDetailsObject())
				}
			}

		}
	}, [vehiclesStore])

	const onColorChange = (value) => {
		let temp = Object.assign({}, vehicleDetails, { color: value })
		setVehicleDetails(temp)
	}

	const onMakeChange = (value) => {
		let temp = Object.assign({}, vehicleDetails, { make: value })
		setVehicleDetails(temp)
	}


	const onIdChange = (value) => {
		if (vehiclesStore.vehicle_to_update)
			return
		let temp = Object.assign({}, vehicleDetails, { id: value })
		setVehicleDetails(temp)
	}

	const onOnlineNumberChange = (value, field) => {
		if (isNaN(value))
			return
		let temp = Object.assign({}, details, { [field]: value })

		setDetails(temp)
	}
	const onNumberChange = (value, field) => {

		if (isNaN(value))
			return
		let temp = Object.assign({}, vehicleDetails, { [field]: value })

		setVehicleDetails(temp)
	}

	const onVehicleTypeAdded = (type) => {
		let temp = Object.assign({}, vehicleDetails)
		let tempArray = [...temp.type]
		tempArray.push(type)
		temp.type = tempArray
		setVehicleDetails(temp)
	}

	const onVehicleTypeRemoved = (type) => {
		let temp = Object.assign({}, vehicleDetails)
		temp.type = vehicleDetails.type.filter(e => e !== type);
		setVehicleDetails(temp)
	}

	const onVehicleLocationsAdded = (location) => {
		let temp = Object.assign({}, vehicleDetails)
		let tempArray = [...temp.locations]
		tempArray.push(location)
		temp.locations = tempArray
		setVehicleDetails(temp)
	}

	const onVehicleLocationsRemoved = (location) => {
		let temp = Object.assign({}, vehicleDetails)
		temp.locations = vehicleDetails.locations.filter(e => e !== location);
		setVehicleDetails(temp)
	}

	const onCloseAddNewPressed = () => {
		setImageUrl(null)
		setSelectedImage(null)
		setVehicleDetails(getEmptyObject())
		setFinances(getEmptyFinancesObject())
		setDetails(getEmptyDetailsObject())
		dispatch(updateVehicle(null))
		navigate('/home/vehicles')
	}

	const handleFileChange = (event) => {
		let selectedFile = event.target.files && event.target.files[0]
		if (!selectedFile)
			return
		setSelectedImage(selectedFile);
	}

	const handleUploadImage = () => {
		ReactS3Client
			.uploadFile(selectedImage)
			.then(data => {
				LOGGER.log('success', data)
				let url = Config.AWS_CLOUDFRONT_URL + data.key
				onImageUploaded(url)
			})
			.catch(err => LOGGER.error('error ', err.message))
	}

	const onImageUploaded = (url) => {
		//once we get the URL, save the vehicle info in backend
		vehicleDetails.pictureUrl = url
		cleanseNumberFields()
		let tempVehicleDetails = cleanseNumberFields()
		let tempFinanceDetails = cleanseFinanceNumberFields()
		let tempDetailsDetails = cleansDetailsNumberFields()
		if (vehiclesStore.vehicle_to_update) {
			dispatch(updateVehicleAndConfirm({ token, data: Object.assign({}, tempVehicleDetails, { finances: tempFinanceDetails, online_status: onlineStatus, partner: partnerDetails, oldPartner: oldPartner_id, details: tempDetailsDetails}) }))
		}
		else {
			dispatch(addVehicleAndConfirm({ token, data: Object.assign({}, tempVehicleDetails, {finances: tempFinanceDetails, online_status: onlineStatus, details: tempDetailsDetails}) }))
		}
	}

	const onAddVehiclePressed = () => {
		let flag = false


		let ignoreFields = ['pictureUrl', 'status', 'vin', 'license_plate', 'locations', 'oldPartner', 'online_status']
		Object.keys(vehicleDetails).forEach(field => {
			if (!ignoreFields.includes(field))
				if ((!vehicleDetails[field] || vehicleDetails[field].length === 0) && vehicleDetails[field] != 0) {
					console.log('field is', field)
					flag = true
				}
		})
		if (flag) {
			setShowError(true)
			setErrorMessage('One or more required fields are missing')
			return
		}
		if (isNaN(vehicleDetails.dailyPrice)) {
			setShowError(true)
			setErrorMessage('Please enter a valid daily price in dollars')
			return
		}

		if (isNaN(vehicleDetails.hourlyPrice)) {
			setShowError(true)
			setErrorMessage('Please enter a valid hourly price in dollars')
			return
		}

		if (!selectedImage && !imageUrl) {
			setShowError(true)
			setErrorMessage('Please upload a vehicle picture')
			return
		}

		if (!vehiclesStore.vehicle_to_update && vehiclesMap[vehicleDetails.id]) {
			setShowError(true)
			setErrorMessage('Vehicle identifier is not unique')
			return
		}

		if (!Helpers.checkAccess(mongoUser, Helpers.getRolesMap(settingsState), 'edit_vehicles')) {
			dispatch(changeUserAccess(false))
			return
		}

		setLoading(true)
		if (vehiclesStore.vehicle_to_update) {
			//need to update instead of adding new vehicle
			if (selectedImage) {
				//user could have updated the image of vehicle
				handleUploadImage()
			} else {
				let tempVehicleDetails = cleanseNumberFields()
				let tempFinanceDetails = cleanseFinanceNumberFields()
				let tempDetailsDetails = cleansDetailsNumberFields()
				dispatch(updateVehicleAndConfirm({ token, data: Object.assign({}, tempVehicleDetails, { finances: tempFinanceDetails, partner: partnerDetails, oldPartner: oldPartner_id, online_status: onlineStatus, details: tempDetailsDetails }) }))

			}
		} else {
			handleUploadImage()
		}
	}

	const cleansDetailsNumberFields = () => {
		let temp = Object.assign({}, details)
		temp.msrp = Number(details.msrp)
		temp.engine_bhp = Number(details.engine_bhp)
		temp.seats = Number(details.seats)
		temp['0to60'] = Number(details['0to60'])
		temp.daily_price = Number(details.daily_price)
		temp.deposit = Number(details.deposit)
		temp.mileage_allowance = Number(details.mileage_allowance)
		temp.mileage_overage_price = Number(details.mileage_overage_price)
		temp.fuel_charge = Number(details.fuel_charge)
		return temp
	}

	const cleanseFinanceNumberFields = () => {
		let temp = Object.assign({}, finances)
		temp.buy_price = Number(finances.buy_price)
		temp.down_payment = Number(finances.down_payment)
		temp.term = Number(finances.term)
		temp.monthly_payment = Number(finances.monthly_payment)
		temp.payment_schedule = Number(finances.payment_schedule)
		temp.insurance_monthly_payment = Number(finances.insurance_monthly_payment)
		temp.insurance_payment_schedule = Number(finances.insurance_payment_schedule)
		return temp
	}

	const cleanseNumberFields = () => {
		let temp = Object.assign({}, vehicleDetails)
		temp.dailyPrice = Number(vehicleDetails.dailyPrice)
		temp.hourlyPrice = Number(vehicleDetails.hourlyPrice)
		temp.deposit = Number(vehicleDetails.deposit)
		temp.mileage_allowance = Number(vehicleDetails.mileage_allowance)
		temp.mileage_overage_price = Number(vehicleDetails.mileage_overage_price)
		temp.fuel_charge = Number(vehicleDetails.fuel_charge)
		return temp
	}

	const renderImageSelector = () => {
		if (selectedImage) {
			return (
				<div className={`${Styles.imageContainer}`}>
					<img alt="not fount" width={'100%'} height={'100%'} src={URL.createObjectURL(selectedImage)} style={{ borderRadius: 10 }} />
					<p onClick={() => fileRef.current.click()} style={{ textDecoration: 'underline', cursor: 'pointer' }}>change picture</p>
				</div>
			)
		}

		if (imageUrl) {
			return (
				<div className={`${Styles.imageContainer}`}>
					<img alt="not fount" width={'100%'} height={'100%'} src={imageUrl} style={{ borderRadius: 10 }} />
					<p onClick={() => fileRef.current.click()} style={{ textDecoration: 'underline', cursor: 'pointer' }}>change picture</p>
				</div>
			)
		}

		return (
			<div className={`${Styles.imageContainer} ${GeneralStyles.boxShadow}`}>
				<img
					src={UploadIcon} style={{ width: 50, height: 50, cursor: 'pointer' }} />
				<p>Upload Vehicle Images</p>
				<CustomButtonContained
					style={{ margin: '0 auto', height: 40 }}
					text={'Browse to upload'}
					onClick={() => fileRef.current.click()} />

			</div>
		)
	}

	const getTabContent = () => {
		if (!vehiclesStore.vehicle_to_update)
			return renderVehicleInfo()

		if (tab === 0)
			return renderVehicleInfo()
		else if(tab === 1)
			return renderFinances()
		else
			return renderDetails()
	}

	const renderContent = () => {
		return (
			<div style={{ paddingBottom: 100 }}>
				{
					vehiclesStore.vehicle_to_update &&
					<Tab tab={tab} onChange={setTab} />
				}

				{getTabContent()}
			</div>
		)
	}

	const renderDetails = () => {
		return (
			<div style={{width: '55%', marginTop: 20}}>
				<div className={Styles.formRow} style={{flexDirection: 'row'}}>
					<label style={{ fontSize: Constants.entryTextSize, fontWeight: 'bold', color: Colors.primaryTextColor }}>Available for online booking</label>
					<CustomSwitch
						onChange={setOnlineStatus}
						containerStyle={{marginLeft: 20}}
						checked={onlineStatus}/>
				</div>

				<label style={{ fontSize: Constants.headerSize, fontWeight: 'bold', color: Colors.primaryTextColor }}>Car Details</label>
				<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 20}}>
					<CustomTextFieldNew
						width={'47%'}
						label={'MSRP ($)'}
						placeholder={'vehicle MSRP'}
						value={details.msrp}
						onChange={(value) => {
							if (isNaN(value))
								return
							let temp = Object.assign({}, details, { msrp: value })
							setDetails(temp)
						}}
					/>

					<CustomTextFieldNew
						width={'47%'}
						label={'# of seats'}
						placeholder={'# of seats'}
						value={details.seats}
						onChange={(value) => {
							if (isNaN(value))
								return
							let temp = Object.assign({}, details, { seats: value })
							setDetails(temp)
						}}
					/>
				</div>

				<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 20}}>
					<CustomTextFieldNew
						width={'47%'}
						label={'Engine'}
						placeholder={'engine capacity'}
						value={details.engine_capacity}
						onChange={(value) => {
							let temp = Object.assign({}, details, { engine_capacity: value })
							setDetails(temp)
						}}
					/>

					<CustomTextFieldNew
						width={'47%'}
						label={'Engine Power (BHP)'}
						placeholder={'engine power'}
						value={details.engine_bhp}
						onChange={(value) => {
							if (isNaN(value))
								return
							let temp = Object.assign({}, details, { engine_bhp: value })
							setDetails(temp)
						}}
					/>
				</div>

				<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 20}}>
					<CustomTextFieldNew
						width={'47%'}
						label={'0 to 60 mph (s)'}
						placeholder={'0 to 60 mph'}
						value={details['0to60']}
						onChange={(value) => {
							if (isNaN(value))
								return
							let temp = Object.assign({}, details, { '0to60': value })
							setDetails(temp)
						}}
					/>
				</div>

				<p style={{ fontSize: Constants.headerSize, fontWeight: 'bold', marginTop: 40 }}>Pricing Information</p>


				<div className={Styles.formRow}>
					<CustomTextFieldNew
						width={'100%'}
						label={'Daily Rental Price ($)'}
						placeholder={'Daily Rental Price'}
						value={details.daily_price}
						onChange={(value) => onOnlineNumberChange(value, 'daily_price')}
					/>
				</div>


				<div className={Styles.formRow}>
					<CustomTextFieldNew
						width={'100%'}
						label={'Refundable Security Deposit ($)'}
						placeholder={'Security Deposit Amount'}
						value={details.deposit}
						onChange={(value) => onOnlineNumberChange(value, 'deposit')}
					/>
				</div>


				<p style={{ fontSize: Constants.headerSize, fontWeight: 'bold', marginTop: 40 }}>Mileage Information</p>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '90%', justifyContent: 'space-between' }}>
					<div className={Styles.formRow} style={{ width: '48%' }}>
						<CustomTextFieldNew
							width={'100%'}
							label={'Daily Mileage Allowance (miles)'}
							placeholder={'Daily Mileage'}
							value={details.mileage_allowance}
							onChange={(value) => onOnlineNumberChange(value, 'mileage_allowance')}
						/>
					</div>

					<div className={Styles.formRow} style={{ width: '48%' }}>
						<CustomTextFieldNew
							width={'100%'}
							label={'Mileage Overage Price ($)'}
							placeholder={'Cost Per Mile Over'}
							value={details.mileage_overage_price}
							onChange={(value) => onOnlineNumberChange(value, 'mileage_overage_price')}
						/>
					</div>
				</div>

				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '90%', justifyContent: 'space-between' }}>
					<div className={Styles.formRow} style={{ width: '48%' }}>
						<CustomTextFieldNew
							width={'100%'}
							label={'Fuel Charge ($/10%)'}
							placeholder={'Fuel Charge'}
							value={details.fuel_charge}
							onChange={(value) => onOnlineNumberChange(value, 'fuel_charge')}
						/>
					</div>
				</div>
			</div>
		)
	}
	const renderFinances = () => {
		return (
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', position: 'relative' }}>
				<div style={{ width: '55%', minHeight: '60vh' }}>
					<label style={{ fontSize: Constants.headerSize, fontWeight: 'bold', color: Colors.primaryTextColor }}>Purchase Information</label>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
						<CustomTextFieldNew
							width={'48%'}
							label={'Purchase Price ($)'}
							placeholder={'Vehicle Buy Price'}
							value={finances.buy_price}
							onChange={(value) => {
								if (isNaN(value))
									return
								let temp = Object.assign({}, finances, { buy_price: value })
								setFinances(temp)
							}}
						/>
						<div style={{ width: '48%', cursor: 'pointer' }}>
							{renderFieldWithLogo('Purchase Date', finances.buy_date && moment(finances.buy_date).format('MM/DD/YYYY'), () => setShowDateSelector(true))}
						</div>
					</div>


					{/*2nd row*/}
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 20 }}>
						<CustomTextFieldNew
							width={'48%'}
							label={'Down Payment ($)'}
							placeholder={'Down Payment'}
							value={finances.down_payment}
							onChange={(value) => {
								if (isNaN(value))
									return
								let temp = Object.assign({}, finances, { down_payment: value })
								setFinances(temp)
							}}
						/>

						<div style={{ width: '48%', cursor: 'pointer' }}>
							{renderFieldWithLogo('Term', finances.term ? `${finances.term} months` : '-', () => {
								setDropdownModalField('term')
								setShowDropdownModal(true)
							})}
						</div>
					</div>

					{/*third row*/}
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 20 }}>
						<CustomTextFieldNew
							width={'48%'}
							label={'Monthly Payment ($)'}
							placeholder={'Monthly Payment'}
							value={finances.monthly_payment}
							onChange={(value) => {
								if (isNaN(value))
									return
								let temp = Object.assign({}, finances, { monthly_payment: value })
								setFinances(temp)
							}}
						/>

						<div style={{ width: '48%', cursor: 'pointer' }}>
							{renderFieldWithLogo('Payment Schedule', finances.payment_schedule ? `${finances.payment_schedule}${getMonthString(finances.payment_schedule)} each month` : '-', () => {
								setDropdownModalField('payment_schedule')
								setShowDropdownModal(true)
							})}
						</div>
					</div>

					{/*	4th row*/}
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 20, marginBottom: 40, width: '101.5%' }}>
						<CustomTextFieldNew
							width={'100%'}
							label={'Finance Company'}
							placeholder={'Finance Company Name'}
							value={finances.finance_company}
							onChange={(value) => {
								let temp = Object.assign({}, finances, { finance_company: value })
								setFinances(temp)
							}}
						/>
					</div>

					{/*	insurance information*/}
					<label style={{ fontSize: Constants.headerSize, fontWeight: 'bold', color: Colors.primaryTextColor }}>Insurance Information</label>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
						<CustomTextFieldNew
							width={'48%'}
							label={'Monthly Payment ($)'}
							placeholder={'Monthly Payment'}
							value={finances.insurance_monthly_payment}
							onChange={(value) => {
								if (isNaN(value))
									return
								let temp = Object.assign({}, finances, { insurance_monthly_payment: value })
								setFinances(temp)
							}}
						/>

						<div style={{ width: '48%', cursor: 'pointer' }}>
							{renderFieldWithLogo('Payment Schedule', finances.insurance_payment_schedule ? `${finances.insurance_payment_schedule}${getMonthString(finances.insurance_payment_schedule)} each month` : '-', () => {
								setDropdownModalField('insurance_payment_schedule')
								setShowDropdownModal(true)
							})}
						</div>
					</div>

					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 20, width: '101.5%' }}>
						<CustomTextFieldNew
							width={'100%'}
							label={'Insurance Provider'}
							placeholder={'Insurance Provider'}
							value={finances.insurance_provider}
							onChange={(value) => {
								let temp = Object.assign({}, finances, { insurance_provider: value })
								setFinances(temp)
							}}
						/>
					</div>
				</div>
				<RenderPartnerSplitInformation partner={partnerDetails} setPartner={setPartnerDetails} />
			</div>
		)
	}

	const renderFieldWithLogo = (field, value, onClick) => {
		return (
			<div
				onClick={onClick}
				className={GeneralStyles.boxShadow}
				style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', height: 50, paddingLeft: 10 }}>
				<img src={CalendarIcon} style={{ height: 20, width: 20 }} />
				<div style={{ marginLeft: 10, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
					<label style={{ fontSize: 14, fontWeight: 'bold', color: Colors.primaryTextColor }}>{field}</label>
					<label style={{ fontSize: 17, color: Colors.primaryTextColor }}>{value}</label>
				</div>

			</div>
		)
	}

	const renderVehicleInfo = () => {
		let selectedTypes = vehicleDetails.type || []
		// let selectedLocations = vehicleDetails.locations || []
		let selected = []
		//let selectedLocationElements = []

		selectedTypes.forEach((type, index) => selected.push((
			<div
				onClick={() => onVehicleTypeRemoved(type)}
				className={Styles.selectedTypes}
				key={index.toString()}
			>
				<p style={{ color: Colors.tertiaryTextColor }}>
					{type}
				</p>
				<p
					style={{ paddingLeft: 20, color: Colors.tertiaryTextColor }}>
					X
				</p>
			</div>
		)))

		// selectedLocations.forEach((location,index) => selectedLocationElements.push((
		// 	<div
		// 		onClick={() => onVehicleLocationsRemoved(location)}
		// 		className={Styles.selectedTypes}
		// 		key={location}
		// 		style={{backgroundColor: Colors.buttonTheme, border: `1px solid ${Colors.themeLight}`}}>
		// 		<p>
		// 			{location}
		// 		</p>
		// 		<p
		// 			style={{paddingLeft: 20}}>
		// 			X
		// 		</p>
		// 	</div>
		// )))

		let unSelected = []
		// let unselectedLocationElements = []

		VEHICLE_TYPES.forEach((type, index) => {
			if (!selectedTypes.includes(type))
				unSelected.push((
					<p
						style={{ color: Colors.themeLight }}
						onClick={() => onVehicleTypeAdded(type)}
						className={Styles.unselectedTypes}
						key={type}
					>
						{type}
					</p>
				))
		})

		// LOCATIONS.forEach((location,index) =>  {
		// 	if(!selectedLocations.includes(location))
		// 		unselectedLocationElements.push((
		// 			<p
		// 				onClick={() => onVehicleLocationsAdded(location)}
		// 				className={Styles.unselectedTypes}
		// 				key={location}
		// 				style={{border: `1px solid ${Colors.themeLight}`}}>
		// 				{location}
		// 			</p>
		// 		))
		// })

		return (
			<div className={Styles.formWrapper}>
				<div style={{ width: '60%' }}>
					<label style={{ fontSize: Constants.headerSize, fontWeight: 'bold', color: Colors.primaryTextColor }}>Vehicle Information</label>
					<div className={Styles.formRow}>
						<CustomTextFieldNew
							marginTop={10}
							width={'100%'}
							label={'Vehicle Make & Model'}
							placeholder={'Vehicle Display Name'}
							value={vehicleDetails.make}
							onChange={onMakeChange}
						/>
					</div>

					<div className={Styles.formRow}>
						<CustomTextFieldNew
							width={'100%'}
							label={'Unique Identifier'}
							placeholder={'Availability Tracking Code'}
							value={vehicleDetails.id}
							onChange={onIdChange}
						/>
					</div>

					<div className={Styles.formRow}>
						<CustomTextFieldNew
							width={'100%'}
							label={'Vehicle Color'}
							placeholder={'Vehicle Color'}
							value={vehicleDetails.color}
							onChange={onColorChange}
						/>
					</div>

					<div className={Styles.formRow}>
						<CustomTextFieldNew
							width={'100%'}
							label={'License Plate'}
							placeholder={'License Plate Number'}
							value={vehicleDetails.license_plate}
							onChange={(text) => {
								let temp = Object.assign({}, vehicleDetails, { license_plate: text })
								setVehicleDetails(temp)
							}}
						/>
					</div>

					<div className={Styles.formRow}>
						<CustomTextFieldNew
							width={'100%'}
							label={'VIN Number'}
							placeholder={'VIN #'}
							value={vehicleDetails.vin}
							onChange={(text) => {
								let temp = Object.assign({}, vehicleDetails, { vin: text })
								setVehicleDetails(temp)
							}}
						/>
					</div>

					<p style={{ fontSize: Constants.headerSize, fontWeight: 'bold', marginTop: 40 }}>Pricing Information</p>


					<div className={Styles.formRow}>
						<CustomTextFieldNew
							width={'100%'}
							label={'Daily Rental Price ($)'}
							placeholder={'Daily Rental Price'}
							value={vehicleDetails.dailyPrice}
							onChange={(value) => onNumberChange(value, 'dailyPrice')}
						/>
					</div>

					<div className={Styles.formRow}>
						<CustomTextFieldNew
							width={'100%'}
							label={'Chauffeur Hourly Price ($)'}
							placeholder={'Chauffeur Hourly Price'}
							value={vehicleDetails.hourlyPrice}
							onChange={(value) => onNumberChange(value, 'hourlyPrice')}
						/>
					</div>

					<div className={Styles.formRow}>
						<CustomTextFieldNew
							width={'100%'}
							label={'Refundable Security Deposit ($)'}
							placeholder={'Security Deposit Amount'}
							value={vehicleDetails.deposit}
							onChange={(value) => onNumberChange(value, 'deposit')}
						/>
					</div>


					<p style={{ fontSize: Constants.headerSize, fontWeight: 'bold', marginTop: 40 }}>Mileage Information</p>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '90%', justifyContent: 'space-between' }}>
						<div className={Styles.formRow} style={{ width: '48%' }}>
							<CustomTextFieldNew
								width={'100%'}
								label={'Daily Mileage Allowance (miles)'}
								placeholder={'Daily Mileage'}
								value={vehicleDetails.mileage_allowance}
								onChange={(value) => onNumberChange(value, 'mileage_allowance')}
							/>
						</div>

						<div className={Styles.formRow} style={{ width: '48%' }}>
							<CustomTextFieldNew
								width={'100%'}
								label={'Mileage Overage Price ($)'}
								placeholder={'Cost Per Mile Over'}
								value={vehicleDetails.mileage_overage_price}
								onChange={(value) => onNumberChange(value, 'mileage_overage_price')}
							/>
						</div>
					</div>

					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '90%', justifyContent: 'space-between' }}>
						<div className={Styles.formRow} style={{ width: '48%' }}>
							<CustomTextFieldNew
								width={'100%'}
								label={'Fuel Charge ($/10%)'}
								placeholder={'Fuel Charge'}
								value={vehicleDetails.fuel_charge}
								onChange={(value) => onNumberChange(value, 'fuel_charge')}
							/>
						</div>
					</div>


					{/*<div className={Styles.formRow}>*/}
					{/*	<label style={{fontWeight: 'bold', marginBottom: 5}}>Locations</label>*/}
					{/*	<div className={Styles.vehicleTypeWrapper} style={{backgroundColor: Colors.theme}}>*/}
					{/*		<div className={Styles.formRow} style={{paddingLeft: 20, marginBottom: 10, marginTop: 10}}>*/}
					{/*			<label style={{marginBottom: 5}}>Selected:</label>*/}
					{/*			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: 60}}>*/}
					{/*				{selectedLocationElements}*/}
					{/*			</div>*/}
					{/*		</div>*/}
					{/*		<div className={Styles.formRow} style={{paddingLeft: 20}}>*/}
					{/*			<label style={{marginBottom: 5}}>Choose from the following:</label>*/}
					{/*			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: 60}}>*/}
					{/*				{unselectedLocationElements}*/}
					{/*			</div>*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*</div>*/}
				</div>


				<div style={{ width: '40%' }}>
					<div>
						<label className={GeneralStyles.darkText} style={{ fontSize: Constants.headerSize, fontWeight: 'bold' }}>Vehicle Picture</label>
						{renderImageSelector()}
						<input
							id="image"
							name="user[image]"
							type="file"
							accept="image/*"
							style={{ display: 'none' }}
							onChange={handleFileChange}
							ref={fileRef} />
					</div>

					<div style={{ marginTop: 30, width: '98%' }}>
						<label style={{ fontSize: Constants.headerSize, fontWeight: 'bold', color: Colors.primaryTextColor }}>Vehicle Type</label>
						<div className={`${Styles.vehicleTypeWrapper} ${GeneralStyles.boxShadow}`}>
							<div className={Styles.formRow} style={{ paddingLeft: 20, marginBottom: 10, marginTop: 10 }}>
								<label className={GeneralStyles.darkText} style={{ marginBottom: 5 }}>Selected:</label>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 60 }}>
									{selected}
								</div>
							</div>
							<div className={Styles.formRow} style={{ paddingLeft: 20 }}>
								<label className={GeneralStyles.darkText} style={{ marginBottom: 5 }}>Choose from the following:</label>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 60 }}>
									{unSelected}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	const getMonthString = (value) => {
		if (!value || value.length === 0)
			return ''

		if (value == 1)
			return 'st'
		else if (value == 2)
			return 'nd'
		else if (value == 3)
			return 'rd'
		else
			return 'th'
	}

	const dropdownSelectorModal = () => {

		return (
			<CustomModal
				show={showDropdownModal}
				handleClose={() => setShowDropdownModal(false)}
				containerWidth={window.innerWidth / 2}
				containerHeight={window.innerHeight / 2.4}
			>
				<div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: Colors.backgroundColor }}>
					{
						dropdownModalField === 'term' ?
							<p className={GeneralStyles.darkText} style={{ fontWeight: '600' }}>Payment Term</p>
							:
							dropdownModalField === 'payment_schedule' ?
								<p className={GeneralStyles.darkText} style={{ fontWeight: '600' }}>Payment Schedule</p>
								:
								<p className={GeneralStyles.darkText} style={{ fontWeight: '600' }}>Insurance Payment Schedule</p>
					}



					<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
						<input
							placeholder={''}
							value={finances[dropdownModalField]?.toString()}
							style={
								{
									height: 25,
									width: 40,
									color: Colors.primaryTextColor,
									fontSize: 17,
									border: 0,
									borderBottom: `1px solid gray`
								}}
							onChange={(e) => {
								let text = e.target.value
								if (isNaN(text))
									return

								if (dropdownModalField !== 'term' && Number(text) > 31)
									return

								let temp = Object.assign({}, finances, { [dropdownModalField]: text })
								setFinances(temp)
							}}
						/>
						{
							dropdownModalField === 'term' ?
								<p className={GeneralStyles.darkText} style={{ marginLeft: 10 }}>months</p>
								:
								<p className={GeneralStyles.darkText} style={{ marginLeft: 10 }}>{`${getMonthString(finances[dropdownModalField])} each month`}</p>
						}
					</div>



					<CustomButtonContained
						text={'Done'}
						onClick={() => {
							setShowDropdownModal(false)
						}}
						style={{ marginTop: 10 }}
					/>

				</div>
			</CustomModal>
		)

	}


	const renderDateSelectorModal = () => {
		return (
			<CustomModal
				show={showDateSelector}
				handleClose={() => setShowDateSelector(false)}
				containerWidth={window.innerWidth / 1.5}
				containerHeight={window.innerHeight / 1.5}
			>
				<div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: Colors.backgroundColor }}>
					<Calendar
						date={finances?.buy_date}
						onChange={(date) => {
							let temp = Object.assign({}, finances, { buy_date: date })
							setFinances(temp)
						}}
					/>

					<CustomButtonContained
						text={'Done'}
						onClick={() => {
							setShowDateSelector(false)
						}}
						style={{ marginTop: 3 }}
					/>

				</div>
			</CustomModal>
		)
	}

	if (loading) {
		return (
			<div className={GeneralStyles.container} style={{ height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
				<CustomLoaderSpinner />
			</div>
		)
	}

	return (
		<div className={GeneralStyles.container}>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative' }}>
				<PageHeader header={vehiclesStore.vehicle_to_update ? 'Update Vehicle' : 'Add New Vehicle'} subHeader={`${moment().format('dddd, MMM Do')} | ${moment().format('h:mm A')}`} />
				<div style={{ position: 'absolute', right: 0, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<CustomButtonContained
						style={{ marginRight: 2 }}
						text={vehiclesStore.vehicle_to_update ? `\u2713 Save Changes` : '+ Save New Vehicle'}
						onClick={onAddVehiclePressed} />
					<img
						onClick={onCloseAddNewPressed}
						src={BackArrow} style={{ width: 40, height: 40, cursor: 'pointer' }} />
				</div>

			</div>

			<PositionedSnackbar
				onClose={() => {
					setShowError(false)
					setShowSuccess(false)
					setErrorMessage('')
					setSuccessMessage('')
				}}
				severity={showError ? 'error' : 'success'}
				openFlag={showError || showSuccess}
				message={showError ? errorMessage : successMessage}
			/>

			{renderContent()}

			{renderDateSelectorModal()}
			{dropdownSelectorModal()}


		</div>
	)
}

const Tab = ({ tab, onChange }) => {
	return (
		<div style={{ marginBottom: 20, width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: 10, border: '1px solid gray', padding: 4, backgroundColor: Colors.theme, borderColor: Colors.theme }}>
			<div
				className={Styles.tabEntry}
				style={{ backgroundColor: tab === 0 ? Colors.tabButtonTheme : 'transparent', }}
				onClick={() => onChange(0)}>
				<p style={{ fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor }}>General Info</p>
			</div>
			<div
				className={Styles.tabEntry}
				style={{ backgroundColor: tab === 1 ? Colors.tabButtonTheme : 'transparent', }}
				onClick={() => onChange(1)}>
				<p style={{ fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor }}>Finances</p>
			</div>
			<div
				className={Styles.tabEntry}
				style={{ backgroundColor: tab === 2 ? Colors.tabButtonTheme : 'transparent', }}
				onClick={() => onChange(2)}>
				<p style={{ fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor }}>Online Booking</p>
			</div>

		</div>
	)
}

export default NewVehicle
