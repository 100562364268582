import React, {useState, useEffect} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import PageHeader from "../../components/general/PageHeader";
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import {useDispatch, useSelector} from "react-redux";
import {API_CALL_STATUS, CLAIM_STATUS} from "../../metadata/enums";
import {LOGGER} from "../../utils/Logger";
import moment from "moment";
import Styles from "../Analytics/styles/Analytics.module.scss";
import Colors from "../../config/colors";
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";
import Constants from "../../config/constants";
import CustomSelect from "../../components/general/CustomSelect";
import {vehiclesMapSelector} from "../../redux/slices/vehiclesSlice";
import {clientsMapSelector} from "../../redux/slices/clientsSlice";
import {reservationsMapSelector} from "../../redux/slices/reservationsSlice";
import CustomModal from "../../components/general/CustomModal";
import {Calendar} from "react-date-range";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import colors from "../../config/colors";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import CustomButtonContained from '../../components/general/CustomButtonContained'
import CustomTextArea from '../../components/general/CustomTextArea'
import CustomTextFieldNew from '../../components/general/CustomTextFieldNew'
import Helpers from "../../utils/helpers";
import {addClaimAndConfirm, updateClaimAndConfirm, updateClaimToUpdate} from '../../redux/slices/claimsSlice'
import {useNavigate} from 'react-router-dom'
import BackArrow from "../../assets/logos/backArrow.svg";

const getEmptyClaims = () => {
	return {
		details: '',
		insurance_provider: '',
		policy_no: '',
		claim_no: '',
		poc: '',
		phone: '',
		email: ''
	}
}

const NewClaim = () => {
	let navigate = useNavigate()
	let dispatch = useDispatch()

	let claimsState = useSelector(state => state.claims)
	let reservationsState = useSelector(state => state.reservations)
	const vehiclesMap = useSelector(state => vehiclesMapSelector(state))
	const clientsMap = useSelector(state => clientsMapSelector(state.clients))
	const reservationsMap = useSelector(state => reservationsMapSelector(state.reservations))
	const token = useSelector(state => state.auth.token)

	const [showChangeStatusModal, setShowChangeStatusModal] = useState(false)
	const [loading, setLoading] = useState(true)
	const [showSuccess, setShowSuccess] = useState(false)
	const [successMessage, setSuccessMessage] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [showError, setShowError] = useState(false)
	const [claimToUpdate, setClaimToUpdate] = useState(null)
	const [currentTime, setCurrentTime] = useState(new Date())
	const [reservations, setReservations] = useState([])
	const [selectedRes, setSelectedRes] = useState(null)
	const [showDateSelector, setShowDateSelector] = useState(false)
	const [resDetails, setResDetails] = useState({})
	const [clientDetails, setClientDetails] = useState({})
	const [vehicleDetails, setVehicleDetails] = useState({})
	const [dateSelected, setDateSelected] = useState(false)
	const [accidentDate, setAccidentDate] = useState(moment().toDate())
	const [time, setTime] = useState(moment().minutes(0).hours(10).toDate())
	const [claimDetails, setClaimDetails] = useState({
		details: '',
		insurance_provider: '',
		policy_no: '',
		claim_no: '',
		poc: '',
		phone: '',
		email: ''
	})
	const [update, setUpdate] = useState('')
	const [showNewUpdate, setShowNewUpdate] = useState(false)
	const [updates, setUpdates] = useState([])

	useEffect(() => {
		if(reservationsState.status === API_CALL_STATUS.LOADING) {
			setLoading(true)
		} else if(reservationsState.status === API_CALL_STATUS.FAILED) {
			setLoading(true)
			setShowError(true)
			setErrorMessage('something went wrong when getting the reservations data')
			LOGGER.error('error effect', reservationsState.error)
		} else {
			setLoading(false)
			let {reservations, old_reservations} = reservationsState
			setReservations(reservations.concat(old_reservations))
		}
	},[reservationsState])

	useEffect(() => {
		if(claimsState.status === API_CALL_STATUS.LOADING) {
			setLoading(true)
		} else if(claimsState.status === API_CALL_STATUS.FAILED) {
			setLoading(false)
			setShowError(true)
			setErrorMessage(claimsState.error)
		} else {
			setLoading(false)
			if(claimsState.claim_added || claimsState.claim_updated) {
				setClaimDetails(getEmptyClaims())
				navigate('/home/claims')
			}

			if(claimsState.claim_to_update) {
				setClaimToUpdate(claimsState.claim_to_update)
				setUpdates(claimsState.claim_to_update?.updates || [])
				setClaimDetails(claimsState.claim_to_update)
				setAccidentDate(moment(claimsState.claim_to_update.date).toDate())
				setTime(moment(claimsState.claim_to_update.date).toDate())
				setDateSelected(true)
			}
		}
	},[claimsState])

	useEffect(() => {
		if(claimToUpdate && !selectedRes) {
			let res = reservationsMap[claimToUpdate.res_id]
			if(res && Object.keys(vehiclesMap).length > 0 && Object.keys(clientsMap).length > 0) {
				let client = clientsMap[res.client]
				let vehicle = vehiclesMap[res.vehicle_id]
				setSelectedRes({label: `Res #${res._id} - ${client?.fName} ${client?.lName} - ${vehicle?.make} - ${moment(res?.pickup_datetime).format('MM/DD/YYYY')}`, value: res})
			}
		}
	},[reservationsMap, clientsMap, vehiclesMap, claimToUpdate])

	useEffect(() => {
		if(selectedRes) {
			let res = selectedRes.value
			setClientDetails(clientsMap[res.client])
			setVehicleDetails(vehiclesMap[res.vehicle_id])
		}
	},[selectedRes])

	const validateData = () => {
		if(!selectedRes) {
			setErrorMessage('Please select the reservation')
			return false
		}


		let flag = false
		Object.keys(claimDetails).forEach(field => {
			if(field !== 'updates' && (!claimDetails[field] || claimDetails[field].length === 0)) {
				flag = true
			}
		})

		if(flag) {
			setErrorMessage('Missing some information')
			return false
		}

		if(!Helpers.validateEmail(claimDetails.email)) {
			setErrorMessage(`Invalid Email`)
			return false
		}

		if(claimDetails.phone.length !== 14) {
			setErrorMessage(`Invalid phone number`)
			return false
		}

		if(!dateSelected) {
			setErrorMessage('Please select the accident date and time')
			return false
		}

		return true

	}

	const onSubmitClicked = () => {
		if(!validateData()) {
			setShowError(true)
			return
		}

		let toSave = Object.assign({}, claimDetails)
		let res = selectedRes?.value || {}
		toSave['res_id'] = res._id
		toSave['client_id'] = res.client
		toSave['vehicle_id'] = res.vehicle_id
		let tempTime = moment(time)
		toSave['date'] = moment(accidentDate).hours(tempTime.hours()).minutes(tempTime.minutes()).toDate().toISOString()
		toSave['updates'] = [...updates]

		//update claim
		if(claimToUpdate) {
			if(update) {
				//new updated added
				toSave['updates'].push({
					text: update,
					created_at: new Date().toISOString()
				})
			}
			toSave['status'] = claimDetails.status
			dispatch(updateClaimAndConfirm({token, data: toSave}))
		} else {
			toSave['status'] = CLAIM_STATUS.CREATED
			dispatch(addClaimAndConfirm({token, data: toSave}))
		}

	}

	const onAddUpdatePressed = () => {
		if(showNewUpdate) {
			setUpdate('')
			setShowNewUpdate(false)
		} else {
			setShowNewUpdate(true)
		}
	}

	const renderUpdates = () => {
		let temp = [...updates]
		let updateElements = temp?.reverse().map((update,index) => (
			<div key={index.toString()} style={{width: '100%'}}>
				<CustomTextArea
					marginTop={index === 0 ? 4 : 10}
					label={moment(update.created_at).format('MM/DD/YYYY : hh:mm A')}
					value={update.text}
					onChange={(text) => {
						let temp = JSON.parse(JSON.stringify(updates))
						temp[index] = {
							text: text,
							created_at: update.created_at,
							updated_at: new Date().toISOString()
						}
						setUpdates(temp)
					}}
				/>
			</div>
		))

		return (
			<div>
				<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
					<label style={{fontWeight: 'bold', fontSize: 18, color: Colors.primaryTextColor}}>Updates</label>
					<label onClick={onAddUpdatePressed} style={{fontSize: 15, color: Colors.primaryTextColor, fontWeight: 400, cursor: 'pointer'}}>{!showNewUpdate ? '+ Add Update' : 'Clear'}</label>
				</div>

				{showNewUpdate &&
					<div style={{width: '100%', marginTop: 10}}>
						<label style={{fontSize: Constants.labelSize, color: Colors.primaryTextColor}}>New Update</label>
						<CustomTextArea
							marginTop={4}
							label={'Update Notes'}
							value={update}
							onChange={setUpdate}
						/>
					</div>
				}


				<div style={{marginTop: 10}}>
					<label style={{fontSize: Constants.labelSize, color: Colors.primaryTextColor}}>Current Claim Updates</label>
					{updates.length > 0 ?
						updateElements
						:
						<p style={{marginTop: 10, color: Colors.secondaryTextColor}}>No Updates Yet</p>
					}

				</div>

			</div>
		)
	}



	const renderResInfo = () => {
		let res = selectedRes?.value
		if(!res)
			return

		return (
			<div style={{width: '100%', paddingBottom: 100}}>
				<label className={`${GeneralStyles.darkText} ${GeneralStyles.entryLabel}`}>{`Reservation Info (prefilled from res #${selectedRes?.value?._id})`}</label>
				<CustomTextFieldNew
					disabled={true}
					marginTop={4}
					width={'100%'}
					label={'Client Name'}
					value={`${clientDetails?.fName} ${clientDetails?.lName}`}
					onChange={null}
				/>

				<CustomTextFieldNew
					disabled={true}
					marginTop={20}
					width={'100%'}
					label={'Client Phone #'}
					value={`${clientDetails?.phone}`}
					onChange={null}
				/>

				<CustomTextFieldNew
					disabled={true}
					marginTop={20}
					width={'100%'}
					label={'Rented Vehicle'}
					value={`${vehicleDetails?.make}`}
					onChange={null}
				/>

				<CustomTextFieldNew
					disabled={true}
					marginTop={20}
					width={'100%'}
					label={'Rental Dates'}
					value={`${moment(res.pickup_datetime).format('MM/DD/YY')} - ${moment(res.dropoff_datetime).format('MM/DD/YY')}`}
					onChange={null}
				/>
			</div>
		)
	}

	const renderAccidentInfo = () => {
		let options = reservations.map(res => {
			let client = clientsMap[res.client]
			let vehicle = vehiclesMap[res.vehicle_id]
			return {label: `Res #${res._id} - ${client?.fName} ${client?.lName} - ${vehicle?.make} - ${moment(res?.pickup_datetime).format('MM/DD/YYYY')}`, value: res}
		})

		return (
			<div style={{width: '100%', marginTop: 10}}>
				{!claimToUpdate &&
					<div style={{width: '100%'}}>
						<label className={`${GeneralStyles.darkText} ${GeneralStyles.entryLabel}`}>Reservation #</label>
						<div
							className={GeneralStyles.boxShadow}
							style={{width: '100%', height: 40, borderRadius: 10, marginTop: 4, marginBottom: 20}}>
							<CustomSelect
								placeholder={'select'}
								borderColor={'transparent'}
								value={selectedRes}
								options={options}
								onChange={setSelectedRes}
							/>
						</div>
					</div>
				}

				<label className={`${GeneralStyles.darkText} ${GeneralStyles.entryLabel}`}>Accident Date</label>
				<div
					onClick={() => setShowDateSelector(true)}
					className={GeneralStyles.boxShadow}
					style={{width: '100%', height: 40, borderRadius: 10, marginTop: 4, display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', marginBottom: 20}}>
					<p style={{paddingLeft: 10, fontSize: Constants.entryTextSize}} className={GeneralStyles.darkText}>
						{dateSelected ? `${moment(accidentDate).format('MMM Do, YYYY - ')} ${moment(time).format('hh:mm A')}` : 'Date & Time of the Accident'}
					</p>
				</div>

				<div style={{width: '97%'}}>
					<CustomTextArea
						label={'Accident Detail & Notes'}
						value={claimDetails.details}
						onChange={(text) => {
							let temp = Object.assign({}, claimDetails, {details: text})
							setClaimDetails(temp)
						}}
					/>
				</div>
			</div>
		)
	}

	const onChangeStatusClicked = (isAccepted) => {
		let temp = Object.assign({}, claimDetails)
		temp['status'] = isAccepted ? CLAIM_STATUS.ACCEPTED : CLAIM_STATUS.REJECTED
		setClaimDetails(temp)
		setShowChangeStatusModal(false)
		let toSave = {
			_id: claimToUpdate._id,
			status: isAccepted ? CLAIM_STATUS.ACCEPTED : CLAIM_STATUS.REJECTED,
		}

		if(isAccepted)
			toSave['accepted_at'] = new Date().toISOString()
		else
			toSave['rejected_at'] = new Date().toISOString()

		dispatch(updateClaimAndConfirm({token, data: toSave}))
	}

	const renderStatusSelectModal = () => {
		return (
			<CustomModal
				show={showChangeStatusModal}
				handleClose={() => setShowChangeStatusModal(false)}
				containerWidth={window.innerWidth/2}
				containerHeight={window.innerHeight/2}
			>
				<div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
					<CustomButtonContained
						text={'Claim Accepted'}
						onClick={() => onChangeStatusClicked(true)}
					/>

					<CustomButtonContained
						text={'Claim Rejected'}
						onClick={() => onChangeStatusClicked(false)}
						style={{marginTop: 4}}
					/>

				</div>
			</CustomModal>
		)
	}

	const renderDateSelectorModal = () => {
		return (
			<CustomModal
				show={showDateSelector}
				handleClose={() => setShowDateSelector(false)}
				containerWidth={window.innerWidth/1.5}
				containerHeight={window.innerHeight/1.5}
			>
				<div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: Colors.backgroundColor}}>
						<Calendar
							date={accidentDate}
							onChange={setAccidentDate}
						/>

						<div style={{marginTop: 30, backgroundColor: Colors.backgroundColor}}>

							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<TimePicker
									value={time}
									onChange={setTime}
									renderInput={(params) => {
										return <TextField
											sx={{
												'.MuiOutlinedInput-root': {backgroundColor: Colors.theme, color: Colors.tertiaryTextColor, height: 30, borderRadius: 5},
												'.MuiSvgIcon-root': {color: Colors.tertiaryTextColor},
											}}
											InputLabelProps={{style:{color: colors.tertiaryTextColor}}}
											{...params} />
									}}
								/>
							</LocalizationProvider>
						</div>

					<CustomButtonContained
						text={'Done'}
						onClick={() => {
							setDateSelected(true)
							setShowDateSelector(false)
						}}
						style={{marginTop: 3}}
					/>

				</div>
			</CustomModal>
		)
	}

	const renderInsuranceInfo = () => {
		return (
			<div style={{marginTop: 30, width: '100%', marginBottom: 30}}>

				<label className={`${GeneralStyles.darkText} ${GeneralStyles.entryLabel}`}>{`Insurance Information`}</label>
				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 4, width: '100%', marginBottom: 20}}>
					<div style={{width: '48%'}}>
						<CustomTextFieldNew
							width={'96%'}
							label={'Insurance Provider'}
							placeholder={'Insurance Provider'}
							value={claimDetails.insurance_provider}
							onChange={(text) => {
								let temp = Object.assign({}, claimDetails, {insurance_provider: text})
								setClaimDetails(temp)
							}}
						/>
					</div>

					<div style={{width: '48%', marginLeft: '4%'}}>
						<CustomTextFieldNew
							width={'96%'}
							placeholder={'Policy #'}
							label={'Policy Number'}
							value={claimDetails.policy_no}
							onChange={(text) => {
								let temp = Object.assign({}, claimDetails, {policy_no: text})
								setClaimDetails(temp)
							}}
						/>
					</div>

				</div>

				<label className={`${GeneralStyles.darkText} ${GeneralStyles.entryLabel}`}>{`Insurance Contact Info`}</label>
				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 4, width: '100%', marginBottom: 20}}>
					<div style={{width: '48%'}}>
						<CustomTextFieldNew
							width={'96%'}
							label={'Claim Number'}
							placeholder={'Claim #'}
							value={claimDetails.claim_no}
							onChange={(text) => {
								let temp = Object.assign({}, claimDetails, {claim_no: text})
								setClaimDetails(temp)
							}}
						/>
					</div>

					<div style={{width: '48%', marginLeft: '4%'}}>
						<CustomTextFieldNew
							width={'96%'}
							placeholder={'Full Name'}
							label={'Point of Contact'}
							value={claimDetails.poc}
							onChange={(text) => {
								let temp = Object.assign({}, claimDetails, {poc: text})
								setClaimDetails(temp)
							}}
						/>
					</div>

				</div>


				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 4, width: '100%'}}>
					<div style={{width: '48%'}}>
						<CustomTextFieldNew
							width={'96%'}
							label={'Phone Number'}
							placeholder={'Phone #'}
							value={claimDetails.phone}
							onChange={(text) => {
								let temp = Object.assign({}, claimDetails, {phone: Helpers.checkPhoneNumberFormat(text)})
								setClaimDetails(temp)
							}}
						/>
					</div>

					<div style={{width: '48%', marginLeft: '4%'}}>
						<CustomTextFieldNew
							width={'96%'}
							placeholder={'Email Address'}
							label={'Email Address'}
							value={claimDetails.email}
							onChange={(text) => {
								let temp = Object.assign({}, claimDetails, {email: text})
								setClaimDetails(temp)
							}}
						/>
					</div>

				</div>

			</div>
		)
	}

	const renderContent = () => {
		return (
			<div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
				<div style={{width: '58%', marginTop: 10}}>
					<label style={{fontWeight: 'bold', width: '100%', fontSize: 18, color: Colors.primaryTextColor}}>Claim Information</label>
					{renderAccidentInfo()}
					{renderInsuranceInfo()}
					{selectedRes && renderResInfo()}
				</div>
				<div style={{width: '38%', marginLeft: '2%'}}>
					{claimToUpdate && renderUpdates()}
				</div>

			</div>
		)
	}

	if(loading) {
		return (
			<div className={GeneralStyles.container} style={{height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
				<CustomLoaderSpinner />
			</div>
		)
	}


	const getSubHeader = () => {
		if(!claimToUpdate) {
			return `${moment(currentTime).format('dddd, MMM Do | hh:mm A')}`
		} else {
			let string = `${vehiclesMap[claimToUpdate?.vehicle_id]?.make} | Status: `
			let statusText
			switch(claimDetails?.status) {
				case CLAIM_STATUS.CREATED:
					statusText = 'Claim Created'
					break
				case CLAIM_STATUS.ACCEPTED:
					statusText = `Claim Accepted`
					break
				case CLAIM_STATUS.REJECTED:
					statusText = 'Claim Rejected'
					break
				case CLAIM_STATUS.CANCELLED:
					statusText = 'Claim Cancelled'
					break
			}

			return string + statusText
		}
	}

	return (
		<div className={GeneralStyles.container}>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
				<PageHeader
					header={claimToUpdate ? `Claim - #${claimToUpdate.claim_no}` : `Create New Claim`}
					subHeader={getSubHeader()}
				/>

				<div style={{position: 'absolute', right: 0, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					{
						claimToUpdate &&
						<CustomButtonContained
							borderColor={Colors.themeLight}
							color={'secondary'}
							text={'Change Status'}
							onClick={() => setShowChangeStatusModal(true)}
							style={{marginRight: 2}}
						/>
					}
					<CustomButtonContained
						text={claimToUpdate ? `\u2713 Save Changes` : `\u2713 Start New Claim`}
						onClick={onSubmitClicked}
						style={{marginRight: 2}}
					/>
					<img
						onClick={() => {
							dispatch(updateClaimToUpdate(null))
							setClaimToUpdate(getEmptyClaims())
							navigate(-1)
						}}
						src={BackArrow} style={{width: 40, height: 40, cursor: 'pointer'}}/>
				</div>

			</div>

			<PositionedSnackbar
				onClose={() => {
					setShowError(false)
					setShowSuccess(false)
					setErrorMessage('')
					setSuccessMessage('')
				}}
				severity={showError ? 'error' : 'success'}
				openFlag={showError || showSuccess}
				message={showError ? errorMessage : successMessage}
			/>

			{renderContent()}

			{renderDateSelectorModal()}
			{renderStatusSelectModal()}
		</div>
	)
}

export default NewClaim
