import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {API_CALL_STATUS} from '../../metadata/enums'
import {GET_ALL_NOTIFICATIONS, UPDATE_ONE_NOTIFICATION} from '../actionTypes'
import Config from "../../config";
import axios from "axios";
import {LOGGER} from "../../utils/Logger";

const initialState = {
  notifications: [],
  status: API_CALL_STATUS.IDLE,
  error: null,
}

export const getAllNotifications = createAsyncThunk(GET_ALL_NOTIFICATIONS, async (payload) => {
  const config = {
    method: 'get',
    url: `${Config.BACKEND_URL}notifications`,
    headers: {Authorization: payload.token},
  }

  try {
    let res = await axios(config)
    return res.data
  } catch (err) {
    LOGGER.error('Error when getting all notifications', err)
    return []
  }

})

export const updateNotifAndConfirm = createAsyncThunk(UPDATE_ONE_NOTIFICATION, async (payload) => {
  try {
    let id = payload.data._id
    const config = {
      method: 'put',
      url: `${Config.BACKEND_URL}notifications/${id}`,
      headers: {Authorization: payload.token, contentType: "application/json",},
      data: payload.data
    }

    await axios(config)
    console.log('updated')
    return payload.data
  } catch (err) {
    LOGGER.log('error when adding updating notification', err)
  }

  // return order
})

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllNotifications.rejected, (state, action) => {
        state.status = API_CALL_STATUS.FAILED
        state.error = action.error.message
      })
      .addCase(getAllNotifications.pending, (state, action) => {
        state.status = API_CALL_STATUS.LOADING
      })
      .addCase(getAllNotifications.fulfilled, (state, action) => {
        state.status = API_CALL_STATUS.SUCCEEDED
        state.notifications = action.payload
      })
      .addCase(updateNotifAndConfirm.rejected, (state, action) => {
        state.status = API_CALL_STATUS.FAILED
        state.error = action.error.message
      })
      .addCase(updateNotifAndConfirm.pending, (state, action) => {
        state.status = API_CALL_STATUS.LOADING
      })
      .addCase(updateNotifAndConfirm.fulfilled, (state, action) => {
        state.status = API_CALL_STATUS.SUCCEEDED
        let temp = []
        state.notifications.forEach(notif => {
          if (notif._id === action.payload._id)
            temp.push(Object.assign({}, notif, action.payload))
          else
            temp.push(notif)
        })
        state.notifications = temp
      })
  }
})


// export const { } = notificationsSlice.actions

export default notificationsSlice.reducer
