const CONSTANTS = {
	tableHeaderSize: 13,
	entryTextSize: 15,
	headerSize: 18,
	subHeaderSize: 14,
	labelSize: 16
}

//header size 13
//main texst 15
//left nav bar 18 regular

export default CONSTANTS
