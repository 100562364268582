import React, {useState, useEffect} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import PageHeader from "../../components/general/PageHeader";
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import {useDispatch, useSelector} from "react-redux";
import {API_CALL_STATUS} from "../../metadata/enums";
import {LOGGER} from "../../utils/Logger";
import moment from "moment";
import Colors from "../../config/colors";
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";
import RollsRoyceImage from '../../assets/images/rolls.jpeg'

const GPSHome = () => {
	let settingsState = useSelector(state => state.settings)
	const [loading, setLoading] = useState(true)
	const [showSuccess, setShowSuccess] = useState(false)
	const [successMessage, setSuccessMessage] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [showError, setShowError] = useState(false)

	useEffect(() => {
		if(settingsState.status === API_CALL_STATUS.LOADING) {
			setLoading(true)
		} else if(settingsState.status === API_CALL_STATUS.FAILED) {
			setLoading(true)
			setShowError(true)
			setErrorMessage('something went wrong when getting the settings data')
			LOGGER.error('error effect', settingsState.error)
		} else {
			setLoading(false)
		}
	},[settingsState])

	const renderContent = () => {
		return (
			<div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				<h4 className={GeneralStyles.darkText} style={{fontSize: 30}}>Coming Soon!!!</h4>
			</div>
		)
	}

	if(loading) {
		return (
			<div className={GeneralStyles.container} style={{height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
				<CustomLoaderSpinner />
			</div>
		)
	}

	return (
		<div className={GeneralStyles.container}>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
				<PageHeader header={'GPS Telematics'}/>
				{/*<CustomButtonContained*/}
				{/*	text={'+ New Reservation'}*/}
				{/*	onClick={onNewReservationClicked}*/}
				{/*	style={{position: 'absolute', right: 40, zIndex: 10}}*/}
				{/*	color={showOverlay ? 'white' : 'primary'}*/}
				{/*/>*/}
			</div>

			<PositionedSnackbar
				onClose={() => {
					setShowError(false)
					setShowSuccess(false)
					setErrorMessage('')
					setSuccessMessage('')
				}}
				severity={showError ? 'error' : 'success'}
				openFlag={showError || showSuccess}
				message={showError ? errorMessage : successMessage}
			/>

			{renderContent()}
		</div>
	)
}

export default GPSHome
