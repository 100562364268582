import React from 'react'
import Select from "react-select";
import Colors from "../../config/colors";

const CustomSelect = ({options, value, onChange, borderColor, placeholder, isMulti, disabled}) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#00000000",
      // match with the menu
      borderRadius: 10,
      // Overwrittes the different states of border
      borderColor: borderColor || Colors.secondaryTextColor,
      width: '100%',
      // Removes weird border around container
      boxShadow: null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: Colors.secondaryTextColor
      }
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
    singleValue: base => ({
      ...base,
      color: Colors.primaryTextColor
    }),
    input: base => ({
      ...base,
      color: Colors.primaryTextColor
    }),
  };

  return (
    <Select
      isDisabled={disabled || false}
      isMulti={isMulti || false}
      placeholder={placeholder || 'select'}
      styles={customStyles}
      value={value}
      options={options}
      onChange={onChange}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: '#5481CB33',
          primary: '#5481CB',
        },
      })}
    />
  )
}

export default CustomSelect
