import React from 'react'
import Button from '@mui/material/Button';
import colors from '../../config/colors'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Slider from "@mui/material/Slider";

const theme = createTheme({
	palette: {
		primary: {
			main: colors.buttonTheme,
		},

	},
});

function CustomSlider({value, setValue, min, max, step, disabled}) {

	return (
		<ThemeProvider theme={theme}>
			<Slider
				disabled={disabled || false}
				step={step}
				min={min}
				max={max}
				value={value}
				onChange={(event, newValue) => {
					setValue(newValue)
				}}
				size="big"
				aria-label="big"
				valueLabelDisplay="on"
				valueLabelFormat={(value) => `${value}%`}
				sx={{
					'& .MuiSlider-rail': {
						backgroundColor: colors.theme,
						height: 10
					}
				}}
			/>
		</ThemeProvider>
	)
}

export default CustomSlider
