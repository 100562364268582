import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {API_CALL_STATUS} from '../../metadata/enums'
import {GET_ALL_PAYMENTS, UPDATE_ONE_PAYMENT} from '../actionTypes'
import Config from "../../config";
import axios from "axios";
import {LOGGER} from "../../utils/Logger";

const initialState = {
  payments: [],
  status: API_CALL_STATUS.IDLE,
  error: null,
}

export const getAllPayments = createAsyncThunk(GET_ALL_PAYMENTS, async (payload) => {
  const config = {
    method: 'get',
    url: `${Config.BACKEND_URL}payments`,
    headers: {Authorization: payload.token},
  }

  try {
    let res = await axios(config)
    return res.data
  } catch (err) {
    LOGGER.error('Error when getting all notifications', err)
    return []
  }

})

export const updateOnePayment = createAsyncThunk(UPDATE_ONE_PAYMENT, async (payload) => {
  try {
    let id = payload.data._id
    const config = {
      method: 'put',
      url: `${Config.BACKEND_URL}payments/${id}`,
      headers: {Authorization: payload.token, contentType: "application/json",},
      data: payload.data
    }
    console.log('updating notif', config)

    await axios(config)
    console.log('updated')
    return payload.data
  } catch (err) {
    LOGGER.log('error when adding updating client', err)
  }

  // return order
})

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    addPayment(state, action) {
      let temp = [...state.payments]
      temp.push(action.payload)
      return Object.assign({}, state, {payments: temp})
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllPayments.rejected, (state, action) => {
        state.status = API_CALL_STATUS.FAILED
        state.error = action.error.message
      })
      .addCase(getAllPayments.pending, (state, action) => {
        state.status = API_CALL_STATUS.LOADING
      })
      .addCase(getAllPayments.fulfilled, (state, action) => {
        state.status = API_CALL_STATUS.SUCCEEDED
        state.payments = action.payload
      })
      .addCase(updateOnePayment.rejected, (state, action) => {
        state.status = API_CALL_STATUS.FAILED
        state.error = action.error.message
      })
      .addCase(updateOnePayment.pending, (state, action) => {
        state.status = API_CALL_STATUS.LOADING
      })
      .addCase(updateOnePayment.fulfilled, (state, action) => {
        state.status = API_CALL_STATUS.SUCCEEDED
        state.payments = action.payload
      })
  }
})


export const {addPayment} = paymentsSlice.actions

export default paymentsSlice.reducer
