import React, {useState} from "react";
// import {useNavigate} from "react-router-dom";
import Helpers from "../../../utils/helpers";

import GeneralStyles from "../../GeneralStyles.module.scss";
import Constants from "../../../config/constants";

// custom components : 
import CustomTextFieldNew from '../../../components/general/CustomTextFieldNew';
import CustomGooglePlacesNew from "../../../components/general/CustomGooglePlacesNew";


const RenderPartnerInfo =({partnerInformation,setPartnerInformation})=>{
    const [partnerInfo, setPartnerInfo] = useState(
		{
			firstName: 'Temp first name',
			lastName:'Temp lask name',
			email: 'Temp email',
			phoneNumber: 'Temp PhoneNumber',
			address: null
		}
	)
    // error handle
    if (!partnerInfo) {
        return (
            <div>partner information not loaded</div>
        )
    }

    return (
        <div style={{width: '58%'}}>
            <p style={{fontWeight: 'bold', width: '100%', fontSize: 18, marginTop: 30, marginBottom: 10}}>Partner Information</p>
            <div style={{display: 'flex', flexDirection: 'column', width: '90%'}}>
                <label
                    className={GeneralStyles.darkText}
                    style={{fontSize: Constants.labelSize}}>
                    Full Name:
                </label>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginBottom: 20, marginTop: 4}}>
                        <div style={{width: '50%'}}>
                            <CustomTextFieldNew
                                width={'100%'}
                                label={'First name'}
                                placeholder={'first name'}
                                value={partnerInformation.firstName}
                                onChange={(text) => {
                                    setPartnerInformation(Object.assign({}, partnerInformation, {firstName: text}))
                                }}
                            />
                        </div>
                        <div style={{width: '50%', marginLeft: '4%'}}>
                            <CustomTextFieldNew
                                width={'100%'}
                                label={'Last name'}
                                placeholder={'last name'}
                                value={partnerInformation.lastName}
                                onChange={(text) => {setPartnerInformation(Object.assign({}, partnerInformation, {lastName: text}))}}
                            />
                        </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginBottom: 20}}>
                        <div style={{width: '50%'}}>
                            <label
                                className={GeneralStyles.darkText}
                                style={{fontSize: Constants.labelSize}}>
                                Email:
                            </label>
                            <CustomTextFieldNew
                                marginTop={4}
                                width={'100%'}
                                label={'Email Address'}
                                placeholder={'Partner Email here'}
                                value={partnerInformation.email}
                                onChange={(text) => {setPartnerInformation(Object.assign({}, partnerInformation, {email: text}))}}
                            />
                        </div>
                        <div style={{width: '50%', marginLeft: '4%'}}>
                            <label
                                className={GeneralStyles.darkText}
                                style={{fontSize: Constants.labelSize}}>
                                Phone:
                            </label>
                            <CustomTextFieldNew
                                marginTop={4}
                                width={'100%'}
                                label={'Phone Number'}
                                placeholder={'phone number'}
                                value={partnerInformation.phone}
                                onChange={(text) => {setPartnerInformation(Object.assign({}, partnerInformation, {phone: Helpers.checkPhoneNumberFormat(text)}))}}
                            />
                        </div>
                    </div>

                    <div style={{width: '100%', marginBottom: 20}}>
                        <label
                            className={GeneralStyles.darkText}
                            style={{fontSize: Constants.labelSize}}>
                            Address:
                        </label>
                        <div style={{marginTop: 4}}>
                            <CustomGooglePlacesNew
                                label={'Address'}
                                placeholder={'search customer address'}
                                value={partnerInformation.address}
                                onChange={(value) => {setPartnerInformation(Object.assign({}, partnerInformation, {address: value}))}}
                            />
                        </div>
                    </div>

                </div>
        </div>
    )
}
export default RenderPartnerInfo;