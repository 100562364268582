import React, {useState, useEffect} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import PageHeader from "../../components/general/PageHeader";
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import {useDispatch, useSelector} from "react-redux";
import {API_CALL_STATUS, CLAIM_STATUS} from "../../metadata/enums";
import {LOGGER} from "../../utils/Logger";
import moment from "moment";
import Styles from "./styles/Claims.module.scss";
import Colors from "../../config/colors";
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";
import CustomButtonContained from '../../components/general/CustomButtonContained'
import {getAllClaims, updateUpdatedClaimStatus, updateAddedClaimStatus, updateClaimToUpdate} from '../../redux/slices/claimsSlice'
import {useNavigate} from 'react-router-dom'
import SearchIcon from "../../assets/logos/searchIcon.png";
import CustomTextFieldNative from "../../components/general/CustomTextFieldNative";
import {vehiclesMapSelector} from "../../redux/slices/vehiclesSlice";
import {clientsMapSelector} from "../../redux/slices/clientsSlice";
import {reservationsMapSelector} from '../../redux/slices/reservationsSlice'
import Fuse from "fuse.js";
import Constants from "../../config/constants";
import Helpers from "../../utils/helpers";
import {changeUserAccess} from "../../redux/slices/authSlice";

const Claims = () => {
	let dispatch = useDispatch()
	let navigate = useNavigate()


	const token = useSelector(state => state.auth.token)
	let claimsState = useSelector(state => state.claims)
	const vehiclesMap = useSelector(state => vehiclesMapSelector(state))
	const clientsMap = useSelector(state => clientsMapSelector(state.clients))
	const reservationsMap = useSelector(state => reservationsMapSelector(state.reservations))
	const mongoUser = useSelector(state => state.user.mongoUser)
	const settingsState = useSelector(state => state.settings)


	const [loading, setLoading] = useState(true)
	const [showSuccess, setShowSuccess] = useState(false)
	const [successMessage, setSuccessMessage] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [showError, setShowError] = useState(false)
	const [fuse, setFuse] = useState(null)
	const [searchResults, setSearchResults] = useState(null)
	const [searchString, setSearchString] = useState('')
	const [claimsList, setClaimsList] = useState([])
	const [tab, setTab] = useState(0)

	useEffect(() => {
		let rolesMap = Helpers.getRolesMap(settingsState)
		if (mongoUser
			&& Object.keys(rolesMap).length > 0
			&& !Helpers.checkAccess(mongoUser, rolesMap, 'insurance_claims')) {
			dispatch(changeUserAccess(false))
			navigate(-1)
		}
	}, [mongoUser, settingsState])

	useEffect(() => {
		if(token)
			dispatch(getAllClaims({token}))
	},[token])

	useEffect(() => {
		if(claimsState.status === API_CALL_STATUS.LOADING) {
			setLoading(true)
		} else if(claimsState.status === API_CALL_STATUS.FAILED) {
			setLoading(true)
			setShowError(true)
			setErrorMessage('something went wrong when getting the claims data')
			LOGGER.error('error effect', claimsState.error)
		} else {
			setLoading(false)
			if(claimsState.claim_added) {
				setShowSuccess(true)
				setSuccessMessage('Successfully added claim')
				dispatch(updateAddedClaimStatus(false))
			}

			if(claimsState.claim_updated) {
				setShowSuccess(true)
				setSuccessMessage('Successfully updated claim')
				dispatch(updateUpdatedClaimStatus(false))
			}

			setClaimsList(claimsState.claims)
		}
	},[claimsState])

	useEffect(() => {
		if(claimsList && claimsList.length > 0) {
			let claims = [...claimsList]

			let temp = new Fuse(claims, {
				keys: ['res_id', 'vehicle_id', 'client.fName', 'client.lName', 'claim_no', 'policy_no', 'insurance_provider']
			})
			setFuse(temp)
		}
	},[claimsList])

	const onSearchStringChanged = (text) => {
		setSearchString(text)
		if(searchString.length === 0)
			return
		let results = fuse.search(text)
		let array = results.map(temp => temp.item)
		setSearchResults(array)
	}

	const renderClaims  = () => {
		let elements
		if(!claimsList || claimsList.length === 0) {
			elements =  <p style={{marginTop: 40, width: '100%', textAlign: 'center'}}>Nothing to display</p>
		} else {
			elements = claimsList.map((claim, index) => {
				if((tab === 0 && claim.status === CLAIM_STATUS.CREATED) || (tab === 1 && claim.status !== CLAIM_STATUS.CREATED))
					return (
						<div
							key={index.toString()}
							onClick={() => {
								dispatch(updateClaimToUpdate(claim))
								navigate('/home/claims/new-claim')
							}}
							className={Styles.tableRow} style={{borderBottom: `0.5px solid ${Colors.tableLineColor}`, cursor: 'pointer'}}>
							<p style={{width: '8%'}} className={Styles.tableRowEntry}>Res #{claim.res_id}</p>
							<p style={{width: '18%'}} className={Styles.tableRowEntry}>{vehiclesMap[claim.vehicle_id]?.make}</p>
							<p style={{width: '18%'}} className={Styles.tableRowEntry}>{`${clientsMap[claim.client_id]?.fName} ${clientsMap[claim.client_id]?.lName}`}</p>
							<p style={{width: '16%'}} className={Styles.tableRowEntry}>{moment(claim.date).format('MM/DD/YY')}</p>
							<p style={{width: '20%'}} className={Styles.tableRowEntry}>{claim.claim_no}</p>
							<p style={{width: '20%'}} className={Styles.tableRowEntry}>{claim.insurance_provider}</p>
						</div>
					)
			})
		}

		return (
			<div style={{width: '100%'}}>
				<div style={{marginTop: 20}}>
					<div className={Styles.tableRow} style={{borderBottom: `0.5px solid ${Colors.tableLineColor}`}}>
						<p style={{width: '8%'}} className={Styles.tableHeader}>Res #</p>
						<p style={{width: '18%'}} className={Styles.tableHeader}>Vehicle</p>
						<p style={{width: '18%'}} className={Styles.tableHeader}>Customer</p>
						<p style={{width: '16%'}} className={Styles.tableHeader}>Accident Date</p>
						<p style={{width: '20%'}} className={Styles.tableHeader}>Claim #</p>
						<p style={{width: '20%'}} className={Styles.tableHeader}>Insurance Provider</p>
					</div>
					<div style={{height: '70vh', overflowY: 'scroll'}}>
						{elements}
					</div>

				</div>
			</div>
		)
	}

	const renderFilterRow = () => {
		return (
			<div style={{width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30}}>
				<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
					<div style={{width: '62%', backgroundColor: Colors.theme, borderRadius: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 20}}>
						<img src={SearchIcon} style={{height: 16, width: 16}}/>
						<CustomTextFieldNative
							containerStyle={{width: '100%'}}
							height={36}
							label={'search'}
							placeholder={'Search claims'}
							value={searchString}
							onChange={onSearchStringChanged}/>
					</div>

					<div style={{width: '38%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
						<Tab tab={tab} onChange={(value) => setTab(value)}/>
					</div>
				</div>
			</div>
		)
	}

	const renderContent = () => {
		if(loading) {
			return (
				<div className={GeneralStyles.container} style={{height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
					<CustomLoaderSpinner />
				</div>
			)
		}

		return (
			<div style={{width: '100%'}}>
				{renderFilterRow()}
				{renderClaims()}
			</div>
		)
	}



	return (
		<div className={GeneralStyles.container}>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
				<PageHeader header={'Claims'}/>
				<CustomButtonContained
					text={'+ New Claim'}
					onClick={() => {
						navigate('/home/claims/new-claim')
					}}
					style={{position: 'absolute', right: 0, zIndex: 10,}}
					color={'primary'}
				/>
			</div>

			<PositionedSnackbar
				onClose={() => {
					setShowError(false)
					setShowSuccess(false)
					setErrorMessage('')
					setSuccessMessage('')
				}}
				severity={showError ? 'error' : 'success'}
				openFlag={showError || showSuccess}
				message={showError ? errorMessage : successMessage}
			/>

			{
					renderContent()
			}
		</div>
	)
}


const Tab = ({tab, onChange}) => {
	return (
		<div style={{width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: 10, border: '1px solid gray', padding: 4, backgroundColor: Colors.theme, borderColor: Colors.theme}}>
			<div
				className={Styles.tabEntry}
				style={{backgroundColor: tab === 0 ? Colors.tabButtonTheme : 'transparent',}}
				onClick={() => onChange(0)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Ongoing Claims</p>
			</div>
			<div
				className={Styles.tabEntry}
				style={{backgroundColor: tab === 1 ? Colors.tabButtonTheme : 'transparent',}}
				onClick={() => onChange(1)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Closed Claims</p>
			</div>
		</div>
	)
}

export default Claims
