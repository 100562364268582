import React, {useState, useEffect, useRef} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import PageHeader from "../../components/general/PageHeader";
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import {useDispatch, useSelector} from "react-redux";
import {API_CALL_STATUS} from "../../metadata/enums";
import {LOGGER} from "../../utils/Logger";
import moment from "moment";
import Styles from "./styles/NewExpense.module.scss";
import Colors from "../../config/colors";
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";
import {useNavigate} from "react-router-dom";
import CustomButtonContained from "../../components/general/CustomButtonContained";
import BackArrow from "../../assets/logos/backArrow.svg";
import {updateExpense, addExpenseAndConfirm, updateExpenseAndConfirm, vehiclesMapSelector, updateNewExpenseStatus, updateUpdateExpenseStatus} from "../../redux/slices/vehiclesSlice";
import CustomSelect from "../../components/general/CustomSelect";
import {getExpensesSettings} from '../../redux/slices/settingsSlice'
import {GlobalStyles} from "@mui/material";
import Constants from "../../config/constants";
import CustomTextFieldNew from "../../components/general/CustomTextFieldNew";
import UploadIcon from "../../assets/logos/uploadIcon.svg";
import ReactS3Client from "../../services/S3Client";
import Config from "../../config";

const NewExpense = () => {
  let navigate = useNavigate()
  let dispatch = useDispatch()

  const vehiclesMap = useSelector(state => vehiclesMapSelector(state))
  const token = useSelector(state => state.auth.token)
  const mongoUser = useSelector(state => state.user.mongoUser)
  const settingsState = useSelector(state => state.settings)
  const vehiclesState = useSelector(state => state.vehicles)
  const expenseSettings = useSelector(state => getExpensesSettings(state.settings))

  let fileRef = useRef(null)

  const [loading, setLoading] = useState(true)
  const [showSuccess, setShowSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showError, setShowError] = useState(false)
  const [expense, setExpense] = useState(null)
  const [vehicle, setVehicle] = useState(null)
  const [type, setType] = useState(null)
  const [newType, setNewType] = useState('')
  const [amount, setAmount] = useState('')
  const [images, setImages] = useState([])
  const [updateIndex, setUpdateIndex] = useState(-1)
  const [forceRender, setForceRender] = useState(false)

  useEffect(() => {
    if(vehiclesState.status === API_CALL_STATUS.LOADING) {
      setLoading(true)
    } else if(vehiclesState.status === API_CALL_STATUS.FAILED) {
      setLoading(true)
      setShowError(true)
      setErrorMessage('something went wrong when getting the vehicles data')
      LOGGER.error('error effect', vehiclesState.error)
    } else {
      setLoading(false)
      if(vehiclesState.expense_to_update) {
        let temp = vehiclesState.expense_to_update
        setExpense(temp)
        let {name, vehicle_id, images, amount} = temp
        let vehicle = vehiclesMap[vehicle_id] || {}
        let tempVehicle = {label: vehicle.make, value: vehicle.id}
        setVehicle(tempVehicle)
        setAmount(amount.toString())
        setImages(images)
        if(expenseSettings.options.includes(name))
          setType({label: name, value: name})
        else
          setNewType(name)
      }


      if(vehiclesState.new_expense) {
        console.log('new exepsne here')
        navigate('/home/vehicles')
        // setSuccessMessage('Successfully added expense')
        // setShowSuccess(true)
        // dispatch(updateNewExpenseStatus(null))
      }

      if(vehiclesState.updated_expense) {
        setSuccessMessage('Successfully updated expense')
        setShowSuccess(true)
        dispatch(updateUpdateExpenseStatus(null))
      }
    }
  },[vehiclesState])

  useEffect(() => {
    if(settingsState.status === API_CALL_STATUS.LOADING) {
      setLoading(true)
    } else if(settingsState.status === API_CALL_STATUS.FAILED) {
      setLoading(true)
      setShowError(true)
      setErrorMessage('something went wrong when getting the settings data')
      LOGGER.error('error effect', settingsState.error)
    } else {
      setLoading(false)
    }
  },[settingsState])

  const onCloseAddNewPressed = () => {
    setType(null)
    setNewType('')
    setVehicle(null)
    setAmount('')
    dispatch(updateExpense(null))
    navigate('/home/vehicles')
  }

  const handleFileChange = (event) => {
    let selectedFile = event.target.files && event.target.files[0]
    if (!selectedFile)
      return
    let temp = [...images]
    if(updateIndex >= 0) {
      //change picture, so update the right index
      temp[updateIndex] = selectedFile
    } else {
      temp.push(selectedFile)
    }
    setImages(temp)
  }

  const deleteImage = (index) => {
    let temp = [...images]
    temp.splice(index, 1)
    setImages(temp)
    setForceRender(!forceRender)
  }



  const onSavePressed = () => {
    let flag = false
    if(!vehicle) {
      setErrorMessage('Please select a vehicle')
      flag = true
    }

    if(!type && (!newType || newType.length === 0)) {
      setErrorMessage('Please select a expense type from the dropdown or enter a custom expense type')
      flag = true
    }

    if(!amount || amount.length === 0 || isNaN(amount)) {
      setErrorMessage('Please enter a valid amount for the expense')
      flag = true
    }

    if(flag){
      setShowError(true)
      return
    }

    let temp = []
    let toUpload = []
    console.log('images', images)
    images.forEach(async(image, index) => {
      if(typeof image === 'string') {
        temp.push(image)
      } else {
        toUpload.push(image)
      }
    })

    console.log('images to upload', toUpload)

    let promises = []
    setLoading(true)
    if(toUpload.length > 0) {
      toUpload.forEach((image,index) => {
        promises.push(uploadImage(image))
      })

      Promise.all(promises)
        .then(values => {
          console.log('promises resolved', values)
          saveData(temp.concat(values))
        }).catch(err => {
        LOGGER.error('error when uploading images', err)
        setErrorMessage('Somethign went wrong when uploading images. Please try again')
        setShowError(true)
        setLoading(false)
      })
    } else {
      saveData(temp)
    }



  }

  const saveData = async (temp) => {
    let toSend = {
      name: type && type.value ? type.value : newType,
      vehicle_id: vehicle?.value,
      amount: Number(amount),
      images: temp,
      user: mongoUser?.name || 'Admin Account'
    }

    LOGGER.log('tosend is ', toSend)
    setLoading(true)

    if(expense) {
      toSend['_id'] = expense._id
      dispatch(updateExpenseAndConfirm({token, data: toSend}))
    } else {
      dispatch(addExpenseAndConfirm({token, data: toSend}))
    }
  }

  const uploadImage = async(image) => {
    return new Promise((resolve, reject) => {
      console.log('uploading image', image)
      ReactS3Client
        .uploadFile(image)
        .then(data => {
          LOGGER.log('success', data)
          let url = Config.AWS_CLOUDFRONT_URL + data.key
          resolve(url)
        })
        .catch(err => {
          LOGGER.error('error when uploading image', err.message)
          reject()
        })
    })
  }

  const renderImage = (image, index) => {
    return (
      <div key={index.toString()} style={{marginTop: 10, borderBottom: `gray 1px solid`}}>
        <img
          alt="not fount"
          width={'100%'}
          height={'100%'}
          src={typeof image === 'string' ? image : URL.createObjectURL(image)}
          style={{borderRadius: 10}}
        />
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
          <p
            onClick={() => {
              setUpdateIndex(index)
              fileRef.current.click()
            }}
            style={{textDecoration: 'underline', cursor: 'pointer'}}
          >
            change picture
          </p>

          <p
            onClick={() => deleteImage(index)}
            style={{textDecoration: 'underline', cursor: 'pointer'}}
          >
            delete picture
          </p>
        </div>
      </div>
    )
  }

  const renderImages = () => {
    let imageElements = []
    if(images.length > 0)
      imageElements = images.map((image, index) => renderImage(image, index))
    return (
      <div style={{width: '100%', paddingBottom: 100}}>
        {imageElements}
        <div className={`${Styles.imageContainer} ${GeneralStyles.boxShadow}`} style={{backgroundColor: 'transparent', marginTop: 20}}>
          <img
            src={UploadIcon} style={{width: 50, height: 50, cursor: 'pointer'}}/>
          <p>Upload Company Logo</p>
          <CustomButtonContained
            style={{margin: '0 auto', height: 40}}
            text={'Browse to upload'}
            onClick={() => fileRef.current.click()}/>

          <input
            id="image"
            name="user[image]"
            type="file"
            accept="image/*"
            style={{display: 'none'}}
            onChange={handleFileChange}
            ref={fileRef}/>
        </div>
      </div>

    )
  }

  const renderContent = () => {
    let allVehicles = vehiclesState.vehicles || []
    let options = allVehicles.map(temp => {
      return {label: temp.make, value: temp.id}
    })

    let expenseOptions = expenseSettings.options?.map(option => {
      return {label: option, value: option}
    })

    return (
      <div>
        <div style={{width: '50%', display: 'flex', flexDirection: 'column'}}>
          <label className={[GeneralStyles.darkText]} style={{fontWeight: 'bold', fontSize: Constants.labelSize}}>Vehicle</label>
          <div
            className={GeneralStyles.boxShadow}
            style={{width: '100%', height: 40, borderRadius: 10, marginTop: 10}}>
            <CustomSelect
              placeholder={'select vehicle'}
              borderColor={'transparent'}
              value={vehicle}
              options={options}
              onChange={setVehicle}
            />
          </div>

          <label className={[GeneralStyles.darkText]} style={{fontWeight: 'bold', marginTop: 20, fontSize: Constants.labelSize}}>Expense Type</label>
          <div
            className={GeneralStyles.boxShadow}
            style={{width: '100%', height: 40, borderRadius: 10, marginTop: 10}}>
            <CustomSelect
              placeholder={'select expense type'}
              borderColor={'transparent'}
              value={type}
              options={expenseOptions}
              onChange={setType}
            />
          </div>
          <label
            className={[GeneralStyles.darkText]}
            style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10, fontSize: Constants.labelSize, width: '100%', textAlign: 'center'}}
          >
            OR
          </label>
          <CustomTextFieldNew
            width={'98%'}
            label={'Custom Expense Type'}
            placeholder={'custom entry'}
            value={newType}
            onChange={setNewType}
          />

          <CustomTextFieldNew
            marginTop={20}
            width={'98%'}
            label={'Amount ($)'}
            placeholder={'enter amount'}
            value={amount}
            onChange={(value) => {
              if(isNaN(value))
                return
              setAmount(value)
            }}
          />

          <label
            className={[GeneralStyles.darkText]}
            style={{fontWeight: 'bold', marginTop: 20, fontSize: Constants.labelSize}}
          >
            Images
          </label>
          {renderImages()}

        </div>
      </div>
    )
  }

  if(loading) {
    return (
      <div className={GeneralStyles.container} style={{height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
        <CustomLoaderSpinner />
      </div>
    )
  }

  return (
    <div className={GeneralStyles.container}>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
        <PageHeader
          header={expense ? `Update Expense - ${expense?._id}` : 'New Expense'}
        />
        <div style={{position: 'absolute', right: 0, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <CustomButtonContained
            style={{marginRight: 2}}
            text={expense ? `\u2713 Save Changes` : `\u2713 Add New Entry`}
            onClick={onSavePressed}/>
          <img
            onClick={onCloseAddNewPressed}
            src={BackArrow} style={{width: 40, height: 40, cursor: 'pointer'}}/>
        </div>
      </div>

      <PositionedSnackbar
        onClose={() => {  4
          setShowError(false)
          setShowSuccess(false)
          setErrorMessage('')
          setSuccessMessage('')
        }}
        severity={showError ? 'error' : 'success'}
        openFlag={showError || showSuccess}
        message={showError ? errorMessage : successMessage}
      />

      {renderContent()}
    </div>
  )
}

export default NewExpense
