import React from 'react'
import Switch from "react-switch";
import Colors from '../../config/colors'




function CustomSwitch({checked, onChange, containerStyle}) {
	return (
		<div style={containerStyle || {}}>
			<Switch
				uncheckedIcon={false}
				checkedIcon={false}
				onColor={Colors.buttonTheme}
				onChange={onChange}
				checked={checked} />
		</div>
	)
}

export default CustomSwitch
