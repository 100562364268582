import React, {useEffect, useState} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import PageHeader from "../../components/general/PageHeader";
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import {useDispatch, useSelector} from "react-redux";
import {API_CALL_STATUS, PAYMENT_STATUSES} from "../../metadata/enums";
import {LOGGER} from "../../utils/Logger";
import moment from "moment";
import Colors from "../../config/colors";
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";
import {getAllPayments} from "../../redux/slices/paymentsSlice";
import SearchIcon from "../../assets/logos/searchIcon.png";
import CustomTextFieldNative from "../../components/general/CustomTextFieldNative";
import Fuse from 'fuse.js'
import {clientsMapSelector} from "../../redux/slices/clientsSlice";
import Styles from "./styles/Payments.module.scss";
import Constants from "../../config/constants";
import {vehiclesMapSelector} from "../../redux/slices/vehiclesSlice";
import SettingsIcon from '@mui/icons-material/Settings';
import Config from "../../config";
import axios from 'axios'
import IndividualPaymentModal from "../../components/payments/IndividualPaymentModal";
import CustomButtonContained from "../../components/general/CustomButtonContained";
import CapturePaymentModal from '../../components/payments/CapturePaymentModal';
import OfflinePaymentModal from '../../components/payments/OfflinePaymentModal';
import InvoiceModal from '../../components/payments/InvoiceModal'
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import Helpers from "../../utils/helpers";
import {changeUserAccess} from "../../redux/slices/authSlice";
import {reservationsMapSelector, updateReservation} from "../../redux/slices/reservationsSlice";
import {useNavigate} from 'react-router-dom'

const SETTINGS_OPTIONS = {
	screen1: 'screen1',
	screenProcessPayment: 'screenProcessPayment',
	screenOfflinePayment: 'screenOfflinePayment',
	screenInvoice: 'screenInvoice',
	screenSelectType: 'screenSelectType'
}


const PaymentsHome = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const mongoUser = useSelector(state => state.user.mongoUser)
	const paymentsState = useSelector(state => state.payments)
	const settingsState = useSelector(state => state.settings)
	const token = useSelector(state => state.auth.token)
	const clientsState = useSelector(state => state.clients)
	const vehiclesMap = useSelector(state => vehiclesMapSelector(state))
	const reservationsMap = useSelector(state => reservationsMapSelector(state.reservations))

	const [prevSettingsScreen, setPrevSettingsScreen] = useState(null)
	const [settingsOptions, setSettingOptions] = useState(SETTINGS_OPTIONS['screen1'])
	const [showOverlay, setShowOverlay] = useState(false)
	const [secTab, setSecTab] = useState(0)
	const [tab, setTab] = useState(0)
	const [fuse, setFuse] = useState(null)
	const [loading, setLoading] = useState(true)
	const [showSuccess, setShowSuccess] = useState(false)
	const [successMessage, setSuccessMessage] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [showError, setShowError] = useState(false)
	const [searchString, setSearchString] = useState('')
	const [searchResults, setSearchResults] = useState([])
	const [allPayments, setAllPayments] = useState([])
	const [allSecurity, setAllSecurity] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [showOfflineModal, setShowOfflineModal] = useState(false)
	const [showInvoiceModal, setShowInvoiceModal] = useState(false)
	const [modalPayment, setModalPayment] = useState(null)
	const [modalLoader, setModalLoader] = useState(true)
	const [selectedResPayments, setSelectedResPayments] = useState([])
	const [payType, setPayType] = useState(null)
	const [showPaymentModal, setShowPaymentModal] = useState(false)
	const [invoices, setInvoices] = useState([])
	const [invoicesLoading, setInvoicesLoading] = useState(false)

	useEffect(() => {
		if (token)
			dispatch(getAllPayments({token}))
	}, [token])

	useEffect(() => {
		if (settingsState.status === API_CALL_STATUS.LOADING) {
			setLoading(true)
		} else if (settingsState.status === API_CALL_STATUS.FAILED) {
			setLoading(true)
			setShowError(true)
			setErrorMessage('something went wrong when getting the settings data')
			LOGGER.error('error effect', settingsState.error)
		} else {
			setLoading(false)
		}
	}, [settingsState])

	useEffect(() => {
		if (paymentsState.status === API_CALL_STATUS.LOADING) {
			setLoading(true)
		} else if (paymentsState.status === API_CALL_STATUS.FAILED) {
			setLoading(true)
			setShowError(true)
			setErrorMessage('something went wrong when getting the payments data')
			LOGGER.error('error effect', paymentsState.error)
		} else {
			setLoading(false)
			let payments = []
			let security = []
			paymentsState.payments.forEach(pay => {
				if (pay.isSecurityHold)
					security.push(pay)
				else
					payments.push(pay)
			})
			setAllPayments(payments)
			setAllSecurity(security)
		}
	}, [paymentsState])

	useEffect(() => {
		let clientsMap = clientsMapSelector(clientsState)
		let paymentsWithCustomer = allSecurity.concat(allPayments).map(res => {
			let {fName, lName, email, phone, address} = clientsMap[res.clientId] || {}
			return Object.assign({}, res, {fName, lName, email, phone, address})
		})

		let temp = new Fuse(paymentsWithCustomer, {
			keys: ['vehicleId', 'fName', 'lName', 'email', 'reservationId', 'salesPerson']
		})
		setFuse(temp)
	}, [allPayments, allSecurity, clientsState])


	const onSearchStringChanged = (text) => {
		setSearchString(text)
		if (text.length === 0)
			return
		let results = fuse.search(text)
		let array = results.map(temp => temp.item)
		setSearchResults(array)
	}

	const onResendInvoiceClicked = (invoice) => {
		if (window.confirm(`Are you sure you want to resend the invoice for rental #${invoice.res_id} to ${invoice?.email}`)) {
			setInvoicesLoading(true)
			const config = {
				method: 'post',
				url: `${Config.BACKEND_URL}invoices/${invoice._id}/resend`,
				headers: {Authorization: token, contentType: "application/json",}
			}
			axios(config)
			.then(res => {
				setInvoicesLoading(false)
				if (res.data?.success)
					window.alert(`Successfully resent the invoice for rental #${invoice.res_id}`)
				else
					window.alert('Something went wrong. Please contact support if the problem persists')
			})
			.catch(err => {
				setInvoicesLoading(false)
				LOGGER.error('error resending invoice', err)
				window.alert('Something went wrong. Please contact support if the problem persists')
			})
		}
	}

	const getInvoices = () => {
		setInvoicesLoading(true)
		const config = {
			method: 'get',
			url: `${Config.BACKEND_URL}invoices`,
			headers: {Authorization: token, contentType: "application/json",}
		}

		axios(config)
		.then(res => {
			console.log('got invoices', res.data)
			setInvoices(res.data || [])
			setInvoicesLoading(false)
		})
		.catch(err => {
			LOGGER.error('Error when getting invoices', err)
			setInvoicesLoading(false)
		})
	}

	const onNewPaymentClicked = () => {
		if (showOverlay) {
			setShowOverlay(false)
			document.getElementById("overlay").style.display = "none";
		} else {
			setShowOverlay(true)
			document.getElementById("overlay").style.display = "block";
		}

		setSettingOptions(SETTINGS_OPTIONS.screen1)
	}

	const onResClicked = (resId) => {
		let reservation = reservationsMap[resId]
		if (!Helpers.checkAccess(mongoUser, Helpers.getRolesMap(settingsState), 'view_reservations')) {
			dispatch(changeUserAccess(false))
		} else {
			dispatch(updateReservation(reservation))
			if (reservation.isChauffeur) {
				navigate('/home/new-chauffeur')
			} else {
				navigate('/home/new-reservation')
			}
		}
	}


	const getStatusText = (payment) => {
		let {transactionStatus} = payment
		let text, color
		switch (transactionStatus) {
			case PAYMENT_STATUSES.AUTHORIZED:
				text = 'ON HOLD'
				color = Colors.themeLight
				break
			case PAYMENT_STATUSES.CAPTURED:
				text = 'CAPTURED'
				color = 'green'
				break
			case PAYMENT_STATUSES.CHARGED:
				text = 'PAID'
				color = Colors.themeLight
				break
			case PAYMENT_STATUSES.VOIDED:
				text = 'RELEASED'
				color = '#acacac'
				break
			case PAYMENT_STATUSES.REFUNDED:
				text = 'REFUNDED'
				color = '#CBAA54'
				break
			case PAYMENT_STATUSES.CANCELLED:
				text = 'CANCELLED'
				color = 'red'
				break
			case PAYMENT_STATUSES.OFFLINE_CHARGED:
				text = 'O/F PAID'
				color = Colors.themeLight
				break
			case PAYMENT_STATUSES.OFFLINE_CANCELLED:
				text = 'O/F CANCELLED'
				color = 'red'
				break
			case PAYMENT_STATUSES.PARTIALLY_CAPTURED:
				text = 'CAPTURED (P)'
				color = 'green'
				break
			case PAYMENT_STATUSES.PARTIALLY_REFUNDED:
				text = 'REFUNDED (P)'
				color = '#acacac'
				break

			case PAYMENT_STATUSES.FRAUD_HELD:
				text = 'FRAUD HELD'
				color = '#ff0000'
				break
			case PAYMENT_STATUSES.FRAUD_DECLINED:
				text = 'DECLINED'
				color = '#ff0000'
				break
			case PAYMENT_STATUSES.FRAUD_APPROVED:
				text = 'APPROVED'
				color = Colors.themeLight
				break
			default:
				text = 'unknown'
				color = '#acacac'
		}

		return (
			<div
				style={{
					marginLeft: 2,
					height: 20,
					backgroundColor: color,
					borderRadius: 10,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<label style={{
					color: 'white',
					textAlign: 'center',
					fontSize: 10
				}}>{text}</label>
			</div>

		)
	}

	const renderInvoiceRow = (invoice, index) => {
		let {res_id, client_id, vehicle_id, created_at, description, amount, email, isSecurityHold, paid} = invoice
		let clientsMap = clientsMapSelector(clientsState)
		let client = clientsMap[client_id] || {}
		return (
			<div key={index.toString()} className={Styles.tableRow}>
				<p
					onClick={() => onResClicked(res_id)}
					style={{width: '8%', marginRight: 14, cursor: 'pointer', textDecoration: 'underline'}}
					className={Styles.tableRowEntry}>
					#{res_id}
				</p>
				<p style={{width: '15%', marginRight: 14}}
					 className={Styles.tableRowEntry}>{`${client.fName} ${client.lName} (${email})`}</p>
				<p style={{width: '15%', marginRight: 14}} className={Styles.tableRowEntry}>{vehiclesMap[vehicle_id]?.make}</p>
				<p style={{width: '8%', marginRight: 14}} className={Styles.tableRowEntry}>{`$${amount}`}</p>
				<p style={{width: '20%', marginRight: 14}} className={Styles.tableRowEntry}>{description}</p>
				<p style={{width: '10%', marginRight: 14}}
					 className={Styles.tableRowEntry}>{moment(created_at).format('MM/DD/YY hh:mm A')}</p>
				<p style={{width: '8%', marginRight: 14}}
					 className={Styles.tableRowEntry}>{isSecurityHold ? 'Auth Hold' : 'Payment'}</p>
				<p style={{width: '12%', marginRight: 14}}
					 className={Styles.tableRowEntry}>{paid ? `Paid @ ${moment(paid).format('MM/DD/YY hh:mm A')}` : 'Not Paid'}</p>
				<div style={{width: '4%', marginRight: 14}}>
					<SendToMobileIcon
						onClick={() => onResendInvoiceClicked(invoice)}
						style={{color: Colors.theme, height: 30, width: 30, cursor: 'pointer'}}/>
				</div>
			</div>
		)
	}

	const renderPaymentRow = (payment, index) => {
		let {
			reservationId,
			vehicleId,
			clientId,
			amount,
			transactionDate,
			transactionType,
			transactionStatus,
			salesPerson
		} = payment
		let clientsMap = clientsMapSelector(clientsState)
		let client = clientsMap[clientId] || {}
		return (
			<div key={index.toString()} className={Styles.tableRow}>
				<p
					onClick={() => onResClicked(reservationId)}
					style={{width: '8%', marginRight: 14, cursor: 'pointer', textDecoration: 'underline'}}
					className={Styles.tableRowEntry}>
					#{reservationId}
				</p>
				<p style={{width: '20%', marginRight: 14}}
					 className={Styles.tableRowEntry}>{`${client.fName} ${client.lName}`}</p>
				<p style={{width: '20%', marginRight: 14}} className={Styles.tableRowEntry}>{vehiclesMap[vehicleId]?.make}</p>
				<p style={{width: '15%', marginRight: 14}} className={Styles.tableRowEntry}>{salesPerson}</p>
				<p style={{width: '11%', marginRight: 14}} className={Styles.tableRowEntry}>{transactionType}</p>
				<p style={{width: '8%', marginRight: 14}}
					 className={Styles.tableRowEntry}>{moment(transactionDate).format('MM/DD/YY')}</p>
				<div style={{
					width: '15%',
					marginRight: 14,
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center'
				}}>
					<p className={Styles.tableRowEntry}>${Number(amount).toFixed(2)}</p>
					{getStatusText(payment)}
				</div>

				<div
					onClick={() => {
						setModalPayment(payment)
						setShowModal(true)
					}}
					style={{width: '3%', cursor: 'pointer'}} className={Styles.tableRowEntry}>
					<SettingsIcon size={10} color={Colors.theme}/>
				</div>
			</div>
		)
	}

	const renderSearchResults = () => {
		let rows = []
		rows = searchResults.map((payment, index) => renderPaymentRow(payment, index))
		return (
			<div className={Styles.paymentsContainer}>
				<div className={Styles.tableRow}>
					<p style={{width: '8%', marginRight: 14}} className={Styles.tableHeader}>Res #</p>
					<p style={{width: '20%', marginRight: 14}} className={Styles.tableHeader}>Client</p>
					<p style={{width: '20%', marginRight: 14}} className={Styles.tableHeader}>Vehicle</p>
					<p style={{width: '15%', marginRight: 14}} className={Styles.tableHeader}>Sales Person</p>
					<p style={{width: '11%', marginRight: 14}} className={Styles.tableHeader}>Payment Method</p>
					<p style={{width: '8%', marginRight: 14}} className={Styles.tableHeader}>Date</p>
					<p style={{width: '15%', marginRight: 14}} className={Styles.tableHeader}>Amount</p>
					<p style={{width: '3%'}} className={Styles.tableHeader}></p>
				</div>
				{
					rows.length === 0 ?
						<p style={{marginTop: 30, width: '100%', textAlign: 'center'}}>No results match that search</p>
						:
						rows
				}
			</div>
		)
	}

	const renderPaymentsTab = () => {
		let rows = []
		if (allPayments && allPayments.length > 0) {
			rows = allPayments.map((payment, index) => renderPaymentRow(payment, index))
		}

		return (
			<div className={Styles.paymentsContainer}>
				<div className={Styles.tableRow}>
					<p style={{width: '8%', marginRight: 14}} className={Styles.tableHeader}>Res #</p>
					<p style={{width: '20%', marginRight: 14}} className={Styles.tableHeader}>Client</p>
					<p style={{width: '20%', marginRight: 14}} className={Styles.tableHeader}>Vehicle</p>
					<p style={{width: '15%', marginRight: 14}} className={Styles.tableHeader}>Sales Person</p>
					<p style={{width: '11%', marginRight: 14}} className={Styles.tableHeader}>Payment Method</p>
					<p style={{width: '8%', marginRight: 14}} className={Styles.tableHeader}>Date</p>
					<p style={{width: '15%', marginRight: 14}} className={Styles.tableHeader}>Amount</p>
					<p style={{width: '3%'}} className={Styles.tableHeader}></p>
				</div>
				{
					rows.length === 0 ?
						<p style={{marginTop: 30, width: '100%', textAlign: 'center'}}>No payments to show</p>
						:
						<div style={{width: '100%', height: '70vh', overflowY: 'scroll', paddingBottom: 40}}>
							{rows}
						</div>
				}
			</div>
		)

	}

	const renderSecurityAuthRow = (payment, index) => {
		let {
			reservationId,
			vehicleId,
			clientId,
			amount,
			transactionDate,
			transactionType,
			transactionStatus,
			salesPerson
		} = payment
		let clientsMap = clientsMapSelector(clientsState)
		let client = clientsMap[clientId] || {}
		let holdTime = moment().diff(moment(transactionDate), 'days')
		return (
			<div key={index.toString()} className={Styles.tableRow}>
				<p
					onClick={() => onResClicked(reservationId)}
					style={{width: '9%', marginRight: 14, cursor: 'pointer', textDecoration: 'underline'}}
					className={Styles.tableRowEntry}>
					#{reservationId}
				</p>
				<p style={{width: '18%', marginRight: 14}}
					 className={Styles.tableRowEntry}>{`${client.fName} ${client.lName}`}</p>
				<p style={{width: '20%', marginRight: 14}} className={Styles.tableRowEntry}>{vehiclesMap[vehicleId]?.make}</p>
				<div style={{width: '11%', marginRight: 14, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<p className={Styles.tableRowEntry}>{holdTime} day(s)</p>
					{
						holdTime >= 7 &&
						<div
							style={{
								marginLeft: 2,
								height: 20,
								backgroundColor: '#CBAA54',
								borderRadius: 10,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								padding: '0px 2px'
							}}
						>
							<label style={{
								color: 'white',
								textAlign: 'center',
								fontSize: 10
							}}>Attention</label>
						</div>
					}
				</div>
				<p style={{width: '11%', marginRight: 14}} className={Styles.tableRowEntry}>{transactionType}</p>
				<p style={{width: '12%', marginRight: 14}}
					 className={Styles.tableRowEntry}>{moment(transactionDate).format('MM/DD/YY')}</p>
				<div style={{
					width: '15%',
					marginRight: 14,
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center'
				}}>
					<p className={Styles.tableRowEntry}>${Number(amount).toFixed(2)}</p>
					{getStatusText(payment)}
				</div>
				<div
					onClick={() => {
						setModalPayment(payment)
						setShowModal(true)
					}}
					style={{width: '5%', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
					className={Styles.tableRowEntry}>
					<SettingsIcon size={10} color={Colors.theme}/>
				</div>
			</div>
		)
	}

	const renderInvoiceTab = () => {
		return (
			<div className={Styles.paymentsContainer}>
				<div className={Styles.tableRow}>
					<p style={{width: '8%', marginRight: 14}} className={Styles.tableHeader}>Res #</p>
					<p style={{width: '15%', marginRight: 14}} className={Styles.tableHeader}>Client</p>
					<p style={{width: '15%', marginRight: 14}} className={Styles.tableHeader}>Vehicle</p>
					<p style={{width: '8%', marginRight: 14}} className={Styles.tableHeader}>Amount</p>
					<p style={{width: '20%', marginRight: 14}} className={Styles.tableHeader}>Description</p>
					<p style={{width: '10%', marginRight: 14}} className={Styles.tableHeader}>Created</p>
					<p style={{width: '8%', marginRight: 14}} className={Styles.tableHeader}>Type</p>
					<p style={{width: '12%', marginRight: 14}} className={Styles.tableHeader}>Status</p>
					<p style={{width: '4%', marginRight: 14}} className={Styles.tableHeader}></p>
				</div>

				<div style={{width: '100%', height: '60vh', overflowY: 'scroll', paddingBottom: 40}}>
					{
						invoicesLoading ?
							<div style={{width: '100%', marginTop: 100}}>
								<CustomLoaderSpinner/>
							</div>
							:
							invoices && invoices.length > 0 ?
								<div style={{width: '100%', height: '70vh', overflowY: 'scroll', paddingBottom: 40}}>
									{invoices.map((invoice, index) => renderInvoiceRow(invoice, index))}
								</div>
								:
								<p style={{width: '100%', textAlign: 'center', marginTop: 50}}>No invoices to show</p>
					}
				</div>

			</div>
		)
	}

	const renderSecurityTab = () => {
		let authRows = []
		let comRows = []
		if (allSecurity && allSecurity.length > 0) {
			allSecurity.forEach((payment, index) => {
				if (payment.transactionStatus === PAYMENT_STATUSES.AUTHORIZED)
					authRows.push(renderSecurityAuthRow(payment, index))
				else
					comRows.push(renderPaymentRow(payment, index))
			})
		}

		return (
			<div className={Styles.paymentsContainer}>
				<div
					style={{width: '40%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', margin: '0 auto'}}>
					<SecTab tab={secTab} onChange={(value) => setSecTab(value)}/>
				</div>

				{
					secTab === 0 ?
						<div>
							<div className={Styles.tableRow}>
								<p style={{width: '9%', marginRight: 14}} className={Styles.tableHeader}>Res #</p>
								<p style={{width: '18%', marginRight: 14}} className={Styles.tableHeader}>Client</p>
								<p style={{width: '20%', marginRight: 14}} className={Styles.tableHeader}>Vehicle</p>
								<p style={{width: '11%', marginRight: 14}} className={Styles.tableHeader}>Hold Time</p>
								<p style={{width: '11%', marginRight: 14}} className={Styles.tableHeader}>Payment Method</p>
								<p style={{width: '12%', marginRight: 14}} className={Styles.tableHeader}>Date</p>
								<p style={{width: '15%', marginRight: 14}} className={Styles.tableHeader}>Amount</p>
								<p style={{width: '5%'}} className={Styles.tableHeader}></p>
							</div>
							{
								authRows.length === 0 ?
									<p style={{marginTop: 30, width: '100%', textAlign: 'center'}}>No to show</p>
									:
									<div style={{width: '100%', height: '60vh', overflowY: 'scroll', paddingBottom: 40}}>
										{authRows}
									</div>
							}
						</div>
						:
						<div>
							<div className={Styles.tableRow}>
								<p style={{width: '8%', marginRight: 14}} className={Styles.tableHeader}>Res #</p>
								<p style={{width: '20%', marginRight: 14}} className={Styles.tableHeader}>Client</p>
								<p style={{width: '20%', marginRight: 14}} className={Styles.tableHeader}>Vehicle</p>
								<p style={{width: '15%', marginRight: 14}} className={Styles.tableHeader}>Sales Person</p>
								<p style={{width: '11%', marginRight: 14}} className={Styles.tableHeader}>Payment Method</p>
								<p style={{width: '8%', marginRight: 14}} className={Styles.tableHeader}>Date</p>
								<p style={{width: '15%', marginRight: 14}} className={Styles.tableHeader}>Amount</p>
								<p style={{width: '3%'}} className={Styles.tableHeader}></p>
							</div>
							{
								comRows.length === 0 ?
									<p style={{marginTop: 30, width: '100%', textAlign: 'center'}}>No to show</p>
									:
									<div style={{width: '100%', height: '60vh', overflowY: 'scroll', paddingBottom: 40}}>
										{comRows}
									</div>
							}
						</div>
				}


			</div>
		)

	}

	const renderContent = () => {
		if (loading) {
			return (
				<div className={GeneralStyles.container}
						 style={{height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
					<CustomLoaderSpinner/>
				</div>
			)
		}

		return (
			<div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				{renderFilterRow()}
				{
					searchString.length > 0 ?
						renderSearchResults()
						:
						tab === 0 ?
							renderPaymentsTab()
							:
							tab === 1 ?
								renderSecurityTab()
								:
								renderInvoiceTab()
				}
			</div>
		)
	}

	const renderFilterRow = () => {
		return (

			<div style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between'
			}}>
				<div style={{
					width: '50%',
					backgroundColor: Colors.theme,
					borderRadius: 10,
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					paddingLeft: 20
				}}>
					<img src={SearchIcon} style={{height: 16, width: 16}}/>
					<CustomTextFieldNative
						containerStyle={{width: '100%'}}
						height={36}
						label={'search'}
						placeholder={'Search by res#, vehicle, customer name, email or salesperson '}
						value={searchString}
						onChange={onSearchStringChanged}/>
				</div>

				<div style={{width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
					<Tab tab={tab} onChange={(value) => {
						setTab(value)
						if (value === 2)
							getInvoices()
					}}/>
				</div>
			</div>

		)
	}

	const renderSettingsOptions = () => {
		let buttons = []
		if (settingsOptions === SETTINGS_OPTIONS.screen1) {
			buttons.push({
				text: 'Process Payment', onClick: () => {
					setSettingOptions(SETTINGS_OPTIONS['screenProcessPayment'])
					setPrevSettingsScreen(SETTINGS_OPTIONS['screenProcessPayment'])
				}
			})
			buttons.push({
				text: 'Offline Payment', onClick: () => {
					setSettingOptions(SETTINGS_OPTIONS['screenOfflinePayment'])
					setPrevSettingsScreen(SETTINGS_OPTIONS['screenOfflinePayment'])
				}
			})
			buttons.push({
				text: 'Send Invoice', onClick: () => {
					setSettingOptions(SETTINGS_OPTIONS['screenInvoice'])
					setPrevSettingsScreen(SETTINGS_OPTIONS['screenInvoice'])
				}
			})
		} else if (
			settingsOptions === SETTINGS_OPTIONS.screenProcessPayment
			|| settingsOptions === SETTINGS_OPTIONS.screenOfflinePayment
			|| settingsOptions === SETTINGS_OPTIONS.screenInvoice
		) {
			buttons.push({
				text: 'New Payment', onClick: () => {
					setPayType('payment')
					if (prevSettingsScreen === SETTINGS_OPTIONS.screenProcessPayment)
						setShowPaymentModal(true)
					else if (prevSettingsScreen === SETTINGS_OPTIONS.screenOfflinePayment)
						setShowOfflineModal(true)
					else
						setShowInvoiceModal(true)
					onNewPaymentClicked()
				}
			})
			buttons.push({
				text: 'Security Deposit', onClick: () => {
					setPayType('security')
					if (prevSettingsScreen === SETTINGS_OPTIONS.screenProcessPayment)
						setShowPaymentModal(true)
					else if (prevSettingsScreen === SETTINGS_OPTIONS.screenOfflinePayment)
						setShowOfflineModal(true)
					else
						setShowInvoiceModal(true)
					onNewPaymentClicked()
				}
			})
		}

		return (
			<div style={{
				position: 'absolute',
				right: 20,
				top: 80,
				backgroundColor: Colors.theme,
				width: 220,
				borderRadius: 10,
				zIndex: 10
			}}>
				{
					buttons.map((button, index) => {
						return (
							<div
								key={index.toString()}
								onClick={button.onClick}
								style={{
									paddingLeft: 10,
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									borderBottom: index !== buttons.length - 1 ? `1px solid ${Colors.secondaryTextColor}` : 0,
									cursor: 'pointer'
								}}>
								<p style={{width: 180, color: Colors.tertiaryTextColor}}>{button.text}</p>
								<p style={{color: Colors.tertiaryTextColor}}>{`>`}</p>
							</div>
						)
					})
				}
			</div>
		)
	}

	return (
		<div className={GeneralStyles.container} style={{overflowY: 'hidden'}}>
			<div style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				position: 'relative'
			}}>
				<PageHeader header={'Payments'} subHeader={`${moment().format('dddd, MMM Do')}`}/>
				<CustomButtonContained
					text={'+ New Payment'}
					onClick={onNewPaymentClicked}
					style={{position: 'absolute', right: 40, zIndex: 10}}
					color={showOverlay ? 'white' : 'primary'}
				/>
			</div>

			<div className={GeneralStyles.overlay} id={'overlay'}/>

			{
				showOverlay &&
				renderSettingsOptions()
			}


			<PositionedSnackbar
				onClose={() => {
					setShowError(false)
					setShowSuccess(false)
					setErrorMessage('')
					setSuccessMessage('')
				}}
				severity={showError ? 'error' : 'success'}
				openFlag={showError || showSuccess}
				message={showError ? errorMessage : successMessage}
			/>

			{renderContent()}
			{
				showModal &&
				<IndividualPaymentModal
					payment={modalPayment}
					modalIsOpen={showModal}
					onModalClose={() => {
						setModalPayment(null)
						setShowModal(false)
					}}
					errorCallback={(message) => {
						setErrorMessage(message)
						setShowError(true)
						setModalPayment(null)
						setShowModal(false)
					}}
					successCallback={(message) => {
						setSuccessMessage(message)
						setShowSuccess(true)
						setModalPayment(null)
						setShowModal(false)
						dispatch(getAllPayments({token}))
					}}

				/>
			}
			{
				showPaymentModal &&
				<CapturePaymentModal
					reservation={null}
					paymentType={payType}
					modalIsOpen={showPaymentModal}
					onModalClose={() => {
						dispatch(getAllPayments({token}))
						setPayType(null)
						setShowPaymentModal(false)
					}}
					errorCallback={(err) => {
						setShowError(true)
						setErrorMessage(err.message)
						setPayType(null)
						setShowPaymentModal(false)
					}}
					successCallback={(message) => {
						setSuccessMessage(message || 'Successful')
						setShowSuccess(true)
						setPayType(null)
						setShowPaymentModal(false)
						dispatch(getAllPayments({token}))
					}}
				/>
			}

			{
				showOfflineModal &&
				<OfflinePaymentModal
					reservation={null}
					paymentType={payType}
					modalIsOpen={showOfflineModal}
					onModalClose={() => {
						setPayType(null)
						setShowOfflineModal(false)
					}}
					errorCallback={(err) => {
						setShowError(true)
						setErrorMessage(err.message)
						setPayType(null)
						setShowOfflineModal(false)
					}}
					successCallback={(message) => {
						setSuccessMessage(message || 'Successful')
						setShowSuccess(true)
						setPayType(null)
						setShowOfflineModal(false)
					}}
				/>
			}

			{
				showInvoiceModal &&
				<InvoiceModal
					reservation={null}
					paymentType={payType}
					modalIsOpen={showInvoiceModal}
					onModalClose={() => {
						setPayType(null)
						setShowInvoiceModal(false)
					}}
					errorCallback={(err) => {
						setShowError(true)
						setErrorMessage(err.message)
						setPayType(null)
						setShowInvoiceModal(false)
					}}
					successCallback={(message) => {
						setSuccessMessage(message || 'Successful')
						setShowSuccess(true)
						setPayType(null)
						setShowInvoiceModal(false)
					}}
				/>
			}
		</div>
	)
}

const Tab = ({tab, onChange}) => {
	return (
		<div style={{
			width: '90%',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			borderRadius: 10,
			border: '1px solid gray',
			padding: 4,
			backgroundColor: Colors.theme,
			borderColor: Colors.theme
		}}>
			<div
				className={Styles.tabEntry}
				style={{backgroundColor: tab === 0 ? Colors.tabButtonTheme : 'transparent',}}
				onClick={() => onChange(0)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Payments</p>
			</div>
			<div
				className={Styles.tabEntry}
				style={{backgroundColor: tab === 1 ? Colors.tabButtonTheme : 'transparent',}}
				onClick={() => onChange(1)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Security Deposits</p>
			</div>
			<div
				className={Styles.tabEntry}
				style={{backgroundColor: tab === 2 ? Colors.tabButtonTheme : 'transparent',}}
				onClick={() => onChange(2)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Invoices</p>
			</div>
		</div>
	)
}

const SecTab = ({tab, onChange}) => {
	return (
		<div style={{
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			borderRadius: 10,
			border: '1px solid gray',
			padding: 4,
			backgroundColor: Colors.theme,
			borderColor: Colors.theme
		}}>
			<div
				className={Styles.tabEntry}
				style={{backgroundColor: tab === 0 ? Colors.tabButtonTheme : 'transparent',}}
				onClick={() => onChange(0)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>On Hold</p>
			</div>
			<div
				className={Styles.tabEntry}
				style={{backgroundColor: tab === 1 ? Colors.tabButtonTheme : 'transparent',}}
				onClick={() => onChange(1)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Released/Captured</p>
			</div>
		</div>
	)
}

export default PaymentsHome
