import React, { useEffect, useState } from "react";
// import {useNavigate} from "react-router-dom";
import { useSelector } from "react-redux";

import Styles from "../styles/NewClient.module.scss";
import Colors from '../../../config/colors'
import file from '../../../assets/logos/file.svg'
import CustomLoaderSpinner from "../../../components/general/CustomLoaderSpinner";
import { API_CALL_STATUS } from "../../../metadata/enums";
import { LOGGER } from "../../../utils/Logger";
import moment from "moment";
import CustomModal from "../../../components/general/CustomModal";
import PartnerSplitPdfTemplate from "../../../components/general/PartnerSplitPdfTemplate";


function getBreakDownForMonth(monthName, monthBreakDown) {
    let totalRevenu = 0;
    let totalExpense = 0;
    let totalProfit = 0;
    let totalpartnerProfit = 0;
    let totalcompanyProfit = 0;
    let total_reservations = 0;
    let timescalled = 0;
    monthBreakDown?.forEach((split) => {
        total_reservations += split.total_reservations;
        totalRevenu += split.revenue;
        totalExpense += split.expense ? split.expense : 0;

        let profit = split.revenue - (split.expense ? split.expense : 0);
        let partnerProfit = (profit / 100) * split.percentage;
        let companyProfit = profit - partnerProfit;

        totalpartnerProfit += partnerProfit;
        totalcompanyProfit += companyProfit;
        totalProfit += profit;

        timescalled++;
        // console.log({month:monthName,revenu:totalRevenu,expense:totalExpense,partnerProfit:totalpartnerProfit,totalProfit:totalProfit,companyProfit:totalcompanyProfit});
    })

    // console.log(timescalled);
    return { month: monthName, revenu: totalRevenu, expense: totalExpense, partnerProfit: totalpartnerProfit, totalProfit: totalProfit, companyProfit: totalcompanyProfit, Status: 'paid' }
}


const RenderMonthlyBreakDown = ({ setTotalCompanyProfit, setTotalPartnerProfit, setTotalProfit }) => {

    const partnersMonthlyBreakDown = useSelector(state => state.partnerSplits.partnerProfileData_monthlyBreakDown)
    const partnersState = useSelector(state => state.partnerSplits)


    const [loading, setLoading] = useState(true);
    const [monthsList, setMonthList] = useState([]);
    const [monthsData, setMonthData] = useState([]);

    const [showPdf, setShowPdf] = useState(false);
    const [pdfData, setPdfData] = useState({
        partnerName: ``,
        startDate: moment(),
        endDate: moment(),
        partnerSplitData: { split: [] }
    });


    //getting the partner split breakdown data:
    useEffect(() => {
        if (partnersState.partnerProfileData_monthlyBreakDown_status === API_CALL_STATUS.LOADING) {
            setLoading(true)
            setMonthList([])
            setMonthData([])
        } else if (partnersState.partnerProfileData_monthlyBreakDown_status === API_CALL_STATUS.FAILED) {
            setLoading(true)
            LOGGER.error('error effect', partnersState.error)
            setMonthList([])
            setMonthData([])
        } else if (partnersState.partnerProfileData_monthlyBreakDown_status === API_CALL_STATUS.SUCCEEDED) {
            if (Object.keys(partnersState.partnerProfileData_monthlyBreakDown).length === 0) {
                console.log('partnersMonthlyBreakDown is empty', partnersMonthlyBreakDown);
                setMonthList([])
                setMonthData([])
            }
            else {
                let data = partnersMonthlyBreakDown;
                setMonthList(Object.keys(data))
                setMonthData(data)
            }
            setLoading(false)
        }
    }, [partnersState])

    useEffect(() => {
        // setting the over view profits here to aviod the bad state error in react 
        // setting the overview profits: 
        setTotalCompanyProfit(Number(qv_totalCompanyProfit).toFixed(0));
        setTotalPartnerProfit(Number(qv_totalPartnerProfit).toFixed(0));
        setTotalProfit(Number(qv_totalProfit).toFixed(0));

    }, [monthsList, monthsData])

    // showing pdf for selected row : 
    const onRowClick = (month) => {
        setShowPdf(true)
        setPdfData({
            partnerName: `${partnersState.partnerProfileData.fName} ${partnersState.partnerProfileData.lName}`,
            startDate: moment(month, 'MMMM YYYY').startOf('month'),
            endDate: moment(month, 'MMMM YYYY').endOf('month'),
            partnerSplitData: { split: [...monthsData[month]] }
        })
    }

    const RenderRowPdf = () => {
        if (showPdf) {

            return (
                <CustomModal
                    show={showPdf}
                    handleClose={() => setShowPdf(false)}
                    containerWidth={window.innerWidth - 100}
                    containerHeight={window.innerHeight - 100}
                >
                    <PartnerSplitPdfTemplate
                        selectedPartner={pdfData.partnerName}
                        startDate={pdfData.startDate}
                        endDate={pdfData.endDate}
                        partnerSplitData={pdfData.partnerSplitData}
                    />
                </CustomModal>
            )
        }

    }

    //values for quick over view: 
    let qv_totalPartnerProfit = 0;
    let qv_totalCompanyProfit = 0;
    let qv_totalProfit = 0;

    //rows data: get the month, totalrevenu, total expenses, total profit, partners cut 
    let rowData = [];
    monthsList.forEach((_month) => {
        let data = getBreakDownForMonth(_month, monthsData[_month]);
        rowData.push(data);
    })

    if (loading) {
        return (
            <div style={{ width: '100%', marginTop: 30 }}>
                <CustomLoaderSpinner />
            </div>

        )
    }

    let rows = [];
    rowData?.forEach((month) => {
        const classString = `${Styles.tableRowEntry} ${month.Status === 'unpaid' ? Styles.inactive : ''}`
        qv_totalPartnerProfit += month.partnerProfit;
        qv_totalCompanyProfit += month.companyProfit;
        qv_totalProfit += month.totalProfit;
        rows.push((
            <div
                key={`${month.month}`}
                className={Styles.tableRow}
                style={{ borderBottom: `0.5px solid ${Colors.tableLineColor}`, cursor: 'auto' }}>
                <p style={{ width: '16.61%', cursor: 'auto' }} className={classString}>{month.month}</p>
                <p style={{ width: '16.61%', cursor: 'auto' }} className={classString}>$ {Number(month.revenu).toFixed(2)}</p>
                <p style={{ width: '16.61%', cursor: 'auto' }} className={classString}>$ {Number(month.expense).toFixed(2)}</p>
                <p style={{ width: '16.61%', cursor: 'auto' }} className={classString}>$ {Number(month.totalProfit).toFixed(2)}</p>
                <p style={{ width: '16.61%', cursor: 'auto' }} className={classString}>$ {Number(month.partnerProfit).toFixed(2)}</p>
                <div style={{ width: '16%', cursor: 'pointer' }} className={classString}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                        <div style={{ backgroundColor: `${month.Status === 'unpaid' ? Colors.buttonTheme_Light : Colors.buttonTheme}`, marginRight: 6, borderRadius: 12, padding: '1px 10px', color: Colors.tertiaryTextColor, textAlign: "center" }}>
                            {`${month.Status}`}
                        </div>
                    </div>
                </div>
                <img onClick={() => { onRowClick(month.month) }} src={file} style={{ width: '3.61%%', height: 30, cursor: 'pointer', paddingRight: '10px' }} />
            </div>
        ))
    })

    return (
        <div style={{ width: '100%', marginTop: 30 }}>
            <p style={{ fontWeight: 'bold', width: '100%', fontSize: 18, marginBottom: 10 }}>Monthly Break Down</p>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{ cursor: 'auto' }} className={Styles.tableRow}>
                    <p style={{ width: '16.61%', cursor: 'auto' }} className={Styles.tableHeader}>Month</p>
                    <p style={{ width: '16.61%', cursor: 'auto' }} className={Styles.tableHeader}>Revenue</p>
                    <p style={{ width: '16.61%', cursor: 'auto' }} className={Styles.tableHeader}>Expenses</p>
                    <p style={{ width: '16.61%', cursor: 'auto' }} className={Styles.tableHeader}>Total Profit</p>
                    <p style={{ width: '16.61%', cursor: 'auto' }} className={Styles.tableHeader}>Partner Profit</p>
                    <p style={{ width: '16%', cursor: 'auto' }} className={Styles.tableHeader}>Status</p>
                    <p style={{ width: '3.61%', cursor: 'auto' }} className={Styles.tableHeader}>PDF</p>
                </div>
                {rows}
            </div>
            {RenderRowPdf()}
        </div>
    )

}
export default RenderMonthlyBreakDown;