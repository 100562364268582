import React,{useState, useEffect} from 'react';
import Config from "../../config";
import Colors from '../../config/colors'
import GooglePlacesAutocomplete from "react-google-places-autocomplete";



export default function CustomGooglePlaces({value, onChange, marginTop, borderColor,placeholder}) {

	const customStyles = {
		control: (base, state) => ({
			...base,
			background: "#00000000",
			// match with the menu
			borderRadius: 10,
			// Overwrittes the different states of border
			borderColor: 'transparent' || Colors.secondaryTextColor ,
			width: '100%',
			marginTop: marginTop || 0,
			// Removes weird border around container
			boxShadow: null,
			"&:hover": {
				// Overwrittes the different states of border
				borderColor: Colors.tableLineColor
			}
		}),
		menu: base => ({
			...base,
			// override border radius to match the box
			borderRadius: 0,
			// kill the gap
			marginTop: 0,
		}),
		menuList: base => ({
			...base,
			// kill the white space on first and last option
			padding: 0,
		}),
		singleValue: base => ({
			...base,
			color: Colors.primaryTextColor
		}),
		input: base => ({
			...base,
			color: Colors.primaryTextColor
		}),
	};

	return (
		<GooglePlacesAutocomplete
			apiKey={Config.GOOGLE_API_KEY}
			placeholder='search address...'
			selectProps={{
				placeholder: placeholder || 'select',
				value: value,
				onChange: onChange,
				styles: customStyles,
				theme: (theme) => ({
					...theme,
					colors: {
					...theme.colors,
					primary25: '#A996FF33',
					primary: '#A996FF',
				},
				})
			}}
			onPress={(data, details = null) => {
				// 'details' is provided when fetchDetails = true
				console.log(data, details);
			}}
		/>
	)
}

