import React, {useEffect, useState} from 'react'
import GeneralStyles from "../../routes/GeneralStyles.module.scss";
import {useSelector} from "react-redux";
import {EMAIL_SQS_TYPE, PAYMENT_STATUSES} from "../../metadata/enums";
import moment from "moment";
import Styles from "./styles/index.module.scss";
import CustomModal from "../../components/general/CustomModal";
import Colors from "../../config/colors";
import EmailIcon from '@mui/icons-material/Email';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {reservationsMapSelector} from "../../redux/slices/reservationsSlice";
import Config from "../../config";
import axios from "axios";
import CustomLoaderSpinner from "../general/CustomLoaderSpinner";
import UndoIcon from '@mui/icons-material/Undo';
import CustomTextFieldNew from "../general/CustomTextFieldNew";
import CustomButtonContained from "../general/CustomButtonContained";
import {clientsMapSelector} from "../../redux/slices/clientsSlice";
import {LOGGER} from "../../utils/Logger";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Constants from "../../config/constants";


export default function IndividualPaymentModal({payment, modalIsOpen, onModalClose, successCallback, errorCallback}) {
	const reservationsMap = useSelector(state => reservationsMapSelector(state.reservations))
	const token = useSelector(state => state.auth.token)
	const clientsMap = useSelector(state => clientsMapSelector(state.clients))

	const [errorMessage, setErrorMessage] = useState('')
	const [showError, setShowError] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)
	const [successMessage, setSuccessMessage] = useState('')

	const [showLoading, setShowLoading] = useState(false)
	const [isSecurity, setIsSecurity] = useState(false)
	const [showRefundFrag, setShowRefundFrag] = useState(false)
	const [refundAmount, setRefundAmount] = useState('')
	const [showCaptureFrag, setShowCaptureFrag] = useState(false)
	const [captureAmount, setCaptureAmount] = useState('')

	useEffect(() => {
		if (!payment)
			return
		setIsSecurity(payment.isSecurityHold)
	}, [payment])


	if (!payment)
		return null


	const onSendEmailClicked = () => {
		if (window.confirm('Are you sure you want to email the receipt for this transaction?')) {
			emailPaymentReceipt()
		}
	}

	const onApproveOrDeclineTransactionClicked = async (shouldApprove) => {
		if (!window.confirm(`Are you sure you want to ${shouldApprove ? 'approve' : 'decline'} the transaction?`))
			return

		const transactionConfig = {
			method: 'post',
			url: `${Config.BACKEND_URL}approveOrDeclineTransaction`,
			data: {
				transactionId: payment.transactionId,
				shouldApprove: shouldApprove,
			},
			headers: {Authorization: token},
		};
		try {
			setShowLoading(true);
			const transactionResponse = await axios(transactionConfig);
			setShowLoading(false);
			if (transactionResponse.data?.success) {
				setSuccessMessage(`Successfully ${shouldApprove ? 'approved' : 'declined'} transaction`)
				setShowSuccess(true)
				successCallback(`Successfully ${shouldApprove ? 'approved' : 'declined'} transaction`)
			} else {
				setErrorMessage('Something went wrong when approving or declining transaction')
				setShowError(true)
				errorCallback('Something went wrong when approving or declining transaction')
			}
		} catch (err) {
			console.log('error when approving or declining transaction', err)
			setErrorMessage('Something went wrong when approving or declining transaction')
			setShowError(true)
			errorCallback('Something went wrong when approving or declining transaction')
		}
	}

	const voidTransaction = async (isStripe) => {
		let transactionConfig
		if (isStripe) {
			transactionConfig = {
				method: 'post',
				url: `${Config.BACKEND_URL}stripe/void`,
				data: {
					payment_intent: payment.payment_intent
				},
				headers: {Authorization: token},
			};
		} else {
			transactionConfig = {
				method: 'post',
				url: `${Config.BACKEND_URL}voidTransaction`,
				data: {
					transactionId: payment.transactionId,
					clientId: payment.clientId,
				},
				headers: {Authorization: token},
			};
		}

		try {
			setShowLoading(true);
			const transactionResponse = await axios(transactionConfig);
			setShowLoading(false);
			if (transactionResponse.data?.success) {
				setSuccessMessage('Successfully released the authorization hold')
				setShowSuccess(true)
				successCallback('Successfully released the authorization hold')
			} else {
				setErrorMessage('Something went wrong when releasing hold')
				setShowError(true)
				errorCallback('Something went wrong when releasing hold')
			}
		} catch (err) {
			console.log('error when voiding trnasction', err)
			setErrorMessage('Something went wrong when releasing hold')
			setShowError(true)
			errorCallback('Something went wrong when releasing hold')
		}
	};

	const emailPaymentReceipt = async () => {
		const reservation = reservationsMap[payment?.reservationId] || {}
		const config = {
			method: 'post',
			url: `${Config.BACKEND_URL}mail`,
			data: {
				resId: reservation?._id,
				paymentId: payment?._id,
				emailType: EMAIL_SQS_TYPE.PAYMENT_RECEIPT,
			},
			headers: {Authorization: token},
		};
		setShowLoading(true);

		try {
			const res = await axios(config);
			if (res.data?.success) {
				setShowLoading(false);
				successCallback('Successfully emailed the payment receipt')
			} else {
				console.error('Error when sending email', res);
				errorCallback('Error when sending email: Could not send the email');
			}
		} catch (err) {
			console.error('Error when sending email', err);
			errorCallback('Error: Could not send the email');
		}
	};

	const handleOfflineCancelClick = () => {
		if (payment.transactionStatus !== PAYMENT_STATUSES.OFFLINE_CHARGED)
			return

		if (window.confirm('Are you sure you want to cancel this offline transaction?')) {
			cancelTransaction()
		}
	}

	const cancelTransaction = async () => {
		const config = {
			method: 'put',
			url: `${Config.BACKEND_URL}payments/${payment._id}`,
			data: {
				transactionStatus: PAYMENT_STATUSES.OFFLINE_CANCELLED,
				voidTime: new Date(),
			},
			headers: {Authorization: token},
		};
		setShowLoading(true);

		try {
			const res = await axios(config);
			setShowLoading(false)
			if (res.data?.success) {
				setSuccessMessage('Successfully cancelled the offline transaction')
				setShowSuccess(true)
				successCallback('Successfully cancelled the offline transaction')
			} else {
				setErrorMessage('Something went wrong when cancelling transaction')
				setShowError(true)
				errorCallback('Something went wrong when cancelling transaction')
			}
		} catch (err) {
			LOGGER.error('error when cancelling trsansaction', err);
			setErrorMessage('Something went wrong when cancelling transaction')
			setShowError(true)
			errorCallback('Something went wrong when cancelling transaction')
		}
	};

	const onCaptureClicked = () => {
		if (!captureAmount || captureAmount.length === 0 || Number(captureAmount) <= 0) {
			setErrorMessage(`Please enter a capture amount less than or equal to ${payment.amount}`)
			setShowError(true)
			return
		}

		if (window.confirm(`Are you sure you want to capture ${captureAmount} out of ${payment.amount}?`)) {
			captureTransaction(payment.stripe)
		}
	}


	const captureTransaction = async (isStripe) => {
		setShowLoading(true);
		let transactionConfig
		if (isStripe) {
			transactionConfig = {
				method: 'post',
				url: `${Config.BACKEND_URL}stripe/capture`,
				data: {
					payment_intent: payment.payment_intent,
					amount: Number(payment.amount),
					captureAmount: Number(captureAmount)
				},
				headers: {Authorization: token},
			};
		} else {
			transactionConfig = {
				method: 'post',
				url: `${Config.BACKEND_URL}captureDeposit`,
				data: {
					transactionId: payment.transactionId,
					clientId: payment.clientId,
					amount: Number(payment.amount),
					captureAmount: Number(captureAmount)
				},
				headers: {Authorization: token},
			};
		}

		try {
			setShowLoading(true);
			const transactionResponse = await axios(transactionConfig);
			setShowLoading(false)
			if (transactionResponse.data?.success) {
				setSuccessMessage(`Successfully captured amount $${captureAmount}`)
				setShowSuccess(true)
				successCallback(`Successfully captured amount $${captureAmount}`)
			} else {
				setErrorMessage('Something went wrong when capturing amount')
				setShowError(true)
				errorCallback('Something went wrong when capturing amount')
			}
		} catch (err) {
			LOGGER.error(err);
			setErrorMessage('Something went wrong when capturing amount')
			setShowError(true)
			errorCallback('Something went wrong when capturing amount')
		}
	};

	const onRefundClicked = () => {
		if (!refundAmount || refundAmount.length === 0 || Number(refundAmount) <= 0) {
			setErrorMessage(`Please enter a refund amount less than or equal to ${payment.amount}`)
			setShowError(true)
			return
		}

		if (window.confirm(`Are you sure you want to refund ${refundAmount} out of ${payment.amount}?`)) {
			refundTransaction(payment.stripe)
		}
	}

	const refundTransaction = async (isStripe) => {
		setShowLoading(true);
		const reservation = reservationsMap[payment?.reservationId] || {}
		const client = clientsMap[reservation.client] || {}

		let transactionConfig
		if(isStripe) {
			transactionConfig = {
				method: 'post',
				url: `${Config.BACKEND_URL}stripe/refund`,
				data: {
					payment_intent: payment.payment_intent,
					amount: Number(payment.amount),
					refundAmount: Number(refundAmount)
				},
				headers: {Authorization: token},
			};
		}else {
			transactionConfig = {
				method: 'post',
				url: `${Config.BACKEND_URL}refundTransaction`,
				data: {
					transactionId: payment.transactionId,
					clientId: client._id,
					amount: Number(payment.amount),
					refundAmount: Number(refundAmount)
				},
				headers: {Authorization: token},
			};
			if (payment.customerPaymentProfileId) {
				// it was paid through stored cards, so need to send that payment profile back
				transactionConfig.data.customerPaymentProfileId =
					payment.customerPaymentProfileId;
				transactionConfig.data.customerProfileId = client?.customerProfileId;
			} else {
				transactionConfig.data.cardNumber =
					payment.creditCard.replace('XXXX', '');
				transactionConfig.data.expirationDate =
					payment.expirationDate;
			}
		}

		try {
			const transactionResponse = await axios(transactionConfig);
			setShowLoading(false);
			if (transactionResponse.data?.success) {
				setSuccessMessage('Successfully refunded transaction')
				setShowSuccess(true)
				successCallback('Successfully refunded transaction')
			} else {
				setErrorMessage('Something went wrong when refunding transaction')
				setShowError(true)
				errorCallback('Something went wrong when refunding transaction')
			}
		} catch (err) {
			console.log('error when refunding transaction', err)
			setErrorMessage('Something went wrong when refunding transaction')
			setShowError(true)
			errorCallback('Something went wrong when refunding transaction')
		}
	};


	const refundFrag = () => {
		return (
			<div
				style={{
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: 20,
					flexDirection: 'column'
				}}>
				<CustomTextFieldNew
					width={'100%'}
					label={'Refund Amount($)'}
					placeholder={'enter amount to refund'}
					value={refundAmount}
					onChange={(text) => {
						if (isNaN(text) || Number(text) > payment.amount)
							return
						setRefundAmount(text)
					}}
				/>

				<div style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					width: '100%',
					justifyContent: 'space-between'
				}}>
					<CustomButtonContained
						text={'Refund Amount'}
						onClick={onRefundClicked}
						style={{marginTop: 2}}
						color={'primary'}
					/>
					<CustomButtonContained
						text={'Cancel'}
						onClick={() => setShowRefundFrag(false)}
						style={{marginTop: 2}}
						color={'secondary'}
					/>
				</div>
			</div>
		)
	}

	const captureFrag = () => {
		return (
			<div
				style={{
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: 20,
					flexDirection: 'column'
				}}>
				<CustomTextFieldNew
					width={'100%'}
					label={'Capture Amount($)'}
					placeholder={'enter amount to capture'}
					value={captureAmount}
					onChange={(text) => {
						if (isNaN(text) || Number(text) > payment.amount)
							return
						setCaptureAmount(text)
					}}
				/>

				<div style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					width: '100%',
					justifyContent: 'space-between'
				}}>
					<CustomButtonContained
						text={'Capture Amount'}
						onClick={onCaptureClicked}
						style={{marginTop: 2}}
						color={'primary'}
					/>
					<CustomButtonContained
						text={'Cancel'}
						onClick={() => setShowCaptureFrag(false)}
						style={{marginTop: 2}}
						color={'secondary'}
					/>
				</div>
			</div>
		)
	}

	const renderButtons = () => {
		if (showLoading) {
			return (
				<div
					style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50}}>
					<CustomLoaderSpinner/>
				</div>
			)
		}

		if (showCaptureFrag) {
			return captureFrag()
		}

		if (showRefundFrag) {
			return refundFrag()
		}

		return (
			<div style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				marginTop: 10
			}}>
				{
					payment.transactionStatus === PAYMENT_STATUSES.CHARGED &&
					<div
						onClick={() => {
							setRefundAmount(payment?.amount || '')
							setShowRefundFrag(true)
						}}
						className={Styles.actionButton}>
						<UndoIcon color={Colors.theme} style={{width: 20, height: 20}}/>
						<label style={{color: Colors.primaryTextColor, fontWeight: 'bold', marginLeft: 25}}>Refund
							Transaction</label>
					</div>
				}

				{
					payment.transactionStatus === PAYMENT_STATUSES.AUTHORIZED &&
					<div>
						<div
							onClick={() => {
								if (window.confirm('Are you sure you want to release the authorization hold?')) {
									voidTransaction(payment.stripe)
								}
							}}
							className={Styles.actionButton}>
							<UndoIcon color={Colors.theme} style={{width: 20, height: 20}}/>
							<label style={{color: Colors.primaryTextColor, fontWeight: 'bold', marginLeft: 25}}>Release Hold</label>
						</div>

						<div
							onClick={() => {
								setCaptureAmount(payment?.amount || '')
								setShowCaptureFrag(true)
							}}
							className={Styles.actionButton}>
							<AttachMoneyIcon color={Colors.theme} style={{width: 20, height: 20}}/>
							<label style={{color: Colors.primaryTextColor, fontWeight: 'bold', marginLeft: 25}}>Capture Amount</label>
						</div>

					</div>

				}

				{
					payment.transactionStatus === PAYMENT_STATUSES.OFFLINE_CHARGED &&
					<div
						onClick={handleOfflineCancelClick}
						className={Styles.actionButton}>
						<DoDisturbIcon color={Colors.theme} style={{width: 20, height: 20}}/>
						<label style={{color: Colors.primaryTextColor, fontWeight: 'bold', marginLeft: 25}}>Cancel
							Transaction</label>
					</div>
				}

				{
					payment?.transactionStatus === PAYMENT_STATUSES.FRAUD_HELD &&
					<>
						<label style={{
							fontSize: Constants.labelSize,
							color: Colors.primaryTextColor,
							width: '70%',
							textAlign: 'center'
						}}>The transaction has been
							flagged as suspicious. Please approve or decline by clicking on the buttons below.</label>
						<div style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-evenly'
						}}>
							<CustomButtonContained
								style={{width: '30%'}}
								text={`\u2713 Approve`}
								onClick={() => onApproveOrDeclineTransactionClicked(true)}
							/>
							<CustomButtonContained
								color={'secondary'}
								style={{width: '30%'}}
								text={`\u274C Decline`}
								onClick={() => onApproveOrDeclineTransactionClicked(false)}
							/>
						</div>
					</>
				}

				<div
					onClick={onSendEmailClicked}
					className={Styles.actionButton}>
					<EmailIcon color={Colors.theme} style={{width: 20, height: 20}}/>
					<label style={{color: Colors.primaryTextColor, fontWeight: 'bold', marginLeft: 25}}>Email Receipt</label>
				</div>


			</div>
		)
	}

	return (
		<CustomModal
			show={modalIsOpen}
			handleClose={onModalClose}
			containerWidth={window.innerWidth / 2}
			containerHeight={window.innerHeight - 200}
		>
			<div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				<label className={GeneralStyles.darkText} style={{fontWeight: 600, fontSize: 20, marginTop: 20}}>
					{isSecurity ? 'Security Deposit' : 'Payment'}
				</label>
				{
					showError &&
					<label style={{width: '100%', textAlign: 'center', color: 'red'}}>{errorMessage}</label>
				}
				{
					showSuccess &&
					<label style={{width: '100%', textAlign: 'center', color: 'green'}}>{successMessage}</label>
				}

				<div style={{
					width: '60%',
					marginTop: 20,
					height: '100%',
					overflowY: 'scroll'
				}}>
					<div className={Styles.modalRow}>
						<label className={Styles.modalLabel}>Payment Type: </label>
						<label className={Styles.modalValue}>{payment?.transactionType}</label>
					</div>
					<div className={Styles.modalRow} style={{marginTop: 10}}>
						<label className={Styles.modalLabel}>Status: </label>
						<label className={Styles.modalValue}>{payment?.transactionStatus}</label>
					</div>
					<div className={Styles.modalRow} style={{marginTop: 10}}>
						<label className={Styles.modalLabel}>Amount: </label>
						<label className={Styles.modalValue}>$ {Number(payment?.amount).toFixed(2)}</label>
					</div>
					{
						(payment?.transactionStatus === PAYMENT_STATUSES.PARTIALLY_CAPTURED || payment?.transactionStatus === PAYMENT_STATUSES.CAPTURED)
						&&
						<div>
							<div className={Styles.modalRow} style={{marginTop: 10}}>
								<label className={Styles.modalLabel}>Captured: </label>
								<label className={Styles.modalValue}>$ {Number(payment?.captureAmount).toFixed(2)}</label>
							</div>
							<div className={Styles.modalRow} style={{marginTop: 10}}>
								<label className={Styles.modalLabel}>Capture Date: </label>
								<label className={Styles.modalValue}>{moment(payment?.captureTime).format("MM/DD/YY, hh:mm A")}</label>
							</div>
						</div>
					}
					{
						(payment?.transactionStatus === PAYMENT_STATUSES.PARTIALLY_REFUNDED || payment?.transactionStatus === PAYMENT_STATUSES.REFUNDED) &&
						<div>
							<div className={Styles.modalRow} style={{marginTop: 10}}>
								<label className={Styles.modalLabel}>Refunded: </label>
								<label className={Styles.modalValue}>$ {Number(payment?.refundAmount).toFixed(2)}</label>
							</div>
							<div className={Styles.modalRow} style={{marginTop: 10}}>
								<label className={Styles.modalLabel}>Refund Date: </label>
								<label className={Styles.modalValue}>{moment(payment?.refundTime).format("MM/DD/YY, hh:mm A")}</label>
							</div>
						</div>
					}
					{
						(payment?.transactionStatus === PAYMENT_STATUSES.VOIDED || payment?.transactionStatus === PAYMENT_STATUSES.OFFLINE_CANCELLED) &&
						<div>
							<div className={Styles.modalRow} style={{marginTop: 10}}>
								<label className={Styles.modalLabel}>Cancelled Date: </label>
								<label className={Styles.modalValue}>{moment(payment?.voidTime).format("MM/DD/YY, hh:mm A")}</label>
							</div>
						</div>
					}

					{
						(payment?.transactionStatus === PAYMENT_STATUSES.OFFLINE_CHARGED || payment?.transactionStatus === PAYMENT_STATUSES.OFFLINE_CANCELLED) &&
						<div>
							<div className={Styles.modalRow} style={{marginTop: 10}}>
								<label className={Styles.modalLabel}>Reference Note: </label>
								<label className={Styles.modalValue}>{payment.reference}</label>
							</div>
						</div>
					}
					<div className={Styles.modalRow} style={{marginTop: 10}}>
						<label className={Styles.modalLabel}>Transaction Date: </label>
						<label className={Styles.modalValue}>{moment(payment?.transactionDate).format('MM/DD/YY, hh:mm A')}</label>
					</div>

					{
						payment?.transactionStatus === PAYMENT_STATUSES.FRAUD_HELD &&
						<div>
							<div className={Styles.modalRow} style={{marginTop: 10}}>
								<label className={Styles.modalLabel}>Fraud Held Time: </label>
								<label
									className={Styles.modalValue}>{payment?.fraud_held_time ? moment(payment?.fraud_held_time).format('MM/DD/YY, hh:mm A') : 'N/A'}</label>
							</div>
						</div>
					}
					{
						payment?.transactionStatus === PAYMENT_STATUSES.FRAUD_DECLINED &&
						<div>
							<div className={Styles.modalRow} style={{marginTop: 10}}>
								<label className={Styles.modalLabel}>Fraud Declined Time: </label>
								<label
									className={Styles.modalValue}>{payment?.fraud_declined_time ? moment(payment?.fraud_declined_time).format('MM/DD/YY, hh:mm A') : 'N/A'}</label>
							</div>
						</div>
					}
					{
						payment?.transactionStatus === PAYMENT_STATUSES.FRAUD_APPROVED &&
						<div>
							<div className={Styles.modalRow} style={{marginTop: 10}}>
								<label className={Styles.modalLabel}>Fraud Approved Time: </label>
								<label
									className={Styles.modalValue}>{payment?.fraud_approved_time ? moment(payment?.fraud_approved_time).format('MM/DD/YY, hh:mm A') : 'N/A'}</label>
							</div>
						</div>
					}

				</div>

				{renderButtons()}

			</div>
		</CustomModal>
	)
}
