import React, {useState, useEffect} from 'react'
import GeneralStyles from "../../routes/GeneralStyles.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {API_CALL_STATUS, PAYMENT_STATUSES} from "../../metadata/enums";
import moment from "moment";
import Styles from "./styles/index.module.scss";
import CustomModal from "../../components/general/CustomModal";
import CustomSelect from "../general/CustomSelect";
import CustomLoaderSpinner from "../general/CustomLoaderSpinner";
import Config from "../../config";
import axios from "axios";
import {LOGGER} from "../../utils/Logger";
import {reservationsMapSelector} from "../../redux/slices/reservationsSlice";
import Helpers from '../../utils/helpers'
import {getPriceSettings} from "../../redux/slices/settingsSlice";
import {vehiclesMapSelector} from "../../redux/slices/vehiclesSlice";
import Colors from "../../config/colors";
import Constants from "../../config/constants";
import CustomTextFieldNew from "../general/CustomTextFieldNew";
import visaImage from '../../assets/logos/card_visa.png';
import mastercardImage from '../../assets/logos/card_mastercard.png';
import amexImage from '../../assets/logos/card_amex.png';
import discoverImage from '../../assets/logos/card_discover.png';
import {clientsMapSelector} from "../../redux/slices/clientsSlice";
import CustomButtonContained from "../general/CustomButtonContained";
import {getUsersUidMap} from '../../redux/slices/userSlice'
import {addPayment} from '../../redux/slices/paymentsSlice'
import {getOneClient} from '../../redux/slices/clientsSlice'
import ApiHelpers from "../../utils/ApiHelpers";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {Calendar} from "react-date-range";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import colors from "../../config/colors";
import {getSalesUsers} from '../../redux/slices/userSlice'

export default function OfflinePaymentModal({
                                              paymentType,
                                              modalIsOpen,
                                              onModalClose,
                                              successCallback,
                                              errorCallback,
                                              reservation
                                            }) {
  const dispatch = useDispatch()

  const salesUsers = useSelector((state) => getSalesUsers(state));
  const token = useSelector(state => state.auth.token)
  const reservationsState = useSelector(state => state.reservations)
  const reservationsMap = useSelector(state => reservationsMapSelector(state.reservations))
  const vehiclesMap = useSelector(state => vehiclesMapSelector(state))
  const priceSettings = useSelector(state => getPriceSettings(state.settings))
  const clientsMap = useSelector(state => clientsMapSelector(state.clients))
  const usersUidMap = useSelector((state) => getUsersUidMap(state.user));
  const mongoUser = useSelector(state => state.user.mongoUser)

  const [showDateSelector, setShowDateSelector] = useState(false)
  const [tab, setTab] = useState(0)
  const [isSecurity, setIsSecurity] = useState(false)
  const [allRes, setAllRes] = useState([])
  const [selectedRes, setSelectedRes] = useState(null)
  const [showLoader, setShowLoader] = useState(false)
  const [payments, setPayments] = useState([])
  const [balancePaid, setBalancePaid] = useState(0);
  const [outstandingBalance, setOutstandingBalance] = useState(0);
  const [balancePayable, setBalancePayable] = useState(0);
  const [securityCaptured, setSecurityCaptured] = useState(0);
  const [securityHold, setSecurityHold] = useState(0);
  const [outstandingSecurityBalance, setOutstandingSecurityBalance] = useState(0);
  const [securityPayable, setSecurityPayable] = useState(0);
  const [cardsLoading, setCardsLoading] = useState(false)
  const [cardsOnFile, setCardsOnFile] = useState([])

  const [errorMessage, setErrorMessage] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [selectedClient, setSelectedClient] = useState(null)
  const [selectedVehicle, setSelectedVehicle] = useState(null)
  const [payType, setPayType] = useState(null)
  const [salesPerson, setSalesPerson] = useState(null);
  const [reference, setReference] = useState('');
  const [date, setDate] = useState(new Date())
  const [time, setTime] = useState(new Date())


  useEffect(() => {
    setIsSecurity(paymentType === 'security')
  }, [paymentType]);

  useEffect(() => {
    if (reservationsState.status !== API_CALL_STATUS.LOADING || reservationsState.status !== API_CALL_STATUS.FAILED) {
      setAllRes(reservationsState.reservations.concat(reservationsState.old_reservations))
    }
  }, [reservationsState])

  useEffect(() => {
    if (reservation)
      setSelectedRes({label: `Res #${reservation._id}-${reservation.vehicle_id}`, value: reservation._id})
  }, [reservation])

  useEffect(() => {
    let reservation = reservationsMap[selectedRes?.value] || {}
    let vehicle = vehiclesMap[reservation.vehicle_id]
    let pricing;
    let totalPrice
    if (reservation.isChauffeur) {
      pricing = Helpers.getChauffeurPricingObject(reservation, vehicle, priceSettings)
      let temp = Object.assign({}, reservation, {pricing: pricing})
      totalPrice = Helpers.getChauffeurTotalPricing(temp)
    } else {
      pricing = Helpers.getRentalsPricingObject(reservation, vehicle, priceSettings)
      let temp = Object.assign({}, reservation, {pricing: pricing})
      totalPrice = Helpers.getRentalTotalPricing(temp)
    }

    const totalSecurity = pricing?.deposit || 0;

    if (payments && payments.length > 0) {
      let totalHold = 0;
      let totalCaptured = 0;
      let totalPaid = 0;

      payments.forEach((trans) => {
        if (trans.isSecurityHold) {
          if (trans.transactionStatus === PAYMENT_STATUSES.AUTHORIZED) totalHold += Number(trans.amount);
          else if (trans.transactionStatus === PAYMENT_STATUSES.CAPTURED || trans.transactionStatus === PAYMENT_STATUSES.OFFLINE_CHARGED) totalCaptured += Number(trans.amount);
          else if (trans.transactionStatus === PAYMENT_STATUSES.PARTIALLY_CAPTURED) totalCaptured += Number(trans.captureAmount);
        } else {
          if (trans.transactionStatus === PAYMENT_STATUSES.CHARGED
            || trans.transactionStatus === PAYMENT_STATUSES.OFFLINE_CHARGED
            || trans.transactionStatus === PAYMENT_STATUSES.FRAUD_APPROVED
          ){
            totalPaid += Number(trans.amount);
          } else if (trans.transactionStatus === PAYMENT_STATUSES.PARTIALLY_REFUNDED){
            totalPaid += Number(trans.amount) - Number(trans.refundAmount);
          }
        }
      });

      const outstandingBalance = totalPrice - totalPaid;
      setOutstandingBalance(outstandingBalance > 0 ? outstandingBalance.toFixed(2) : 0);
      setBalancePayable(outstandingBalance > 0 ? outstandingBalance.toFixed(2) : 0);
      setBalancePaid(totalPaid.toFixed(2));

      const outstandingSecurity = totalSecurity - totalHold - totalCaptured;
      setOutstandingSecurityBalance(outstandingSecurity > 0 ? outstandingSecurity.toFixed(2) : 0);
      setSecurityPayable(outstandingSecurity > 0 ? outstandingSecurity.toFixed(2) : 0);
      setSecurityHold(totalHold.toFixed(2));
      setSecurityCaptured(totalCaptured.toFixed(2));
    } else {
      setOutstandingSecurityBalance(Number(pricing?.deposit).toFixed(2));
      setSecurityPayable(Number(pricing?.deposit).toFixed(2));
      setSecurityHold(0);
      setSecurityCaptured(0);

      setOutstandingBalance(totalPrice.toFixed(2));
      setBalancePayable(totalPrice.toFixed(2));
      setBalancePaid(0);
    }
  }, [payments]);

  useEffect(() => {
    if (selectedRes && selectedRes.value) {
      setShowLoader(true)
      ApiHelpers.getPaymentsForRes(token, selectedRes.value)
        .then(data => {
          setPayments(data)
          setShowLoader(false)
        }).catch(err => {
        LOGGER.error('Error when getting payments for reservation', err)
        errorCallback(err?.message || "Error when getting payments for reservation")
      })

      const reservation = reservationsMap[selectedRes?.value] || {}
      const client = clientsMap[reservation.client]
      const vehicle = vehiclesMap[reservation.vehicle_id]
      setSelectedVehicle(vehicle)
      setSelectedClient(client)
    }

  }, [selectedRes]);

  const onSubmitClicked = async () => {
    if (!payType) {
      setErrorMessage('Please select a payment type from the dropdown')
      setShowError(true)
      return
    }

    let amount = isSecurity ? securityPayable : balancePayable
    if (!amount || amount.length === 0 || isNaN(amount)) {
      setErrorMessage('Please enter valid amount')
      setShowError(true)
      return
    }
    if (!window.confirm(`Are you sure you want to record this offline payment for reservation #${selectedRes?.value}`))
      return
    let reservation = reservationsMap[selectedRes?.value] || {}
    let finalDateTime = moment(date).hours(time?.getHours()).minutes(time?.getMinutes()).toDate().toISOString()
    const payload = {
      transactionDate: finalDateTime,
      amount: Number(amount),
      transactionType: payType?.value,
      reservationId: reservation?._id,
      vehicleId: reservation?.vehicle_id,
      salesPerson: salesPerson?.label,
      reference,
      clientId: reservation.client,
      isSecurityHold: isSecurity
    };

    const config = {
      method: 'post',
      url: `${Config.BACKEND_URL}payments/offline`,
      data: payload,
      headers: {Authorization: token},
    };
    setShowLoader(true)
    try {
      const res = await axios(config);
      setShowLoader(false)
      if (res.data) {
        setShowSuccess(true)
        setSuccessMessage('Successfully recorded offline transaction')
        successCallback('Successfully recorded offline transaction')
      } else {
        setShowError(true)
        setErrorMessage('Something when wrong when processing offline payment')
      }
    } catch (err) {
      LOGGER.error('Error when processing payment', err)
      setErrorMessage('Something went wrong when recording offline payment')
      setShowError(true)
      errorCallback(err?.message || "Something went wrong when recording offline payment")
    }
  }

  const renderDetails = () => {
    if (!selectedRes)
      return null

    let options = ['Cash', 'Zelle', 'Venmo', 'CashApp', 'ACH/Wire'].map(res => {
      return {label: res, value: res}
    })

    const salesOptions = salesUsers.map((user) => ({label: user.name, value: user._id}));

    return (
      <div
        style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        {
          isSecurity ?
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div style={{
                marginTop: 10,
                width: '80%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}>
                <div style={{width: '50%'}}>
                  <label style={{color: Colors.secondaryTextColor}}>On Hold: </label>
                  <label style={{color: Colors.primaryTextColor, marginLeft: 5}}>${securityHold}</label>
                </div>
                <div style={{width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                  <label style={{color: Colors.secondaryTextColor}}>Captured: </label>
                  <label style={{color: Colors.primaryTextColor, marginLeft: 5}}>${securityCaptured}</label>
                </div>
              </div>
              <div className={Styles.modalRow}
                   style={{marginTop: 4, width: '80%', justifyContent: 'center'}}>
                <label style={{color: Colors.secondaryTextColor}}>Balance: </label>
                <label style={{color: Colors.primaryTextColor, marginLeft: 5}}>${outstandingSecurityBalance}</label>
              </div>
            </div>
            :
            <div style={{
              marginTop: 10,
              width: '80%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}>
              <div className={Styles.modalRow}
                   style={{width: '50%', justifyContent: 'center'}}>
                <label style={{color: Colors.secondaryTextColor}}>Total Paid: </label>
                <label style={{color: Colors.primaryTextColor, marginLeft: 5}}>${balancePaid}</label>
              </div>
              <div className={Styles.modalRow}
                   style={{marginTop: 4, width: '50%', justifyContent: 'center'}}>
                <label style={{color: Colors.secondaryTextColor}}>Balance: </label>
                <label style={{color: Colors.primaryTextColor, marginLeft: 5}}>${outstandingBalance}</label>
              </div>
            </div>
        }
        {
          selectedRes && selectedRes.value &&
          <div style={{width: '80%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>

            {
              isSecurity ?
                <CustomTextFieldNew
                  marginTop={10}
                  width={'98%'}
                  label={'Amount Paid($)'}
                  placeholder={'amount paid'}
                  value={securityPayable}
                  onChange={(text) => {
                    if (isNaN(text))
                      return
                    setSecurityPayable(text)
                  }}
                />
                :
                <CustomTextFieldNew
                  marginTop={10}
                  width={'98%'}
                  label={'Amount Paid($)'}
                  placeholder={'amount paid'}
                  value={balancePayable}
                  onChange={(text) => {
                    if (isNaN(text))
                      return
                    setBalancePayable(text)
                  }}
                />
            }

          </div>
        }

        <div
          className={GeneralStyles.boxShadow}
          style={{width: '80%', height: 40, borderRadius: 10, marginTop: 10}}>
          <CustomSelect
            placeholder={'payment type'}
            borderColor={'transparent'}
            value={payType}
            options={options}
            onChange={setPayType}
          />
        </div>

        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '80%',
          justifyContent: 'space-between',
          marginTop: 10
        }}>
          <div
            className={GeneralStyles.boxShadow}
            style={{width: '60%', height: 40, borderRadius: 10}}>
            <div
              onClick={() => {
                setShowDateSelector(true)
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: 280,
                borderRadius: 10,
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: 'white',
                height: 40,
                padding: '0px 10px',
                cursor: 'pointer'
              }}>
              <p className={GeneralStyles.darkText}>{moment(date).format('dddd, MMM Do YYYY')}</p>
              <CalendarMonthIcon style={{color: Colors.primaryTextColor}}/>
            </div>
          </div>

          <div
            className={GeneralStyles.boxShadow}
            style={{
              width: '30%',
              backgroundColor: 'white',
              height: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 10,
              padding: '0px 10px'
            }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                slotProps={{textField: {variant: 'standard',}}}
                value={setTime}
                onChange={(newValue) => {
                  setTime(newValue)
                }}
                renderInput={(params) => {
                  return <TextField
                    sx={{
                      '.MuiOutlinedInput-root': {
                        backgroundColor: 'white',
                        color: Colors.primaryTextColor,
                        height: 30,
                        borderRadius: 5
                      },
                      '.MuiSvgIcon-root': {color: Colors.primaryTextColor},
                    }}
                    InputLabelProps={{style: {color: colors.primaryTextColor}}}
                    {...params} />
                }}
              />
            </LocalizationProvider>
          </div>
        </div>

        {
          showDateSelector &&
          <div style={{width: '80%'}}>
            <Calendar
              date={date}
              onChange={(date) => {
                setDate(date)
                setShowDateSelector(false)
              }}
            />
          </div>
        }

        <div
          className={GeneralStyles.boxShadow}
          style={{width: '80%', height: 40, borderRadius: 10, marginTop: 10}}>
          <CustomSelect
            placeholder={'select salesperson'}
            borderColor={'transparent'}
            value={salesPerson}
            options={salesOptions}
            onChange={setSalesPerson}
          />
        </div>

        <CustomTextFieldNew
          marginTop={10}
          width={'78%'}
          label={'Reference'}
          placeholder={'reference text'}
          value={reference}
          onChange={setReference}
        />

        <CustomButtonContained
          text={'Record Payment'}
          onClick={onSubmitClicked}
          style={{marginTop: 2}}
          color={'primary'}
        />

      </div>
    )
  }

  const renderContent = () => {
    let resOptions = allRes.map(res => {
      return {label: `Res #${res._id}-${res.vehicle_id}`, value: res._id}
    })

    return (
      <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <label className={GeneralStyles.darkText} style={{fontWeight: 600, fontSize: 20, marginTop: 20}}>
          Offline Payment
        </label>
        <label className={GeneralStyles.grayText} style={{fontSize: 18, marginTop: 10}}>
          {isSecurity ? 'Security Deposit' : 'New Payment'}
        </label>
        {
          showError &&
          <label style={{width: '100%', textAlign: 'center', color: 'red'}}>{errorMessage}</label>
        }
        {
          showSuccess &&
          <label style={{width: '100%', textAlign: 'center', color: 'green'}}>{successMessage}</label>
        }
        <div
          className={GeneralStyles.boxShadow}
          style={{width: '80%', height: 40, borderRadius: 10, marginTop: 20}}>
          <CustomSelect
            disabled={reservation !== null}
            placeholder={'select or search reservation'}
            borderColor={'transparent'}
            value={selectedRes}
            options={resOptions}
            onChange={setSelectedRes}
          />
        </div>
        {
          showLoader ?
            <div
              style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50}}>
              <CustomLoaderSpinner/>
            </div>
            :
            renderDetails()
        }
      </div>
    )

  }

  return (
    <CustomModal
      show={modalIsOpen}
      handleClose={onModalClose}
      containerWidth={window.innerWidth / 3}
      containerHeight={window.innerHeight - 100}
    >
      {renderContent()}
    </CustomModal>
  )

}
