import React,{useState, useEffect} from 'react';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import Fab from "@mui/material/Fab";
import {createTheme,ThemeProvider} from "@mui/material/styles";
import colors from "../../config/colors";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

const theme = createTheme({
	palette: {
		primary: {
			main: colors.primaryTextColor,
			darker: '#ffffff',
			contrastText: '#fff',

		}
	},
});



export default function CustomTextField({label, onChange, type, value, inputProps, multiline}) {
	const [textLabel, setTextLabel] = useState('')

	useEffect(() => {
		setTextLabel(label)
	},[label])

	return (
		<ThemeProvider theme={theme}>
			<TextField
				multiline={multiline}
				required={true}
				color={'primary'}
				id="outlined-basic"
				label={textLabel}
				variant="outlined"
				inputProps={inputProps || {style:{color: colors.primaryTextColor}}}
				InputLabelProps={{style:{color: colors.primaryTextColor}}}
				fullWidth
				type={type || 'text'}
				onChange={(e) => onChange(e.target.value)}
				value={value}
			/>
		</ThemeProvider>

	)
}

