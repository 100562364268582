import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import {firestore} from '../../services/firebase'
import {GET_ANALYTICS_DATA,GET_SALES_ANALYTICS_DATA, GET_VEHICLES_ANALYTICS_DATA, GET_PROFITABILITY_ANALYTICS_DATA} from '../actionTypes'
import {API_CALL_STATUS} from '../../metadata/enums'
import Config from "../../config";
import axios from "axios";
import {LOGGER} from "../../utils/Logger";
import moment from 'moment'

const initialState = {
	analytics: null,
	sales_analytics: null,
	vehicles_analytics: null,
	profitability_analytics: null,
	status: API_CALL_STATUS.IDLE,
	sales_status: API_CALL_STATUS.IDLE,
	vehicles_status: API_CALL_STATUS.IDLE,
	profitability_status: API_CALL_STATUS.IDLE,
	error: null
}

export const getVehicleAnalyticsData = createAsyncThunk(GET_VEHICLES_ANALYTICS_DATA, async (payload) => {
	const config = {
		method: 'get',
		url: `${Config.BACKEND_URL}analytics/utilization`,
		headers: {Authorization: payload.token},
	}

	try {
		let res = await axios(config)
		return res.data
	}catch(err) {
		LOGGER.error('Error when getting analytics data', err)
		return []
	}

})

export const getAnalyticsData = createAsyncThunk(GET_ANALYTICS_DATA, async (payload) => {
	const config = {
		method: 'get',
		url: `${Config.BACKEND_URL}analytics?timeline=${payload.timeline}`,
		headers: {Authorization: payload.token},
	}

	try {
		let res = await axios(config)
		return res.data
	}catch(err) {
		LOGGER.error('Error when getting analytics data', err)
		return []
	}

})

export const getProfitabilityAnalyticsData = createAsyncThunk(GET_PROFITABILITY_ANALYTICS_DATA, async (payload) => {
	let startDate = moment(payload.data?.start_date).hours(0).minutes(0).seconds(0).toDate().toISOString()
	let endDate = moment(payload.data?.end_date).hours(0).minutes(0).seconds(0).toDate().toISOString()
	let url = `${Config.BACKEND_URL}analytics/profitability?start=${startDate}&end=${endDate}`

	const config = {
		method: 'get',
		url: url,
		headers: {Authorization: payload.token, "Content-Type": "application/json"},
	}

	try {
		let res = await axios(config)
		console.log('got profitability analytics', res.data)
		return res.data
	}catch(err) {
		LOGGER.error('Error when getting profitability analytics data', err)
		return []
	}

})

export const getSalesAnalyticsData = createAsyncThunk(GET_SALES_ANALYTICS_DATA, async (payload) => {
	let startDate = moment(payload.data?.start_date).hours(0).minutes(0).seconds(0).toDate().toISOString()
	let endDate = moment(payload.data?.end_date).hours(0).minutes(0).seconds(0).toDate().toISOString()
	let headers = {
		Authorization: payload.token,
		start_date: startDate,
		end_date: endDate,
		"Content-Type": "application/json"
	}

	if(payload.data?.sales_person && payload.data.sales_person !== 'none')
		headers['sales_person'] = payload.data.sales_person


	const config = {
		method: 'get',
		url: `${Config.BACKEND_URL}analytics/sales`,
		headers: headers,
	}

	try {
		let res = await axios(config)
		console.log('got sales analytics', res.data)
		return res.data
	}catch(err) {
		LOGGER.error('Error when getting sales analytics data', err)
		return []
	}

})


const analyticsSlice = createSlice({
	name: 'analytics',
	initialState,
	reducers: {

	},
	extraReducers(builder) {
		builder
			.addCase(getAnalyticsData.pending, (state, action) => {
				state.status = API_CALL_STATUS.LOADING
			})
			.addCase(getAnalyticsData.fulfilled, (state, action) => {
				state.status = API_CALL_STATUS.SUCCEEDED
				state.analytics = action.payload
			})
			.addCase(getAnalyticsData.rejected, (state, action) => {
				state.status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
			.addCase(getSalesAnalyticsData.pending, (state, action) => {
				state.sales_status = API_CALL_STATUS.LOADING
			})
			.addCase(getSalesAnalyticsData.fulfilled, (state, action) => {
				state.sales_status = API_CALL_STATUS.SUCCEEDED
				state.sales_analytics = action.payload
			})
			.addCase(getSalesAnalyticsData.rejected, (state, action) => {
				state.sales_status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
			.addCase(getVehicleAnalyticsData.pending, (state, action) => {
				state.vehicles_status = API_CALL_STATUS.LOADING
			})
			.addCase(getVehicleAnalyticsData.fulfilled, (state, action) => {
				state.vehicles_status = API_CALL_STATUS.SUCCEEDED
				state.vehicles_analytics = action.payload
			})
			.addCase(getVehicleAnalyticsData.rejected, (state, action) => {
				state.vehicles_status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
			.addCase(getProfitabilityAnalyticsData.pending, (state, action) => {
				state.profitability_status = API_CALL_STATUS.LOADING
			})
			.addCase(getProfitabilityAnalyticsData.fulfilled, (state, action) => {
				state.profitability_status = API_CALL_STATUS.SUCCEEDED
				state.profitability_analytics = action.payload
			})
			.addCase(getProfitabilityAnalyticsData.rejected, (state, action) => {
				state.profitability_status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
	}
})




// export const {  } = analyticsSlice.actions

export default analyticsSlice.reducer
