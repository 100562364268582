import React, {useState, useEffect} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import PageHeader from "../../components/general/PageHeader";
import moment from "moment";
import Styles from './styles/Notifications.module.scss'
import {useSelector, useDispatch} from 'react-redux'
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import CustomButtonContained from "../../components/general/CustomButtonContained";
import {API_CALL_STATUS, NOTIFICATION_TYPES} from "../../metadata/enums";
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";
import Colors from "../../config/colors";
import Constants from '../../config/constants'
import {vehiclesMapSelector} from "../../redux/slices/vehiclesSlice";
import {useNavigate} from 'react-router-dom'
import {clientsMapSelector, getAllClients, getClientDetails, updateClient} from '../../redux/slices/clientsSlice';
import Helpers from '../../utils/helpers'
import {
  fetchOldReservations,
  fetchUpcomingReservations,
  reservationsMapSelector,
  updateReservation
} from "../../redux/slices/reservationsSlice";
import {updateNotifAndConfirm} from "../../redux/slices/notificationsSlice";
import {getPriceSettings} from "../../redux/slices/settingsSlice";


const USER_IMAGE = "https://d3nk8x6ofxzl5v.cloudfront.net/vehicles/test/pT8eaThPSiMcChJtYAupGw.jpeg"

const Notifications = ({navigation}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const reservationState = useSelector(state => state.reservations)
  const notificationsState = useSelector(state => state.notifications)
  const vehiclesMap = useSelector(state => vehiclesMapSelector(state))
  const clientsMap = useSelector(state => clientsMapSelector(state.clients))
  const token = useSelector(state => state.auth.token)
  const reservationsMap = useSelector(state => reservationsMapSelector(state.reservations))
  const priceSettings = useSelector(state => getPriceSettings(state.settings))


  const [tab, setTab] = useState(1)
  const [loading, setLoading] = useState(true)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [allNotifications, setAllNotifications] = useState([])

  useEffect(() => {
    if (notificationsState.status === API_CALL_STATUS.LOADING) {
      setLoading(true)
    } else {
      setLoading(false)
      if (notificationsState.notifications) {
        setAllNotifications(notificationsState.notifications)
        let newRes = false
        notificationsState.notifications.every(res => {
          if (!res.opened) {
            newRes = true
            return false
          }
          return true
        })

        if (newRes) {
          console.log('getting new reservation details')
          dispatch(fetchOldReservations({token}))
          dispatch(fetchUpcomingReservations({token, startDate: new Date().toISOString()}))
          dispatch(getAllClients({token}))
        }
      }

    }

  }, [notificationsState])

  useEffect(() => {
    if (reservationState.status === API_CALL_STATUS.LOADING) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [reservationState])

  const onInsuranceNotifClicked = (notif, client) => {
    if(!client)
      return
    if(!notif.opened)
      dispatch(updateNotifAndConfirm({token, data: {_id: notif._id, opened: true}}))

    dispatch(getClientDetails({token: token, _id: client._id}))
    navigate('/home/clients/update-client')
  }
  const onWebsiteResNotifClicked = (notif) => {
    let res = reservationsMap[notif?.res_id]
    if (!res) return
    dispatch(updateReservation(res))
    if(!notif.opened)
      dispatch(updateNotifAndConfirm({token, data: {_id: notif._id, opened: true}}))
    if (res.isChauffeur) {
      navigate('/home/new-chauffeur')
    } else {
      navigate('/home/new-reservation')
    }
  }


  const renderNotifRow = (notif, index) => {
    if(notif.type === NOTIFICATION_TYPES.WEBSITE_RES)
      return renderWebsiteBookingNotifRow(notif, index)
    else if(notif.type === NOTIFICATION_TYPES.AXLE_AUTH_COMPLETE)
      return insuranceNotiRow(notif, index)
  }

  const insuranceNotiRow = (notif, index) => {
    let {client_id, opened, created_at} = notif
    let client = clientsMap[client_id] || {}
    return (
      <div
        key={index.toString()}
        onClick={() => onInsuranceNotifClicked(notif, client)}
        className={Styles.rowContainer} style={{backgroundColor: opened ? 'transparent' : Colors.notifBackgroundColor, height: 40}}>
        <label className={[GeneralStyles.darkText]} style={{ marginLeft: 10, cursor: 'pointer'}}>
          The user <span style={{fontWeight: 'bold', fontSize: Constants.headerSize}}>{client.fName} {client.lName}</span> has given permission to pull their insurance records.
        </label>
        <label style={{
          color: Colors.secondaryTextColor,
          cursor: 'pointer',
          marginLeft: 4,
          position: 'absolute',
          right: 5,
          top: 0
        }}>{moment(created_at).format('MM/DD/YY, hh:mm A')}</label>

      </div>
    )
  }

  const renderWebsiteBookingNotifRow = (notif, index) => {
    let {res_id, opened, created_at} = notif
    const res = reservationsMap[res_id] || {}
    const {client, vehicle_id, pickup_datetime, dropoff_datetime, pickup_location} = res
    const vehicle = vehiclesMap[vehicle_id] || {}
    const clientData = clientsMap[client] || {}
    const totalDays = Helpers.getTotalDays(res) || 'X'
    const pricing = Helpers.getRentalsPricingObject(res, vehicle, priceSettings)
    const temp = Object.assign({}, res, {pricing: pricing})
    const totalPrice = Helpers.getRentalTotalPricing(temp)

    return (
      <div
        key={index.toString()}
        onClick={() => onWebsiteResNotifClicked(notif)}
        className={Styles.rowContainer} style={{backgroundColor: opened ? 'transparent' : Colors.notifBackgroundColor}}>
        <div style={{width: 400, height: '100%'}}>
          <img src={vehicle.pictureUrl} width={'100%'} height={'100%'} style={{borderRadius: 4}}/>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 20,
          paddingRight: 4,
          width: '100%',
          padding: 4
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
            marginBottom: 10
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
              <label style={{
                color: Colors.primaryTextColor,
                cursor: 'pointer',
                fontWeight: 'bold',
                fontSize: Constants.headerSize
              }}>{vehiclesMap[vehicle_id]?.make}</label>
              <label style={{color: Colors.primaryTextColor, cursor: 'pointer', marginLeft: 4}}> - #{res_id}</label>
            </div>
            <label style={{
              color: Colors.secondaryTextColor,
              cursor: 'pointer',
              marginLeft: 4
            }}>{moment(created_at).format('MM/DD/YY, hh:mm A')}</label>
          </div>


          <label style={{color: Colors.primaryTextColor, cursor: 'pointer'}}>
            Website booking by <span style={{fontWeight: 'bold'}}>{`${clientData?.fName} ${clientData?.lName} `}</span>
            from <span
            style={{fontWeight: 'bold'}}>{` ${moment(pickup_datetime).format('MMM Do, YYYY hh:mm A')} `}</span>
            to <span style={{fontWeight: 'bold'}}>{` ${moment(dropoff_datetime).format('MMM Do, YYYY hh:mm A')}`}</span>
          </label>

          <div style={{display: 'flex', alignItems: 'center', width: '100%', marginTop: 20}}>
            <label style={{color: Colors.secondaryTextColor, cursor: 'pointer', width: 80}}>Location: </label>
            <label style={{
              color: Colors.primaryTextColor,
              cursor: 'pointer'
            }}>{typeof pickup_location === 'string' ? 'Headquarters' : pickup_location?.label}</label>
          </div>
          <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
            <label style={{color: Colors.secondaryTextColor, cursor: 'pointer', width: 80}}>Duration: </label>
            <label style={{color: Colors.primaryTextColor, cursor: 'pointer'}}>{totalDays} day(s)</label>
          </div>
          <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
            <label style={{color: Colors.secondaryTextColor, cursor: 'pointer', width: 80}}>Price: </label>
            <label style={{color: Colors.primaryTextColor, cursor: 'pointer'}}>${Number(totalPrice).toFixed(2)}</label>
          </div>

        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (!allNotifications || allNotifications.length === 0) {
      return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <h4>No notifications to display</h4>
        </div>
      )
    }
    let elements = []
    if (tab === 1) {
      //unread only
      allNotifications?.forEach((notif, index) => {
        if (!notif.opened)
          elements.push(renderNotifRow(notif, index))
      })
    } else {
      allNotifications?.forEach((notif, index) => {
        if (notif.opened)
          elements.push(renderNotifRow(notif, index))
      })
    }

    return (
      <div>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <Tab tab={tab} onChange={setTab}/>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          height: '80vh',
          overflowY: 'scroll',
        }}>
          {
            elements && elements.length > 0 ?
              elements
              :
              <p style={{marginTop: 50, widht: '100%', textAlign: 'center'}}>No notifications to show</p>
          }

        </div>
      </div>

    )
  }

  return (
    <div className={GeneralStyles.container}>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
        <PageHeader header={'Notifications'} subHeader={`${moment().format('dddd, MMM Do')}`}/>
        <PositionedSnackbar
          onClose={() => {
            setShowSuccessMessage(false)
            setSuccessMessage('')
            setShowErrorMessage(false)
            setErrorMessage('')
          }}
          severity={showSuccessMessage ? 'success' : 'error'}
          openFlag={showSuccessMessage || showErrorMessage}
          message={showSuccessMessage ? successMessage : errorMessage}
        />

      </div>

      {
        loading ?
          <div style={{height: '80vh', alignItems: 'center', justifyContent: 'center', display: 'flex', width: '100%'}}>
            <CustomLoaderSpinner/>
          </div>
          :
          renderContent()
      }
    </div>
  )
}

const Tab = ({tab, onChange}) => {
  return (
    <div style={{
      width: 200,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 10,
      border: '1px solid gray',
      padding: 4,
      backgroundColor: Colors.theme,
      borderColor: Colors.theme,
      marginBottom: 20
    }}>
      <div
        style={{
          height: 30,
          cursor: 'pointer',
          width: '50%',
          backgroundColor: tab === 1 ? Colors.tabButtonTheme : 'transparent',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          borderRadius: 5,
          alignItems: 'center'
        }}
        onClick={() => onChange(1)}>
        <p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Unread</p>
      </div>
      <div
        style={{
          height: 30,
          cursor: 'pointer',
          width: '50%',
          backgroundColor: tab === 2 ? Colors.tabButtonTheme : 'transparent',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          borderRadius: 5,
          alignItems: 'center'
        }}
        onClick={() => onChange(2)}>
        <p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Read</p>
      </div>

    </div>
  )
}

export default Notifications
