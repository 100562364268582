import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {API_CALL_STATUS} from '../../metadata/enums'

const initialState = {
	token: null,
	status: API_CALL_STATUS.IDLE,
	error: null,
	access_allowed: true
}

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		addToken(state, action) {
			return Object.assign({}, state, {token: action.payload})
		},
		changeUserAccess(state, action) {
			return Object.assign({}, state, {access_allowed: action.payload})
		}

	}
})


export const { addToken, changeUserAccess } = authSlice.actions

export default authSlice.reducer
