import React from 'react'
import Button from '@mui/material/Button';
import colors from '../../config/colors'
import {Audio} from "react-loader-spinner";

function CustomLoaderSpinner({color}) {
	return (
		<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1}}>
			<Audio
				height="100"
				width="100"
				color={color || colors.theme}
				ariaLabel='loading'
			/>
		</div>
	)
}

export default CustomLoaderSpinner
