import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from "react-redux";

// style
import Colors from "../../../config/colors";
import Constants from "../../../config/constants";
// components
import Tab from '../../../components/general/Tab';
import CustomTextFieldNew from "../../../components/general/CustomTextFieldNew";
import CustomSelect from '../../../components/general/CustomSelect';
const gaps = {
    marginTop: '21px',
}

const RenderPartnerSplitInformation = ({ partner = null, setPartner = () => { } }) => {

    const partnersState = useSelector(state => state.partnerSplits)

    const [tab, setTab] = useState(0);
    const [partnerList, setPartnerList] = useState([])
    const [dropDownSelectedPartner, setDropDownSelectedPartner] = useState({ label: 'No Partner', value: 0 })
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        // getting all partners for drop down: 
        let temp = partnersState.partnerSplit_nameAndId.map((_partner) => {
            return { label: `${_partner.fName} ${_partner.lName}`, value: _partner._id }
        })
        temp.unshift({ label: 'No Partner', value: 0 })
        setPartnerList([...temp]);

        // setting partner information on first render, if there is any
        if (partner.partner_id != 0) {
            let _partner = partnersState.partnerSplit_nameAndId.find((_partner) => { return _partner._id == partner.partner_id })
            // value for the drop down: 
            setDropDownSelectedPartner({ label: `${_partner?.fName} ${_partner?.lName}`, value: _partner?._id })
            setIsDisabled(false);
        }
        else {
            setIsDisabled(true);
            setDropDownSelectedPartner({ label: 'No Partner', value: 0 });
        }
        // setting the current tab for the value the partner is assoicated with: 
        if (partner.percentage) {
            setTab(0);
        } else if (partner.wholeSaleRate) {
            setTab(1);
        }
    }, [])

    const onDropDownChange = (value) => {
        setDropDownSelectedPartner(value);
        if (value.value == 0) {
            setPartner({
                partner_id: 0,
                percentage: 0,
                wholeSaleRate: 0
            });
            setIsDisabled(true);
        }
        else {
            setIsDisabled(false);
            setPartner({
                partner_id: value.value,
                percentage: 0,
                wholeSaleRate: 0
            });
        }
    }
    const onTabChange = (value) => {
        setTab(value)
        setPartner({
            ...partner,
            percentage: 0,
            wholeSaleRate: 0
        });
    }

    return (
        <div style={{ width: '45%', minHeight: '60vh', paddingLeft: '30px', paddingRight: '30px', }}>
            <label style={{ fontSize: Constants.headerSize, fontWeight: 'bold', color: Colors.primaryTextColor }}>Partner Splits</label>
            <Tab
                _style={gaps}
                tab={tab}
                onChange={(value) => { onTabChange(value) }}
                // OptionsStringArray={['% Split', 'Wholesale Rate']}
                OptionsStringArray={['% Split', 'Wholesale Rate']}
                width='80%'
            />

            <div style={{ ...gaps }}>

                <CustomSelect
                    isMulti={false}
                    placeholder={'Select a partner for the vehicle'}
                    onChange={(value) => { onDropDownChange(value) }}
                    value={dropDownSelectedPartner}
                    options={partnerList}
                />
            </div>

            {tab === 0 ?
                <div style={{ ...gaps }}>
                    <CustomTextFieldNew
                        disabled={isDisabled}
                        width={'98%'}
                        label={'Partner Split (%)'}
                        placeholder={'Partner Split'}
                        value={partner.percentage}
                        onChange={(value) => { setPartner({ ...partner, percentage: isNaN(value) ? partner.percentage : Number(value) }) }}
                    />
                </div>
                :
                <div style={{ ...gaps }}>
                    <CustomTextFieldNew
                        disabled={isDisabled}
                        width={'98%'}
                        label={'Wholesale Rate ($)'}
                        placeholder={'Whole Sale Rate here'}
                        value={partner.wholeSaleRate}
                        onChange={(value) => { setPartner({ ...partner, wholeSaleRate: isNaN(value) ? partner.wholeSaleRate : Number(value) }) }}
                    />
                </div>
            }
        </div>
    )
}
export default RenderPartnerSplitInformation;
