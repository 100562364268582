import React from 'react'
import Colors from "../../config/colors";
import {RENTAL_STATUS} from '../../metadata/enums'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


const PageHeader = ({header, subHeader, reservation}) => {

	const getStatusColor = () => {
		if(reservation.deleted)
			return Colors.status_colors.cancelled

		switch(reservation.rental_status) {
			case 1: return Colors.status_colors.waiting
			case 2: return Colors.status_colors.renting
			case 3: return Colors.status_colors.completed
			case 4: return Colors.status_colors.cancelled
		}
	}

	const getStatusText = () => {
		if(reservation.deleted)
			return 'Cancelled or Deleted'

		switch(reservation.rental_status) {
			case 1: return 'Ready for Pick-Up'
			case 2: return 'On Rent'
			case 3: return 'Completed'
			case 4: return 'Cancelled'
		}
	}


	const getSubheaderStatus = () => {
		let toReturn  = (
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative', top: -20}}>
				<FiberManualRecordIcon style={{marginRight: 10, height: 20, width: 20, color: getStatusColor()}}/>
				<label style={{color: Colors.primaryTextColor}}>{`STATUS: ${getStatusText()}`}</label>
			</div>
			)

		return toReturn

	}

	return (
		<div>
			<h2 style={{color: Colors.primaryTextColor}}>{header}</h2>
			{
				reservation ?
					getSubheaderStatus()
					:
					<p style={{position: 'relative', top: -20}}>{subHeader}</p>
			}
		</div>
	)
}

export default PageHeader
