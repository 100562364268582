import React, { useState, useEffect, useRef } from 'react';
import GeneralStyles from '../GeneralStyles.module.scss';
import PageHeader from '../../components/general/PageHeader';
import CustomButtonContained from '../../components/general/CustomButtonContained';
import CustomTextFieldNew from '../../components/general/CustomTextFieldNew';
import CustomGooglePlacesNew from '../../components/general/CustomGooglePlacesNew';
import CustomSelect from '../../components/general/CustomSelect';
import Helpers from "../../utils/helpers";
import Colors from '../../config/colors';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import { addPartnerAndVehicleThenConfirm, fetchAllPartnerSplits_nameAndId } from '../../redux/slices/partnerSplitSlice';
import { API_CALL_STATUS } from '../../metadata/enums';
import { getAllClients } from "../../redux/slices/clientsSlice";
import { fetchAllVehicles } from '../../redux/slices/vehiclesSlice'


const getEmptyPartner = () => {
    return {
        fName: '',
        lName: '',
        email: '',
        phone: '',
        address: null,
        split: [],
    };
};

const NewPartner = () => {
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const token = useSelector(state => state.auth.token);
    const vehiclesStore = useSelector(state => state.vehicles)
    const partnerSplitState = useSelector(state => state.partnerSplits)



    const [partnerInfo, setPartnerInfo] = useState(getEmptyPartner());
    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const [vehicleList, setVehicleList] = useState([]);
    const [selectedVehicles, setSelectedVehicles] = useState([]);

    // use effects: 
    useEffect(() => {
        // loading for vehicle list
        if (vehiclesStore.status === API_CALL_STATUS.SUCCEEDED) {
            setLoading(false)
            // save vehicles that do not have a partner assigned to them: 
            const available_vehicles = vehiclesStore.vehicles.filter((vehicle) => !vehicle.partner)
                .map((vehicle) => ({ label: vehicle.id, value: vehicle._id, percentage: 0, wholeSaleRate: 0 }));
            setVehicleList([...available_vehicles]);
        } else if (vehiclesStore.status === API_CALL_STATUS.FAILED) {
            setShowError(true)
            setLoading(false)
            setErrorMessage('Something went wrong while getting vehicle data, please reload')
        } else {
            setLoading(true)
        }
    }, [vehiclesStore])
    useEffect(() => {
        if (partnerSplitState.NewPartnerAdded) {
            setLoading(false)
            setShowSuccess(true);
            setSuccessMessage('scuessfully added partner and updated the vehicles')
            // for now fetching every thing that has changed : 
            dispatch(fetchAllVehicles({ token }))
            dispatch(getAllClients({ token }))
            dispatch(fetchAllPartnerSplits_nameAndId({ token }))
            // going back: 
            navigate(-1);
            // new partner saved, with vehicle update.
        } else if (partnerSplitState.status === API_CALL_STATUS.FAILED) {
            setShowError(true)
            setLoading(false)
            setErrorMessage('Somthing went wrong with saving, please try again')
        } else if (partnerSplitState.status === API_CALL_STATUS.LOADING) {
            setLoading(true)
        }
    }, [partnerSplitState])

    useEffect(() => {
        // Update partnerInfo.splits when selectedVehicles change
        const updatedSplits = selectedVehicles.map((vehicle) => ({
            label: vehicle.label,
            value: vehicle.value,
            percentage: vehicle.percentage,
            wholeSaleRate: vehicle.wholeSaleRate,
        }));
        setPartnerInfo({ ...partnerInfo, split: updatedSplits });
    }, [selectedVehicles]);

    // calculation : 
    const handlePercentageChange = (index, value) => {
        const temp = [...selectedVehicles];
        temp[index].percentage = isNaN(value) ? 0 : Number(value);
        setSelectedVehicles(temp);
    };
    //validating: 
    const validateData = () => {
        let missingField = false;
        //adding selected vehicles
        Object.keys(partnerInfo).every(field => {
            if (field !== 'address' && (!partnerInfo[field] || partnerInfo[field].length === 0)) {
                missingField = true;
                console.log(field);
                return false;
            }
            return true;
        });

        if (!partnerInfo.address || Object.keys(partnerInfo.address).length !== 2)
            missingField = true;

        if (missingField) {
            setShowError(true);
            setErrorMessage('Missing partner information');
            return false;
        } else {
            if (!validateEmail(partnerInfo.email)) {
                setShowError(true);
                setErrorMessage('Invalid Email');
                return false;
            }
            return true;
        }
    };

    const onSavePressed = () => {
        setPartnerInfo({ ...partnerInfo, split: [...selectedVehicles] })
        if (!validateData()) {
            setShowError(true);
            return;
        }

        setLoading(true);
        let toSave = Object.assign({}, partnerInfo, { created_at: new Date().toISOString() });
        console.log(toSave);

        setLoading(false);
        dispatch(addPartnerAndVehicleThenConfirm({ token, data: partnerInfo }));
    };

    const validateEmail = email => {
        // You can add a more robust email validation logic here
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };
    // selection: 
    const OnSelectVehicle = (value) => {
        // console.log(value);
        setSelectedVehicles([...value]);

    }
    // rendering 
    const RenderSplits = () => {
        let rows = selectedVehicles.map((vehicle, index) => {
            return (
                <div key={index} style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                    <CustomTextFieldNew
                        disabled={true}
                        width={'50%'}
                        label={'Vehicle Name'}
                        placeholder={''}
                        value={vehicle.label}
                    />
                    <CustomTextFieldNew
                        width={'25%'}
                        label={'Percentage (%)'}
                        placeholder={''}
                        value={vehicle.percentage}
                        onChange={value => {
                            handlePercentageChange(index, value);
                        }}
                    />
                </div>
            );
        })
        return (
            <div style={{ width: '100%', margin: 'auto', marginTop: 30 }}>
                <label className={GeneralStyles.darkText} style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 20 }}>
                    Partner Splits
                </label>
                <CustomSelect
                    isMulti={true}
                    onChange={(value) => { OnSelectVehicle(value) }}
                    options={vehicleList}
                    placeholder={'Select Vehicle for Partner Split'}
                    value={selectedVehicles}
                />
                {(selectedVehicles.length > 0 ? <>
                    <label className={GeneralStyles.darkText} style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 20, marginTop: 10 }}>
                        Vehicle Split Information
                    </label>
                    {rows}

                </> : <></>)
                }
            </div >
        );
    }
    const RenderPartnerInfo = () => {
        return (
            <div style={{ width: '100%', margin: 'auto', marginTop: 30 }}>
                <label className={GeneralStyles.darkText} style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 20 }}>
                    Partner Information
                </label>

                <div style={{ marginBottom: 20, marginTop: 10 }}>
                    <CustomTextFieldNew
                        width={'100%'}
                        label={'First Name'}
                        placeholder={'First Name'}
                        value={partnerInfo.fName}
                        onChange={text => {
                            setPartnerInfo({ ...partnerInfo, fName: text });
                        }}
                    />
                </div>

                <div style={{ marginBottom: 20 }}>
                    <CustomTextFieldNew
                        width={'100%'}
                        label={'Last Name'}
                        placeholder={'Last Name'}
                        value={partnerInfo.lName}
                        onChange={text => {
                            setPartnerInfo({ ...partnerInfo, lName: text });
                        }}
                    />
                </div>

                <div style={{ marginBottom: 20 }}>
                    <CustomTextFieldNew
                        width={'100%'}
                        label={'Email'}
                        placeholder={'Email'}
                        value={partnerInfo.email}
                        onChange={text => {
                            setPartnerInfo({ ...partnerInfo, email: text });
                        }}
                    />
                </div>

                <div style={{ marginBottom: 20 }}>
                    <CustomTextFieldNew
                        width={'100%'}
                        label={'Phone Number'}
                        placeholder={'Phone Number'}
                        value={partnerInfo.phone}
                        onChange={text => {
                            setPartnerInfo({ ...partnerInfo, phone: Helpers.checkPhoneNumberFormat(text) });
                        }}
                    />
                </div>

                <div style={{ marginBottom: 20 }}>
                    <CustomGooglePlacesNew
                        label={'Address'}
                        placeholder={'Search Partner Address'}
                        value={partnerInfo.address}
                        onChange={value => {
                            setPartnerInfo({ ...partnerInfo, address: value });
                        }}
                    />
                </div>
            </div>
        );
    }

    const RenderContent = () => {
        return (
            <div style={{ marginLeft: '20px', width: '100%', maxWidth: '65%' }}>
                {RenderPartnerInfo()}
                {RenderSplits()}
            </div>
        );
    };

    if (loading) {
        return (
            <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: Colors.contentBackgroundColor, width: '100%' }}>
                <CustomLoaderSpinner />
            </div>
        );
    }

    return (
        <div className={GeneralStyles.container}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative' }}>
                <PageHeader header={'New Partner'} />
                <div style={{ position: 'absolute', right: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', top: 24 }}>
                    <CustomButtonContained text={'Save Partner'} onClick={onSavePressed} color={'primary'} style={{ margin: 5 }} />
                    <CustomButtonContained text={'Cancel'} onClick={() => navigate(-1)} color={'secondary'} />
                </div>
            </div>

            <PositionedSnackbar
                onClose={() => {
                    setShowError(false);
                    setShowSuccess(false);
                    setErrorMessage('');
                    setSuccessMessage('');
                }}
                severity={showError ? 'error' : 'success'}
                openFlag={showError || showSuccess}
                message={showError ? errorMessage : successMessage}
            />
            {RenderContent()}
        </div>
    );
};

export default NewPartner;
