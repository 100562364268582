import React,{useState, useEffect} from 'react';
import Colors from '../../config/colors'


export default function CustomTextFieldNative({label, onChange, value, placeholder, disabled, containerStyle, height, backgroundColor, hidden}) {
	return (
		<div style={containerStyle || {width: '100%'}}>
			<input
				disabled={disabled || false}
				type={hidden ? "password" : "text"}
				id={label}
				placeholder={placeholder}
				value={value}
				style={
				{
					paddingLeft: 10,
					height: height || 40,
					width: '100%',
					backgroundColor: backgroundColor || Colors.theme,
					borderRadius: 10,
					color: Colors.tertiaryTextColor,
					borderWidth: 0
				}}
				onChange={(e) => onChange(e.target.value)}
			/>
		</div>
	)
}

