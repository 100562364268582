import React,{useState, useRef} from 'react';
import Colors from '../../config/colors'
import GeneralStyles from '../../routes/GeneralStyles.module.scss'



export default function CustomTextArea({label, onChange, value, width, height, backgroundColor, marginTop}) {
	const inputRef = useRef(null);

	return (
		<div
			className={GeneralStyles.boxShadow}
			style={{width: width || '100%', borderRadius: 10, padding: 10, marginTop: marginTop || 0}}>
			<label
				onClick={() => inputRef.current.focus()}
				style={{color: Colors.primaryTextColor, fontSize: 14, fontWeight: '600'}}>{label}</label>
			<textarea
				ref={inputRef}
				style={{width: '100%', borderWidth: 0, color: Colors.primaryTextColor}}
				rows={4}
				value={value}
				onChange={(e) => onChange(e.target.value)}
			/>
		</div>
	)
}

