import React, {useState, useEffect, useRef} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import Styles from './styles/Vehicles.module.scss'
import CustomButtonContained from '../../components/general/CustomButtonContained'
import Colors from '../../config/colors'
import CustomTextField from '../../components/general/CustomTextField'
import {Add} from '@mui/icons-material'
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import {firebaseStorage} from '../../services/firebase'
import {ref, uploadBytesResumable, getDownloadURL} from "firebase/storage"
import CustomLoaderSpinner from '../../components/general/CustomLoaderSpinner'
import {useDispatch, useSelector} from 'react-redux'
import CustomTextFieldNew from '../../components/general/CustomTextFieldNew';
import {
	updateExpense,
	updateNewVehicleStatus,
	vehiclesMapSelector,
	setUpdatedStatus,
	updateVehicleAndConfirm,
	updateVehicle,
	fetchAllMaintenances,
	updateMaintenanceToUpdate,
	fetchAllExpenses,
	updateDeletedExpenseStatus,
	deleteOneExpense,
	updateNewExpenseStatus
} from '../../redux/slices/vehiclesSlice'
import {API_CALL_STATUS, MAINTENANCE_STATUS} from '../../metadata/enums'
import PageHeader from "../../components/general/PageHeader";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import moment from "moment";
import CustomSelect from "../../components/general/CustomSelect";
import CustomTextFieldNative from "../../components/general/CustomTextFieldNative";
import {useNavigate} from 'react-router-dom'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactS3Client from '../../services/S3Client';
import {LOGGER} from '../../utils/Logger'
import Config from '../../config'
import Constants from '../../config/constants'
import BackArrow from "../../assets/logos/backArrow.svg";
import SearchIcon from "../../assets/logos/searchIcon.png";
import Fuse from "fuse.js";
import Helpers from "../../utils/helpers";
import UploadIcon from '../../assets/logos/uploadIcon.svg';
import GlobalStyles from '../GeneralStyles.module.scss'
import CONSTANTS from "../../config/constants";
import CustomModal from "../../components/general/CustomModal";
import {DateRangePicker} from "react-date-range";
import DeleteIcon from "@mui/icons-material/Delete";

function getEmptyMaintenanceObject(){
	return {
		start_date: null,
		end_date: null,
		description: '',
		cost: 0,
		status: null
	}
}

const Vehicles = () => {
	let navigate = useNavigate()
	let dispatch = useDispatch()


	const token = useSelector(state => state.auth.token)
	const vehiclesStore = useSelector(state => state.vehicles)
	const vehiclesMap = useSelector(state => vehiclesMapSelector(state))


	const [showLoading, setShowLoading] = useState(false)
	const [showSuccessMessage, setShowSuccessMessage] = useState(null)
	const [successMessage, setSuccessMessage] = useState('')
	const [showError, setShowError] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [searchString, setSearchString] = useState('')
	const [fuse, setFuse] = useState(null)
	const [searchResults, setSearchResults] = useState([])
	const [tab, setTab] = useState(1)
	const [active, setActive] = useState([])
	const [inactive, setInactive] = useState([])
	const [searchStringMain, setSearchStringMain] = useState('')
	const [fuseMain, setFuseMain] = useState(null)
	const [searchResultsMain, setSearchResultsMain] = useState([])

	useEffect(() => {
		dispatch(updateExpense(null))
		dispatch(updateVehicle(null))
		dispatch(updateMaintenanceToUpdate(null))
	}, []);

	useEffect(() => {
		if(vehiclesStore.status === API_CALL_STATUS.SUCCEEDED) {
			setShowLoading(false)

			if(vehiclesStore.new_vehicle) {
				//a new vehicle was added. Show success message
				setShowSuccessMessage(true)
				setSuccessMessage('Successfully added the vehicle')
				dispatch(updateNewVehicleStatus(false))
			}

			if(vehiclesStore.updated_vehicle) {
				setShowSuccessMessage(true)
				setSuccessMessage('Successfully updated vehicle details')
				dispatch(setUpdatedStatus(false))
				dispatch(updateVehicle(null))
			}

			if(vehiclesStore.deleted_expense) {
				setSuccessMessage('Successfully deleted expense')
				setShowSuccessMessage(true)
				dispatch(updateDeletedExpenseStatus(false))
			}

			if(vehiclesStore.new_expense) {
				setSuccessMessage('Successfully added new expense')
				setShowSuccessMessage(true)
				dispatch(updateNewExpenseStatus(false))
				setTab(4)
			}


			let active = []
			let inActive = []
			vehiclesStore.vehicles.forEach(vehicle => {
				if(vehicle.status === false)
					inActive.push(vehicle)
				else
					active.push(vehicle)
			})
			setActive(active)
			setInactive(inActive)
			let allVehicles = active.concat(inActive)

			let temp = new Fuse(allVehicles, {
				keys: ['make', 'id']
			})
			setFuse(temp)

			if(vehiclesStore.maintenances && vehiclesStore.maintenances.length > 0){
				// setup search for maintenances
				let temp = new Fuse(vehiclesStore.maintenances, {
					keys: ['vehicle_id', 'id']
				})
				setFuseMain(temp)
			}
		} else if(vehiclesStore.status === API_CALL_STATUS.FAILED) {
			setShowError(true)
			setShowLoading(false)
			setErrorMessage('Something went wrong')
		} else {
			setShowLoading(true)
		}


	},[vehiclesStore])

	const onSearchStringChanged = (text) => {
		setSearchString(text)
		if(text.length === 0)
			return
		let results = fuse.search(text)
		let array = results.map(temp => temp.item)
		setSearchResults(array)
	}

	const onSearchStringMainChanged = (text) => {
		setSearchStringMain(text)
		if(text.length === 0)
			return
		let results = fuseMain.search(text)
		let array = results.map(temp => temp.item)
		setSearchResultsMain(array)
	}

	const renderStatusSelect = (index, vehicle) => {
		if(vehicle.id === 'Rolls-Royce Cullinan [Black/Orange]')
			console.log('status select', vehicle)
		const OPTIONS = [{value: true, label: 'Active'}, {value: false, label: 'Inactive'}]
		let value = vehicle.status === false ? OPTIONS[1] : OPTIONS[0]
		return (
			<div style={{width: '95%', display: 'flex', flexDirection: 'row', marginLeft: 10}}>
				<CustomSelect
					value={value}
					options={OPTIONS}
					onChange={(value) => {
						let temp = JSON.parse(JSON.stringify(vehicle))
						temp['status'] = value.value
						dispatch(updateVehicleAndConfirm({token, data: temp}))
					}}
				/>
			</div>

		)
	}

	const onVehicleSelected = (vehicle) => {
		dispatch(updateVehicle(vehicle))
		navigate('/home/update-vehicle')
	}

	const renderVehicleRow = (vehicle,index) => {
		return (
			<div
				className={Styles.vehicleRow} key={vehicle.id} style={{borderBottom: `1px solid ${Colors.tableLineColor}`, cursor: 'pointer'}}>
				<div onClick={() => onVehicleSelected(vehicle)} style={{width: '28%', marginRight: 10}} className={Styles.tableEntryWrapper}>
					<img width={'100%'} height={'100%'} src={vehicle.pictureUrl} style={{borderRadius: 10}}/>
				</div>
				<div style={{width: '24%', marginRight: 10}} className={Styles.tableEntryWrapper}>
					<p onClick={() => onVehicleSelected(vehicle)} className={Styles.tableEntry}>{vehicle.make}</p>
				</div>
				<div style={{width: '10%', marginRight: 10}} className={Styles.tableEntryWrapper}>
					<p onClick={() => onVehicleSelected(vehicle)} className={Styles.tableEntry}>${vehicle.dailyPrice}</p>
				</div>
				<div style={{width: '20%', marginRight: 10}} className={Styles.tableEntryWrapper}>
					<div onClick={() => onVehicleSelected(vehicle)} className={Styles.tableEntry} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
						{vehicle.type.map(type => <p key={type} style={{backgroundColor: Colors.buttonTheme, marginRight: 6, borderRadius: 10, padding: '0px 10px', color: Colors.tertiaryTextColor}}>{type}</p>)}
					</div>
				</div>
				<div style={{width: '18%'}} className={Styles.tableEntryWrapper}>
					{renderStatusSelect(index, vehicle)}
				</div>


			</div>
		)

	}

	const renderFilterRow = () => {
		return (
			<div style={{width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: 40}}>
				<div style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between'
				}}>
					<div style={{
						width: '40%',
						backgroundColor: Colors.theme,
						borderRadius: 10,
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						paddingLeft: 20
					}}>
						<img src={SearchIcon} style={{height: 16, width: 16}}/>
						{
							tab === 3 ?
								<CustomTextFieldNative
									containerStyle={{width: '100%'}}
									height={36}
									label={'search'}
									placeholder={'Search maintenances'}
									value={searchStringMain}
									onChange={onSearchStringMainChanged}/>
								:
								<CustomTextFieldNative
									containerStyle={{width: '100%'}}
									height={36}
									label={'search'}
									placeholder={'Search vehicles'}
									value={searchString}
									onChange={onSearchStringChanged}/>
						}
					</div>

					<div style={{width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
						<Tab tab={tab} onChange={(value) => {
							if (value === 3) {
								//get maintenance data
								dispatch(fetchAllMaintenances({token}))
							} else if(value === 4) {
								dispatch(fetchAllExpenses({token}))
							}
							setTab(value)
						}}/>
					</div>
				</div>
			</div>
		)
	}

	const getMaintenanceStatusText = (value) => {
		switch(value) {
			case MAINTENANCE_STATUS.CREATED:
				return 'Open'
			case MAINTENANCE_STATUS.COMPLETED:
				return 'Completed'
		}
	}

	const onDeleteExpenseClicked = (expense) => {
		if(window.confirm(`Are you sure you want to delete the expense of ${expense.name} for the car ${expense.vehicle_id}?`)) {
			dispatch(deleteOneExpense({token, data: expense}))
		}
	}

	const renderExpenseRow = (expense, index) => {
		let vehicle = vehiclesMap[expense.vehicle_id] || {}
		return (
			<div
				className={Styles.maintenanceRow} key={index.toString()} style={{borderBottom: `1px solid ${Colors.tableLineColor}`, cursor: 'pointer'}}>
				<div
					onClick={() => {
						dispatch(updateExpense(expense))
						navigate('/home/update-expense')
					}}
					style={{width: '30%', marginRight: 10}}>
					<p className={Styles.tableEntry}>{vehicle.make}</p>
				</div>
				<div
					onClick={() => {
						dispatch(updateExpense(expense))
						navigate('/home/update-expense')
					}}
					style={{width: '18%', marginRight: 10}}>
					<p className={Styles.tableEntry}>{expense.name}</p>
				</div>
				<div
					onClick={() => {
						dispatch(updateExpense(expense))
						navigate('/home/update-expense')
					}}
					style={{width: '10%', marginRight: 10}}>
					<p className={Styles.tableEntry}>$ {Number(expense.amount).toFixed(2)}</p>
				</div>
				<div
					onClick={() => {
						dispatch(updateExpense(expense))
						navigate('/home/update-expense')
					}}
					style={{width: '20%', marginRight: 10}}>
					<p className={Styles.tableEntry}>{expense.user}</p>
				</div>
				<div
					onClick={() => {
						dispatch(updateExpense(expense))
						navigate('/home/update-expense')
					}}
					style={{width: '15%', marginRight: 10}}>
					<p className={Styles.tableEntry}>{expense?.created_at ? moment(expense.created_at).format('MM/DD/YYYY') : ''}</p>
				</div>
				<div style={{width: '7%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<p className={Styles.tableEntry}>{expense?.images?.length}</p>
					<DeleteIcon
						onClick={() => onDeleteExpenseClicked(expense)}
						style={{cursor: 'pointer',color: Colors.theme, height: 20, width: 20, marginLeft: 20, zIndex: 10}}/>
				</div>
			</div>
		)
	}

	const renderMaintenanceRow = (maintenance, index) => {
		return (
			<div
				onClick={() => {
					dispatch(updateMaintenanceToUpdate(maintenance))
					navigate('/home/update-maintenance')
				}}
				className={Styles.maintenanceRow} key={index.toString()} style={{borderBottom: `1px solid ${Colors.tableLineColor}`, cursor: 'pointer'}}>
				<div style={{width: '25%', marginRight: 10}}>
					<p className={Styles.tableEntry}>{vehiclesMap[maintenance.vehicle_id]?.make}</p>
				</div>
				<div style={{width: '10%', marginRight: 10}}>
					<p className={Styles.tableEntry}>{moment(maintenance.start_date).format('MM/DD/YY')}</p>
				</div>
				<div style={{width: '10%', marginRight: 10}}>
					<p className={Styles.tableEntry}>{moment(maintenance.end_date).format('MM/DD/YY')}</p>
				</div>
				<div style={{width: '30%', marginRight: 10}}>
					<p className={Styles.tableEntry}>{maintenance.description}</p>
				</div>
				<div style={{width: '10%'}}>
					<p className={Styles.tableEntry}>$ {Number(maintenance.cost).toFixed(2)}</p>
				</div>
				<div style={{width: '15%'}}>
					<p className={Styles.tableEntry}>{getMaintenanceStatusText(maintenance.status)}</p>
				</div>

			</div>
		)
	}

	const renderExpensesList = () => {
		if(vehiclesStore.status !== API_CALL_STATUS.SUCCEEDED)
			return

		console.log('here here', vehiclesStore.expenses)
		let elements = vehiclesStore.expenses?.map((expense, index) => renderExpenseRow(expense, index))

		return (
			<div className={Styles.vehiclesListContainer}>
				<div style={{height: 60, display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: `1px solid ${Colors.tableLineColor}`}}>
					<div style={{width: '30%', marginRight: 10}}>
						<p className={Styles.tableHeader}>Vehicle</p>
					</div>
					<div style={{width: '18%', marginRight: 10}}>
						<p className={Styles.tableHeader}>Expense</p>
					</div>
					<div style={{width: '10%', marginRight: 10}}>
						<p className={Styles.tableHeader}>Amount</p>
					</div>
					<div style={{width: '20%', marginRight: 10}}>
						<p className={Styles.tableHeader}>User</p>
					</div>
					<div style={{width: '15%', marginRight: 10}}>
						<p className={Styles.tableHeader}>Date</p>
					</div>
					<div style={{width: '7%'}}>
						<p className={Styles.tableHeader}>Images</p>
					</div>
				</div>

				{elements?.length > 0 ?
					elements
					:
					<p className={GlobalStyles.darkText} style={{marginTop: 30, width: '100%', textAlign: 'center'}}>There are no entries to show</p>
				}
			</div>
		)

	}

	const renderMaintenanceList = () => {
		if(vehiclesStore.status !== API_CALL_STATUS.SUCCEEDED)
			return
		let maintenances = searchStringMain && searchStringMain.length > 0 ? searchResultsMain : vehiclesStore.maintenances
		let elements = maintenances.map((maintenance,index) => renderMaintenanceRow(maintenance,index))

		return (
			<div className={Styles.vehiclesListContainer}>
				<div style={{height: 60, display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: `1px solid ${Colors.tableLineColor}`}}>
					<div style={{width: '25%', marginRight: 10}}>
						<p className={Styles.tableHeader}>Vehicle</p>
					</div>
					<div style={{width: '10%', marginRight: 10}}>
						<p className={Styles.tableHeader}>Start</p>
					</div>
					<div style={{width: '10%', marginRight: 10}}>
						<p className={Styles.tableHeader}>End</p>
					</div>
					<div style={{width: '30%', marginRight: 10}}>
						<p className={Styles.tableHeader}>Description</p>
					</div>
					<div style={{width: '10%'}}>
						<p className={Styles.tableHeader}>Cost</p>
					</div>
					<div style={{width: '15%'}}>
						<p className={Styles.tableHeader}>Status</p>
					</div>
				</div>

				{elements?.length > 0 ?
					elements
					:
					<p className={GlobalStyles.darkText} style={{marginTop: 30, width: '100%', textAlign: 'center'}}>There are no entries to show</p>
				}

			</div>

		)
	}

	const renderVehiclesList = () => {
		let vehicles = searchString && searchString.length > 0 ? searchResults : tab === 1 ? active : inactive
		let vehicleElements = vehicles.map((vehicle,index) => renderVehicleRow(vehicle,index))

		return (
			<div className={Styles.vehiclesListContainer}>
				<div className={Styles.vehicleRow}>
					<div style={{width: '28%', marginRight: 10}}>
						<p className={Styles.tableHeader}>Image</p>
					</div>
					<div style={{width: '24%', marginRight: 10}}>
						<p className={Styles.tableHeader}>Vehicle</p>
					</div>
					<div style={{width: '10%', marginRight: 10}}>
						<p className={Styles.tableHeader}>Daily Price</p>
					</div>
					<div style={{width: '20%', marginRight: 10}}>
						<p className={Styles.tableHeader}>Type</p>
					</div>
					<div style={{width: '18%'}}>
						<p className={Styles.tableHeader}>Status</p>
					</div>
				</div>
				{vehicleElements}
			</div>
		)
	}

	const onAddNewPressed = () => {
		switch(tab) {
			case 1:
			case 2:
				navigate('/home/new-vehicle')
				break
			case 3:
				navigate('/home/new-maintenance')
				break
			case 4:
				navigate('/home/new-expense')

		}

	}


	const getPageHeader = () => {
		switch(tab) {
			case 1:
				return 'Active Vehicles'
			case 2:
				return 'Inactive Vehicles'
			case 3:
				return 'Vehicle Maintenances'
			case 4:
				return 'Vehicle Expenses'

		}
	}

	const renderTabContent = () => {
		switch(tab) {
			case 1:
			case 2:
				return renderVehiclesList()
			case 3:
				return renderMaintenanceList()
			case 4:
				return renderExpensesList()
			default:
				return renderVehiclesList()
		}
	}

	const getNewButtonText = () => {
		switch(tab) {
			case 1:
			case 2:
				return '+ Add New Vehicle'
			case 3:
				return '+ Add New Maintenance'
			case 4:
				return '+ Add New Expense'
		}
	}

	const renderContent = () => {
		if(Object.keys(vehiclesMap).length === 0) {
			return (
				<div className={Styles.container}>
					<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
						<PageHeader
							header={getPageHeader()}
							subHeader={`${moment().format('dddd, MMM Do')}`}
						/>
						<CustomButtonContained
							style={{position: 'absolute', right: 0}}
							text={getNewButtonText()}
							onClick={onAddNewPressed}
						/>
					</div>

					<p className={GlobalStyles.darkText} style={{width: '100%', marginTop: 40, textAlign: 'center'}}>There are no vehicles in the system yet</p>
				</div>
			)
		}

		return (
			<div className={Styles.container}>
				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
					<PageHeader header={getPageHeader()} subHeader={`${moment().format('dddd, MMM Do')}`}/>
						<CustomButtonContained
							style={{position: 'absolute', right: 0}}
							text={getNewButtonText()}
							onClick={onAddNewPressed}
						/>
				</div>

				{renderFilterRow()}
				{renderTabContent()}


			</div>
		)
	}

	if(showLoading) {
		return (
			<div className={GeneralStyles.container} style={{height: '90vh', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
				<CustomLoaderSpinner />
			</div>
		)
	}

	return (
		<div className={GeneralStyles.container}>
			<PositionedSnackbar
				onClose={() => {
					setErrorMessage('')
					setShowError(false)
					setShowSuccessMessage(false)
					setSuccessMessage('')
				}}
				openFlag={showError || showSuccessMessage}
				message={showSuccessMessage ? successMessage : errorMessage}
				severity={showError ? 'error' : 'success'}
			/>

			{renderContent()}
		</div>
	)
}

const Tab = ({tab, onChange}) => {
	return (
		<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: 10, border: '1px solid gray', padding: 4, backgroundColor: Colors.theme, borderColor: Colors.theme}}>
			<div
				style={{height: 30, cursor: 'pointer',width: '50%', backgroundColor: tab === 1 ? Colors.tabButtonTheme : 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center',borderRadius: 5, alignItems: 'center'}}
				onClick={() => onChange(1)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Active</p>
			</div>
			<div
				style={{height: 30, cursor: 'pointer',width: '50%', backgroundColor: tab === 2 ? Colors.tabButtonTheme : 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center',borderRadius: 5, alignItems: 'center'}}
				onClick={() => onChange(2)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Inactive</p>
			</div>
			<div
				style={{height: 30, cursor: 'pointer',width: '50%', backgroundColor: tab === 3 ? Colors.tabButtonTheme : 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center',borderRadius: 5, alignItems: 'center'}}
				onClick={() => onChange(3)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Maintenance</p>
			</div>
			<div
				style={{height: 30, cursor: 'pointer',width: '50%', backgroundColor: tab === 4 ? Colors.tabButtonTheme : 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center',borderRadius: 5, alignItems: 'center'}}
				onClick={() => onChange(4)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Expenses</p>
			</div>
		</div>
	)
}

export default Vehicles
