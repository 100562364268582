import React, {useEffect, useState} from 'react'
import GeneralStyles from "../../routes/GeneralStyles.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {API_CALL_STATUS, PAYMENT_STATUSES} from "../../metadata/enums";
import Styles from "./styles/index.module.scss";
import CustomModal from "../../components/general/CustomModal";
import CustomSelect from "../general/CustomSelect";
import CustomLoaderSpinner from "../general/CustomLoaderSpinner";
import Config from "../../config";
import axios from "axios";
import {LOGGER} from "../../utils/Logger";
import {reservationsMapSelector} from "../../redux/slices/reservationsSlice";
import Helpers from '../../utils/helpers'
import {getPriceSettings} from "../../redux/slices/settingsSlice";
import {vehiclesMapSelector} from "../../redux/slices/vehiclesSlice";
import Colors from "../../config/colors";
import CustomTextFieldNew from "../general/CustomTextFieldNew";
import {clientsMapSelector} from "../../redux/slices/clientsSlice";
import CustomButtonContained from "../general/CustomButtonContained";
import {getSalesUsers, getUsersUidMap} from '../../redux/slices/userSlice'
import ApiHelpers from "../../utils/ApiHelpers";

export default function Invoice({
                                  paymentType,
                                  modalIsOpen,
                                  onModalClose,
                                  successCallback,
                                  errorCallback,
                                  reservation
                                }) {
  const dispatch = useDispatch()

  const salesUsers = useSelector((state) => getSalesUsers(state));
  const token = useSelector(state => state.auth.token)
  const reservationsState = useSelector(state => state.reservations)
  const reservationsMap = useSelector(state => reservationsMapSelector(state.reservations))
  const vehiclesMap = useSelector(state => vehiclesMapSelector(state))
  const priceSettings = useSelector(state => getPriceSettings(state.settings))
  const clientsMap = useSelector(state => clientsMapSelector(state.clients))
  const usersUidMap = useSelector((state) => getUsersUidMap(state.user));
  const mongoUser = useSelector(state => state.user.mongoUser)

  const [showDateSelector, setShowDateSelector] = useState(false)
  const [tab, setTab] = useState(0)
  const [isSecurity, setIsSecurity] = useState(false)
  const [allRes, setAllRes] = useState([])
  const [selectedRes, setSelectedRes] = useState(null)
  const [showLoader, setShowLoader] = useState(false)
  const [payments, setPayments] = useState([])
  const [balancePaid, setBalancePaid] = useState(0);
  const [outstandingBalance, setOutstandingBalance] = useState(0);
  const [balancePayable, setBalancePayable] = useState(0);
  const [securityCaptured, setSecurityCaptured] = useState(0);
  const [securityHold, setSecurityHold] = useState(0);
  const [outstandingSecurityBalance, setOutstandingSecurityBalance] = useState(0);
  const [securityPayable, setSecurityPayable] = useState(0);
  const [cardsLoading, setCardsLoading] = useState(false)
  const [cardsOnFile, setCardsOnFile] = useState([])
  const [amount, setAmount] = useState('0')

  const [errorMessage, setErrorMessage] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [selectedClient, setSelectedClient] = useState(null)
  const [selectedVehicle, setSelectedVehicle] = useState(null)
  const [payType, setPayType] = useState(null)
  const [salesPerson, setSalesPerson] = useState(null);
  const [description, setDescription] = useState('');
  const [date, setDate] = useState(new Date())
  const [time, setTime] = useState(new Date())
  const [email, setEmail] = useState('')


  useEffect(() => {
    setIsSecurity(paymentType === 'security')
  }, [paymentType]);

  useEffect(() => {
    if (reservationsState.status !== API_CALL_STATUS.LOADING || reservationsState.status !== API_CALL_STATUS.FAILED) {
      setAllRes(reservationsState.reservations.concat(reservationsState.old_reservations))
    }
  }, [reservationsState])

  useEffect(() => {
    if (reservation)
      setSelectedRes({label: `Res #${reservation._id}-${reservation.vehicle_id}`, value: reservation._id})
  }, [reservation])

  useEffect(() => {
    let reservation = reservationsMap[selectedRes?.value] || {}
    let vehicle = vehiclesMap[reservation.vehicle_id]
    let pricing;
    let totalPrice
    console.log('res is', reservation)
    if (reservation.isChauffeur) {
      pricing = Helpers.getChauffeurPricingObject(reservation, vehicle, priceSettings)
      console.log('pricing is', pricing)
      let temp = Object.assign({}, reservation, {pricing: pricing})
      totalPrice = Helpers.getChauffeurTotalPricing(temp)
    } else {
      pricing = Helpers.getRentalsPricingObject(reservation, vehicle, priceSettings)
      let temp = Object.assign({}, reservation, {pricing: pricing})
      totalPrice = Helpers.getRentalTotalPricing(temp)
    }

    console.log('pricing', totalPrice, pricing)

    const totalSecurity = pricing?.deposit || 0;

    if (payments && payments.length > 0) {
      let totalHold = 0;
      let totalCaptured = 0;
      let totalPaid = 0;

      payments.forEach((trans) => {
        if (trans.isSecurityHold) {
          if (trans.transactionStatus === PAYMENT_STATUSES.AUTHORIZED) totalHold += Number(trans.amount);
          else if (trans.transactionStatus === PAYMENT_STATUSES.CAPTURED || trans.transactionStatus === PAYMENT_STATUSES.OFFLINE_CHARGED) totalCaptured += Number(trans.amount);
          else if (trans.transactionStatus === PAYMENT_STATUSES.PARTIALLY_CAPTURED) totalCaptured += Number(trans.captureAmount);
        } else {
          if (trans.transactionStatus === PAYMENT_STATUSES.CHARGED || trans.transactionStatus === PAYMENT_STATUSES.OFFLINE_CHARGED) totalPaid += Number(trans.amount);
          else if (trans.transactionStatus === PAYMENT_STATUSES.PARTIALLY_REFUNDED) totalPaid += Number(trans.amount) - Number(trans.refundAmount);
        }
      });

      const outstandingBalance = totalPrice - totalPaid;
      setOutstandingBalance(outstandingBalance > 0 ? outstandingBalance.toFixed(2) : 0);
      setBalancePayable(outstandingBalance > 0 ? outstandingBalance.toFixed(2) : 0);
      setBalancePaid(totalPaid.toFixed(2));

      const outstandingSecurity = totalSecurity - totalHold - totalCaptured;
      setOutstandingSecurityBalance(outstandingSecurity > 0 ? outstandingSecurity.toFixed(2) : 0);
      setSecurityPayable(outstandingSecurity > 0 ? outstandingSecurity.toFixed(2) : 0);
      setSecurityHold(totalHold.toFixed(2));
      setSecurityCaptured(totalCaptured.toFixed(2));
    } else {
      setOutstandingSecurityBalance(Number(pricing?.deposit).toFixed(2));
      setSecurityPayable(Number(pricing?.deposit).toFixed(2));
      setSecurityHold(0);
      setSecurityCaptured(0);

      setOutstandingBalance(totalPrice.toFixed(2));
      setBalancePayable(totalPrice.toFixed(2));
      setBalancePaid(0);
    }
  }, [payments]);

  useEffect(() => {
    if (selectedRes && selectedRes.value) {
      setShowLoader(true)
      ApiHelpers.getPaymentsForRes(token, selectedRes.value)
        .then(data => {
          setPayments(data)
          setShowLoader(false)
        }).catch(err => {
        LOGGER.error('Error when getting payments for reservation', err)
        errorCallback(err?.message || "Error when getting payments for reservation")
      })

      const reservation = reservationsMap[selectedRes?.value] || {}
      const client = clientsMap[reservation.client]
      const vehicle = vehiclesMap[reservation.vehicle_id]
      setSelectedVehicle(vehicle)
      setSelectedClient(client)
    }

  }, [selectedRes]);

  useEffect(() => {
    let reservation = reservationsMap[selectedRes?.value] || {}
    let client = clientsMap[reservation?.client] || {}
    setEmail(client?.email)
  }, [selectedRes]);

  const onSubmitClicked = async () => {
    if (!amount || amount.length === 0 || isNaN(amount) || Number(amount) === 0) {
      setErrorMessage('Please enter a valid amount')
      setShowError(true)
      return
    } else if (!description || description.length === 0) {
      setErrorMessage('Please enter a valid description for the invoice')
      setShowError(true)
      return
    } else if (!email || !Helpers.validateEmail(email)) {
      setErrorMessage('Please enter a valid email address')
      setShowError(true)
      return
    }

    let reservation = reservationsMap[selectedRes?.value] || {}
    if (!window.confirm(`Are you sure you want to send the invoice for rental #${reservation?._id} to ${email}`))
      return

    setShowError(false)
    setShowLoader(true)
    let payload = {
      amount: Number(amount),
      description: description,
      email: email,
      res_id: reservation?._id,
      vehicle_id: reservation?.vehicle_id,
      client_id: reservation?.client,
      isSecurityHold: isSecurity
    }


    const config = {
      method: 'post',
      url: `${Config.BACKEND_URL}invoices`,
      data: payload,
      headers: {Authorization: token},
    };

    try {
      const res = await axios(config);
      setShowLoader(false)
      if (res.data) {
        setShowSuccess(true)
        setSuccessMessage('Successfully sent the payment invoice')
        successCallback('Successfully sent the payment invoice')
      } else {
        setShowError(true)
        setErrorMessage('Something when wrong when sending the payment invoice')
      }
    } catch (err) {
      LOGGER.error('Error when sending invoice', err)
      setErrorMessage('Something went wrong when sending the payment invoice')
      setShowError(true)
      errorCallback(err?.message || "Something went wrong when sending the payment invoice")
    }
  }

  const renderDetails = () => {
    if (!selectedRes)
      return null

    let options = ['Cash', 'Zelle', 'Venmo', 'CashApp', 'ACH/Wire'].map(res => {
      return {label: res, value: res}
    })

    const salesOptions = salesUsers.map((user) => ({label: user.name, value: user._id}));

    return (
      <div
        style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        {
          isSecurity ?
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div style={{
                marginTop: 10,
                width: '80%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}>
                <div style={{width: '50%'}}>
                  <label style={{color: Colors.secondaryTextColor}}>On Hold: </label>
                  <label style={{color: Colors.primaryTextColor, marginLeft: 5}}>${securityHold}</label>
                </div>
                <div style={{width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                  <label style={{color: Colors.secondaryTextColor}}>Captured: </label>
                  <label style={{color: Colors.primaryTextColor, marginLeft: 5}}>${securityCaptured}</label>
                </div>
              </div>
              <div className={Styles.modalRow}
                   style={{marginTop: 4, width: '80%', justifyContent: 'center'}}>
                <label style={{color: Colors.secondaryTextColor}}>Balance: </label>
                <label style={{color: Colors.primaryTextColor, marginLeft: 5}}>${outstandingSecurityBalance}</label>
              </div>
            </div>
            :
            <div style={{
              marginTop: 10,
              width: '80%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}>
              <div className={Styles.modalRow}
                   style={{width: '50%', justifyContent: 'center'}}>
                <label style={{color: Colors.secondaryTextColor}}>Total Paid: </label>
                <label style={{color: Colors.primaryTextColor, marginLeft: 5}}>${balancePaid}</label>
              </div>
              <div className={Styles.modalRow}
                   style={{marginTop: 4, width: '50%', justifyContent: 'center'}}>
                <label style={{color: Colors.secondaryTextColor}}>Balance: </label>
                <label style={{color: Colors.primaryTextColor, marginLeft: 5}}>${outstandingBalance}</label>
              </div>
            </div>
        }

        <CustomTextFieldNew
          marginTop={10}
          width={'78%'}
          label={isSecurity ? 'Amount to Hold($)' : 'Amount to Pay($)'}
          placeholder={'enter amount'}
          value={amount}
          onChange={(text) => {
            if (isNaN(text))
              return
            setAmount(text)
          }}
        />

        <CustomTextFieldNew
          marginTop={10}
          width={'78%'}
          label={'Email Address'}
          placeholder={'enter email address'}
          value={email}
          onChange={setEmail}
        />

        <CustomTextFieldNew
          marginTop={10}
          width={'78%'}
          label={'Description'}
          placeholder={'enter description'}
          value={description}
          onChange={setDescription}
        />

        <CustomButtonContained
          text={'Send Invoice'}
          onClick={onSubmitClicked}
          style={{marginTop: 2}}
          color={'primary'}
        />

      </div>
    )
  }

  const renderContent = () => {
    let resOptions = allRes.map(res => {
      return {label: `Res #${res._id}-${res.vehicle_id}`, value: res._id}
    })

    return (
      <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <label className={GeneralStyles.darkText} style={{fontWeight: 600, fontSize: 20, marginTop: 20}}>
          Send Invoice
        </label>
        <label className={GeneralStyles.grayText} style={{fontSize: 18, marginTop: 10}}>
          {isSecurity ? 'Security Deposit' : 'New Payment'}
        </label>
        {
          showError &&
          <label style={{width: '100%', textAlign: 'center', color: 'red'}}>{errorMessage}</label>
        }
        {
          showSuccess &&
          <label style={{width: '100%', textAlign: 'center', color: 'green'}}>{successMessage}</label>
        }
        <div
          className={GeneralStyles.boxShadow}
          style={{width: '80%', height: 40, borderRadius: 10, marginTop: 20}}>
          <CustomSelect
            disabled={reservation !== null}
            placeholder={'select or search reservation'}
            borderColor={'transparent'}
            value={selectedRes}
            options={resOptions}
            onChange={setSelectedRes}
          />
        </div>
        {
          showLoader ?
            <div
              style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50}}>
              <CustomLoaderSpinner/>
            </div>
            :
            renderDetails()
        }
      </div>
    )

  }

  return (
    <CustomModal
      show={modalIsOpen}
      handleClose={onModalClose}
      containerWidth={window.innerWidth / 3}
      containerHeight={window.innerHeight - 100}
    >
      {renderContent()}
    </CustomModal>
  )

}
