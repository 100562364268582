import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_CALL_STATUS } from '../../metadata/enums'
import { ADD_NEW_PARTNER_AND_UPDATE_VEHICLE, GET_PARTNER_PROFILE_DATA_MONTHLY_BREAKDOWN, GET_PARTNER_SLICE_DATA, GET_PARTNER_SLICE_NAME_AND_ID, GET_PARTNER_PROFILE_DATA } from '../actionTypes'
import axios from 'axios'
import Config from '../../config'
import { LOGGER } from "../../utils/Logger";
import moment from 'moment'

const initialState = {
    NewPartnerAdded: false,
    partnerSplit: [],
    partnerSplit_nameAndId: [],
    partnerProfileData: null,
    partnerProfileData_monthlyBreakDown: null,
    partnerProfileData_monthlyBreakDown_status: API_CALL_STATUS.IDLE,
    status: API_CALL_STATUS.IDLE,
    error: null,
}

export const addPartnerAndVehicleThenConfirm = createAsyncThunk(ADD_NEW_PARTNER_AND_UPDATE_VEHICLE, async (payload) => {
    try {
        const config = {
            method: 'post',
            url: `${Config.BACKEND_URL}partners`,
            headers: { Authorization: payload.token, contentType: "application/json", },
            data: payload.data
        }

        let res = await axios(config)
        let data = Object.assign({}, payload.data)
        data._id = res.data?._id
        return data
    } catch (err) {
        LOGGER.log('error when adding new partner and updating vehicle', err)
    }

    // return order
})
export const fetchAllPartnerSplits = createAsyncThunk(GET_PARTNER_SLICE_DATA, async (payload) => {
    let startDate = moment(payload.data?.start_date).hours(0).minutes(0).seconds(0).toDate().toISOString()
    let endDate = moment(payload.data?.end_date).hours(0).minutes(0).seconds(0).toDate().toISOString()
    let _id = payload.data?._id
    let _revenueTypes = payload.data?._revenueTypes;
    const config = {
        method: 'post',
        url: `${Config.BACKEND_URL}partners/analytics?start=${startDate}&end=${endDate}&id=${_id}`,
        headers: { Authorization: payload.token },
        data: { revenueTypes: _revenueTypes }
    }
    try {
        let res = await axios(config)
        return res.data
    } catch (err) {
        console.log('error: ', err);
        LOGGER.error('Error when getting partnerSplit', err)
        return []
    }
})

export const fetchPartnerData_forPartnerProfile = createAsyncThunk(GET_PARTNER_PROFILE_DATA, async (payload) => {
    console.log(payload);
    let _id = payload?._id
    const config = {
        method: 'get',
        url: `${Config.BACKEND_URL}partners/${_id}`,
        headers: { Authorization: payload.token },
    }
    try {
        let res = await axios(config)
        return res.data
    } catch (err) {
        console.log('error: ', err);
        LOGGER.error('Error when getting partner data', err)
        return []
    }
})

export const fetchAllPartnerSplits_nameAndId = createAsyncThunk(GET_PARTNER_SLICE_NAME_AND_ID, async (payload) => {
    const config = {
        method: 'get',
        url: `${Config.BACKEND_URL}partners`,
        headers: { Authorization: payload.token }
    }
    try {
        let res = await axios(config)
        return res.data
    } catch (err) {
        console.log('error: ', err);
        LOGGER.error('Error when getting partnerSplit names and ids', err)
        return []
    }
})

export const fetchPartnerMonthlyBreakDown = createAsyncThunk(GET_PARTNER_PROFILE_DATA_MONTHLY_BREAKDOWN, async (payload) => {
    const _splits = JSON.stringify(payload.split)
    const config = {
        method: 'post',
        url: `${Config.BACKEND_URL}partners/monthlyBreakdown`,
        headers: { Authorization: payload.token, "Content-Type": "application/json" },
        data: { data: _splits }
    }
    try {
        let res = await axios(config)
        return res.data
    } catch (err) {
        console.log('error: ', err);
        LOGGER.error('Error when getting partner Split monthly breakdown', err)
        return []
    }
})

const partnerSplitSlice = createSlice({
    name: 'partnerSplits',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(fetchAllPartnerSplits.pending, (state, action) => {
                state.status = API_CALL_STATUS.LOADING
            })
            .addCase(fetchAllPartnerSplits.fulfilled, (state, action) => {
                state.status = API_CALL_STATUS.SUCCEEDED
                state.partnerSplit = action.payload
            })
            .addCase(fetchAllPartnerSplits.rejected, (state, action) => {
                state.status = API_CALL_STATUS.FAILED
                state.error = action.error.message
            })
            .addCase(fetchAllPartnerSplits_nameAndId.pending, (state, action) => {
                state.status = API_CALL_STATUS.LOADING
            })
            .addCase(fetchAllPartnerSplits_nameAndId.fulfilled, (state, action) => {
                state.status = API_CALL_STATUS.SUCCEEDED
                state.partnerSplit_nameAndId = action.payload
            })
            .addCase(fetchAllPartnerSplits_nameAndId.rejected, (state, action) => {
                state.status = API_CALL_STATUS.FAILED
                state.error = action.error.message
            })
            .addCase(fetchPartnerData_forPartnerProfile.pending, (state, action) => {
                state.status = API_CALL_STATUS.LOADING
            })
            .addCase(fetchPartnerData_forPartnerProfile.fulfilled, (state, action) => {
                state.status = API_CALL_STATUS.SUCCEEDED
                state.partnerProfileData = action.payload
            })
            .addCase(fetchPartnerData_forPartnerProfile.rejected, (state, action) => {
                state.status = API_CALL_STATUS.FAILED
                state.error = action.error.message
            })
            .addCase(fetchPartnerMonthlyBreakDown.pending, (state, action) => {
                state.partnerProfileData_monthlyBreakDown_status = API_CALL_STATUS.LOADING
                state.partnerProfileData_monthlyBreakDown = null;
            })
            .addCase(fetchPartnerMonthlyBreakDown.fulfilled, (state, action) => {
                state.partnerProfileData_monthlyBreakDown_status = API_CALL_STATUS.SUCCEEDED
                state.partnerProfileData_monthlyBreakDown = null;
                state.partnerProfileData_monthlyBreakDown = action.payload
            })
            .addCase(fetchPartnerMonthlyBreakDown.rejected, (state, action) => {
                state.partnerProfileData_monthlyBreakDown_status = API_CALL_STATUS.FAILED
                state.partnerProfileData_monthlyBreakDown = null;
                state.error = action.error.message
            })
            .addCase(addPartnerAndVehicleThenConfirm.pending, (state, action) => {
                state.status = API_CALL_STATUS.LOADING
            })
            .addCase(addPartnerAndVehicleThenConfirm.fulfilled, (state, action) => {
                state.NewPartnerAdded = true;
            })
            .addCase(addPartnerAndVehicleThenConfirm.rejected, (state, action) => {
                state.status = API_CALL_STATUS.FAILED
                state.error = action.error.message
            })
    }

})

export default partnerSplitSlice.reducer
