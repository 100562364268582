// style
import React from 'react'
import Colors from "../../config/colors";
import Constants from "../../config/constants";
import Styles from "./Styles/Tab.module.scss"

const Tab = ({tab, onChange, OptionsStringArray = [], width = '100%', _style = ''}) => {
  // get string array , with tab and setTab states
  // assign options with list of options and states for each.
  // return to the onChange is the index of the option's index in the OptionsStringArray
  let _options = [];
  OptionsStringArray.forEach((option, index) => {
    _options.push((
      <div
        key={index}
        className={`${Styles.tabEntry}`}
        style={{backgroundColor: tab === index ? Colors.tabButtonTheme : 'transparent'}}
        onClick={() => onChange(index)}>
        <p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>{option}</p>
      </div>
    ))
  })

  return (
    <div style={{..._style}}>
      <div style={{
        marginBottom: 20,
        width: width,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 10,
        border: '1px solid gray',
        padding: 4,
        backgroundColor: Colors.theme,
        borderColor: Colors.theme
      }}>
        {_options}
      </div>
    </div>
  )
}
export default Tab;
