import React,{useState, useEffect} from 'react';
import Colors from '../../config/colors'
import GeneralStyles from '../../routes/GeneralStyles.module.scss'

export default function CustomTextFieldNew({label, onChange, value, placeholder, disabled, width, marginTop, height, backgroundColor, hidden}) {
	return (
		<div
			className={GeneralStyles.boxShadow}
			style={{paddingLeft: 10,paddingRight: 4,width: width || '100%', marginTop: marginTop || 0, borderRadius: 10}}>
			<label style={{color: Colors.primaryTextColor, fontWeight: '600', fontSize: 14}}>{label}</label>
			<input
				disabled={disabled || false}
				type={hidden ? "password" : "text"}
				id={label}
				placeholder={placeholder}
				value={value}
				style={
					{
						height: height || 25,
						width: '100%',
						backgroundColor: backgroundColor || Colors.contentBackgroundColor,
						borderRadius: 10,
						color: Colors.primaryTextColor,
						borderWidth: 0,
						fontSize: 17
					}}
				onChange={(e) => onChange(e.target.value)}
			/>
		</div>
	)
}

