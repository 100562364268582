import React, {useState, useEffect, useRef} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import PageHeader from "../../components/general/PageHeader";
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import {useDispatch, useSelector} from "react-redux";
import {API_CALL_STATUS, PERMISSIONS, WEBSITE_PAYMENT_AMOUNT, WEBSITE_PAYMENT_REQUIREMENTS} from "../../metadata/enums";
import {LOGGER} from "../../utils/Logger";
import Colors from "../../config/colors";
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";
import CONSTANTS from '../../config/constants'
import Config from '../../config'
import Styles from './styles/Settings.module.scss'
import Helpers from '../../utils/helpers'
import DeleteIcon from "@mui/icons-material/Delete";
import {useNavigate} from 'react-router-dom'
import {
  getPriceSettings,
  getSalesSettings,
  updateSettingsData,
  updateUpdatedStatus,
  getPermissionSettings,
  getIntegrationSettings
} from '../../redux/slices/settingsSlice'
import CustomButtonContained from "../../components/general/CustomButtonContained";
import CustomModal from "../../components/general/CustomModal";
import CustomTextFieldNew from '../../components/general/CustomTextFieldNew'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomSwitch from '../../components/general/CustomSwitch'
import {
  updateUserAndConfirm,
  updateUserUpdatedStatus,
  addUserAndConfirm,
  updateNewUserStatus
} from '../../redux/slices/userSlice'
import Constants from "../../config/constants";
import CustomSelect from "../../components/general/CustomSelect";
import Checkbox from '@mui/material/Checkbox';
import {changeUserAccess} from "../../redux/slices/authSlice";
import UploadIcon from "../../assets/logos/uploadIcon.svg";
import ReactS3Client from "../../services/S3Client";
import vehicles from "../Vehicles/Vehicles";

const getEmptyUser = () => {
  return {
    name: '',
    email: '',
    phone: '',
    role: null,
    enabled: true
  }
}

const Settings = () => {
  let navigate = useNavigate()
  let dispatch = useDispatch()
  let fileRef = useRef(null)
  let imageRef = useRef(null)

  const token = useSelector(state => state.auth.token)
  const users = useSelector(state => state.user.users)
  const userState = useSelector(state => state.user)
  const mongoUser = useSelector(state => state.user.mongoUser)
  const settingsState = useSelector(state => state.settings)
  const vehiclesList = useSelector(state => {return state.vehicles.vehicles})

  const [loading, setLoading] = useState(true)
  const [loading2, setLoading2] = useState(true)
  const [message, setMessage] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showUserModal, setShowUserModal] = useState(false)
  const [showDiscountModal, setShowDiscountModal] = useState(false)
  const [toAddPerson, setToAddPerson] = useState('')
  const [expanded, setExpanded] = useState(null)
  const [discountModalDetails, setDiscountModalDetails] = useState({name: '', discount: ''})
  const [salesSettings, setSalesSettings] = useState(null)
  const [roles, setRoles] = useState([])
  const [rolesMap, setRolesMap] = useState({})
  const [permChanged, setPermChanged] = useState(false)
  const [enableUserEdit, setEnableUserEdit] = useState(false)
  const [businessDetails, setBusinessDetails] = useState(null)
  const [selectedLogo, setSelectedLogo] = useState(null)
  const [payReqTab, setPayReqTab] = useState(0)
  const [payAmountTab, setPayAmountTab] = useState(0)
  const [instantBookTab, setInstantBookTab] = useState(0)
  const [webAmount, setWebAmount] = useState('')
  const [webMin, setWebMin] = useState('')
  const [webGrace, setWebGrace] = useState('')
  const [selectedVehicle, setSelectedVehicle] = useState(null)

  const [loginId, setLoginId] = useState('')
  const [transactionKey, setTransactionKey] = useState('')
  const [signKey, setSignKey] = useState('')
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null)

  const [modalUser, setModalUser] = useState(getEmptyUser())
  const [pwd, setPwd] = useState('')
  const [priceSettings, setPriceSettings] = useState({
    sales_tax: '',
    service_tax: {rentals: '', chauffeur: ''},
    enabled: true,
    location_fee: '',
    card_fee: ''
  })
  const [integrationSettings, setIntegrationSettings] = useState(null)
  const [onlineSettings, setOnlineSettings] = useState(null)
  const [rolesTab, setRolesTab] = useState('users')
  const [requireId, setRequireId] = useState(false)
  const [customDel, setCustomDel] = useState(false)

  //this is to check
  useEffect(() => {
    settingsState.settings?.forEach(setting => {
      if (setting._id === 'businessDetails')
        setBusinessDetails(setting)
    })

    let rolesMap = Helpers.getRolesMap(settingsState)
    if (mongoUser && Object.keys(rolesMap).length > 0) {
      //if they dont have access, go back and show message
      if (!Helpers.checkAccess(mongoUser, rolesMap, 'view_edit_settings')) {
        dispatch(changeUserAccess(false))
        navigate(-1)
        return
      }

      let adminRole
      settingsState.settings.forEach(setting => {
        if (setting._id === 'permissions') {
          setting.roles.forEach(role => {
            if (role.name === 'Administrator')
              adminRole = role
          })
        }
      })

      if (Config.WHITELIST_UIDS[mongoUser.uid] || mongoUser.role === adminRole.id) {
        setEnableUserEdit(true)
      } else {
        setEnableUserEdit(false)
      }
    }
  }, [mongoUser, settingsState])

  useEffect(() => {
    if (userState.status === API_CALL_STATUS.LOADING || userState.status === API_CALL_STATUS.IDLE) {
      setLoading(true)
    } else if (userState.status === API_CALL_STATUS.FAILED) {
      setLoading(false)
      setShowError(true)
      setMessage('something went wrong when updating user')
      LOGGER.error('error effect', userState.error)
    } else {
      setLoading(false)
      setShowUserModal(false)
      if (userState.updated_user) {
        setExpanded('perms')
        setShowSuccess(true)
        setMessage('Successfully updated user information')
        dispatch(updateUserUpdatedStatus(false))
      }

      if (userState.new_user) {
        setExpanded('perms')
        setShowUserModal(false)
        setShowSuccess(true)
        setMessage('Successfully added new user')
        dispatch(updateNewUserStatus(false))
      }
    }
  }, [userState])

  useEffect(() => {
    if (integrationSettings) {
      let {websiteIntegration} = integrationSettings
      if (!websiteIntegration || Object.keys(websiteIntegration).length === 0)
        return
      if (websiteIntegration.payment_requirement === WEBSITE_PAYMENT_REQUIREMENTS.PAYMENT) {
        setPayReqTab(0)
        if (websiteIntegration.payment_amount_requirement === WEBSITE_PAYMENT_AMOUNT.FULL_PAYMENT)
          setPayAmountTab(0)
        else if (websiteIntegration.payment_amount_requirement === WEBSITE_PAYMENT_AMOUNT.PARTIAL_PAYMENT)
          setPayAmountTab(1)
        else
          setPayAmountTab(2)
      } else {
        setPayReqTab(1)
      }

      setWebAmount(websiteIntegration.amount || '')
      setWebMin(websiteIntegration.minimum_duration)
      setWebGrace(websiteIntegration.grace_period)
      setInstantBookTab(websiteIntegration.instant ? 0 : 1)
      setRequireId(websiteIntegration.require_id)
      setCustomDel(websiteIntegration.custom_delivery)
    }
  }, [integrationSettings])

  useEffect(() => {
    if (settingsState.status === API_CALL_STATUS.LOADING || settingsState.status === API_CALL_STATUS.IDLE) {
      setLoading2(true)
    } else if (settingsState.status === API_CALL_STATUS.FAILED) {
      setLoading2(false)
      setShowError(true)
      setMessage('something went wrong when getting the settings data')
      LOGGER.error('error effect', settingsState.error)
    } else {
      setLoading(false)
      setLoading2(false)
      if (settingsState.updated_settings) {
        setShowSuccess(true)
        setMessage('Successfully updated')
        setToAddPerson('')
        dispatch(updateUpdatedStatus(false))
      }
      setSalesSettings(getSalesSettings(settingsState))
      setPriceSettings(getPriceSettings(settingsState))
      let integrationSettings = getIntegrationSettings(settingsState)
      setLoginId(integrationSettings.authorizeNetIntegration?.name)
      setTransactionKey(integrationSettings.authorizeNetIntegration?.transactionKey)
      setSignKey(integrationSettings.authorizeNetIntegration?.signatureKey)
      setIntegrationSettings(integrationSettings)
      let temp = Object.assign({}, integrationSettings?.websiteIntegration)
      if(!temp.sales_tax) {
        temp = Object.assign({}, temp, {
          sales_tax: 0,
          service_tax: {rentals: 0, chauffeur: 0},
          location_fee: 0,
          card_fee: 0
        })
      }
      setOnlineSettings(temp)
      let permissionSettings = getPermissionSettings(settingsState)
      setRoles(permissionSettings.roles)
      let map = {}
      permissionSettings.roles.forEach(role => map[role.id] = role)
      setRolesMap(map)
    }
  }, [settingsState])

  const deleteUerClicked = (name) => {
    if (window.confirm(`Are you sure you want to remove ${name}`)) {
      let tempSettings = JSON.parse(JSON.stringify(salesSettings))
      let inActive = tempSettings['in_active']
      let active = tempSettings['active']
      inActive.push(name)
      for (let i = 0; i < active.length; i++) {
        if (active[i] === name) {
          active.splice(i, 1);
        }
      }
      dispatch(updateSettingsData({token, data: tempSettings}))
    }
  }

  const onSaveWebsiteInfoClicked = () => {
    if (payReqTab === 0 && (payAmountTab === 1 || payAmountTab === 2) && (!webAmount || webAmount.length === 0 || isNaN(webAmount))) {
      //if there is partial or minimum payment requirement, then ente rhte meount
      setShowError(true)
      setMessage('Please enter a valid amount for payment requirement')
      return
    }

    if (!webMin || webMin.length === 0 || isNaN(webMin)) {
      setShowError(true)
      setMessage('Please enter a valid number for minimum rental duration')
      return
    }
    if (!webGrace || webGrace.length === 0 || isNaN(webGrace)) {
      setShowError(true)
      setMessage('Please enter a valid grace period(hours) for booking')
      return
    }

    let {sales_tax, location_fee, service_tax, card_fee} = onlineSettings || {}
    if (!sales_tax || sales_tax.length === 0 || isNaN(sales_tax)) {
      setShowError(true)
      setMessage('Please enter the sales tax for online bookings')
      return
    }

    if (!location_fee || location_fee.length === 0 || isNaN(location_fee)) {
      setShowError(true)
      setMessage('Please enter the location fee for online bookings')
      return
    }

    if (!card_fee || card_fee.length === 0 || isNaN(card_fee)) {
      setShowError(true)
      setMessage('Please enter the card fee for online bookings')
      return
    }

    if (!service_tax?.rentals || service_tax?.rentals?.length === 0 || isNaN(service_tax?.rentals)) {
      setShowError(true)
      setMessage('Please enter the service tax for online bookings')
      return
    }


    setLoading(true)
    saveWebsiteIntegrationData()
    // if (selectedImage) {
    //   handleUploadImage(false)
    // } else {
    //   console.log('url is', imageUrl)
    //
    // }


  }

  const saveWebsiteIntegrationData = () => {
    let temp2 = Object.assign({}, onlineSettings?.service_tax, {
      rentals: Number(onlineSettings?.service_tax?.rentals),
      chauffeur: Number(onlineSettings?.service_tax?.chauffeur)
    })
    let toSave = {
      minimum_duration: Number(webMin),
      grace_period: Number(webGrace),
      business_hours: {
        m_to_f: {start: '', end: '', status: true},
        sat: {start: '', end: '', status: true},
        sun: {start: '', end: '', status: true}
      },
      instant: instantBookTab === 0,
      require_id: requireId,
      custom_delivery: customDel,
      sales_tax: Number(onlineSettings.sales_tax),
      service_tax: temp2,
      location_fee: Number(onlineSettings.location_fee),
      card_fee: Number(onlineSettings.card_fee),
    }

    if (payReqTab === 0) {
      //payment has been selected, either full payment or partial should be set
      toSave['payment_requirement'] = WEBSITE_PAYMENT_REQUIREMENTS.PAYMENT
      switch (payAmountTab) {
        case 0:
          toSave['payment_amount_requirement'] = WEBSITE_PAYMENT_AMOUNT.FULL_PAYMENT
          break
        case 1:
          toSave['payment_amount_requirement'] = WEBSITE_PAYMENT_AMOUNT.PARTIAL_PAYMENT
          toSave['amount'] = Number(webAmount)
          break
        case 2:
          toSave['payment_amount_requirement'] = WEBSITE_PAYMENT_AMOUNT.MINIMUM_PAYMENT
          toSave['amount'] = Number(webAmount)
          break
      }
    } else {
      toSave['payment_requirement'] = WEBSITE_PAYMENT_REQUIREMENTS.NO_PAYMENT
    }

    let temp = Object.assign({}, integrationSettings, {_id: 'integration', websiteIntegration: toSave})
    dispatch(updateSettingsData({token, data: temp}))
  }

  const onAddDiscountClicked = () => {
    let {discount, name} = discountModalDetails
    if (!name || name.length === 0) {
      setShowError(true)
      setMessage('Please enter a valid name for the discount')
      return
    }

    if (!discount || discount.length === 0) {
      setShowError(true)
      setMessage('Please enter a valid discount percentage')
      return
    }
    setShowDiscountModal(false)
    setLoading(true)

    let currentDiscounts = priceSettings?.discounts || []
    let temp = [...currentDiscounts]
    temp.push({name: name, discount: Number(discount), status: true})

    let toSave = {
      _id: 'pricing',
      discounts: temp
    }
    dispatch(updateSettingsData({token, data: toSave}))
  }

  const onAddPersonConfirmed = () => {
    let flag = false
    let {email, name, role, phone} = modalUser
    if (!email || email.length === 0 || !Helpers.validateEmail(email)) {
      flag = true
      setMessage('Please enter a valid email')
    }

    if (!name || name.length === 0) {
      flag = true
      setMessage('Please enter first and last name')
    }

    if (!phone || phone.length !== 14) {
      flag = true
      setMessage('Please enter a valid phone number')
    }

    if (!pwd || pwd.length < 6) {
      flag = true
      setMessage('Password must be minimum 6 characters')
    }

    if (!role || typeof role !== 'number') {
      flag = true
      setMessage('Please select a user role from the dropdown')
    }

    if (flag) {
      setShowError(true)
    } else {
      setShowUserModal(false)
      setLoading(true)
      let temp = Object.assign({}, modalUser, {password: pwd})
      dispatch(addUserAndConfirm({token: token, data: temp}))
    }
  }

  const reAddUserClicked = (name) => {
    if (window.confirm(`Are you sure you want to re-add ${name}`)) {
      let tempSettings = JSON.parse(JSON.stringify(salesSettings))
      let inActive = tempSettings['in_active']
      let active = tempSettings['active']
      active.push(name)
      for (let i = 0; i < active.length; i++) {
        if (inActive[i] === name) {
          inActive.splice(i, 1);
        }
      }
      dispatch(updateSettingsData({token, data: tempSettings}))
    }
  }

  const onSaveCompanyClicked = () => {
    let flag = false
    Object.keys(businessDetails).forEach(field => {

      if (!businessDetails[field] || businessDetails[field].length === 0)
        flag = true
    })

    if (flag) {
      setShowError(true)
      setMessage('Missing some company details or incorrect data')
      return
    }

    if (!Helpers.validateEmail(businessDetails.email)) {
      setShowError(true)
      setMessage('Incorrect email format')
      return
    }

    if (businessDetails.phone?.length !== 14) {
      setShowError(true)
      setMessage('Incorrect phone number')
      return
    }
    setLoading(true)

    if (selectedLogo) {
      //need to upload logo
      handleUploadImage(true)
    } else {
      dispatch(updateSettingsData({token, data: businessDetails}))
    }
  }

  const handleUploadImage = (companyLogo) => {
    let image = companyLogo ? selectedLogo : selectedImage
    console.log('uploading', image)
    ReactS3Client
      .uploadFile(image)
      .then(data => {
        LOGGER.log('success', data)
        let url = Config.AWS_CLOUDFRONT_URL + data.key
        if (companyLogo)
          onLogoUploaded(url)
        else {
          saveWebsiteIntegrationData(url)
        }
      })
      .catch(err => LOGGER.error('error ', err.message))
  }

  const onLogoUploaded = (url) => {
    let temp = Object.assign({}, businessDetails, {logo_url: url})
    dispatch(updateSettingsData({token, data: temp}))
  }

  const onSavePermsClicked = () => {
    let toSave = {
      _id: 'permissions',
      roles: roles
    }
    dispatch(updateSettingsData({token, data: toSave}))
  }

  const onSaveAuthorizeIntegration = () => {
    authenticateCredentials();
  }

  const authenticateCredentials = () => {
    setLoading2(true)
    const url = Config.AUTHORIZENET_URL;
    const data = JSON.stringify({
      authenticateTestRequest: {
        merchantAuthentication: {
          name: loginId,
          transactionKey: transactionKey,
        },
      },
    });
    const headers = {
      "Content-Type": "application/json",
    };

    fetch(url, {
      method: "POST",
      headers: headers,
      body: data,
    })
      .then((response) => response.json())
      .then((responseData) => {
        setLoading2(false)
        if (responseData.messages?.resultCode === "Ok") {
          let temp = Object.assign({}, integrationSettings, {
            _id: 'integration',
            authorizeNetIntegration: {name: loginId, transactionKey: transactionKey, signatureKey: signKey}
          })
          dispatch(updateSettingsData({token, data: temp}));
          return responseData;
        } else {
          setShowError(true);
          setMessage('Authorize.net credentials verification failed');
        }
      })
      .catch((error) => {
        setLoading2(false)
        console.error('error when verifying with Authorize', error);
      });
  };

  const onSavePriceClicked = () => {
    let salesTax = priceSettings['sales_tax']
    if (!salesTax || salesTax.length === 0) {
      setShowError(true)
      setMessage('Missing Sales Tax')
      return
    }

    let serviceTax = priceSettings['service_tax']
    if (!serviceTax.rentals || !serviceTax.chauffeur || serviceTax.rentals.length === 0 || serviceTax.chauffeur.length === 0) {
      setShowError(true)
      setMessage('Missing Service Tax')
      return
    }

    let locationFee = priceSettings['location_fee']
    if (!locationFee || locationFee.length === 0) {
      setShowError(true)
      setMessage('Missing Additional Location Fee')
      return
    }

    let toSave = {
      _id: 'pricing',
      sales_tax: Number(priceSettings.sales_tax),
      service_tax: {
        rentals: Number(priceSettings.service_tax?.rentals),
        chauffeur: Number(priceSettings.service_tax?.chauffeur)
      },
      enabled: priceSettings.enabled,
      location_fee: Number(priceSettings.location_fee),
      discounts: priceSettings.discounts,
      card_fee: Number(priceSettings.card_fee)
    }
    console.log('to save', toSave)
    dispatch(updateSettingsData({token, data: toSave}))
  }

  const handleExpandChange = (id) => {
    if (id === expanded)
      setExpanded(null)
    else
      setExpanded(id)
  }

  const renderDiscounts = () => {
    let discounts = priceSettings?.discounts || []
    let elements = discounts.map((entry, index) => (
      <div
        key={index.toString()}
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          height: 40,
          borderBottom: `1px solid ${Colors.tableLineColor}`,
          alignItems: 'center',
          marginTop: 4
        }}>
        <div
          style={{width: '40%', maxWidth: '40%', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
          <label style={{
            fontSize: CONSTANTS.entryTextSize,
            width: '100%',
            textAlign: 'left',
            paddingLeft: 20,
            color: Colors.primaryTextColor
          }}>{entry.name}</label>
        </div>
        <div style={{width: '40%', marginLeft: 20, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
          <label style={{
            fontSize: CONSTANTS.entryTextSize,
            width: '100%',
            textAlign: 'left',
            paddingLeft: 20,
            color: Colors.primaryTextColor
          }}>{entry.discount}% OFF</label>
        </div>
        <div style={{width: '20%', marginLeft: 20, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
          <CustomSwitch
            onChange={(value) => {
              let temp = JSON.parse(JSON.stringify(priceSettings))
              temp['discounts'][index]['status'] = value
              setPriceSettings(temp)
            }}
            containerStyle={{}}
            checked={entry.status}/>
        </div>


      </div>
    ))
    return (
      <div style={{width: '100%', height: '60%'}}>
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <label style={{fontSize: CONSTANTS.entryTextSize, color: Colors.primaryTextColor}}>Discounts</label>
          <label
            onClick={() => setShowDiscountModal(true)}
            style={{
              fontSize: CONSTANTS.headerSize,
              marginLeft: 40,
              cursor: 'pointer',
              color: Colors.primaryTextColor
            }}>+</label>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
          <div style={{
            width: '40%',
            maxWidth: '40%',
            backgroundColor: Colors.theme,
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
            <label style={{fontWeight: 'bold', fontSize: CONSTANTS.entryTextSize, paddingLeft: 20}}>Name</label>
          </div>
          <div style={{
            width: '40%',
            backgroundColor: Colors.theme,
            height: 40,
            marginLeft: 20,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
            <label style={{fontWeight: 'bold', fontSize: CONSTANTS.entryTextSize, paddingLeft: 20}}>Discount
              Percentage</label>
          </div>
          <div style={{
            width: '20%',
            backgroundColor: Colors.theme,
            height: 40,
            marginLeft: 20,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
            <label style={{fontWeight: 'bold', fontSize: CONSTANTS.entryTextSize, paddingLeft: 20}}>Status</label>
          </div>
        </div>

        <div style={{width: '100%', height: '100%', marginTop: 10}}>
          {elements}
        </div>
      </div>
    )
  }

  const renderUserRow = (user, index) => {
    let options = []
    let currentRole

    roles.forEach(role => {
      options.push({label: role.name, value: role.id})
      if (role.id === user.role)
        currentRole = role
    })

    let roleValue = null
    if (user.role)
      roleValue = {value: user.role, label: currentRole?.name}


    return (
      <div
        key={index.toString()}
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderBottom: `1px solid ${Colors.tableLineColor}`,
          marginTop: 10,
          marginBottom: 10,
          padding: '4px 0px'
        }}>
        <div style={{display: 'flex', width: '25%'}}>
          <label style={{
            color: Colors.primaryTextColor,
            fontSize: CONSTANTS.entryTextSize,
            marginLeft: 10
          }}>{user.name}</label>
        </div>

        <div style={{display: 'flex', width: '22%', marginLeft: 20}}>
          <label style={{
            color: Colors.primaryTextColor,
            fontSize: CONSTANTS.entryTextSize,
            marginLeft: 10
          }}>{user.email}</label>
        </div>

        <div style={{display: 'flex', width: '22%', marginLeft: 20}}>
          <label style={{
            color: Colors.primaryTextColor,
            fontSize: CONSTANTS.entryTextSize,
            marginLeft: 10
          }}>{user.phone}</label>
        </div>

        <div style={{display: 'flex', width: '21%', marginLeft: 20}}>
          {
            enableUserEdit ?
              <div
                className={GeneralStyles.boxShadow}
                style={{width: '100%', height: 40, borderRadius: 10, marginTop: 4}}>
                <CustomSelect
                  placeholder={'select'}
                  borderColor={'transparent'}
                  value={roleValue}
                  options={options}
                  onChange={(selected) => {
                    let temp = JSON.parse(JSON.stringify(user))
                    temp.role = selected.value
                    dispatch(updateUserAndConfirm({token: token, data: temp}))
                  }}
                />

              </div>
              :
              <label style={{
                color: Colors.primaryTextColor,
                fontSize: CONSTANTS.entryTextSize,
                marginLeft: 10
              }}>{rolesMap[user.role]?.name}</label>
          }
        </div>

        <div style={{display: 'flex', width: '10%', marginLeft: 20}}>
          <CustomSwitch
            onChange={(value) => {
              let temp = JSON.parse(JSON.stringify(user))
              temp.enabled = value
              dispatch(updateUserAndConfirm({token: token, data: temp}))
            }}
            containerStyle={{marginLeft: 30}}
            checked={user.enabled}/>
        </div>
      </div>
    )
  }

  const renderUsers = () => {
    let tempRole
    let userElements = users && users.map(renderUserRow)

    return (
      <div style={{width: '100%', marginTop: 20}}>
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <div
            className={Styles.tableHeader}
            style={{width: '25%'}}
          >
            <label style={{color: Colors.tertiaryTextColor, fontSize: CONSTANTS.tableHeaderSize, marginLeft: 10}}>User
              Name</label>
          </div>

          <div
            className={Styles.tableHeader}
            style={{width: '22%'}}
          >
            <label style={{color: Colors.tertiaryTextColor, fontSize: CONSTANTS.tableHeaderSize, marginLeft: 10}}>Email
              Address</label>
          </div>

          <div
            className={Styles.tableHeader}
            style={{width: '22%'}}
          >
            <label style={{color: Colors.tertiaryTextColor, fontSize: CONSTANTS.tableHeaderSize, marginLeft: 10}}>Phone
              Number</label>
          </div>

          <div
            className={Styles.tableHeader}
            style={{width: '21%'}}
          >
            <label style={{
              color: Colors.tertiaryTextColor,
              fontSize: CONSTANTS.tableHeaderSize,
              marginLeft: 10
            }}>Role</label>
          </div>

          <div
            className={Styles.tableHeader}
            style={{width: '10%'}}
          >
            <label style={{
              color: Colors.tertiaryTextColor,
              fontSize: CONSTANTS.tableHeaderSize,
              marginLeft: 10
            }}>Status</label>
          </div>
        </div>

        {
          !users || users.length === 0 ?
            <div style={{width: '100%', marginTop: 40,}}>
              <p style={{
                color: Colors.primaryTextColor,
                fontSize: CONSTANTS.entryTextSize,
                textAlign: 'center',
                width: '100%'
              }}>No Users</p>
            </div>
            :
            <div style={{height: '100%', overflowY: 'scroll', paddingBottom: 150}}>
              {userElements}
            </div>
        }
      </div>
    )
  }

  const onPermChange = (value, permissionKey, roleId) => {
    let temp = JSON.parse(JSON.stringify(roles))
    temp.every(role => {
      if (role.id === roleId) {
        role.permissions[permissionKey] = value
        return false
      }
      return true
    })

    setPermChanged(true)
    setRoles(temp)

  }

  const renderPerms = () => {
    let length = roles.length - 1
    let headers = []
    roles.forEach((role, index) => {
      if (role.id !== 4) {
        headers.push((
          <div
            key={index.toString()}
            className={Styles.tableHeader}
            style={{width: `${60 / length}%`}}
          >
            <label style={{
              color: Colors.tertiaryTextColor,
              fontSize: CONSTANTS.entryTextSize,
              marginLeft: 10
            }}>{role.name}</label>
          </div>
        ))
      }
    })

    let entries = Object.keys(PERMISSIONS).map((key, index) => {
      let permission = PERMISSIONS[key]
      let roleEntries = []
      roles.forEach((role, index2) => {
        if (role.id !== 4) {
          let value = role.permissions[key]
          roleEntries.push((
            <div
              key={index2.toString()}
              style={{
                display: 'flex',
                width: `${60 / length}%`,
                padding: '5px 0px',
                marginLeft: 10,
                flexDirection: 'row',
                justifyContent: 'center'
              }}>
              <Checkbox
                checked={value}
                onChange={(event) => onPermChange(event.target.checked, key, role.id)}
                sx={{
                  color: 'gray',
                  '&.Mui-checked': {
                    color: Colors.buttonTheme,
                  },
                }}
              />
            </div>
          ))
        }
      })

      return (
        <div
          key={index.toString()}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderBottom: `1px solid ${Colors.tableLineColor}`,
          }}>
          <div style={{display: 'flex', width: '40%', padding: '10px 0px'}}>
            <label style={{
              color: Colors.primaryTextColor,
              fontSize: CONSTANTS.entryTextSize,
              marginLeft: 10
            }}>{permission}</label>
          </div>
          {roleEntries}
        </div>
      )
    })
    return (
      <div style={{width: '100%', marginTop: 20}}>
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <div
            className={Styles.tableHeader}
            style={{width: '40%'}}
          >
            <label style={{
              color: Colors.tertiaryTextColor,
              fontSize: CONSTANTS.entryTextSize,
              marginLeft: 10
            }}>Permissions</label>
          </div>
          {headers}
        </div>
        {entries}
      </div>
    )
  }

  const renderCompanyInfo = () => {
    return (
      <Accordion
        sx={{boxShadow: '0px 4px 25px 0px #0000001F', border: '1px solid #E7E7E7', marginTop: 2}}
        expanded={expanded === 'business'}
        onChange={() => handleExpandChange('business')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color: Colors.primaryTextColor}}/>}
          aria-controls="permissions-content"
          id="permissions-header"
          sx={{height: 40}}
        >
          <p className={GeneralStyles.darkText} style={{fontWeight: 'bold'}}>Company Information</p>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{
            width: '100%',
            padding: '10px 0px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
            <div style={{width: '50%'}}>
              <CustomTextFieldNew
                width={'100%'}
                label={'Company Name'}
                placeholder={'Company Name'}
                value={businessDetails?.name}
                onChange={(text) => {
                  let temp = Object.assign({}, businessDetails)
                  temp['name'] = text
                  setBusinessDetails(temp)
                }}
              />

              <CustomTextFieldNew
                marginTop={10}
                width={'100%'}
                label={'Company Email'}
                placeholder={'Email Address'}
                value={businessDetails?.email}
                onChange={(text) => {
                  let temp = Object.assign({}, businessDetails)
                  temp['email'] = text
                  setBusinessDetails(temp)
                }}
              />

              <CustomTextFieldNew
                marginTop={10}
                width={'100%'}
                label={'Company Phone #'}
                placeholder={'Phone Number'}
                value={businessDetails?.phone}
                onChange={(text) => {
                  let temp = Object.assign({}, businessDetails)
                  temp['phone'] = Helpers.checkPhoneNumberFormat(text)
                  setBusinessDetails(temp)
                }}
              />

              <CustomTextFieldNew
                marginTop={10}
                width={'100%'}
                label={'Company Address'}
                placeholder={'Address Line 1'}
                value={businessDetails?.address}
                onChange={(text) => {
                  let temp = Object.assign({}, businessDetails)
                  temp['address'] = text
                  setBusinessDetails(temp)
                }}
              />

              <CustomTextFieldNew
                marginTop={10}
                width={'100%'}
                label={'Company City/State'}
                placeholder={'City & State'}
                value={businessDetails?.city_state}
                onChange={(text) => {
                  let temp = Object.assign({}, businessDetails)
                  temp['city_state'] = text
                  setBusinessDetails(temp)
                }}
              />


              <CustomTextFieldNew
                marginTop={10}
                width={'100%'}
                label={'Company Zip Code'}
                placeholder={'Zip Code'}
                value={businessDetails?.zip}
                onChange={(text) => {
                  let temp = Object.assign({}, businessDetails)
                  temp['zip'] = text
                  setBusinessDetails(temp)
                }}
              />

              <CustomTextFieldNew
                marginTop={10}
                width={'100%'}
                label={'Terms Of Service URL'}
                placeholder={'Terms Of Service URL'}
                value={businessDetails?.tos_url || ''}
                onChange={(text) => {
                  let temp = Object.assign({}, businessDetails, {tos_url: text})
                  setBusinessDetails(temp)
                }}
              />

              <CustomTextFieldNew
                marginTop={10}
                width={'100%'}
                label={'Privacy Policy URL'}
                placeholder={'Privacy Policy URL'}
                value={businessDetails?.privacy_url || ''}
                onChange={(text) => {
                  let temp = Object.assign({}, businessDetails, {privacy_url: text})
                  setBusinessDetails(temp)
                }}
              />

              <CustomTextFieldNew
                marginTop={10}
                width={'100%'}
                label={'Google Reviews URL'}
                placeholder={'Google Reviews URL'}
                value={businessDetails?.google_url || ''}
                onChange={(text) => {
                  let temp = Object.assign({}, businessDetails, {google_url: text})
                  setBusinessDetails(temp)
                }}
              />


            </div>

            <div style={{width: '45%', position: 'relative'}}>
              <div>
                <label className={GeneralStyles.darkText} style={{fontWeight: '600', fontSize: 15}}>Company Logo</label>
                {renderCompanyLogoSelector()}
                <input
                  id="image"
                  name="user[image]"
                  type="file"
                  accept="image/*"
                  style={{display: 'none'}}
                  onChange={handleFileChange}
                  ref={fileRef}/>
              </div>

              <CustomButtonContained
                style={{height: 36, position: 'absolute', bottom: 0, right: 0}}
                text={`\u2713 Save Changes`}
                onClick={onSaveCompanyClicked}
              />

            </div>

          </div>
        </AccordionDetails>
      </Accordion>
    )
  }

  const handleFileChange = (event) => {
    let selectedFile = event.target.files && event.target.files[0]
    if (!selectedFile)
      return
    setSelectedLogo(selectedFile);
  }

  const handleImageChange = (event) => {
    let selectedFile = event.target.files && event.target.files[0]
    if (!selectedFile)
      return
    setSelectedImage(selectedFile);
  }

  const renderCompanyLogoSelector = () => {
    if (selectedLogo) {
      return (
        <div className={`${Styles.imageContainer}`}>
          <img alt="not fount" width={'80%'} height={'100%'} src={URL.createObjectURL(selectedLogo)}
               style={{borderRadius: 10}}/>
          <p onClick={() => fileRef.current.click()} style={{textDecoration: 'underline', cursor: 'pointer'}}>change
            picture</p>
        </div>
      )
    }

    if (businessDetails?.logo_url) {
      return (
        <div className={`${Styles.imageContainer}`}>
          <img alt="not fount" width={'80%'} height={'100%'} src={businessDetails?.logo_url}
               style={{borderRadius: 10}}/>
          <p onClick={() => fileRef.current.click()} style={{textDecoration: 'underline', cursor: 'pointer'}}>change
            picture</p>
        </div>
      )
    }

    return (
      <div className={`${Styles.imageContainer} ${GeneralStyles.boxShadow}`} style={{backgroundColor: 'transparent'}}>
        <img
          src={UploadIcon} style={{width: 50, height: 50, cursor: 'pointer'}}/>
        <p>Upload Company Logo</p>
        <CustomButtonContained
          style={{margin: '0 auto', height: 40}}
          text={'Browse to upload'}
          onClick={() => fileRef.current.click()}/>
      </div>
    )
  }

  const renderAccountSettings = () => {
    return (
      <Accordion
        sx={{boxShadow: '0px 4px 25px 0px #0000001F', border: '1px solid #E7E7E7', marginTop: 1}}
        expanded={expanded === 'perms'}
        onChange={() => handleExpandChange('perms')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color: Colors.primaryTextColor}}/>}
          aria-controls="permissions-content"
          id="permissions-header"
          sx={{height: 40}}
        >
          <p className={GeneralStyles.darkText} style={{fontWeight: 'bold'}}>Accounts & Permissions</p>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{width: '100%', padding: '10px 0px'}}>
            <div style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <div style={{width: '30%'}}>
                <Tab tab={rolesTab} onChange={setRolesTab}/>
              </div>
              {
                rolesTab === 'users' ?
                  <label
                    onClick={() => setShowUserModal(true)}
                    style={{color: Colors.secondaryTextColor, textDecoration: 'underline', cursor: 'pointer'}}>+ Add
                    User</label>
                  :
                  permChanged &&
                  <CustomButtonContained
                    style={{marginRight: 2, height: 34}}
                    text={`\u2713 Save Changes`}
                    onClick={onSavePermsClicked}
                  />
              }
            </div>
            {
              rolesTab === 'perms' ?
                renderPerms()
                :
                renderUsers()
            }
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }

  const getPayAmountField = () => {
    if (payReqTab === 1 || payAmountTab === 0)
      return null

    return (
      <CustomTextFieldNew
        marginTop={20}
        width={'100%'}
        label={payAmountTab === 1 ? 'Amount (%)' : 'Amount ($)'}
        placeholder={payAmountTab === 1 ? 'Enter % amount required to book' : 'Enter minimum $ amount required to book'}
        value={webAmount}
        onChange={(text) => {
          if (!isNaN(text)) {
            setWebAmount(text)
            if (payAmountTab === 1 && Number(webAmount) >= 100) {
              setMessage('Please enter partial percentage for payment which is less than 100')
              setShowError(true)
            }
          }
        }}
      />
    )

  }

  // const renderImageSelector = () => {
  //   if (selectedImage) {
  //     return (
  //       <div className={`${Styles.imageContainer2}`}>
  //         <img alt="not fount" width={'100%'} height={'100%'} src={URL.createObjectURL(selectedImage)}
  //              style={{borderRadius: 10}}/>
  //         <p onClick={() => imageRef.current.click()} style={{textDecoration: 'underline', cursor: 'pointer'}}>change
  //           picture</p>
  //       </div>
  //     )
  //   }
  //
  //   if (imageUrl) {
  //     return (
  //       <div className={`${Styles.imageContainer2}`}>
  //         <img alt="not fount" width={'100%'} height={'100%'} src={imageUrl} style={{borderRadius: 10}}/>
  //         <p onClick={() => imageRef.current.click()} style={{textDecoration: 'underline', cursor: 'pointer'}}>change
  //           picture</p>
  //       </div>
  //     )
  //   }
  //
  //   return (
  //     <div className={`${Styles.imageContainer2} ${GeneralStyles.boxShadow}`}>
  //       <img
  //         src={UploadIcon} style={{width: 50, height: 50, cursor: 'pointer'}}/>
  //       <p>Upload Vehicle Images</p>
  //       <CustomButtonContained
  //         style={{margin: '0 auto', height: 40}}
  //         text={'Browse to upload'}
  //         onClick={() => imageRef.current.click()}/>
  //
  //     </div>
  //   )
  // }

  const renderApiIntegration = () => {
    const token = integrationSettings?.apiIntegration?.token || ''
    const endpoint = `${Config.BACKEND_URL}api/`
    return (
      <Accordion
        sx={{boxShadow: '0px 4px 25px 0px #0000001F', border: '1px solid #E7E7E7', marginTop: 2}}
        expanded={expanded === 'apiIntegration'}
        onChange={() => handleExpandChange('apiIntegration')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color: Colors.primaryTextColor}}/>}
          aria-controls="settings-content"
          id="settings-header"
          sx={{height: 40}}
        >
          <p className={GeneralStyles.darkText} style={{fontWeight: 'bold'}}>API Integration Settings</p>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{width: '100%', display: 'flex', flexDirection: 'column',position: 'relative'}}>
            <CustomTextFieldNew
              marginTop={20}
              width={'50%'}
              label={"API Transaction Key"}
              placeholder={''}
              value={token}
              onChange={null}
              disabled
            />

            <CustomTextFieldNew
              marginTop={20}
              width={'50%'}
              label={"API Endpoint"}
              placeholder={''}
              value={endpoint}
              onChange={null}
              disabled
            />
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }
  const renderWebsiteIntegrationAccordion = () => {
    let homePageUrl = `${Config.CONTRACT_URL}iframe?bus=${Config.BUSINESS}&view=dropdown&random=${Config.RANDOM}`
    let vehiclesPageUrl = `${Config.CONTRACT_URL}iframe?bus=${Config.BUSINESS}&view=list&random=${Config.RANDOM}`
    let indiVehiclesPageUrl = `${Config.CONTRACT_URL}iframe/vehicles?vehicleId=${selectedVehicle?.value}&bus=${Config.BUSINESS}&random=${Config.RANDOM}`
    let options = vehiclesList.map(vehicle => { return {label: vehicle.make, value: vehicle.id}})
    return (
      <Accordion
        sx={{boxShadow: '0px 4px 25px 0px #0000001F', border: '1px solid #E7E7E7', marginTop: 2}}
        expanded={expanded === 'websiteIntegration'}
        onChange={() => handleExpandChange('websiteIntegration')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color: Colors.primaryTextColor}}/>}
          aria-controls="settings-content"
          id="settings-header"
          sx={{height: 40}}
        >
          <p className={GeneralStyles.darkText} style={{fontWeight: 'bold'}}>Online Booking Settings</p>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', position: 'relative'}}>
            <div style={{width: '40%'}}>
              <div>
                <label style={{fontSize: CONSTANTS.entryTextSize, color: Colors.primaryTextColor}}>Payment Requirements
                  for booking:</label>
                <div style={{width: '100%', marginTop: 6}}><CustomTab tab={payReqTab} onChange={setPayReqTab}
                                                                      options={['Payment', 'No Payment']}/></div>
              </div>

              {
                payReqTab === 0 &&
                <div style={{marginTop: 20}}>
                  <label style={{fontSize: CONSTANTS.entryTextSize, color: Colors.primaryTextColor}}>Payment
                    Amount:</label>
                  <div style={{width: '100%', marginTop: 6}}><CustomTab tab={payAmountTab} onChange={setPayAmountTab}
                                                                        options={['Full Payment', 'Partial %', 'Minimum $']}/>
                  </div>
                </div>
              }

              {getPayAmountField()}

              <div style={{marginTop: 20}}>
                <label style={{fontSize: CONSTANTS.entryTextSize, color: Colors.primaryTextColor}}>Minimum Rental
                  Duration:</label>
                <CustomTextFieldNew
                  marginTop={6}
                  width={'100%'}
                  label={"Minimum Rental Duration (hours)"}
                  placeholder={'Enter minimum rental duration in hours'}
                  value={webMin}
                  onChange={(text) => {
                    if (!isNaN(text)) {
                      setWebMin(text)
                    }
                  }}
                />
              </div>

              <div style={{marginTop: 20}}>
                <label style={{fontSize: CONSTANTS.entryTextSize, color: Colors.primaryTextColor}}>Booking Grace
                  Period:</label>
                <CustomTextFieldNew
                  marginTop={6}
                  width={'100%'}
                  label={"Booking Grace Period (hours)"}
                  placeholder={'Enter minimum time before rental can start'}
                  value={webGrace}
                  onChange={(text) => {
                    if (!isNaN(text)) {
                      setWebGrace(text)
                    }
                  }}
                />
              </div>
            </div>

            <div style={{width: '50%', marginLeft: '6%'}}>
              <div>
                <label style={{fontSize: CONSTANTS.entryTextSize, color: Colors.primaryTextColor}}>Allow instant
                  bookings or require approval:</label>
                <div style={{width: '80%', marginTop: 6}}><CustomTab tab={instantBookTab} onChange={setInstantBookTab}
                                                                     options={['Instant Bookings', 'Requires Approval']}/>
                </div>
              </div>

              <div style={{marginTop: 20}}>
                <label style={{fontSize: CONSTANTS.entryTextSize, color: Colors.primaryTextColor}}>Require ID
                  Verification:</label>
                <CustomSwitch
                  onChange={setRequireId}
                  containerStyle={{marginTop: 6}}
                  checked={requireId}/>
              </div>

              <div style={{marginTop: 20}}>
                <label style={{fontSize: CONSTANTS.entryTextSize, color: Colors.primaryTextColor}}>Allow Custom
                  Delivery:</label>
                <CustomSwitch
                  onChange={setCustomDel}
                  containerStyle={{marginTop: 6}}
                  checked={customDel}/>
              </div>

              {/*{renderImageSelector()}*/}
              <div style={{marginTop: 20}}>
                <CustomTextFieldNew
                    width={'100%'}
                    label={'Sales Tax %: (Rentals)'}
                    placeholder={'Sales Tax'}
                    value={onlineSettings?.sales_tax}
                    onChange={(text) => {
                      if (isNaN(text))
                        return
                      let temp = Object.assign({}, onlineSettings)
                      temp['sales_tax'] = text
                      setOnlineSettings(temp)
                    }}
                />

              </div>

              <div style={{marginTop: 10}}>
                <CustomTextFieldNew
                    width={'100%'}
                    label={'Service Tax %: (Rentals)'}
                    placeholder={'Service Tax'}
                    value={onlineSettings?.service_tax?.rentals}
                    onChange={(text) => {
                      if (isNaN(text))
                        return
                      let temp = JSON.parse(JSON.stringify(onlineSettings))
                      console.log('tep', temp)
                      temp['service_tax']['rentals'] = text
                      setOnlineSettings(temp)
                    }}
                />
              </div>

              {/*<div style={{marginTop: 10}}>*/}
              {/*  <CustomTextFieldNew*/}
              {/*      width={'100%'}*/}
              {/*      label={'Service Tax %: (Chauffeur)'}*/}
              {/*      placeholder={'Service Tax Chauffeur'}*/}
              {/*      value={onlineSettings?.service_tax?.chauffeur}*/}
              {/*      onChange={(text) => {*/}
              {/*        if (isNaN(text))*/}
              {/*          return*/}
              {/*        let temp = JSON.parse(JSON.stringify(onlineSettings))*/}
              {/*        temp['service_tax']['chauffeur'] = text*/}
              {/*        setOnlineSettings(temp)*/}
              {/*      }}*/}
              {/*  />*/}
              {/*</div>*/}

              <div style={{marginTop: 10}}>
                <CustomTextFieldNew
                    width={'100%'}
                    label={'Additional Location Charge $: (rentals)'}
                    placeholder={'Additional Location Charge'}
                    value={onlineSettings?.location_fee}
                    onChange={(text) => {
                      if (isNaN(text))
                        return
                      let temp = Object.assign({}, onlineSettings)
                      temp['location_fee'] = text
                      setOnlineSettings(temp)
                    }}
                />
              </div>

              <div style={{marginTop: 10}}>
                <CustomTextFieldNew
                    width={'100%'}
                    label={'Card Fee %:'}
                    placeholder={'Card Fee'}
                    value={onlineSettings?.card_fee}
                    onChange={(text) => {
                      if (isNaN(text))
                        return
                      let temp = JSON.parse(JSON.stringify(onlineSettings))
                      temp['card_fee'] = text
                      setOnlineSettings(temp)
                    }}
                />
              </div>
            </div>
          </div>

          <div style={{marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <CustomButtonContained
              style={{height: 36}}
              text={`\u2713 Save Changes`}
              onClick={onSaveWebsiteInfoClicked}
            />
          </div>

          <p style={{fontSize: CONSTANTS.labelSize, color: Colors.theme, fontWeight: 'bold', marginBottom: 20}}>Iframe
            Code Snippet</p>
          <label style={{fontSize: CONSTANTS.entryTextSize, color: Colors.primaryTextColor}}>Search Bar Page:</label>
          <div style={{
            marginTop: 10,
            width: '80%',
            height: 40,
            border: '1px solid #c0c0c0',
            borderRadius: 10,
            padding: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 20
          }}>
            <p
              style={{color: Colors.secondaryTextColor}}>{homePageUrl}</p>
          </div>

          <label style={{fontSize: CONSTANTS.entryTextSize, color: Colors.primaryTextColor}}>Vehicles List Page:</label>
          <div style={{
            marginTop: 10,
            width: '80%',
            height: 40,
            border: '1px solid #c0c0c0',
            borderRadius: 10,
            padding: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 20
          }}>
            <p
              style={{color: Colors.secondaryTextColor}}>{vehiclesPageUrl}</p>
          </div>

          <label style={{fontSize: CONSTANTS.entryTextSize, color: Colors.primaryTextColor}}>Individual Vehicle Page:</label>
          <div
            className={GeneralStyles.boxShadow}
            style={{width: 300, height: 40, borderRadius: 10, marginTop: 10, marginBottom: 10}}>
            <CustomSelect
              placeholder={'select vehicle'}
              borderColor={'transparent'}
              value={selectedVehicle}
              options={options}
              onChange={setSelectedVehicle}
            />
          </div>
          {
            selectedVehicle &&
            <div style={{
              marginTop: 10,
              width: '80%',
              height: 40,
              border: '1px solid #c0c0c0',
              borderRadius: 10,
              padding: 20,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 20
            }}>
              <p
                style={{color: Colors.secondaryTextColor}}>{encodeURI(indiVehiclesPageUrl)}</p>
            </div>
          }

        </AccordionDetails>
      </Accordion>
    )
  }

  const renderIntegrationsAccordion = () => {
    let webhookUrl = `${Config.BACKEND_URL}authorize/webhook`
    return (
      <Accordion
        sx={{boxShadow: '0px 4px 25px 0px #0000001F', border: '1px solid #E7E7E7', marginTop: 1}}
        expanded={expanded === 'paymentIntegration'}
        onChange={() => handleExpandChange('paymentIntegration')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color: Colors.primaryTextColor}}/>}
          aria-controls="settings-content"
          id="settings-header"
          sx={{height: 40}}
        >
          <p className={GeneralStyles.darkText} style={{fontWeight: 'bold'}}>Authorize.net Integration</p>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{width: '30%'}}>
            <CustomTextFieldNew
              width={'100%'}
              label={'Enter Name/ Login ID'}
              placeholder={'Name / Login ID'}
              value={loginId}
              onChange={(e) => setLoginId(e)}
            />
            <div style={{marginTop: 10}}>
              <CustomTextFieldNew
                width={'100%'}
                label={'Enter Transaction Key'}
                placeholder={'Transaction Key'}
                value={transactionKey}
                onChange={(e) => setTransactionKey(e)}
              />
            </div>

            <div style={{marginTop: 10}}>
              <CustomTextFieldNew
                width={'100%'}
                label={'Enter Signature Key'}
                placeholder={'Signature Key'}
                value={signKey}
                onChange={(e) => setSignKey(e)}
              />
            </div>

            <div style={{marginTop: 10}}>
              <CustomTextFieldNew
                width={'100%'}
                label={'Webhook URL'}
                placeholder={''}
                value={webhookUrl}
                // disabled={true}
                onChange={() => null}
              />
            </div>

          </div>
          <div
            style={{marginTop: 10, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <CustomButtonContained
              style={{marginRight: 2}}
              text={'Save Changes'}
              onClick={onSaveAuthorizeIntegration}
            />
          </div>

        </AccordionDetails>
      </Accordion>
    )
  }

  const renderPriceSettings = () => {
    return (
      <div style={{width: '100%'}}>
        <Accordion
          sx={{boxShadow: '0px 4px 25px 0px #0000001F', border: '1px solid #E7E7E7', marginTop: 1}}
          expanded={expanded === 'price'}
          onChange={() => handleExpandChange('price')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{color: Colors.primaryTextColor}}/>}
            aria-controls="settings-content"
            id="settings-header"
            sx={{height: 40}}
          >
            <p className={GeneralStyles.darkText} style={{fontWeight: 'bold'}}>Pricing & Taxes</p>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', position: 'relative'}}>
              <div style={{width: '50%'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <label style={{fontSize: CONSTANTS.entryTextSize, color: Colors.primaryTextColor}}>Enable / Disable
                    Taxes & Fees</label>
                  <CustomSwitch
                    onChange={(value) => {
                      let temp = Object.assign({}, priceSettings)
                      temp['enabled'] = value
                      setPriceSettings(temp)
                    }}
                    containerStyle={{marginLeft: 30}}
                    checked={priceSettings?.enabled}/>
                </div>

                <div style={{marginTop: 10}}>
                  <CustomTextFieldNew
                    width={'100%'}
                    label={'Sales Tax %: (Rentals)'}
                    placeholder={'Sales Tax'}
                    value={priceSettings?.sales_tax}
                    onChange={(text) => {
                      if (isNaN(text))
                        return
                      let temp = Object.assign({}, priceSettings)
                      temp['sales_tax'] = text
                      setPriceSettings(temp)
                    }}
                  />

                </div>

                <div style={{marginTop: 10}}>
                  <CustomTextFieldNew
                    width={'100%'}
                    label={'Service Tax %: (Rentals)'}
                    placeholder={'Service Tax'}
                    value={priceSettings?.service_tax?.rentals}
                    onChange={(text) => {
                      if (isNaN(text))
                        return
                      let temp = JSON.parse(JSON.stringify(priceSettings))
                      temp['service_tax']['rentals'] = text
                      setPriceSettings(temp)
                    }}
                  />
                </div>

                <div style={{marginTop: 10}}>
                  <CustomTextFieldNew
                    width={'100%'}
                    label={'Service Tax %: (Chauffeur)'}
                    placeholder={'Service Tax Chauffeur'}
                    value={priceSettings?.service_tax?.chauffeur}
                    onChange={(text) => {
                      if (isNaN(text))
                        return
                      let temp = JSON.parse(JSON.stringify(priceSettings))
                      temp['service_tax']['chauffeur'] = text
                      setPriceSettings(temp)
                    }}
                  />
                </div>

                <div style={{marginTop: 10}}>
                  <CustomTextFieldNew
                    width={'100%'}
                    label={'Additional Location Charge $: (rentals)'}
                    placeholder={'Additional Location Charge'}
                    value={priceSettings?.location_fee}
                    onChange={(text) => {
                      if (isNaN(text))
                        return
                      let temp = Object.assign({}, priceSettings)
                      temp['location_fee'] = text
                      setPriceSettings(temp)
                    }}
                  />
                </div>

                <div style={{marginTop: 10}}>
                  <CustomTextFieldNew
                    width={'100%'}
                    label={'Card Fee %:'}
                    placeholder={'Card Fee'}
                    value={priceSettings?.card_fee}
                    onChange={(text) => {
                      if (isNaN(text))
                        return
                      let temp = JSON.parse(JSON.stringify(priceSettings))
                      temp['card_fee'] = text
                      setPriceSettings(temp)
                    }}
                  />
                </div>
              </div>

              <div style={{width: '50%', marginLeft: 40, height: '25vh', overflowY: 'scroll'}}>
                {renderDiscounts()}
              </div>

              <div style={{position: 'absolute', right: 0, bottom: 0}}>
                <CustomButtonContained
                  style={{marginRight: 2}}
                  text={'Save Changes'}
                  onClick={onSavePriceClicked}
                />
              </div>
            </div>

          </AccordionDetails>
        </Accordion>
      </div>
    )
  }

  const handlePhoneChange = (text) => {
    setModalUser(Object.assign({}, modalUser, {phone: Helpers.checkPhoneNumberFormat(text)}))
  }

  const renderUserModal = () => {
    let options = []
    let currentRole

    roles.forEach(role => {
      options.push({label: role.name, value: role.id})
      if (role.id === modalUser.role)
        currentRole = role
    })

    let roleValue = null
    if (modalUser.role)
      roleValue = {value: modalUser.role, label: currentRole?.name}
    return (
      <CustomModal
        show={showUserModal}
        handleClose={() => setShowUserModal(false)}
        containerWidth={window.innerWidth / 2.2}
        containerHeight={window.innerHeight / 1.2}
      >
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%', margin: '0 auto'}}>
          <p style={{
            textAlign: 'center',
            width: '100%',
            fontWeight: 'bold',
            marginBottom: 20,
            fontSize: CONSTANTS.headerSize
          }}>Add User & Assign Role</p>
          <label style={{
            color: Colors.primaryTextColor,
            fontSize: CONSTANTS.entryTextSize,
            width: '100%',
            marginBottom: 10,
            fontWeight: '600'
          }}>Profile Information</label>
          <div style={{width: '100%', marginBottom: 14}}>
            <CustomTextFieldNew
              width={'100%'}
              label={'Full Name'}
              placeholder={'First & Last Name'}
              value={modalUser.name}
              onChange={(text) => {
                let temp = Object.assign({}, modalUser, {name: text})
                setModalUser(temp)
              }}
            />
          </div>
          <div style={{width: '100%', marginBottom: 14}}>
            <CustomTextFieldNew
              width={'100%'}
              label={'Phone'}
              placeholder={'Phone Number'}
              value={modalUser.phone}
              onChange={handlePhoneChange}
            />
          </div>
          <div style={{width: '100%', marginBottom: 14}}>
            <CustomTextFieldNew
              width={'100%'}
              label={'Email'}
              placeholder={'Email Address'}
              value={modalUser.email}
              onChange={(text) => {
                let temp = Object.assign({}, modalUser, {email: text})
                setModalUser(temp)
              }}
            />
          </div>
          <div style={{width: '100%', marginBottom: 14}}>
            <CustomTextFieldNew
              hidden={true}
              width={'100%'}
              label={'Password'}
              placeholder={'Temporary Password'}
              value={pwd}
              onChange={setPwd}
            />
          </div>

          <label style={{
            color: Colors.primaryTextColor,
            fontSize: CONSTANTS.entryTextSize,
            width: '100%',
            marginTop: 20,
            marginBottom: 10,
            fontWeight: '600'
          }}>Assign Role</label>
          <div
            className={GeneralStyles.boxShadow}
            style={{width: '100%', height: 40, borderRadius: 10, marginTop: 4}}>
            <CustomSelect
              placeholder={'select'}
              borderColor={'transparent'}
              value={roleValue}
              options={options}
              onChange={(selected) => {
                let temp = Object.assign({}, modalUser, {role: selected.value})
                setModalUser(temp)
              }}
            />

          </div>
        </div>


        <div style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          bottom: 10
        }}>
          <CustomButtonContained
            style={{}}
            text={`\u2713 Add User`}
            onClick={onAddPersonConfirmed}
          />
        </div>
      </CustomModal>
    )
  }

  const renderContent = () => {
    return (
      <div style={{width: '100%', paddingBottom: 200}}>
        {/*{renderSalesPeople()}*/}
        <label
          className={GeneralStyles.darkText}
          style={{fontSize: CONSTANTS.headerSize, fontWeight: 'bold'}}>Account Settings</label>
        {renderAccountSettings()}
        {renderCompanyInfo()}

        <div style={{marginTop: 30}}/>
        <label
          className={GeneralStyles.darkText}
          style={{fontSize: CONSTANTS.headerSize, fontWeight: 'bold'}}>System Settings</label>
        {renderPriceSettings()}

        <div style={{marginTop: 30}}/>
        <label
          className={GeneralStyles.darkText}
          style={{fontSize: CONSTANTS.headerSize, fontWeight: 'bold'}}>Integrations</label>
        {renderIntegrationsAccordion()}
        {renderWebsiteIntegrationAccordion()}
        {renderApiIntegration()}

      </div>
    )
  }

  if (loading || loading2) {
    return (
      <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
        <CustomLoaderSpinner/>
      </div>
    )
  }

  return (
    <div className={GeneralStyles.container}>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
        <PageHeader header={'Settings'}/>
      </div>

      <PositionedSnackbar
        onClose={() => {
          setShowError(false)
          setShowSuccess(false)
          setMessage('')
        }}
        severity={showSuccess ? 'success' : 'error'}
        openFlag={showError || showSuccess}
        message={message}
      />

      {renderContent()}

      <CustomModal
        show={showDiscountModal}
        handleClose={() => {
          setShowDiscountModal(false)
          setDiscountModalDetails({name: '', discount: ''})
        }}
        containerWidth={window.innerWidth / 3}
        containerHeight={300}
      >
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <p style={{textAlign: 'center', width: '100%', fontWeight: 'bold', marginBottom: 20}}>Add Discount Code</p>
          <div style={{width: '80%'}}>
            <CustomTextFieldNew
              width={'100%'}
              label={'Discount Name'}
              placeholder={'Discount Display Name'}
              value={discountModalDetails.name}
              onChange={(text) => {
                let temp = Object.assign({}, discountModalDetails)
                temp['name'] = text
                setDiscountModalDetails(temp)
              }}
            />
          </div>

          <div style={{width: '80%', marginTop: 20}}>
            <CustomTextFieldNew
              width={'100%'}
              label={'Discount Percentage'}
              placeholder={'Discount Percentage'}
              value={discountModalDetails.discount}
              onChange={(text) => {
                if (isNaN(text))
                  return
                let temp = Object.assign({}, discountModalDetails)
                temp['discount'] = text
                setDiscountModalDetails(temp)
              }}
            />

          </div>
        </div>

        <div style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          bottom: 10
        }}>
          <CustomButtonContained
            style={{}}
            text={`\u2713 Add Discount Code`}
            onClick={onAddDiscountClicked}
          />
        </div>

      </CustomModal>

      {renderUserModal()}
    </div>
  )
}

const Tab = ({tab, onChange}) => {
  return (
    <div style={{
      width: '90%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 10,
      border: '1px solid gray',
      padding: 4,
      backgroundColor: Colors.theme
    }}>
      <div
        style={{
          height: 30,
          cursor: 'pointer',
          width: '50%',
          backgroundColor: tab === 'users' ? Colors.tabButtonTheme : 'transparent',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          borderRadius: 5,
          alignItems: 'center'
        }}
        onClick={() => onChange('users')}>
        <p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>User Accounts</p>
      </div>
      <div
        style={{
          height: 30,
          cursor: 'pointer',
          width: '50%',
          backgroundColor: tab === 'perms' ? Colors.tabButtonTheme : 'transparent',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          borderRadius: 5,
          alignItems: 'center'
        }}
        onClick={() => onChange('perms')}>
        <p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Roles & Perms</p>
      </div>
    </div>
  )
}

const CustomTab = ({tab, onChange, options}) => {
  return (
    <div style={{
      width: '90%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 10,
      border: '1px solid gray',
      padding: 4,
      backgroundColor: Colors.theme
    }}>
      {
        options.map((option, index) => (
          <div
            key={index.toString()}
            style={{
              height: 30,
              cursor: 'pointer',
              width: '50%',
              backgroundColor: tab === index ? Colors.tabButtonTheme : 'transparent',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              borderRadius: 5,
              alignItems: 'center'
            }}
            onClick={() => onChange(index)}>
            <p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>{option}</p>
          </div>
        ))
      }

    </div>
  )
}

export default Settings
