import React,{useState, useEffect} from 'react';
import Colors from '../../config/colors'
import GeneralStyles from '../../routes/GeneralStyles.module.scss'
import Config from "../../config";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

export default function CustomGooglePlacesNew({label, onChange, value, placeholder, disabled, width, marginTop, height, backgroundColor, hidden}) {
	const customStyles = {
		control: (base, state) => ({
			...base,
			background: "#00000000",
			// match with the menu
			borderRadius: 10,
			// Overwrittes the different states of border
			borderColor: 'transparent' || Colors.secondaryTextColor ,
			width: '100%',
			marginTop: marginTop || 0,
			position: 'relative',
			left: -10,
			// Removes weird border around container
			boxShadow: null,
			"&:hover": {
				// Overwrittes the different states of border
				borderColor: Colors.tableLineColor
			}
		}),
		menu: base => ({
			...base,
			// override border radius to match the box
			borderRadius: 0,
			// kill the gap
			marginTop: 0,
		}),
		menuList: base => ({
			...base,
			// kill the white space on first and last option
			padding: 0,
		}),
		singleValue: base => ({
			...base,
			color: Colors.primaryTextColor
		}),
		input: base => ({
			...base,
			color: Colors.primaryTextColor
		}),
	};

	return (
		<div
			className={GeneralStyles.boxShadow}
			style={{paddingLeft: 10,paddingRight: 4,width: width || '100%', marginTop: marginTop || 0, borderRadius: 10}}>
			<label style={{color: Colors.primaryTextColor, fontWeight: '600', fontSize: 14}}>{label}</label>
			<GooglePlacesAutocomplete
				apiKey={Config.GOOGLE_API_KEY}
				placeholder='search address...'
				selectProps={{
					placeholder: placeholder || 'select',
					value: value,
					onChange: onChange,
					styles: customStyles,
					theme: (theme) => ({
						...theme,
						colors: {
							...theme.colors,
							primary25: '#5481CB33',
							primary: '#5481CB',
						},
					})
				}}
				onPress={(data, details = null) => {
					// 'details' is provided when fetchDetails = true
					console.log(data, details);
				}}
			/>
		</div>
	)
}

