import React, {useState, useEffect} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import PageHeader from '../../components/general/PageHeader'
import moment from 'moment'
import Styles from './styles/Reservations.module.scss'
import Colors from '../../config/colors'
import {useSelector} from 'react-redux'
import {vehiclesMapSelector} from "../../redux/slices/vehiclesSlice";
import {updateReservation, deleteReservation, setDeletedReservation} from '../../redux/slices/reservationsSlice'
import CustomButtonContained from "../../components/general/CustomButtonContained";
import {useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import Fuse from 'fuse.js'
import CustomTextFieldNative from "../../components/general/CustomTextFieldNative";
import {API_CALL_STATUS, RENTAL_STATUS} from "../../metadata/enums";
import SearchIcon from '../../assets/logos/searchIcon.png'
import Constants from '../../config/constants'
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";
import Helpers from "../../utils/helpers";
import {changeUserAccess} from "../../redux/slices/authSlice";
import {clientsMapSelector} from "../../redux/slices/clientsSlice";

const Reservations = () => {
	let navigate = useNavigate()
	let dispatch = useDispatch()

	const mongoUser = useSelector(state => state.user.mongoUser)
	const settingsState = useSelector(state => state.settings)
	const token = useSelector(state => state.auth.token)
	const reservations = useSelector(state => state.reservations)
	const vehiclesMap = useSelector(state => vehiclesMapSelector(state))
	const clientsState = useSelector(state => state.clients)

	const [showLoading, setShowLoading] = useState(true)
	const [tab, setTab] = useState(0)
	const [showSuccessMessage,setShowSuccessMessage] = useState(false)
	const [successMessage, setSuccessMessage] = useState('')
	const [oldReservations, setOldReservations] = useState([])
	const [toDisplayReservations, setToDisplayReservations] = useState([])
	const [upcomingReservations, setUpcomingReservations] = useState([])
	const [allReservations, setAllReservations] = useState([])
	const [searchString, setSearchString] = useState('')
	const [fuse, setFuse] = useState(null)
	const [showOverlay, setShowOverlay] = useState(false)


	useEffect(() => {
		if(reservations.status === API_CALL_STATUS.SUCCEEDED) {
			setShowLoading(false)
			setUpcomingReservations(reservations.reservations)
			setToDisplayReservations(reservations.reservations)
			setOldReservations(reservations.old_reservations)
			setAllReservations(reservations.reservations.concat(reservations.old_reservations))

			if(reservations.deletedReservation) {
				setSuccessMessage('Successfully deleted the reservation')
				setShowSuccessMessage(true)
				dispatch(setDeletedReservation(false))
			}
		} else {
			setShowLoading(true)
		}
	},[reservations])

	useEffect(() => {
		if(clientsState.status === API_CALL_STATUS.LOADING || !allReservations || allReservations.length === 0)
			return

		let clientsMap = clientsMapSelector(clientsState)
		let reservationsWithCustomer = allReservations.map(res => {
			let {fName, lName, email, phone, address} = clientsMap[res.client] || {}
			return Object.assign({}, res, {fName, lName, email, phone, address})
		})

		let temp = new Fuse(reservationsWithCustomer, {
			keys: ['vehicle_id', 'fName', 'lName', 'email', '_id']
		})
		setFuse(temp)
	},[allReservations, clientsState])

	const onNewReservationClicked = () => {
		if(!Helpers.checkAccess(mongoUser, Helpers.getRolesMap(settingsState), 'create_reservations')) {
			dispatch(changeUserAccess(false))
		} else {
			if(showOverlay) {
				setShowOverlay(false)
				document.getElementById("overlay").style.display = "none";
			} else {
				setShowOverlay(true)
				document.getElementById("overlay").style.display = "block";
			}
		}
	}

	const onNewNavigatorClicked = (key) => {
		dispatch(updateReservation(null))
		setShowOverlay(false)
		document.getElementById("overlay").style.display = "none";
		if(key === 'reservation') {
			navigate('/home/new-reservation')
		} else {
			navigate('/home/new-chauffeur')
		}
	}

	const onDeleteReservationClicked = reservation => {
		if(!Helpers.checkAccess(mongoUser, Helpers.getRolesMap(settingsState), 'delete_reservations')) {
			dispatch(changeUserAccess(false))
		} else {
			if(window.confirm(`Are you sure you want to delete the reservation #${reservation._id}`)) {
				dispatch(deleteReservation({token: token, id: reservation._id}))
			}
		}
	}

	const onSearchStringChanged = (text) => {
		setSearchString(text)
		if(text.length === 0)
			return
		let results = fuse.search(text)
		let array = results.map(temp => temp.item)
		setToDisplayReservations(array)
	}

	const onReservationClicked = (reservation) => {
		if(!Helpers.checkAccess(mongoUser, Helpers.getRolesMap(settingsState), 'view_reservations')) {
			dispatch(changeUserAccess(false))
		} else {
			dispatch(updateReservation(reservation))
			if(reservation.isChauffeur) {
				navigate('/home/new-chauffeur')
			} else {
				navigate('/home/new-reservation')
			}
		}
	}

	function getRentalStatusString(value) {
		switch(value) {
			case 1 : return 'waiting pickup'
			case 2 : return 'renting'
			case 3 : return 'returned'
			case 4 : return 'cancelled'
			default : return ''
		}
	}

	const renderReservationRow = (reservation,index) => {
		let clientsMap = clientsMapSelector(clientsState)
		let {fName, lName} = clientsMap[reservation.client] || {}
		let name = `${fName} ${lName}`
		return (
			<div
				style={{borderBottom: `0.5px solid ${Colors.tableLineColor}`}}
				className={Styles.tableRow}
				key={index.toString()}>
				<p
					onClick={() => onReservationClicked(reservation)}
					className={Styles.tableRowEntry} style={{width: '9%', overflowWrap: 'break-word', marginRight: 14}}>Res #{reservation._id}</p>
				<p
					onClick={() => onReservationClicked(reservation)}
					className={Styles.tableRowEntry}  style={{ width: '21%', marginRight: 14}}>{vehiclesMap[reservation.vehicle_id]?.make}</p>
				<p
					onClick={() => onReservationClicked(reservation)}
					className={Styles.tableRowEntry}  style={{width: '19%', marginRight: 14}}>{name}</p>
				<p
					onClick={() => onReservationClicked(reservation)}
					className={Styles.tableRowEntry}  style={{ width: '19%', marginRight: 14}}>{moment(reservation.pickup_datetime).format('MM/DD/YY : hh:mm A')}</p>
				<p
					onClick={() => onReservationClicked(reservation)}
					className={Styles.tableRowEntry} style={{width: '19%', marginRight: 14}}>{moment(reservation.dropoff_datetime).format('MM/DD/YY : hh:mm A')}</p>
				<p
					onClick={() => onReservationClicked(reservation)}
					className={Styles.tableRowEntry} style={{width: '11%'}}>{reservation.isChauffeur ? 'Chauffeur Service' : typeof reservation.pickup_location === 'string' ? 'Company HQ' : 'Delivery'}</p>
				{
					!reservation.deleted &&
					<DeleteIcon
						onClick={() => onDeleteReservationClicked(reservation)}
						style={{cursor: 'pointer',color: Colors.theme, height: 20, width: 20}}/>
				}
			</div>
		)

	}

	const renderReservations = () => {
		let temp = []
		if(searchString && searchString.length > 0) {
			//if the user is searching, show the search results
			temp = toDisplayReservations
		} else {
			switch(tab) {
				case 0:
					temp = upcomingReservations
					break
				case 1:
					temp = oldReservations
					break
				case 2:
					temp = allReservations
					break
				default:
					temp = upcomingReservations
			}
		}

		let rows = []
		temp.forEach((reservation,index) => {
			let shouldPush = false
			if(!searchString || searchString.length === 0) {
				//if there is no search string entered, display based on tab
				if(tab === 2) {
					if(reservation.deleted)
						shouldPush = true
				} else {
					if(!reservation.deleted)
						shouldPush = true

				}
			} else {
				shouldPush = true
			}

			if(shouldPush)
				rows.push(renderReservationRow(reservation,index))

		})

		return (
			<div style={{width: '100%'}}>
				<div className={Styles.reservationsContainer}>
					<div className={Styles.tableRow}>
						<p style={{width: '9%', marginRight: 14}} className={Styles.tableHeader}>Res #</p>
						<p style={{width: '21%', marginRight: 14}} className={Styles.tableHeader}>Vehicle</p>
						<p style={{ width: '19%', marginRight: 14}} className={Styles.tableHeader}>Customer</p>
						<p style={{ width: '19%', marginRight: 14}} className={Styles.tableHeader}>Pick-Up Date/Time</p>
						<p style={{ width: '19%', marginRight: 14}} className={Styles.tableHeader}>Return Date/Time</p>
						<p style={{ width: '13%'}} className={Styles.tableHeader}>Location</p>
					</div>
					{rows}
				</div>
			</div>

		)
	}

	const renderFilterRow = () => {
		return (
			<div style={{width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
				<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
					<div style={{width: '62%', backgroundColor: Colors.theme, borderRadius: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 20}}>
						<img src={SearchIcon} style={{height: 16, width: 16}}/>
						<CustomTextFieldNative
							containerStyle={{width: '100%'}}
							height={36}
							label={'search'}
							placeholder={'Search the reservation'}
							value={searchString}
							onChange={onSearchStringChanged}/>
					</div>

					<div style={{width: '38%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
						<Tab tab={tab} onChange={(value) => setTab(value)}/>
					</div>
				</div>
			</div>
		)
	}

	if(showLoading){
		return (
			<div className={GeneralStyles.container} style={{height: '90vh', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
				<CustomLoaderSpinner />
			</div>
		)
	}

	return (
		<div className={GeneralStyles.container}>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
				<PageHeader header={'Reservations'} subHeader={`${moment().format('dddd, MMM Do')}`}/>
				<PositionedSnackbar
					onClose={() => {
						setShowSuccessMessage(false)
						setSuccessMessage('')
					}}
					severity={showSuccessMessage ? 'success' : 'error'}
					openFlag={showSuccessMessage}
					message={successMessage}
				/>
				<CustomButtonContained
					text={'+ New Reservation'}
					onClick={onNewReservationClicked}
					style={{position: 'absolute', right: 0, zIndex: 10}}
					color={showOverlay ? 'white' : 'primary'}
				/>
			</div>

			<div className={GeneralStyles.overlay} id={'overlay'} />

			{
				showOverlay &&
				<div style={{position: 'absolute', right: 20, top: 80, backgroundColor: Colors.theme, height: 110, width: 220, borderRadius: 10, zIndex: 10}}>
					<div
						onClick={() => onNewNavigatorClicked('reservation')}
						style={{paddingLeft: 10,display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: `1px solid ${Colors.tertiaryTextColor}`, cursor: 'pointer'}}>
						<p style={{width: 180, color: Colors.tertiaryTextColor}}>Daily Rentals</p>
						<p style={{color: Colors.tertiaryTextColor}}>{`>`}</p>
					</div>
					<div
						onClick={() => onNewNavigatorClicked('chauffeur')}
						style={{paddingLeft: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer'}}>
						<p style={{width: 180, color: Colors.tertiaryTextColor}}>Chauffeur Service</p>
						<p style={{color: Colors.tertiaryTextColor}}>{`>`}</p>
					</div>
				</div>
			}

			{renderFilterRow()}
			{renderReservations()}
		</div>
	)
}

const Tab = ({tab, onChange}) => {
	return (
		<div style={{width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: 10, border: '1px solid gray', padding: 4, backgroundColor: Colors.theme, borderColor: Colors.theme}}>
			<div
				className={Styles.tabEntry}
				style={{backgroundColor: tab === 0 ? Colors.tabButtonTheme : 'transparent',}}
				onClick={() => onChange(0)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Upcoming</p>
			</div>
			<div
				className={Styles.tabEntry}
				style={{backgroundColor: tab === 1 ? Colors.tabButtonTheme : 'transparent',}}
				onClick={() => onChange(1)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Completed</p>
			</div>
			<div
				className={Styles.tabEntry}
				style={{backgroundColor: tab === 2 ? Colors.tabButtonTheme : 'transparent',}}
				onClick={() => onChange(2)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Deleted</p>
			</div>
		</div>
	)
}

export default Reservations
