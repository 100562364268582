import Config from '../config'
import moment from 'moment'
import axios from "axios";
import {LOGGER} from "./Logger";

const functions = {
  getPaymentsForRes: function (token, resId) {
    return new Promise((resolve, reject) => {
      const url = `${Config.BACKEND_URL}payments?resId=${resId}`;
      const config = {
        method: 'get',
        url,
        headers: {Authorization: token, contentType: 'application/json'},
      };
      axios(config)
        .then((res) => {
          console.log('got payments for ', resId)
          resolve(res.data)
        })
        .catch((err) => {
          LOGGER.error('error when getting payments for reservation', err);
          reject(err)
        });
    })
  }

}

export default functions
