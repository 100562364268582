import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {API_CALL_STATUS} from '../../metadata/enums'
import {GET_ALL_USERS, UPDATE_USER, ADD_NEW_USER} from '../actionTypes'
import Config from "../../config";
import axios from "axios";
import {LOGGER} from "../../utils/Logger";
import {getPermissionSettings} from './settingsSlice'

const initialState = {
  user: null, //this is only the firebase user with uid and email
  mongoUser: null, // this is the mongo user with all other details
  users: [],
  updated_user: false,
  new_user: false,
  status: API_CALL_STATUS.IDLE,
  error: null
}

export const addUserAndConfirm = createAsyncThunk(ADD_NEW_USER, async (payload) => {
  const config = {
    method: 'post',
    url: `${Config.BACKEND_URL}users`,
    headers: {Authorization: payload.token},
    data: payload.data
  }
  try {
    let res = await axios(config)
    let {_id, uid} = res.data
    let temp = JSON.parse(JSON.stringify(payload.data))
    delete temp['password']
    return Object.assign({}, temp, {_id: _id, uid: uid})
  } catch (err) {
    LOGGER.error('Error when getting all users', err)
    return []
  }

})

export const getAllUsers = createAsyncThunk(GET_ALL_USERS, async (payload) => {
  const config = {
    method: 'get',
    url: `${Config.BACKEND_URL}users`,
    headers: {Authorization: payload.token},
  }

  try {
    let res = await axios(config)
    return res.data
  } catch (err) {
    LOGGER.error('Error when getting all users', err)
    return []
  }

})

export const updateUserAndConfirm = createAsyncThunk(UPDATE_USER, async (payload) => {
  try {
    let id = payload.data._id

    const config = {
      method: 'put',
      url: `${Config.BACKEND_URL}users/${id}`,
      headers: {Authorization: payload.token, contentType: "application/json",},
      data: payload.data
    }

    await axios(config)
    return payload.data
  } catch (err) {
    LOGGER.log('error when updating user', err)
  }

  // return order
})

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser(state, action) {
      return Object.assign({}, state, {user: action.payload})
    },
    addMongoUser(state, action) {
      return Object.assign({}, state, {mongoUser: action.payload})
    },
    updateUserUpdatedStatus(state, action) {
      return Object.assign({}, state, {updated_user: action.payload})
    },
    updateNewUserStatus(state, action) {
      return Object.assign({}, state, {new_user: action.payload})
    }
  },
  extraReducers(builder) {
    builder
      .addCase(addUserAndConfirm.rejected, (state, action) => {
        state.status = API_CALL_STATUS.FAILED
        state.error = action.error.message
      })
      .addCase(addUserAndConfirm.pending, (state, action) => {
        state.status = API_CALL_STATUS.LOADING
      })
      .addCase(addUserAndConfirm.fulfilled, (state, action) => {
        state.status = API_CALL_STATUS.SUCCEEDED
        state.users.push(action.payload)
        state.new_user = true
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.status = API_CALL_STATUS.FAILED
        state.error = action.error.message
      })
      .addCase(getAllUsers.pending, (state, action) => {
        state.status = API_CALL_STATUS.LOADING
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.status = API_CALL_STATUS.SUCCEEDED
        state.users = action.payload
      })
      .addCase(updateUserAndConfirm.pending, (state, action) => {
        state.status = API_CALL_STATUS.LOADING
      })
      .addCase(updateUserAndConfirm.fulfilled, (state, action) => {
        let temp = []
        state.users.forEach(user => {
          if (user._id === action.payload._id)
            temp.push(action.payload)
          else
            temp.push(user)
        })

        state.users = temp
        state.status = API_CALL_STATUS.SUCCEEDED
        state.updated_user = true
      })
      .addCase(updateUserAndConfirm.rejected, (state, action) => {
        state.status = API_CALL_STATUS.FAILED
        state.error = action.error.message
      })
  }
})

export const getSalesUsers = (state) => {
  let users = state.user.users
  let permissionSettings = getPermissionSettings(state.settings)
  let salesRoleId
  permissionSettings.roles.forEach(role => {
    if (role.name === 'Sales Associate')
      salesRoleId = role.id
  })

  return users.filter(user => user.role === salesRoleId)
}


export const getUsersUidMap = (usersState) => {
  const map = {};
  usersState.users.forEach((user) => map[user.uid] = user);
  return map;
};


// export const asyncUserAction = amount => dispatch => {
// 	console.log('thunk called', amount)
// 	setTimeout(() => {
// 		console.log('dispatched after timeout')
// 		dispatch(userSlice.actions.testAdded(amount))
// 	}, 1000)
// }

export const {addUser, updateUserUpdatedStatus, updateNewUserStatus, addMongoUser} = userSlice.actions

export default userSlice.reducer
