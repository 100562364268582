import React, {useState, useEffect} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import PageHeader from "../../components/general/PageHeader";
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import {useDispatch, useSelector} from "react-redux";
import {API_CALL_STATUS, MAINTENANCE_STATUS} from "../../metadata/enums";
import {LOGGER} from "../../utils/Logger";
import moment from "moment";
import Styles from "./styles/NewMaintenance.module.scss";
import Colors from "../../config/colors";
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";
import CONSTANTS from "../../config/constants";
import GlobalStyles from "../GeneralStyles.module.scss";
import CustomSelect from "../../components/general/CustomSelect";
import {Calendar, DateRangePicker} from "react-date-range";
import BackArrow from "../../assets/logos/backArrow.svg";
import CustomButtonContained from '../../components/general/CustomButtonContained'
import {updateMaintenance, vehiclesMapSelector, updateMaintenanceToUpdate, addNewMaintenance} from "../../redux/slices/vehiclesSlice";
import {useNavigate} from "react-router-dom";
import CalendarIcon from "../../assets/logos/calendar.png";
import CustomModal from "../../components/general/CustomModal";
import CustomTextArea from "../../components/general/CustomTextArea";
import CustomTextFieldNew from "../../components/general/CustomTextFieldNew";
import Config from "../../config";
import axios from "axios";


const NewMaintenance = () => {
	let navigate = useNavigate()
	let dispatch = useDispatch()

	const token = useSelector(state => state.auth.token)
	let vehiclesState = useSelector(state => state.vehicles)
	const vehiclesMap = useSelector(state => vehiclesMapSelector(state))

	const [loading, setLoading] = useState(true)
	const [showSuccess, setShowSuccess] = useState(false)
	const [successMessage, setSuccessMessage] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [showError, setShowError] = useState(false)
	const [selectedVehicle, setSelectedVehicle] = useState(null)
	const [startDate, setStartDate] = useState(moment().toDate())
	const [endDate, setEndDate] = useState(moment().add(3, 'days').toDate())
	const [desc, setDesc] = useState('')
	const [cost, setCost] = useState('')
	const [status, setStatus] = useState(null)
	const [showDateSelector, setShowDateSelector] = useState(false)
	const [dateType, setDateType] = useState(null)


	useEffect(() => {
		if(vehiclesState.status === API_CALL_STATUS.LOADING) {
			setLoading(true)
		} else if(vehiclesState.status === API_CALL_STATUS.FAILED) {
			setLoading(true)
			setShowError(true)
			setErrorMessage('something went wrong when getting the vehicles data')
			LOGGER.error('error effect', vehiclesState.error)
		} else {
			setLoading(false)
			if(vehiclesState.maintenance_to_update) {
				let data = vehiclesState.maintenance_to_update
				let selectedVehicle = vehiclesMap[data.vehicle_id]
				setSelectedVehicle({label: selectedVehicle.make, value: selectedVehicle.id})
				setStartDate(moment(data.start_date).toDate())
				setEndDate(moment(data.end_date).toDate())
				setDesc(data.description)
				setCost(data.cost)
			}
		}
	},[vehiclesState])

	const validateData = () => {
		if(!selectedVehicle || !selectedVehicle.value) {
			setErrorMessage('Please select a vehicle from dropdown')
			return false
		}

		if(!startDate) {
			setErrorMessage('Please select the maintenance start date')
			return false
		}

		if(!endDate) {
			setErrorMessage('Please select the maintenance end date')
			return false
		}

		if(moment(startDate).isAfter(moment(endDate))) {
			setErrorMessage('Start date must be before end date')
			return false
		}

		if(!desc || desc.length === 0) {
			setErrorMessage('Please enter a description for the entry')
			return false
		}

		return true
	}

	const onAddEntryPressed = (forceUpdate) => {
		if(!validateData()) {
			setShowError(true)
			return
		}

		setLoading(true)

		let toSave = {
			vehicle_id: selectedVehicle.value,
			start_date: startDate.toISOString(),
			end_date: endDate.toISOString(),
			description: desc,
			cost: cost && cost.length > 0 ? Number(cost) : 0,
			status: MAINTENANCE_STATUS.CREATED
		}


		let url
		if(vehiclesState.maintenance_to_update)
			url = `${Config.BACKEND_URL}maintenances/${vehiclesState.maintenance_to_update._id}`
		else
			url = `${Config.BACKEND_URL}maintenances`

		const config = {
			method: vehiclesState.maintenance_to_update ? 'put' : 'post',
			url: url,
			headers: { Authorization: token, contentType: "application/json", force_update: forceUpdate ? 'true' : 'false'},
			data: toSave
		}

		axios(config)
			.then(res => {
				handleApiCallback(toSave, res.data)
			}).catch(err => {
				LOGGER.error('error when saving new entry', err)
				setErrorMessage('Something went wrong. Please contact support if the problem persists.')
				setShowError(true)
		})
	}


	const handleApiCallback = (toSave, data) => {
		if(data.success) {
			let temp
			if(data._id) {
				temp = Object.assign({}, toSave, {_id: data._id})
				setSuccessMessage('Successfully added new maintenance entry')
				dispatch(addNewMaintenance(temp))
			} else {
				temp = toSave
				setSuccessMessage('Successfully updated maintenance entry')
				dispatch(updateMaintenance(temp))
			}

			dispatch(updateMaintenanceToUpdate(temp))
			setLoading(false)
			setShowError(false)
			setShowSuccess(true)
		} else {
			//there are blocking reservations, handle accordingly
			setLoading(false)
			let message
			let {blocking_reservations} = data
			if(blocking_reservations.length === 1) {
				let data = blocking_reservations[0]
				message = `${selectedVehicle.label} has been rented from ${moment(data?.pickup_datetime).format('MMM Do YYYY')} to ${moment(data?.dropoff_datetime)} with the reservation ID #${data._id}`
			} else {
				message = `There are more than 1 reservations with overlapping dates : ${blocking_reservations.map((temp,index) => `#${temp._id}${index === blocking_reservations.length-1 ? '': ', '}`)}`
			}

			if(window.confirm(`${message}. Are you sure you want to create this overlapping maintenance entry?`)) {
				//if the user ackowledges overlappping reservations, let them do what ever they want bro.
				//you can only take the horse to the lake, but you cannot make it drink the water from it.
				onAddEntryPressed(true)
			} else {
				setErrorMessage('Maintenance entry was not created for this vehicle')
				setShowError(true)
			}

		}
	}

	const onCloseAddNewPressed = () => {
		setSelectedVehicle(null)
		setStartDate(moment().toDate())
		setEndDate(moment().add(3, 'days').toDate())
		setDesc('')
		setCost('')
		setStatus(null)
		dispatch(updateMaintenanceToUpdate(null))
		navigate('/home/vehicles')
	}

	const renderContent = () => {
		let allVehicles = vehiclesState.vehicles || []
		let options = allVehicles.map(temp => {
			return {label: temp.make, value: temp.id}
		})

		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '60%', padding: '20px 0px'}}>
				<div className={Styles.entryRow}>
					<div
						className={GeneralStyles.boxShadow}
						style={{width: '100%', height: 40, borderRadius: 10}}>
						<CustomSelect
							placeholder={'select vehicle'}
							borderColor={'transparent'}
							value={selectedVehicle}
							options={options}
							onChange={setSelectedVehicle}
						/>
					</div>
				</div>

				<div className={Styles.entryRow} style={{marginTop: 40, width: '100%'}}>
					<div style={{width: '46%'}}>
						{
							renderFieldWithLogo(
								'Start Date',
								moment(startDate).format('MMM Do YYYY'),
								() => {
									setDateType('start')
									setShowDateSelector(true)
								})
						}
					</div>
					<div style={{width: '46%', marginLeft: '6%'}}>
						{
							renderFieldWithLogo(
								'End Date',
								moment(endDate).format('MMM Do YYYY'),
								() => {
									setDateType('end')
									setShowDateSelector(true)
								})
						}
					</div>

				</div>

				<div className={Styles.entryRow} style={{marginTop: 40, width: '100%'}}>
					<CustomTextArea
						label={'Maintenance Description'}
						value={desc}
						onChange={setDesc}
					/>
				</div>

				<div className={Styles.entryRow} style={{marginTop: 40, width: '100%'}}>
					<CustomTextFieldNew
						width={'100%'}
						label={'Cost ($)'}
						placeholder={'Maintenance Cost'}
						value={cost}
						onChange={(value) => {
							if(isNaN(value))
								return
							setCost(value)
						}}
					/>
				</div>



			</div>
		)
	}

	const renderFieldWithLogo = (field, value, onClick) => {
		return (
			<div
				onClick={onClick}
				className={GeneralStyles.boxShadow}
				style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', height: 50, paddingLeft: 10}}>
				<img src={CalendarIcon} style={{height: 20, width: 20}}/>
				<div style={{marginLeft: 10, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
					<label style={{fontSize: 14, fontWeight: 'bold', color: Colors.primaryTextColor}}>{field}</label>
					<label style={{fontSize: 17, color: Colors.primaryTextColor}}>{value}</label>
				</div>
			</div>
		)
	}

	if(loading) {
		return (
			<div className={GeneralStyles.container} style={{height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
				<CustomLoaderSpinner />
			</div>
		)
	}

	const renderDateSelectorModal = () => {
		return (
			<CustomModal
				show={showDateSelector}
				handleClose={() => setShowDateSelector(false)}
				containerWidth={window.innerWidth/1.5}
				containerHeight={window.innerHeight/1.5}
			>
				<div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: Colors.backgroundColor}}>
					<Calendar
						date={dateType === 'start' ? startDate : endDate}
						onChange={(date) => {
							if(dateType === 'start')
								setStartDate(date)
							else
								setEndDate(date)
						}}
					/>

					<CustomButtonContained
						text={'Done'}
						onClick={() => {
							setShowDateSelector(false)
						}}
						style={{marginTop: 3}}
					/>

				</div>
			</CustomModal>
		)
	}

	const getSubHeader = () => {
		if(vehiclesState.maintenance_to_update) {
			let {status, created_at} = vehiclesState.maintenance_to_update
			let statusText
			if(status === MAINTENANCE_STATUS.CREATED)
				statusText = 'Open'
			else if (status === MAINTENANCE_STATUS.COMPLETED)
				statusText = 'Completed'

			return `Created - ${moment(created_at).format('MMM Do YYYY')} | Status - ${statusText}`
		} else {
			return `${moment().format('MMM Do YYYY')} | Status - Create New Entry`
		}
	}

	return (
		<div className={GeneralStyles.container}>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
				<PageHeader
					header={vehiclesState.maintenance_to_update ? `Update Maintenance Entry - ${vehiclesState.maintenance_to_update._id}` : `New Maintenance Entry`}
					subHeader={getSubHeader()}
				/>
				<div style={{position: 'absolute', right: 0, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<CustomButtonContained
						style={{marginRight: 2}}
						text={vehiclesState.maintenance_to_update ? `\u2713 Save Changes` : `\u2713 Add New Entry`}
						onClick={() => onAddEntryPressed(false)}/>
					<img
						onClick={onCloseAddNewPressed}
						src={BackArrow} style={{width: 40, height: 40, cursor: 'pointer'}}/>
				</div>
			</div>

			<PositionedSnackbar
				onClose={() => {
					setShowError(false)
					setShowSuccess(false)
					setErrorMessage('')
					setSuccessMessage('')
				}}
				severity={showError ? 'error' : 'success'}
				openFlag={showError || showSuccess}
				message={showError ? errorMessage : successMessage}
			/>

			{renderContent()}
			{renderDateSelectorModal()}
		</div>
	)
}

export default NewMaintenance
