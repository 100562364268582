import React, {useEffect, useState} from 'react'
import GeneralStyles from "../../routes/GeneralStyles.module.scss";
import CustomModal from "../../components/general/CustomModal";
import CustomTextFieldNew from "../general/CustomTextFieldNew";
import CustomButtonContained from "../general/CustomButtonContained";
import Helpers from "../../utils/helpers";
import Config from "../../config";
import {useSelector} from "react-redux";
import axios from "axios";
import CustomLoaderSpinner from "../general/CustomLoaderSpinner";
import Styles from './styles/Insurance.module.scss'
import Colors from "../../config/colors";
import moment from "moment";
import CheckMark from "../../components/general/CheckMark";

export default function InsuranceDetailsModal({modalIsOpen, onModalClose, customerInfo, showAxleModal}) {
	const token = useSelector(state => state.auth.token)

	const [axleDataPresent, setAxleDataPresent] = useState(false)
	const [email, setEmail] = useState('')
	const [showError, setShowError] = useState(false)
	const [errorTxt, setErrorTxt] = useState('')
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)
	const [insuranceData, setInsuranceData] = useState(null)
	const [selectedPolicy, setSelectedPolicy] = useState(null)


	useEffect(() => {
		setEmail(customerInfo?.email || '')
		if (!customerInfo?.axle?.access_token) {
			console.log('missing customer ID')
		} else {
			setAxleDataPresent(true)
			getAxleAccountDetails(customerInfo.axle)
		}

		// setAxleDataPresent(true)
	}, [customerInfo]);

	useEffect(() => {
		if (insuranceData && customerInfo?.axle?.access_token) {
			let userSelectedPolicy = customerInfo.axle.policies ? customerInfo.axle.policies[0] : null
			insuranceData.policies.every(policy => {
				if (policy.id === userSelectedPolicy) {
					console.log('user selected policy is', userSelectedPolicy)
					setSelectedPolicy(policy)
					return false
				}
				return true
			})
		}
	}, [insuranceData, customerInfo]);

	const getAxleAccountDetails = (axleData, axleClientId) => {
		setLoading(true)
		const config = {
			method: 'get',
			url: `${Config.BACKEND_URL}axle/accounts/${axleData.account_id}`,
			headers: {
				Authorization: token,
				axle_token: axleData.access_token
			},
		}
		axios(config)
		.then(response => {
			if (response.status === 200) {
				console.log('got data', response.data)
				setInsuranceData(response.data)
				setLoading(false)
			} else {
				setErrorTxt('Something went wrong when getting insurance details. If the problem persists, please contact support')
				setShowError(true)
				setLoading(false)
			}
		}).catch(err => {
			console.error('error when sending email', err)
			setErrorTxt('Something went wrong getting insurance details. Please contact support if the problem persists.')
			setShowError(true)
			setLoading(false)
		})
	}

	const onSendEmailClicked = () => {
		if (!email || !Helpers.validateEmail(email)) {
			setErrorTxt('Invalid email address')
			setShowError(true)
			return
		}

		if(!window.confirm(`Are you sure you want send insurance permission email to ${email}?`))
			return
		setLoading(true)


		const config = {
			method: 'post',
			url: `${Config.BACKEND_URL}axle/oauth/mail`,
			data: {
				clientId: customerInfo?._id,
				email: email
			},
			headers: {
				Authorization: token
			},
		}
		axios(config)
		.then(response => {
			if (response.status === 200) {
				setSuccess(true)
				setLoading(false)
			}
		}).catch(err => {
			console.error('error when sending email', err)
			setErrorTxt('Something went wrong when sending email, please contact support if the problem persists.')
			setShowError(true)
			setLoading(false)
		})
	}

	const getCoverageString = (coverage) => {
		switch (coverage.code) {
			case 'BI':
				return `Bodily Injury ($${Helpers.numFormatter(coverage.limitPerAccident)} / $${Helpers.numFormatter(coverage.limitPerPerson)})`
			case 'PD':
				return `Property Damage ($${Helpers.numFormatter(coverage.limitPerAccident)})`
			case 'UMBI':
				return `Uninsured Motorists Bodily Injury ($${Helpers.numFormatter(coverage.limitPerAccident)} / $${Helpers.numFormatter(coverage.limitPerPerson)})`
			case 'COMP':
				return `Comprehensive ($${Helpers.numFormatter(coverage.deductible)})`
			case 'COLL':
				return `Collision ($${Helpers.numFormatter(coverage.deductible)})`

		}
	}

	const renderPolicyInformation = () => {
		if (!selectedPolicy)
			return <p style={{color: Colors.secondaryTextColor}}>Please select a insurance policy from the dropdown</p>
		let insureds = selectedPolicy.insureds || []
		let insuredsValue = insureds.map((temp, index) => `${temp.firstName} ${temp.lastName}`)

		let properties = selectedPolicy.properties || []
		let propertyValues = properties.map(temp => {
			let {model, make, year} = temp.data
			return `${year} ${make} ${model}`
		})

		let coverages = selectedPolicy.coverages || []

		return (
			<div style={{width: '80%', paddingBottom: 100}}>
				<div className={Styles.row}>
					<label className={Styles.fieldName}>Account Linked</label>
					<label
						className={Styles.fieldValue}>{moment(customerInfo?.axle?.ignition_completed).format('MMM Do, YYYY, hh:mm A')}</label>
				</div>
				<div className={Styles.row}>
					<label className={Styles.fieldName}>Status</label>
					<label className={Styles.fieldValue}
								 style={{color: selectedPolicy?.isActive ? 'green' : 'red'}}>{selectedPolicy?.isActive ? 'Active' : 'Inactive'}</label>
				</div>
				<div className={Styles.row}>
					<label className={Styles.fieldName}>Carrier</label>
					<label className={Styles.fieldValue}>{selectedPolicy.carrier || ''}</label>
				</div>
				<div className={Styles.row}>
					<label className={Styles.fieldName}>Number</label>
					<label className={Styles.fieldValue}>{selectedPolicy.policyNumber || ''}</label>
				</div>
				<div className={Styles.row}>
					<label className={Styles.fieldName}>Effective</label>
					<label
						className={Styles.fieldValue}>{`${moment(selectedPolicy.effectiveDate).format('MMM Do, YYYY')} - ${moment(selectedPolicy.expirationDate).format('MMM Do, YYYY')}`}</label>
				</div>
				<div className={Styles.row}>
					<label className={Styles.fieldName}>Insureds</label>
					<label className={Styles.fieldValue}>{insuredsValue.join(' / ')}</label>
				</div>
				<div className={Styles.row}>
					<label className={Styles.fieldName}>Properties</label>
					<div style={{display: 'flex', flexDirection: 'column', width: '70%'}}>
						{
							propertyValues.map((temp, index) => {
								return (
									<label className={Styles.fieldValue} key={index.toString()} style={{width: '100%'}}>{temp}</label>
								)
							})
						}
					</div>

				</div>
				<div className={Styles.row}>
					<label className={Styles.fieldName}>Coverages</label>
					<div style={{display: 'flex', flexDirection: 'column', width: '70%'}}>
						{
							coverages.map((temp, index) => {
								return (
									<label className={Styles.fieldValue} key={index.toString()}
												 style={{width: '100%'}}>{getCoverageString(temp)}</label>
								)
							})
						}
					</div>
				</div>
				<div className={Styles.row}>
					<label className={Styles.fieldName}>Documents</label>
					<div style={{display: 'flex', flexDirection: 'column', width: '70%'}}>
						{
							selectedPolicy.documents?.length > 0 ?
								<label
									onClick={() => {
										showAxleModal(selectedPolicy.documents.map(doc => doc.url))
									}}
									className={Styles.fieldValue} style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>Show
									Documents</label>
								:
								<label className={Styles.fieldValue} style={{width: '100%'}}>No Documents</label>
						}
					</div>
				</div>
			</div>
		)
	}

	const renderInsuranceInformation = () => {
		if (loading) {
			return (
				<div
					style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50}}>
					<CustomLoaderSpinner/>
				</div>
			)
		}
		if (!insuranceData) {
			return (
				<div
					style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50}}>
					<p style={{color: Colors.darkTextColor, width: '100%', fontSize: 12, textAlign: 'center'}}>No insurance
						information</p>
				</div>
			)
		}

		return (
			<div style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				overflowY: 'scroll',
				paddingBottom: 30,
			}}>
				{
					showError &&
					<p style={{color: 'red', width: '100%', fontSize: 12, textAlign: 'center'}}>{errorTxt}</p>
				}

				{/*<div*/}
				{/*	className={GeneralStyles.boxShadow}*/}
				{/*	style={{width: '80%', height: 40, borderRadius: 10, marginTop: 20}}>*/}
				{/*	<CustomSelect*/}
				{/*		placeholder={'select policy'}*/}
				{/*		borderColor={'transparent'}*/}
				{/*		value={selectedPolicy}*/}
				{/*		options={options}*/}
				{/*		onChange={setSelectedPolicy}*/}
				{/*	/>*/}
				{/*</div>*/}
				<p className={GeneralStyles.darkText}
					 style={{fontSize: 20}}>{`${insuranceData.firstName} ${insuranceData.lastName}`}</p>
				{renderPolicyInformation()}
				<CustomButtonContained
					text={'Resend Insurance Email'}
					onClick={() => setAxleDataPresent(false)}
					style={{position: 'absolute', bottom: 10}}
					color={'primary'}
				/>

			</div>
		)
	}


	const renderAuthScreen = () => {
		if (loading) {
			return (
				<div
					style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50}}>
					<CustomLoaderSpinner/>
				</div>
			)
		}

		if (success) {
			return (
				<div
					style={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						width: '100%',
					}}>
					<CheckMark/>
					<label className={GeneralStyles.darkText} style={{width: '100%', textAlign: 'center'}}>Successfully sent the
						insurance request email.</label>
				</div>
			)
		}

		return (
			<div style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				position: 'relative',
			}}>
				<label className={[GeneralStyles.darkText]}
							 style={{fontSize: 18, width: '80%', textAlign: 'center', marginTop: 40}}>Insurance
					data
					unavailable. Send the permission request email by clicking on the button below.</label>
				<CustomTextFieldNew
					marginTop={20}
					width={'80%'}
					label={'Email Address'}
					placeholder={'enter email address'}
					value={email}
					onChange={setEmail}
				/>
				{
					showError &&
					<p style={{color: 'red', width: '100%', fontSize: 12, textAlign: 'center'}}>{errorTxt}</p>
				}
				<CustomButtonContained
					text={'Send Email'}
					onClick={onSendEmailClicked}
					style={{position: 'absolute', bottom: 10}}
					color={'primary'}
				/>
			</div>
		)
	}

	const renderContent = () => {
		return (
			<div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
				<label className={GeneralStyles.darkText} style={{fontWeight: 600, fontSize: 20, marginTop: 20}}>
					Insurance Information
				</label>
				{
					axleDataPresent ?
						renderInsuranceInformation()
						:
						renderAuthScreen()
				}
			</div>
		)
	}

	return (
		<CustomModal
			show={modalIsOpen}
			handleClose={onModalClose}
			containerWidth={window.innerWidth / 2}
			containerHeight={window.innerHeight / 1.5}
		>
			{renderContent()}
		</CustomModal>
	)
}
