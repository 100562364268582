import React, {useEffect, useState} from 'react'
import GeneralStyles from "../../routes/GeneralStyles.module.scss";
import CustomModal from "../../components/general/CustomModal";

export default function DocsViewModal({modalIsOpen, onModalClose, urls}) {
    const [index, setIndex] = useState(0)
    const [length, setLength] = useState(0)
    const [temp, setTemp] = useState([])

    useEffect(() => {
        if (!urls || urls.length === 0) {
            window.alert('Something went wrong, please contact support')
            return
        }
        setTemp(urls)
        setLength(urls.length)
    }, [urls]);

    const renderContent = () => {
        return (
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                position: 'relative'
            }}>
                <p
                    onClick={onModalClose}
                    style={{
                        border: `1px solid gray`,
                        cursor: 'pointer',
                        padding: 4,
                        fontSize: 20,
                        position: 'absolute',
                        right: 5,
                        top: 0
                    }}>X</p>
                <label className={GeneralStyles.darkText} style={{fontWeight: 600, fontSize: 20, marginTop: 20}}>
                    Documents
                </label>
                <div style={{
                    width: '50%',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly'
                }}>
                    <label
                        onClick={() => {
                            if (index > 0)
                                setIndex(index - 1)
                        }}
                        style={{
                            color: index > 0 ? 'blue' : 'gray',
                            cursor: 'pointer',
                            textDecoration: 'underline'
                        }}>{`<<prev`}</label>
                    <label className={GeneralStyles.darkText}>{`${index + 1}/${length} pages`}</label>
                    <label
                        onClick={() => {
                            if (index < length - 1)
                                setIndex(index + 1)
                        }}
                        style={{
                            color: index < length - 1 ? 'blue' : 'gray',
                            cursor: 'pointer',
                            textDecoration: 'underline'
                        }}>{`next>>`}</label>

                </div>
                <iframe height={'100%'} width={'99.5%'} src={temp[index]}/>

            </div>
        )
    }

    return (
        <CustomModal
            show={modalIsOpen}
            handleClose={onModalClose}
            containerWidth={window.innerWidth - 20}
            containerHeight={window.innerHeight - 20}
        >
            {renderContent()}
        </CustomModal>
    )
}