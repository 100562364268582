/* 
	this is to show the partner information page on the front-end
	it will include: 
	HEADING:-
		partner name
		partner sub-heading
		save changes button
		back button
	QUICK OVERVIEW:-
		active vehicles
		outstanding balance
		total partner profit
		total client profit ?
		total profit
	PARTNER INFORMATION:-
		partner name: first and last
		email
		phone
		Address
	VEHICLES:-
		Vehicles name
		share
		status (if they are active or not)
		agreement? 
	MONTHLY BREAKDOWN:- 
		month (month + year)
		revenue
		expense
		total profit
		partner profit
		status:-
			some docuemnt? 
			paid/unpaid
		forward arrow ?
*/
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import GeneralStyles from "../GeneralStyles.module.scss";
import Colors from "../../config/colors";

import { LOGGER } from "../../utils/Logger";
import { API_CALL_STATUS } from "../../metadata/enums";
import { fetchPartnerMonthlyBreakDown } from "../../redux/slices/partnerSplitSlice";


import BackArrow from "../../assets/logos/backArrow.svg";

// custom components : 
import MetricBox from "./components/MetricBox";
import CustomButtonContained from '../../components/general/CustomButtonContained'
import PageHeader from "../../components/general/PageHeader";
import RenderPartnerInfo from './components/PartnerInformation'
import RenderVehicleInfo from "./components/PartnerVehicleInfo";
import RenderMonthlyBreakDown from "./components/PartnerMonthlyBreakDown";
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";


const PartnerProfile = () => {
	let navigate = useNavigate()
	let dispatch = useDispatch()

	const partnersState = useSelector(state => state.partnerSplits)
	const token = useSelector(state => state.auth.token)

	const [loading, setLoading] = useState(true);
	// over view states: 
	const [activeVehicles, setActiveVehicles] = useState(0);
	const [outstandingBalance, setOutstandingBalance] = useState(0);
	const [totalPartnerProfit, setTotalPartnerProfit] = useState(0);
	const [totalCompanyProfit, setTotalCompanyProfit] = useState(0);
	const [totalprofit, setTotalProfit] = useState(0);

	const [partnerSplits, setPartnerSplits] = useState([]); // for vehicles, monthly breakdown & quickOverview
	const [partnerInformation, setPartnerInformation] = useState({
		firstName: '',
		lastName: '',
		phone: '',
		email: '',
		address: null
	}); // for info add and edit

	useEffect(() => {
		if (partnersState.status === API_CALL_STATUS.LOADING) {
			setLoading(true)
		} else if (partnersState.status === API_CALL_STATUS.FAILED) {
			setLoading(true)
			// setShowError(true)
			// setErrorMessage("something went wrong when getting the partners data")
			LOGGER.error('error effect', partnersState.error)
		} else if (partnersState.status === API_CALL_STATUS.SUCCEEDED) {
			if (!partnersState.partnerProfileData) {
				console.log('going back since no partner in data');
				navigate('/home/clients');
			}
			else {
				let data = partnersState.partnerProfileData;
				setPartnerSplits(data.split);
				setPartnerInformation({
					firstName: data.fName,
					lastName: data.lName,
					phone: data.phone,
					email: data.email,
					address: data.address
				})
			}
			setLoading(false)
		}
	}, [partnersState])
	useEffect(() => {
		if (partnerSplits?.length > 0) {
			let data = [...partnerSplits];
			dispatch(fetchPartnerMonthlyBreakDown({ token: token, split: data }));
		}
	}, [partnerSplits])

	const RenderQuickOverView = () => {
		return (
			<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', alignItems: 'center' }}>
				<MetricBox isMainPricing={false} mainText={activeVehicles} subText={'Active Vehicles'} />
				<MetricBox isMainPricing={true} mainText={outstandingBalance} subText={'OutStanding Balance'} />
				<MetricBox isMainPricing={true} mainText={totalPartnerProfit} subText={'Total Partner Profit'} />
				<MetricBox isMainPricing={true} mainText={totalCompanyProfit} subText={'Total Company Profit'} />
				<MetricBox isMainPricing={true} mainText={totalprofit} subText={'Total Profit'} />
			</div>
		)
	}
	const RenderHeading = () => {
		return (
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative' }}>
				<PageHeader header={`${partnerInformation.firstName} ${partnerInformation.lastName}`} subHeader={'PARTNER'} />
				{/* HEADING BUTTONS: */}
				<div style={{ position: 'absolute', right: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', top: 24 }}>
					<CustomButtonContained
						text={'\u2713 Save Changes'}
						onClick={() => { }}
						style={{ marginRight: 2 }}
						color={'primary'}
					/>
					<img
						onClick={() => {
							navigate(-1)
						}}
						src={BackArrow} style={{ width: 40, height: 40, cursor: 'pointer' }} />
				</div>
			</div>
		)
	}

	if (loading) {
		return (
			<div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: Colors.contentBackgroundColor, width: '100%' }}>
				<CustomLoaderSpinner />
			</div>
		)
	}
	return (
		<div className={GeneralStyles.container} style={{ overflowY: 'scroll', height: '98vh' }}>
			{/* HEADING: */}
			<RenderHeading />
			{!loading &&
				<div style={{ width: '100%' }}>
					{/* QUICK OVERVIEW */}
					<RenderQuickOverView />
					{/* PATNER INFO & VEHICLE INFO */}
					<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', alignItems: 'normal' }}>
						<RenderPartnerInfo partnerInformation={partnerInformation} setPartnerInformation={setPartnerInformation} />
						<RenderVehicleInfo partnerSplits={partnerSplits} setActiveVehicles={setActiveVehicles} parentLoading={loading} />
					</div>
					{/* RENDER THE MONTHLY BREAK-DOWN */}
					<RenderMonthlyBreakDown setTotalCompanyProfit={setTotalCompanyProfit} setTotalPartnerProfit={setTotalPartnerProfit} setTotalProfit={setTotalProfit} />
				</div>
			}
		</div>

	)
}
export default PartnerProfile