import React from 'react'
import Button from '@mui/material/Button';
import colors from '../../config/colors'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: colors.buttonTheme,
			dark: '#5481CB66',
			contrastText: '#fff',
		},
		secondary: {
			main: '#ffffff00',
			dark: '#ffffff66',
			contrastText: '#fff',
			borderColor: '#fff'
		},
		tertiary: {
			main: colors.buttonThemeLight,
			dark: '#5481CB66',
			contrastText: '#000',
			borderColor: '#fff'
		},
		white: {
			main: colors.primaryTextColor,
			dark: '#5481CB66',
			contrastText: '#000',
		}
	},
});

function CustomButtonContained({text, onClick, style, color, textPadding, borderColor, textColor}) {

	const getButtonColor = () => {
		switch(color) {
			case 'primary': return colors.buttonTheme
			case 'secondary': return '#ffffff00'
			case 'tertiary': return colors.buttonThemeLight
			default: return colors.buttonTheme
		}
	}

	const getTextColor = () => {
		if(textColor)
			return textColor

		if(color === 'secondary')
			return colors.themeLight
		else
			return colors.tertiaryTextColor
	}

	// return (
	// 	<div style={[
	// 		{padding: '6px 30px', backgroundColor: getButtonColor(), display: 'flex', alignItems: 'center', justifyContent: 'center'}
	// 		, style
	// 	]}>
	// 		<p style={{color: color === 'primary' ? colors.darkTextColor : colors.primaryTextColor}}>{text}</p>
	// 	</div>
	// )

	return (
		<ThemeProvider theme={theme}>
			<Button
				color={color || "primary"}
				sx={[style,{borderRadius: 3, border: 1, borderColor: borderColor || 'transparent'}]}
				onClick={onClick}
				variant='contained'>
				<span style={{
					padding: textPadding || '6px 30px',
					textTransform: 'none',
					color: getTextColor()}}
				>
					{text}
				</span>
			</Button>
		</ThemeProvider>
	)
}

export default CustomButtonContained
