import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function PositionedSnackbar({openFlag,onClose, message, severity}) {
	const [state, setState] = React.useState({
		vertical: 'top',
		horizontal: 'center',
	});

	const { vertical, horizontal} = state;

	return (
		<div>
			<Snackbar
				autoHideDuration={3000}
				anchorOrigin={{ vertical, horizontal }}
				open={openFlag}
				onClose={onClose}
				key={vertical + horizontal}
			>
				<Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
					{message}
				</Alert>
			</Snackbar>
		</div>
	);
}
