import React, { useState, useEffect } from 'react'
import Colors from '../../config/colors'
import { Document, Page, PDFViewer, StyleSheet, Text, View, Image, Tspan } from "@react-pdf/renderer";
import CarImage from '../../assets/images/car.png'
import Logo from '../../assets/images/new_logo.png'
import TNCStrings from '../../metadata/TNC'
import Helpers from '../../utils/helpers'
import CalendarIcon from '../../assets/logos/calendar.png'
import PinIcon from '../../assets/logos/pin.png'
import moment from 'moment'


const BACKGROUND_COLOR = '#5481CB'
const TEXT_COLOR = '#23395D'

const pdfStyles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		backgroundColor: 'white',
	},
	section: {
		padding: '10px 10px',
		flexDirection: 'column',
		color: TEXT_COLOR,
		height: '100%',
		width: '100%'
	},
	footer: {
		position: 'absolute',
		bottom: 10,
		left: 20,
		fontSize: 12
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	headerText: {
		fontSize: 14,
		fontWeight: 'bold',
		width: '100%',
		textAlign: 'center',
		padding: '6px 0px',
		color: 'white'
	},
	entryText: {
		fontSize: 12,
		width: '100%',
		textAlign: 'center',
		padding: '3px 0px'
	},
	tableCell: {
		height: '100%',
		borderRight: `0.5px solid #000000`,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	}
});



const PartnerSplitPdfTemplate = ({ selectedPartner, partnerSplitData, startDate, endDate }) => {

	let _totalExpense = 0;
	let _totalRevenu = 0;
	let _totalProfit = 0;
	let _totalCompaniesProfit = 0;
	let _totalPartnersProfit = 0;
	let _totalResesavations = 0;

	const renderFooter = () => {
		// return <Text style={pdfStyles.footer} fixed>©2023-2024 DDE Rentals, LLC</Text>
		return <Text style={[pdfStyles.footer, { color: 'white' }]} fixed>footer</Text>
	}
	const renderTableTotal = () => {
		// this is to render the total at the bottom of the table: 
		return (
			<View style={[pdfStyles.row, { border: '0.5px solid black', backgroundColor: 'gray' }]}>
				<View style={[pdfStyles.tableCell, { width: '40%' }]}>
					<Text style={pdfStyles.headerText}>Total</Text>
				</View>
				<View style={[pdfStyles.tableCell, { width: '10%' }]}>
					<Text style={pdfStyles.headerText}>{''}</Text>
				</View>
				<View style={[pdfStyles.tableCell, { width: '10%' }]}>
					<Text style={pdfStyles.headerText}>{_totalResesavations}</Text>
				</View>
				<View style={[pdfStyles.tableCell, { width: '13%' }]}>
					<Text style={pdfStyles.headerText}>$ {Number(_totalRevenu).toFixed(2)}</Text>
				</View>
				<View style={[pdfStyles.tableCell, { width: '13%' }]}>
					<Text style={pdfStyles.headerText}>$ {Number(_totalExpense.toFixed(2))}</Text>
				</View>
				<View style={[pdfStyles.tableCell, { width: '13%' }]}>
					<Text style={pdfStyles.headerText}>$ {Number(_totalProfit).toFixed(2)}</Text>
				</View>
				<View style={[pdfStyles.tableCell, { width: '13%' }]}>
					<Text style={pdfStyles.headerText}>$ {Number(_totalCompaniesProfit).toFixed(2)}</Text>
				</View>
				<View style={[pdfStyles.tableCell, { width: '13%', borderRightWidth: 0 }]}>
					<Text style={pdfStyles.headerText}>$ {Number(_totalPartnersProfit).toFixed(2)}</Text>
				</View>


			</View>
		)
	}
	const renderTableData = () => {
		// this is to render the data in the table: 
		if (!partnerSplitData) return null;
		let elements = []
		partnerSplitData.split.forEach((row) => {
			let revenue = row['revenue'] || 0
			let expense = row['expense'] || 0
			let totalReservations = row['total_reservations'] || 0
			let totalProfit = revenue - expense
			let PartnerProfit = (totalProfit / 100) * row['percentage'];
			let companyProfit = totalProfit - PartnerProfit;
			// getting totals : 
			_totalResesavations += totalReservations;
			_totalExpense += expense;
			_totalRevenu += revenue;
			_totalProfit += totalProfit;
			_totalCompaniesProfit += companyProfit;
			_totalPartnersProfit += PartnerProfit;


			elements.push(
				<View style={[pdfStyles.row, { border: '0.5px solid black' }]}>
					<View style={[pdfStyles.tableCell, { width: '40%' }]}>
						<Text style={pdfStyles.entryText}>{row?.Vehicle}</Text>
					</View>
					<View style={[pdfStyles.tableCell, { width: '10%' }]}>
						<Text style={pdfStyles.entryText}>{row?.percentage} %</Text>
					</View>
					<View style={[pdfStyles.tableCell, { width: '10%' }]}>
						<Text style={pdfStyles.entryText}>{totalReservations}</Text>
					</View>
					<View style={[pdfStyles.tableCell, { width: '13%' }]}>
						<Text style={pdfStyles.entryText}>$ {Number(revenue).toFixed(2)}</Text>
					</View>
					<View style={[pdfStyles.tableCell, { width: '13%' }]}>
						<Text style={pdfStyles.entryText}>$ {Number(expense).toFixed(2)}</Text>
					</View>
					<View style={[pdfStyles.tableCell, { width: '13%' }]}>
						<Text style={pdfStyles.entryText}>$ {Number(totalProfit).toFixed(2)}</Text>
					</View>
					<View style={[pdfStyles.tableCell, { width: '13%' }]}>
						<Text style={pdfStyles.entryText}>$ {Number(companyProfit).toFixed(2)}</Text>
					</View>
					<View style={[pdfStyles.tableCell, { width: '13%', borderRightWidth: 0 }]}>
						<Text style={pdfStyles.entryText}>$ {Number(PartnerProfit).toFixed(2)}</Text>
					</View>
				</View>
			)
		})
		return elements
	}
	const renderTableDate_inRange = (starting_index, ending_index) => {
		// this is a loop for the range of rows to add to the table: 
		// this is to render the data in the table: 
		if (!partnerSplitData) return null;


		let elements = []
		partnerSplitData.split.forEach((row, index) => {
			if (index >= starting_index && index < ending_index) {
				console.log('index', index);
				let revenue = row['revenue'] || 0
				let expense = row['expense'] || 0
				let totalReservations = row['total_reservations'] || 0
				let totalProfit = revenue - expense
				let PartnerProfit = (totalProfit / 100) * row['percentage'];
				let companyProfit = totalProfit - PartnerProfit;
				// getting totals : 
				_totalResesavations += totalReservations;
				_totalExpense += expense;
				_totalRevenu += revenue;
				_totalProfit += totalProfit;
				_totalCompaniesProfit += companyProfit;
				_totalPartnersProfit += PartnerProfit;

				elements.push(
					<View key={index} style={[pdfStyles.row, { border: '0.5px solid black' }]}>
						<View style={[pdfStyles.tableCell, { width: '40%' }]}>
							<Text style={pdfStyles.entryText}>{row?.Vehicle}</Text>
						</View>
						<View style={[pdfStyles.tableCell, { width: '10%' }]}>
							<Text style={pdfStyles.entryText}>{row?.percentage} %</Text>
						</View>
						<View style={[pdfStyles.tableCell, { width: '10%' }]}>
							<Text style={pdfStyles.entryText}>{totalReservations}</Text>
						</View>
						<View style={[pdfStyles.tableCell, { width: '13%' }]}>
							<Text style={pdfStyles.entryText}>$ {Number(revenue).toFixed(2)}</Text>
						</View>
						<View style={[pdfStyles.tableCell, { width: '13%' }]}>
							<Text style={pdfStyles.entryText}>$ {Number(expense).toFixed(2)}</Text>
						</View>
						<View style={[pdfStyles.tableCell, { width: '13%' }]}>
							<Text style={pdfStyles.entryText}>$ {Number(totalProfit).toFixed(2)}</Text>
						</View>
						<View style={[pdfStyles.tableCell, { width: '13%' }]}>
							<Text style={pdfStyles.entryText}>$ {Number(companyProfit).toFixed(2)}</Text>
						</View>
						<View style={[pdfStyles.tableCell, { width: '13%', borderRightWidth: 0 }]}>
							<Text style={pdfStyles.entryText}>$ {Number(PartnerProfit).toFixed(2)}</Text>
						</View>
					</View>
				)
			}
		})
		return elements
	}
	const renderTableHeader = () => {
		/* this is to display the table Heading colums */
		return (
			<View style={[pdfStyles.row, { border: '0.5px solid black', backgroundColor: 'gray' }]}>
				<View style={[pdfStyles.tableCell, { width: '40%' }]}>
					<Text style={pdfStyles.headerText}>Vehicle</Text>
				</View>
				<View style={[pdfStyles.tableCell, { width: '10%' }]}>
					<Text style={pdfStyles.headerText}>Partners Split</Text>
				</View>
				<View style={[pdfStyles.tableCell, { width: '10%' }]}>
					<Text style={pdfStyles.headerText}>Total Res</Text>
				</View>
				<View style={[pdfStyles.tableCell, { width: '13%' }]}>
					<Text style={pdfStyles.headerText}>Revenue</Text>
				</View>
				<View style={[pdfStyles.tableCell, { width: '13%' }]}>
					<Text style={pdfStyles.headerText}>Expense</Text>
				</View>
				<View style={[pdfStyles.tableCell, { width: '13%' }]}>
					<Text style={pdfStyles.headerText}>Profit</Text>
				</View>
				<View style={[pdfStyles.tableCell, { width: '13%' }]}>
					<Text style={pdfStyles.headerText}>Companies Profit</Text>
				</View>
				<View style={[pdfStyles.tableCell, { width: '13%', borderRightWidth: 0 }]}>
					<Text style={pdfStyles.headerText}>Partners Profit</Text>
				</View>
			</View>
		)

	}
	const renderHeader = () => {
		/* this is for the information at the top of the pdf */
		return (
			<View>
				<Text style={{ fontSize: 16, fontWeight: '600', width: '100%', marginBottom: 10 }}>PARTNER SPLIT BREAKDOWN</Text>
				<View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
					<Text style={{ fontSize: 14, fontWeight: '600' }}>DATE RANGE: </Text>
					<Text style={{ fontSize: 14, fontWeight: '600' }}>{moment(startDate).format('MM/DD/YY')} - {moment(endDate).format('MM/DD/YY')}</Text>
				</View>
				<View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: 5, marginBottom: 10 }}>
					<Text style={{ fontSize: 14, fontWeight: '600' }}>PARTNER NAME: </Text>
					<Text style={{ fontSize: 14, fontWeight: '600' }}>{`${selectedPartner}`}</Text>
				</View>
			</View>
		)
	}
	const renderPage = (row_per_page) => {
		// this is to render several pdf pages with a specific amount of rows
		let starting_index = 0;
		let ending_index = row_per_page;
		let element = [];

		// debugger
		for (let index = 0; index < partnerSplitData.split.length - 1; index++) {
			if (starting_index == 0) {
				console.log('first page');
				// this is the first page of the pdf
				element.push(
					<Page size="A4" style={pdfStyles.page} orientation={"landscape"}>
						<View style={pdfStyles.section}>
							{renderHeader()}
							{renderTableHeader()}
							{renderTableDate_inRange(starting_index, ending_index)}
						</View>
					</Page>
				)
				starting_index = starting_index + row_per_page;
				ending_index = ending_index + row_per_page;
			}
			else if (ending_index >= partnerSplitData.split.length - 1) {
				console.log('last page');
				// this is the last page of the pdf
				element.push(
					<Page size="A4" style={pdfStyles.page} orientation={"landscape"}>
						<View style={pdfStyles.section}>
							{renderTableHeader()}
							{renderTableDate_inRange(starting_index, partnerSplitData.split.length)}
							{renderTableTotal()}
						</View>
					</Page>
				)
				return element
			}
			else {
				console.log(`page no ${index}`);
				// this is the any of the middle pages of the pdf
				element.push(
					<Page size="A4" style={pdfStyles.page} orientation={"landscape"}>
						<View style={pdfStyles.section}>
							{renderTableHeader()}
							{renderTableDate_inRange(starting_index, ending_index)}
						</View>
					</Page>
				)
				starting_index = starting_index + row_per_page;
				ending_index = starting_index + row_per_page;
			}
		}
		console.log('starting_index', starting_index, 'ending_index', ending_index);

		return element

	}
	const renderMain = () => {
		// this is the main function to render all the data on the pdf
		// and to make sure that after 20 rows a new page is made
		const row_per_page = 20
		// -2 is for table header row and total row 

		// when the data is less than allowed rows:
		if (partnerSplitData?.split.length <= row_per_page) {

			return (
				<Document>
					<Page size="A4" style={pdfStyles.page} orientation={"landscape"}>
						<View style={pdfStyles.section}>
							{renderHeader()}
							{renderTableHeader()}
							{renderTableData()}
							{renderTableTotal()}
						</View>
					</Page>
				</Document>
			)
		}
		// data is more than the allowed rows per page: 
		return (
			<Document>
				{renderPage(row_per_page)}
			</Document>
		)
	}
	return (
		<PDFViewer style={{ height: '100%', width: '100%' }}>
			{renderMain()}
		</PDFViewer>
	)
}
export default PartnerSplitPdfTemplate
