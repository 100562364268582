import React, { useState, useEffect } from 'react'
import GeneralStyles from "../../GeneralStyles.module.scss";
import CustomButtonContained from "../../../components/general/CustomButtonContained";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPartnerSplits, fetchAllPartnerSplits_nameAndId } from "../../../redux/slices/partnerSplitSlice"
import { useNavigate } from 'react-router-dom'
import { API_CALL_STATUS } from "../../../metadata/enums";
import PositionedSnackbar from "../../../components/general/PositionedSnackbar";
import { LOGGER } from "../../../utils/Logger";
import Styles from ".././styles/Analytics.module.scss";
import Colors from "../../../config/colors";
import CustomLoaderSpinner from "../../../components/general/CustomLoaderSpinner";
import CustomSelect from "../../../components/general/CustomSelect";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CustomModal from "../../../components/general/CustomModal";
import { DateRangePicker } from "react-date-range";
import Helpers from '../../../utils/helpers'
import { changeUserAccess } from '../../../redux/slices/authSlice'
import DownloadIcon from '@mui/icons-material/Download';
import PartnerSplitPdfTemplate from '../../../components/general/PartnerSplitPdfTemplate';
import { clientsMapSelector } from "../../../redux/slices/clientsSlice";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { reservationsMapSelector, updateReservation } from "../../../redux/slices/reservationsSlice";


const PartnerSplit = () => {
	let navigate = useNavigate()
	const dispatch = useDispatch();

	const token = useSelector(state => state.auth.token)
	const mongoUser = useSelector(state => state.user.mongoUser)
	const settingsState = useSelector(state => state.settings)

	const [expandedVehicleId, setExpandedVehicleId] = useState(null)

	const reservationsMap = useSelector(state => reservationsMapSelector(state.reservations))
	// list of clients mapped to name: 
	const clientsMap = useSelector(state => clientsMapSelector(state.clients))
	// partner split states: 
	// the main partnerSplit
	const partnerSplitState = useSelector(state => state.partnerSplits)
	// partnerSplit array with all the data: 
	const partnerSplitsDATA = useSelector(state => state.partnerSplits.partnerSplit)
	// partnerSplit array with only the names and _ids
	const partnerSplits_nameAndIds = useSelector(state => state.partnerSplits.partnerSplit_nameAndId)


	const [errorMessage, setErrorMessage] = useState('')
	const [showError, setShowError] = useState(false)
	const [showDateSelector, setShowDateSelector] = useState(false)
	const [startDate, setStartDate] = useState(moment.utc().subtract(30, 'days').toDate())
	const [endDate, setEndDate] = useState(moment.utc().toDate())
	// partner split variables: 
	const [selectedPartner, setSelectedPartner] = useState(null)
	const [selectedRevenueType, setSelectedRevenueType] = useState(null)
	const [partnerSplit_loading, setPartnerSplit_loading] = useState(false)
	const [showPartnerSplitPdfModal, setShowPartnerSplitPdfModal] = useState(false)

	// revenu types:
	const revenueTypes = [
		// {label: 'Rental daily rate', value: 'rental daily rate'},
		{ label: 'Sales Tax', value: 'Sales Tax' },
		{ label: 'Service Fee', value: 'Service Fee' },
		{ label: 'Card Fee', value: 'Card Fee' },
		{ label: 'Mileage Packages', value: 'Mileage Packages' },// after discount
		{ label: 'Location Charge', value: 'Location Charge' }, // 
		// {label: 'Additional Mileage', value: 'Additional Mileage'},
	]
	//this is to check
	useEffect(() => {
		let rolesMap = Helpers.getRolesMap(settingsState)
		if (mongoUser
			&& Object.keys(rolesMap).length > 0
			&& !Helpers.checkAccess(mongoUser, rolesMap, 'view_analytics')) {
			dispatch(changeUserAccess(false))
			navigate(-1)
		}
	}, [mongoUser, settingsState])

	useEffect(() => {
		if (partnerSplitState.status === API_CALL_STATUS.LOADING) {
			setPartnerSplit_loading(true)
			console.log('partnerSplitState loading');
		}
		if (partnerSplitState.status === API_CALL_STATUS.FAILED) {
			setPartnerSplit_loading(false)
			setShowError(true)
			setErrorMessage('something went wrong when getting the Partners Split data')
			LOGGER.error('error effect', partnerSplitState.error)
			console.log('partnerSplitState failed');
		}
		if (partnerSplitState.status === API_CALL_STATUS.SUCCEEDED) {
			setPartnerSplit_loading(false)
			console.log('partnerSplitState passed');
		}

	}, [partnerSplitState])
	// getting partner names and ids: 
	useEffect(() => {
		if (token) {
			dispatch(fetchAllPartnerSplits_nameAndId({ token: token }))
		}
	}, [token])
	//  loading the partners data : 
	const getPartnerSplitData = () => {
		if (selectedPartner) {
			// converting selectedRevenueType to string array:
			let selected_type = ['Rental daily rate']
			selectedRevenueType?.forEach((selected) => {
				selected_type.push(selected.label)
			})
			dispatch(fetchAllPartnerSplits({
				token: token, data: {
					_id: selectedPartner.value, start_date: startDate.toISOString(), end_date: endDate.toISOString(), _revenueTypes: selected_type
				}
			}))
		}
	}
	// setting the loading data for the partner : 
	const onDateRangeSelectorClicked = () => {
		setShowDateSelector(true)
	}
	const handleDateSelect = (ranges) => {
		let { selection } = ranges

		setStartDate(selection.startDate)
		setEndDate(selection.endDate)
	}

	// accordionView for each vehicle:
	const handleExpandChange = (id) => {
		if (id === expandedVehicleId)
			setExpandedVehicleId(null)
		else
			setExpandedVehicleId(id)
	}
	const onReservationClicked = (id) => {
		if (!Helpers.checkAccess(mongoUser, Helpers.getRolesMap(settingsState), 'view_reservations')) {
			dispatch(changeUserAccess(false))
			return
		}

		let reservationToShow = reservationsMap[id]
		if (reservationToShow) {
			dispatch(updateReservation(reservationToShow))
			if (reservationToShow.isChauffeur) {
				navigate('/home/new-chauffeur')
			} else {
				navigate('/home/new-reservation')
			}
		}

	}
	const getAccordionVehicleRow = (vehicleData, vehicle, revenue, expense, totalReservations, _revenue, _expense, _totalProfit, _PartnerProfit, _companyProfit) => {
		let reservations = vehicleData.reservations || []
		let maintenances = vehicleData.maintenances || []
		let expenses = vehicleData.expenses || []

		let expenseEntries = expenses.map((expense, index) => {
			let { created_at, amount, name, user } = expense
			return (
				<div key={index.toString()} style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, justifyContent: 'space-between' }}>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>{name}</label>
					<label className={GeneralStyles.darkText} style={{ width: '15%' }}>{user}</label>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>{moment(created_at).format('MM/DD/YY hh:mm A')}</label>
					<label className={GeneralStyles.darkText} style={{ width: '25%' }}>${Number(amount).toFixed(2)}</label>
				</div>
			)
		})

		let maintenanceEntries = maintenances.map((maintenance, index) => {
			let { start_date, end_date, cost } = maintenance
			return (
				<div key={index.toString()} style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, justifyContent: 'space-between' }}>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>{moment(start_date).format('MMMM Do, YYYY')}</label>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>{moment(end_date).format('MMMM Do, YYYY')}</label>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>{`$${Number(cost).toFixed(2)}`}</label>
				</div>
			)
		})

		let resEntries = reservations.map((res, index) => {
			let { pickup_datetime, dropoff_datetime, client, totalPriceWithEverything, _id } = res
			// debugger;
			let customer = clientsMap[client] || {}
			return (
				<div key={index.toString()} style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
					<label onClick={() => onReservationClicked(res._id)} className={GeneralStyles.darkText} style={{ width: '10%', textDecoration: 'underline', cursor: 'pointer' }}>Res #{_id}</label>
					<label className={GeneralStyles.darkText} style={{ width: '25%' }}>{`${customer?.fName} ${customer?.lName}`}</label>
					<label className={GeneralStyles.darkText} style={{ width: '25%' }}>{moment(pickup_datetime).format('MM/DD/YYYY')}</label>
					<label className={GeneralStyles.darkText} style={{ width: '25%' }}>{moment(dropoff_datetime).format('MM/DD/YYYY')}</label>
					<label className={GeneralStyles.darkText} style={{ width: '15%' }}>{`$${Number(totalPriceWithEverything)?.toFixed(2)}`}</label>
				</div>
			)
		})
		return (
			<Accordion
				sx={{ marginTop: 1, boxShadow: 0, width: '100%', cursor: 'pointer' }}
				expanded={expandedVehicleId === vehicle.Vehicle}
				onChange={() => handleExpandChange(vehicle.Vehicle)}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon style={{ color: Colors.primaryTextColor, display: 'none' }} />}
					aria-controls={`${vehicle.id} - details`}
					id={`${vehicle.id} - details`}
					sx={{ height: 50, display: 'flex', flexDirection: 'row', width: '100%', padding: 0, margin: 0 }}
				>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>{vehicle?.Vehicle}</label>
					<label className={GeneralStyles.darkText} style={{ width: '30%', marginLeft: 5 }}>{vehicle?.percentage} %</label>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>$ {Number(_revenue).toFixed(2)}</label>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>$ {Number(_expense).toFixed(2)}</label>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>$ {Number(_totalProfit).toFixed(2)}</label>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>{totalReservations}</label>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>$ {Number(_PartnerProfit).toFixed(2)}</label>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>$ {Number(_companyProfit).toFixed(2)}</label>


				</AccordionSummary>
				<AccordionDetails sx={{ width: '100%', boxShadow: 0, display: 'flex' }}>
					<div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
						{/* ALL RESERVATIONS */}
						<p className={GeneralStyles.darkText} style={{ fontSize: 16, fontWeight: 600, width: '100%', textAlign: 'center' }}>Reservations</p>
						{
							reservations.length > 0 ?
								<div style={{ width: '100%' }}>
									<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: `0.5px solid gray` }}>
										<label className={GeneralStyles.darkText} style={{ width: '10%', fontWeight: '600' }}>Res Id</label>
										<label className={GeneralStyles.darkText} style={{ width: '25%', fontWeight: '600' }}>Customer</label>
										<label className={GeneralStyles.darkText} style={{ width: '25%', fontWeight: '600' }}>Pick-Up</label>
										<label className={GeneralStyles.darkText} style={{ width: '25%', fontWeight: '600' }}>Drop-Off</label>
										<label className={GeneralStyles.darkText} style={{ width: '15%', fontWeight: '600' }}>Revenue</label>
									</div>
									{resEntries}
								</div>
								:
								<label className={GeneralStyles.darkText} style={{ width: '100%', textAlign: 'center' }}>No Reservations</label>
						}

						{/* ALL MAINTANICES */}
						<p className={GeneralStyles.darkText} style={{ fontSize: 16, fontWeight: 600, width: '100%', textAlign: 'center', marginTop: 20 }}>Maintenances</p>
						{
							maintenances.length > 0 ?
								<div style={{ width: '100%' }}>
									<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: `0.5px solid gray`, justifyContent: 'space-between' }}>
										<label className={GeneralStyles.darkText} style={{ width: '30%', fontWeight: '600' }}>Start Date</label>
										<label className={GeneralStyles.darkText} style={{ width: '30%', fontWeight: '600' }}>End Date</label>
										<label className={GeneralStyles.darkText} style={{ width: '30%', fontWeight: '600' }}>Total Cost</label>
									</div>
									{maintenanceEntries}
								</div>
								:
								<label className={GeneralStyles.darkText} style={{ width: '100%', textAlign: 'center' }}>No Maintenance Entries</label>
						}
						{/* ALL EXPENSES */}
						<p className={GeneralStyles.darkText} style={{ fontSize: 16, fontWeight: 600, width: '100%', textAlign: 'center', marginTop: 20 }}>Expense Entries</p>
						{
							expenses.length > 0 ?
								<div style={{ width: '100%' }}>
									<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: `0.5px solid gray`, justifyContent: 'space-between' }}>
										<label className={GeneralStyles.darkText} style={{ width: '30%', fontWeight: '600' }}>Expense Type</label>
										<label className={GeneralStyles.darkText} style={{ width: '15%', fontWeight: '600' }}>Created By</label>
										<label className={GeneralStyles.darkText} style={{ width: '30%', fontWeight: '600' }}>Date</label>
										<label className={GeneralStyles.darkText} style={{ width: '25%', fontWeight: '600' }}>Total Cost</label>
									</div>
									{expenseEntries}
								</div>
								:
								<label className={GeneralStyles.darkText} style={{ width: '100%', textAlign: 'center' }}>No Expense Entries</label>
						}
					</div>
				</AccordionDetails>
			</Accordion>
		)
	}


	const renderPartnerSplitTable = () => {
		// this is for rendering the partner in the table : 
		// rendering the selected partners data: 
		if (!partnerSplitsDATA || Object.keys(partnerSplitsDATA).length === 0) {
			console.log('Nothing to display');
			return (
				<div style={{ marginTop: 60 }}>
					<p>Nothing to display</p>
				</div>
			)
		}
		if (!selectedPartner) {
			// there is not partner selected to display: 
			return (
				<div style={{ marginTop: 60 }}>
					<p>Please select a partner first</p>
				</div>
			)
		}
		// showing the data for the selected partner: 
		let elements = []
		// getting the displaying the vehicle names and splits for the partner:
		// calculation FOR THE TOTAL:
		let totalRevenue = 0;
		let totalExpense = 0;
		let totalRes = 0;
		let totalPartnersProfit = 0;
		let totalCompanysProfit = 0;
		// making the rows:
		partnerSplitsDATA.split.forEach((vehicle, index) => {
			// calculation FOR THE ROWS: 
			let revenue = vehicle['revenue'] || 0
			let expense = vehicle['expense'] || 0
			let totalReservations = vehicle['total_reservations'] || 0
			let totalProfit = revenue - expense
			let PartnerProfit = (totalProfit / 100) * vehicle['percentage'];
			let companyProfit = totalProfit - PartnerProfit;
			totalRevenue += revenue;
			totalExpense += expense;
			totalRes += totalReservations;
			totalPartnersProfit += PartnerProfit;
			totalCompanysProfit += companyProfit;

			// making accordion for drop down
			elements.push(getAccordionVehicleRow(vehicle, vehicle, revenue, expense, totalReservations, revenue, expense, totalProfit, PartnerProfit, companyProfit));

			// debugger;
			/*
			elements.push((
				<div
					key={index}
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						width: '100%',
						height: 50,
						borderBottom: `0.5px solid ${Colors.secondaryTextColor}`
					}}>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>{vehicle?.Vehicle}</label>
					<label className={GeneralStyles.darkText} style={{ width: '30%', marginLeft: 5 }}>{vehicle?.percentage} %</label>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>$ {Number(revenue).toFixed(2)}</label>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>$ {Number(expense).toFixed(2)}</label>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>$ {Number(totalProfit).toFixed(2)}</label>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>{totalReservations}</label>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>$ {Number(PartnerProfit).toFixed(2)}</label>
					<label className={GeneralStyles.darkText} style={{ width: '30%' }}>$ {Number(companyProfit).toFixed(2)}</label>
				</div>
			))*/
		})
		return (
			<div style={{ width: '94%', height: 'auto' }}>
				{/* HEADING ROW */}
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
					<p style={{ fontWeight: 'bold', width: '30%' }}>Vehicle</p>
					<p style={{ fontWeight: 'bold', width: '30%', marginLeft: 5 }}>% to partner</p>
					<p style={{ fontWeight: 'bold', width: '30%' }}>Revenue</p>
					<p style={{ fontWeight: 'bold', width: '30%' }}>Expenses</p>
					<p style={{ fontWeight: 'bold', width: '30%' }}>Total Profit</p>
					<p style={{ fontWeight: 'bold', width: '30%' }}>Total Reservations</p>
					<p style={{ fontWeight: 'bold', width: '30%' }}>Partners Profit</p>
					<p style={{ fontWeight: 'bold', width: '30%' }}>Company Profit</p>
				</div>
				{/* ROWS */}
				{elements}
				{/* TOTAL ROW:  */}
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: 4 }}>
					<label className={GeneralStyles.darkText} style={{ fontWeight: 'bold', width: '30%' }}>TOTAL</label>
					<label className={GeneralStyles.darkText} style={{ fontWeight: 'bold', width: '30%' }}></label>
					<label className={GeneralStyles.darkText} style={{ fontWeight: 'bold', width: '30%', marginLeft: 5 }}>$ {Number(totalRevenue).toFixed(2)}</label>
					<label className={GeneralStyles.darkText} style={{ fontWeight: 'bold', width: '30%' }}>$ {Number(totalExpense).toFixed(2)}</label>
					<label className={GeneralStyles.darkText} style={{ fontWeight: 'bold', width: '30%' }}>$ {Number(totalRevenue - totalExpense).toFixed(2)}</label>
					<label className={GeneralStyles.darkText} style={{ fontWeight: 'bold', width: '30%' }}>{totalRes}</label>
					<label className={GeneralStyles.darkText} style={{ fontWeight: 'bold', width: '30%' }}>$ {Number(totalPartnersProfit).toFixed(2)}</label>
					<label className={GeneralStyles.darkText} style={{ fontWeight: 'bold', width: '30%' }}>$ {Number(totalCompanysProfit).toFixed(2)}</label>
				</div>
			</div>
		)
	}
	const renderDateSelectors = () => {
		return (
			<div
				className={Styles.dateRangeDisplayContainer}>
				<div
					className={GeneralStyles.boxShadow}
					onClick={onDateRangeSelectorClicked}
					style={{ display: 'flex', flexDirection: 'row', width: '45%', borderRadius: 5, alignItems: 'center', justifyContent: 'space-evenly', height: 40 }}>
					<p>{moment(startDate).format('dddd, MMM Do YYYY')}</p>
					<CalendarMonthIcon style={{ color: 'white' }} />
				</div>
				<p style={{ fontSize: 28 }}>
					{`\u2B64`}
				</p>
				<div
					className={GeneralStyles.boxShadow}
					onClick={onDateRangeSelectorClicked}
					style={{ display: 'flex', flexDirection: 'row', width: '45%', borderRadius: 5, alignItems: 'center', justifyContent: 'space-evenly', height: 40 }}>
					<p>{moment(endDate).format('dddd, MMM Do YYYY')}</p>
					<CalendarMonthIcon style={{ color: 'white' }} />
				</div>


			</div>
		)
	}
	const renderPartnerSplitFilters = () => {
		/* this is for: 
			partner name select
			dates select
			search button
			diffrent type of revenu selector */

		let partner_name_array = []
		if (partnerSplits_nameAndIds == []) {
			console.log('Error partnerSplit_nameAndId not loaded');
		}
		else {
			partnerSplits_nameAndIds.forEach((partner) => {
				partner_name_array.push({ label: `${partner.fName} ${partner.lName}`, value: partner._id })
			})
		}
		return (
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-evenly' }}>
				<div className={GeneralStyles.boxShadow} style={{ width: 225, minHeight: 40, borderRadius: 10, marginTop: 4, marginLeft: 40 }}>
					{/* partner select drop-down */}
					<CustomSelect
						isMulti={false}
						placeholder={'Select Partner'}
						borderColor={'transparent'}
						value={selectedPartner}
						options={partner_name_array}
						onChange={setSelectedPartner}
					/>
				</div>
				<div className={GeneralStyles.boxShadow} style={{ width: 225, minHeight: 40, borderRadius: 10, marginTop: 4, marginLeft: 5 }}>
					{/* Revenu type selector: */}
					<CustomSelect
						isMulti={true}
						placeholder={'Select Revenue'}
						borderColor={'transparent'}
						value={selectedRevenueType}
						options={revenueTypes}
						onChange={setSelectedRevenueType}
					/>
				</div>
				{/* date range selector */}
				{renderDateSelectors()}
				<div style={{ marginLeft: 5, marginRight: 5 }}>
					<CustomButtonContained
						text={'Search'}
						onClick={() => {
							console.log('hello');
							getPartnerSplitData()
						}}
						style={{ height: 34 }}
						textPadding={'4px 10px'}
					/>
				</div>
			</div>
		)
	}
	const renderPartnerSplits = () => {
		// this is for displaying seletected partner data:
		return (
			<div className={`${Styles.chartWrapper} ${GeneralStyles.boxShadow}`} style={{ marginTop: 40, height: 'auto', paddingBottom: 40 }}>
				<div style={{ display: 'flex', flexDirection: 'row', width: '96%', alignItems: 'center', justifyContent: 'space-between' }}>
					<p style={{ fontSize: 22, fontWeight: 'bold' }}>Partner Split</p>
					{
						partnerSplitsDATA && partnerSplitsDATA.length !== 0 &&
						<div
							onClick={() => setShowPartnerSplitPdfModal(true)}
							style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', alignItems: 'center' }}>
							<DownloadIcon size={20} style={{ color: Colors.primaryTextColor }} />
							<p style={{ fontSize: 14, marginLeft: 6 }}>Download</p>
						</div>
					}
				</div>
				{renderPartnerSplitFilters()}
				{
					partnerSplit_loading ?
						<div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
							<CustomLoaderSpinner />
						</div>
						:
						renderPartnerSplitTable()
				}
			</div>
		)
	}
	const renderDateSelectorModal = () => {
		const selectionRange = {
			startDate: startDate,
			endDate: endDate,
			key: 'selection',
		}
		return (
			<CustomModal show={showDateSelector} handleClose={() => setShowDateSelector(false)} containerWidth={window.innerWidth / 2}>
				<DateRangePicker
					rangeColors={[Colors.themeLight]}
					ranges={[selectionRange]}
					onChange={handleDateSelect}
				/>
			</CustomModal>
		)
	}
	const renderPartnerSpllitPdfModal = () => {
		return (
			<CustomModal
				show={showPartnerSplitPdfModal}
				handleClose={() => setShowPartnerSplitPdfModal(false)}
				containerWidth={window.innerWidth - 100}
				containerHeight={window.innerHeight - 100}
			>
				<PartnerSplitPdfTemplate
					selectedPartner={selectedPartner?.label}
					startDate={startDate}
					endDate={endDate}
					partnerSplitData={partnerSplitsDATA}
				/>
			</CustomModal>
		)
	}


	const renderContent = () => {

		return (
			<div >
				{renderPartnerSplits()}
				{renderPartnerSpllitPdfModal()}
				{renderDateSelectorModal()}
			</div>
		)
	}

	return (
		<div >
			<PositionedSnackbar
				onClose={() => {
					setShowError(false)
					setErrorMessage('')
				}}
				severity={'error'}
				openFlag={showError}
				message={errorMessage}
			/>

			{
				renderContent()
			}
		</div>
	)
}

export default PartnerSplit;